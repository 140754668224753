import { selector } from 'recoil';
import { TransactionType } from '../data-models/transaction-type.data-model';
import { transactionTypesAtom } from '../services/state/AppConfigStateJ';
import { getForesightStore } from './jotai-store';

export class TransactionTypeService {
  static #instance: TransactionTypeService | undefined;
  #transactionTypes: Map<number, TransactionType>;

  private constructor() {
    this.#transactionTypes = new Map<number, TransactionType>();
  }

  init(transactionTypes: TransactionType[]) {
    transactionTypes.forEach((transactionType) => {
      this.#transactionTypes.set(transactionType.id, transactionType);
    });
  }

  getTransactionType(transactionTypeId: number) {
    return this.#transactionTypes.get(transactionTypeId)?.name;
  }

  static initService(transactionTypes: TransactionType[]) {
    if (!this.#instance) {
      this.#instance = new TransactionTypeService();
      this.#instance.init(transactionTypes);
    }
  }

  static get() {
    if (!this.#instance) {
      throw new Error('Transaction Type service has not been initialized');
    }

    return this.#instance!;
  }

  static destroyService() {
    this.#instance = undefined;
  }
}

export const transactionTypeServiceState = selector<TransactionTypeService>({
  key: 'transactionTypeServiceState',
  get: () => {
    const store = getForesightStore();
    const transactionTypes = store.get(transactionTypesAtom);

    TransactionTypeService.destroyService();
    TransactionTypeService.initService(transactionTypes);

    return TransactionTypeService.get();
  },
});
