import { atom, selector } from 'recoil';
import { IFundDataModel } from '../../../data-models/fund.data-model';
import { fundsAtom } from '../../../services/state/AppConfigStateJ';
import { SelectItem } from '../../../types';
import { compareBySortOrder } from '../../../util/comparators';
import { getForesightStore } from '../../../util/jotai-store';
import { selectedViewPF } from './ViewState';

export const checkedFundsState = atom<IFundDataModel[] | null>({
  key: 'checkedFundsState',
  default: null,
});

export const activeFundsState = selector<IFundDataModel[] | null>({
  key: 'activeFundsState',
  get: ({ get }) => {
    const view = get(selectedViewPF);
    return view?.filteredFunds ?? null;
  },
});

export const areAllFundsSelectedState = selector<boolean>({
  key: 'areAllFundsSelectedState',
  get: ({ get }) => {
    const checkedFunds = get(checkedFundsState);
    const store = getForesightStore();
    const allFunds = store.get(fundsAtom);
    return !checkedFunds || checkedFunds.length === allFunds.length;
  },
});

export const areNoFundsSelectedState = selector<boolean>({
  key: 'areNoFundsSelectedState',
  get: ({ get }) => {
    const checkedFunds = get(checkedFundsState);
    return Boolean(checkedFunds) && checkedFunds?.length === 0;
  },
});

export const fundOptionsState = selector<SelectItem[]>({
  key: 'fundOptionsState',
  get: () => {
    const store = getForesightStore();
    const funds = store.get(fundsAtom);
    const sortedFunds = sortGroupedFunds(funds);
    return sortedFunds.map(fundToSelectItem) ?? [];
  },
});

export const selectedFundsAsOptionsPFState = selector<SelectItem[]>({
  key: 'selectedFundsAsOptionsPFState',
  get: ({ get }) => {
    const checkedFunds = get(checkedFundsState);
    const options = get(fundOptionsState);
    return checkedFunds?.map(fundToSelectItem) ?? options;
  },
});

export function sortGroupedFunds(funds: IFundDataModel[]) {
  return [...funds].sort(fundComparator);
}

export function fundToSelectItem(fund: IFundDataModel): SelectItem {
  return { id: fund.id, value: fund.name, group: fund.fundType };
}

export function fundComparator(a: IFundDataModel, b: IFundDataModel) {
  const aFundType = a.fundType || 'Other';
  const bFundType = b.fundType || 'Other';
  if (a.fundType === b.fundType) {
    return compareBySortOrder(a, b);
  }
  if (aFundType === 'Other') return 1;
  if (bFundType === 'Other') return -1;
  // since there is no sort order for fund types at the moment, sort by fund type name
  return a.fundType.localeCompare(b.fundType, 'en', { sensitivity: 'base' });
}
