import { styled } from '@mui/material/styles';
import { useAtomValue } from 'jotai/index';
import { useEffect, useState } from 'react';
import { UnknownUser } from '../../data-models/user.data-model';
import { ICommentDataModel } from '../../pages/DealFlow2/data-models/comment.data-model';
import { usersByEmailMapAtom } from '../../services/state/AppConfigStateJ';
import { Comment } from './Comment';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  max-height: 30rem;
  overflow-y: auto;
`;

interface ICommentListProps {
  comments: ICommentDataModel[];
  handleDelete: (commentId: number) => void;
  handleUpdate: (comment: string, commentId: number) => Promise<void>;
  isUpdatingComment?: boolean;
}

export function CommentList({
  comments,
  handleDelete,
  handleUpdate,
  isUpdatingComment = false,
}: ICommentListProps) {
  const [sortedData, setSortedData] = useState(comments);
  const usersByEmail = useAtomValue(usersByEmailMapAtom);

  useEffect(() => {
    if (!comments) return;
    const dataCopy = [...comments];
    dataCopy.sort(({ createdAt: a }, { createdAt: b }) => new Date(b).getTime() - new Date(a).getTime());
    setSortedData(dataCopy);
  }, [comments]);

  if (!sortedData?.length) return null;

  return (
    <Wrapper data-testid={'comment-list'}>
      {sortedData.map(({ createdBy, comment, createdAt, id, isLoading }) => {
        const user = usersByEmail.get(createdBy) ?? UnknownUser;
        return (
          <Comment
            authorFirstName={user.firstName}
            authorLastName={user.lastName}
            authorEmail={user?.email ?? ''}
            comment={comment}
            createdAt={new Date(createdAt)}
            key={id}
            id={id}
            isLoading={isLoading || isUpdatingComment}
            onDelete={handleDelete}
            onUpdate={handleUpdate}
          />
        );
      })}
    </Wrapper>
  );
}
