import { IFundDataModel } from '../../data-models/fund.data-model';
import { IInitializedFormatter } from './IInitializedFormatter';

export class FundFormatter implements IInitializedFormatter<Map<number, IFundDataModel>, number | number[]> {
  #funds: Map<number, IFundDataModel> = new Map();

  init(funds: Map<number, IFundDataModel>) {
    this.#funds = funds;
    return this;
  }

  format = (value: number | number[]) => {
    if (Array.isArray(value)) {
      return value.map(this.#format).join(', ');
    }
    return this.#format(value);
  };

  #format = (value: number) => {
    return this.#funds.get(value)?.name ?? `Unknown fund (id ${value.toString()})`;
  };
}

export const NoGroupFundLabel = 'Other';
