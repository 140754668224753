import { useState, useEffect, useCallback, useMemo } from 'react';
import { UseFormTrigger, FieldValues } from 'react-hook-form';
import { BlockerFunction } from 'react-router';

export interface IKpiTemplateFormNavBlocker {
  onSave: () => Promise<void>;
  isDirty: boolean;
  trigger: UseFormTrigger<FieldValues>;
}

export function useKpiTemplateFormNavBlocker({ onSave, isDirty, trigger }: IKpiTemplateFormNavBlocker) {
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    trigger().then((valid) => setIsValid(valid));
  }, [trigger]);

  const shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => {
      return (
        currentLocation.pathname !== nextLocation.pathname && isDirty && !nextLocation.state?.isProgrammatic
      );
    },
    [isDirty]
  );

  const onSaveAndProceed = useCallback(async () => {
    setLoading(true);
    await onSave();
    setLoading(false);
  }, [onSave]);

  const doBeforeProceed = useMemo(() => {
    return !isValid
      ? undefined
      : {
          onClick: onSaveAndProceed,
          label: 'Save and Exit',
          loading,
        };
  }, [isValid, onSaveAndProceed, loading]);

  return { shouldBlock, doBeforeProceed };
}
