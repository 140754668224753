import { styled } from '@mui/material/styles';
import ContentLoader from 'react-content-loader';
import { useRef } from 'react';
import { useContainerDimensions } from '../../../../../hooks/useContainerDimensions';

const SkeletonLoaderWrapper = styled('div')`
  width: 100%;
  height: 368px; // chart height
`;

const CHART_GAP = 16;
const N_CHARTS = 4;

export function PerfChartsSkeletonLoader() {
  // FIXME: https://foresightdata.atlassian.net/browse/MAGGIE-8620
  // eslint-disable-next-line
  const containerRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line
  const { width } = useContainerDimensions(containerRef);
  const chartWidth = (width - CHART_GAP * (N_CHARTS - 1)) / 4;

  const chartRects = [];
  for (let i = 0; i < 4; i++) {
    chartRects.push(
      <rect
        key={i}
        x={i * chartWidth + i * CHART_GAP}
        y='0'
        rx='0'
        ry='0'
        width={`${chartWidth}px`}
        height='100%'
        fill='#EEEFF1'
      />
    );
  }

  return (
    <SkeletonLoaderWrapper ref={containerRef}>
      <ContentLoader speed={2} width={'100%'} height={'100%'}>
        <>{chartRects}</>
      </ContentLoader>
    </SkeletonLoaderWrapper>
  );
}
