import { endOfYear } from 'date-fns';
import { KPIRequestFrequency } from '../../data-models/kpi-requests.data-model';
import { ICustomDateFormatConfig, IFormatterDataModel } from '../../data-models/formatter.data-model';
import { FMT } from '../formatter-service';
import { extractISODate } from '../date-utilts';

export const DateFormattersId = {
  YYYY: 'YYYY',
  YY: 'YY',
  'MMM yyyy': 'monthYear',
  date: 'date',
  dateLocal: 'dateLocal',
  dateNumeric: 'dateNumeric',
  dateShort: 'dateShort',
  dayMonth: 'dayMonth',
  monthLong: 'monthLong',
  monthYear: 'monthYear',
  monthYearLong: 'monthYearLong',
  time: 'time', // hh:mm:ss
  time12hr: 'time12hr',
  timeLocal: 'timeLocal',
} as const;
export type DateFormattersId = (typeof DateFormattersId)[keyof typeof DateFormattersId];

export const DateFormattersConfig: Record<DateFormattersId, IFormatterDataModel<ICustomDateFormatConfig>> = {
  YYYY: {
    config: { timeZone: 'UTC', year: 'numeric' },
    id: 'YYYY',
    type: 'date',
  },
  YY: {
    config: { timeZone: 'UTC', year: '2-digit' },
    id: 'yy',
    type: 'date',
  },
  date: {
    config: {
      timeZone: 'UTC',
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    },
    id: 'date',
    type: 'date',
  },
  dateLocal: {
    config: {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    },
    id: 'dateLocal',
    type: 'date',
  },
  dateNumeric: {
    config: { timeZone: 'UTC', year: 'numeric', month: '2-digit', day: '2-digit' },
    id: 'dateNumeric',
    type: 'date',
  },
  dateShort: {
    config: {
      timeZone: 'UTC',
      month: 'short',
      day: '2-digit',
    },
    id: 'dateShort',
    type: 'date',
  },
  dayMonth: {
    config: {
      timeZone: 'UTC',
      day: 'numeric',
      month: 'short',
    },
    id: 'dayMonth',
    type: 'date',
  },
  monthLong: {
    config: {
      timeZone: 'UTC',
      month: 'long',
    },
    id: 'monthLong',
    type: 'date',
  },
  monthYear: {
    config: { timeZone: 'UTC', month: 'short', year: 'numeric' },
    id: 'monthYear',
    type: 'date',
  },
  monthYearLong: {
    config: {
      timeZone: 'UTC',
      month: 'long',
      year: 'numeric',
    },
    id: 'monthYearLong',
    type: 'date',
  },
  time: {
    config: {
      timeZone: 'UTC',
      timeStyle: 'medium',
      hour12: false,
    },
    id: 'time',
    type: 'date',
  },
  time12hr: {
    config: {
      timeZone: 'UTC',
      timeStyle: 'medium',
      hour12: true,
    },
    id: 'time12hr',
    type: 'date',
  },
  timeLocal: {
    config: {
      timeStyle: 'medium',
      hour12: true,
    },
    id: 'timeLocal',
    type: 'date',
  },
};

/** @deprecated use {@link FormatterService.format} directly instead. */
export function formatMonthLong(date: string | Date) {
  return FMT.format('monthLong', date);
}

/** @deprecated use {@link FormatterService.format} directly instead. */
export function formatISODateOnly(date: string | Date) {
  if (typeof date === 'string') {
    return extractISODate(date);
  }
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
}

/** @deprecated use {@link FormatterService.format} directly instead. */
export function formatDateYYYY(dateAsISOString: string) {
  return FMT.format('YYYY', dateAsISOString);
}

/** @deprecated use {@link FormatterService.format} directly instead. */
export function formatTime(dateAsISOString: string) {
  return FMT.format('time', new Date(dateAsISOString));
}

/** @deprecated use {@link FormatterService.format} directly instead. */
export function formatDateNumeric(dateAsISOString: string | Date) {
  return FMT.format('dateNumeric', dateAsISOString);
}

/** @deprecated use {@link FormatterService.format} directly instead. */
export function formatDate(dateAsISOString?: string | null) {
  return FMT.format('date', dateAsISOString);
}

/** @deprecated use {@link FormatterService.format} directly instead. */
export function formatDateShort(dateAsISOString: string | Date) {
  return FMT.format('dateShort', dateAsISOString);
}

/** @deprecated use {@link FormatterService.format} directly instead. */
export function formatMonthYear(date: string | Date) {
  return FMT.format('monthYear', date);
}

/** @deprecated use {@link FormatterService.format} directly instead. */
export function formatMonthYearLong(date: string) {
  return FMT.format('monthYearLong', date);
}

export const getDaysBetween = (date1String: string, date2String: string) => {
  const d1: Date = new Date(date1String);
  const d2: Date = new Date(date2String);
  return Math.round((d1.getTime() - d2.getTime()) / (1000 * 3600 * 24));
};

export function fiscalDateFormatter(
  frequency: KPIRequestFrequency,
  periodISODate: string,
  fyeISODate?: string
): string {
  switch (frequency) {
    case KPIRequestFrequency.Monthly:
      return formatMonthYear(periodISODate);

    case KPIRequestFrequency.Quarterly: {
      const fyeDate = fyeISODate ? new Date(fyeISODate) : endOfYear(new Date());
      const period = new Date(periodISODate);
      const fyeMonth = fyeDate.getMonth();
      const { fiscalQuarter, fiscalYear } = getFiscalQuarterAndYear(period, fyeMonth);

      if (fyeMonth === 11) {
        return `Q${fiscalQuarter} ${fiscalYear}`;
      } else {
        const nextYear = (fiscalYear + 1).toString().substring(2, 4);

        return `Q${fiscalQuarter} ${fiscalYear}-${nextYear}`;
      }
    }
    case KPIRequestFrequency.Annual: {
      const fyeDate = fyeISODate ? new Date(fyeISODate) : endOfYear(new Date());
      const period = new Date(periodISODate);
      const fyeMonth = fyeDate.getMonth();
      const { fiscalYear } = getFiscalQuarterAndYear(period, fyeMonth);

      if (fyeMonth === 11) {
        return period.getFullYear().toString();
      } else {
        return `${fiscalYear}-${(fiscalYear + 1).toString().substring(2, 4)}`;
      }
    }

    case KPIRequestFrequency.None:
    default:
      return '';
  }
}

export function getFiscalQuarterAndYear(
  period: Date,
  fyeMonth = 11
): { fiscalQuarter: number; fiscalYear: number } {
  const month = period.getMonth();
  const year = period.getFullYear();

  if (fyeMonth === 11) {
    return {
      fiscalQuarter: Math.ceil((month + 1) / 3),
      fiscalYear: year,
    };
  }
  if (month <= fyeMonth) {
    return {
      fiscalQuarter: Math.ceil((month + 12 - fyeMonth) / 3),
      fiscalYear: year - 1,
    };
  }
  if (month > fyeMonth) {
    return {
      fiscalQuarter: Math.ceil((month - fyeMonth) / 3),
      fiscalYear: year,
    };
  }

  throw new Error(`Invalid month / fyeMonth provided; [args: period: ${period}; fyeMonth: ${fyeMonth}]`);
}
