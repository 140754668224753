import { compareDesc } from 'date-fns';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { roundsByIdMapAtom } from '../../../services/state/AppConfigStateJ';
import { companyMetricsByIdState } from '../../../services/state/CompanyMetricsByIdState';
import { latestFundingsByCompanyState } from '../../CompanyProfiles/state/CompanyFundingsState';
import { roundsByCompanyIdState } from '../../Finance2/state/FinanceState';

export function useMergedDigestData(company: ICompanyDataModel) {
  const rounds = useRecoilValue(roundsByCompanyIdState(company.id));
  const latestRoundData = useMemo(
    () => [...(rounds ?? [])].sort((a, b) => compareDesc(new Date(a.roundDate), new Date(b.roundDate)))?.[0],
    [rounds]
  );
  const metrics = useRecoilValue(companyMetricsByIdState(company.id));
  const lastFundingsData = useRecoilValue(latestFundingsByCompanyState(company.id));
  const roundsMap = useAtomValue(roundsByIdMapAtom);

  const result = { ...company };
  if (company?.companyType === 'portfolio') {
    result.lastPostMoney = metrics?.metrics?.[0]?.lastPostMoneyRound ?? null;
    result.latestFundingRound = roundsMap.get(latestRoundData?.roundId ?? -1)?.name ?? '';
    result.latestFundingAmount = latestRoundData?.raiseAmount ?? null;
    result.latestLeadInvestor = latestRoundData?.leadInvestors ?? [];
    result.latestFundingDate = latestRoundData?.roundDate ?? null;
    result.raisedToDate = metrics?.included?.[0]?.raisedToDate ?? null;
  } else {
    result.lastPostMoney =
      lastFundingsData?.valuationInMillions != null ? lastFundingsData.valuationInMillions * 1_000_000 : null;
  }
  return result;
}
