import { useAtomValue } from 'jotai';
import { capitalize } from 'lodash-es';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { ICompanyDataModel } from '../../../../../data-models/company.data-model';
import { sectorsByIdMapAtom, usersByIdMapAtom } from '../../../../../services/state/AppConfigStateJ';
import { formatDateNumeric } from '../../../../../util/formatters/DateFormatters';
import { useGetCompanyIfSet } from '../../../hooks/useGetCompanyData';
import { activeCompanyListIdState } from '../../../state/ListsState';
import { filteredAndSortedListsState } from '../../state/FiltersState';

export function useGenerateCompanyRows() {
  const activeListId = useRecoilValue(activeCompanyListIdState);
  const sortedItemsList = useRecoilValue(filteredAndSortedListsState(activeListId ?? -1));
  const usersMap = useAtomValue(usersByIdMapAtom);
  const sectorsMap = useAtomValue(sectorsByIdMapAtom);
  const getCompany = useGetCompanyIfSet();

  return useCallback(() => {
    const companiesRows = [] as Record<string, string>[];
    sortedItemsList?.forEach((item) => {
      // Get most updated company values
      const itemCompany = getCompany(item.companyId);

      const selectedItemDetailsSector = sectorsMap.get(itemCompany?.sectorId ?? -1);

      const owner = usersMap.get(itemCompany?.assignee ?? -1)?.name ?? '';
      const sector = selectedItemDetailsSector?.name ?? '';

      const { actionType, createdAt: addedDate, updatedAt: actionDate } = item;

      const { name: companyName, website } = itemCompany as ICompanyDataModel;

      companiesRows.push({
        companyName,
        website: website ?? '',
        sector,
        addedDate: formatDateNumeric(addedDate),
        action: capitalize(actionType ?? ''),
        actionDate: actionDate ? formatDateNumeric(actionDate) : '',
        owner: owner,
      });
    });

    return companiesRows;
  }, [getCompany, sortedItemsList, usersMap, sectorsMap]);
}
