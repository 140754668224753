import { css } from '@emotion/react';
import { Tooltip, Typography } from '@mui/material';
import { useMemo } from 'react';
import { colors } from '../../../theme/colors';
import { FMT } from '../../../util/formatter-service';
import { DistributionSegment, WaterfallGridData } from './FPWaterfallData';
import { getVisibleColumnWidths } from './utils';

const DistributionGrid = css`
  padding: 0.25rem 0.1rem;
  display: grid;
  grid-template-columns: auto 1fr;
  row-gap: 0.35rem;
  column-gap: 0.5rem;
  &&&& .MuiTypography-root {
    font-size: 0.75rem;
  }
`;
const DistributionRow = css`
  display: grid;
  grid-template-columns: subgrid;
  grid-column: 1 / -1;
`;

const Segment = css`
  height: 1.25rem;
  outline: 1px solid ${colors.primary[0]};
  width: 100%;
  display: grid;
  place-items: center;
  font-size: 0.6rem;
  filter: brightness(1);
  &.actionable:hover {
    filter: brightness(0.95);
  }
  transition: filter 0.3s;
`;
const Bar = css`
  display: grid;
`;

export function DistributionBar({ data }: { data: WaterfallGridData }) {
  const { visualization: segments } = data;
  const nonZeroSegments = useMemo(() => segments?.filter((s) => s.percentValue > 0) ?? [], [segments]);
  const gridTemplateColumns = useMemo(() => {
    const colWidths = getVisibleColumnWidths(nonZeroSegments);
    return colWidths.map((w) => `${w}%`).join(' ') + ' 1fr';
  }, [nonZeroSegments]);

  const hasPortionData = data.lpPortion || data.gpPortion;

  const bars = (
    <div css={Bar} style={{ gridTemplateColumns }}>
      {nonZeroSegments.map((segment, index) => {
        const roundedValue = FMT.format('percent2dpAsIs', Math.round(segment.percentValue * 100));
        const bar = (
          <div
            key={index}
            css={Segment}
            className={hasPortionData ? 'actionable' : ''}
            style={{
              backgroundColor: getSegmentColor(segment),
            }}
          >
            {segment.percentValue >= 0.1 && segment.highlight && roundedValue}
          </div>
        );

        return bar;
      })}
      <div css={Segment} style={{ backgroundColor: colors.secondary[30] }}></div>
    </div>
  );

  if (hasPortionData) {
    return (
      <Tooltip
        title={<LpGpPortions data={data} />}
        slotProps={{ popper: { sx: { minWidth: 'fit-content' } } }}
        placement='top'
      >
        {bars}
      </Tooltip>
    );
  }
  return bars;
}

function getSegmentColor(segment: DistributionSegment) {
  const { highlight, type } = segment;
  if (!highlight) return colors.neutral[10];
  return type === 'lp' ? colors.primary[20] : colors.tertiary[30];
}

export function LpGpPortions({ data }: { data: WaterfallGridData }) {
  const { lpPortion, gpPortion } = data;

  if (lpPortion == null || gpPortion == null) return null;

  return (
    <Typography component='div' css={DistributionGrid} color='text.primary'>
      <div css={DistributionRow}>
        <Typography color={'text.secondary'}>LP Portion</Typography>
        <Typography style={{ justifySelf: 'right', marginLeft: '1rem' }}>
          {FMT.format('usd', lpPortion)}
        </Typography>
      </div>
      <div css={DistributionRow}>
        <Typography color={'text.secondary'}>GP Portion</Typography>
        <Typography style={{ justifySelf: 'right', marginLeft: '1rem' }}>
          {FMT.format('usd', gpPortion)}
        </Typography>
      </div>
    </Typography>
  );
}
