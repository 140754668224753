import { FC, useCallback, useMemo, SyntheticEvent } from 'react';
import { Tab, Tabs } from '@mui/material';
import { useLocation } from 'react-router';
import { useScenarioActions } from '../../hooks/useScenarioActions';
import { ROUTES } from '../../../../../constants/RouteNameMapping';
import { useValidateAndRun } from '../RunScenarioAction';

type ScenarioTab = 'settings' | 'results';

interface IScenarioTabsProps {
  onGoToSettings?: () => void;
}

export const ScenarioTabs: FC<IScenarioTabsProps> = ({ onGoToSettings }) => {
  const { gotoSettings } = useScenarioActions();
  const { pathname } = useLocation();
  const { handleValidateAndRun } = useValidateAndRun();

  const currentTab: ScenarioTab | undefined = useMemo(() => {
    if (pathname.includes(ROUTES.COMPANY_PROFILE_SCENARIO_RUN)) {
      return 'results';
    } else if (pathname.includes(ROUTES.COMPANY_PROFILE_SCENARIO_SETTINGS)) {
      return 'settings';
    }
  }, [pathname]);

  const handleChange = useCallback(
    async (event: SyntheticEvent, newValue: ScenarioTab) => {
      if (newValue === 'settings') {
        onGoToSettings?.();
        gotoSettings();
      } else if (newValue === 'results') {
        handleValidateAndRun();
      }
    },
    [gotoSettings, handleValidateAndRun, onGoToSettings]
  );

  return (
    <Tabs value={currentTab} onChange={handleChange} sx={{ mb: '1rem' }}>
      <Tab value='settings' label='Settings' />
      <Tab value='results' label='Results' />
    </Tabs>
  );
};
