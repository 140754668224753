// FIXME: Remove istanbul and add test + https://foresightdata.atlassian.net/browse/MAGGIE-4306
import { useRecoilValueLoadable } from 'recoil';
import { useLocation, useMatch, useNavigate } from 'react-router';
import { useCallback, useMemo, useState } from 'react';
import { Typography, useTheme } from '@mui/material';
import { ROUTES } from '../../../../../constants/RouteNameMapping';

import { createKPITemplateDataModel, IKPITemplate } from '../../../../../data-models/kpi-template.data-model';
import { ConfirmDeletionDialog } from '../../../../Dialog/ConfirmDialog/ConfirmDeletionDialog';

import PlusIconButton from '../../../../IconButton/PlusIconButton';
import { SubSectionNavigation } from '../../SubSectionNavigation/SubSectionNavigation';
import { kpiTemplatesListState } from '../../../../../services/state/KPI/KPITemplatesState';
import { useKPITemplateActions } from '../../../../../pages/KPI/hooks/useKPITemplateActions';
import KPITemplatesNavItems from './KPITemplatesNavItems';

export const KPITemplatesSubNavigation = () => {
  const { colors } = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isNewTemplate = useMatch(`/${ROUTES.KPI}/${ROUTES.KPI_TEMPLATES}/new`);

  const templateList = useRecoilValueLoadable(kpiTemplatesListState).valueMaybe();
  const visibleTemplates = useMemo(() => templateList?.filter((t) => !t.isDeleted), [templateList]);
  const { deleteKPITemplateAction, createKPITemplateAction } = useKPITemplateActions();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const selectedTemplateId = useMemo(() => pathname.split('/')?.pop(), [pathname]);

  const handleNavigateNewTemplate = useCallback(() => {
    navigate(`/${ROUTES.KPI}/${ROUTES.KPI_TEMPLATES}/new`);
  }, [navigate]);

  const templatesListWithNewOne = useMemo(() => {
    if (!visibleTemplates) return null;
    const newTemplate = isNewTemplate ? createKPITemplateDataModel({ id: -1, name: 'New Template' }) : null;

    const templates = isNewTemplate ? [newTemplate, ...visibleTemplates] : visibleTemplates;

    return templates;
  }, [isNewTemplate, visibleTemplates]);

  const selectedTemplate = useMemo(() => {
    if (!selectedTemplateId) return null;
    const template = templatesListWithNewOne?.find((t) => String(t?.id) === selectedTemplateId);
    return template;
  }, [selectedTemplateId, templatesListWithNewOne]);

  const onDuplicateTemplate = useCallback(async () => {
    if (!selectedTemplate) return;

    const kpiTemplate = await createKPITemplateAction({
      ...selectedTemplate,
      name: `${selectedTemplate.name} (Copy)`,
    });

    if (!kpiTemplate?.id) return;

    return navigate(`/${ROUTES.KPI_TEMPLATES_FULL_ROUTE}/${kpiTemplate.id}`);
  }, [createKPITemplateAction, navigate, selectedTemplate]);

  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const handleOnDeleteTemplate = useCallback(async () => {
    if (!selectedTemplate) return;
    await deleteKPITemplateAction({
      ...selectedTemplate,
    });
    handleCloseDeleteModal();
    handleNavigateNewTemplate();
  }, [deleteKPITemplateAction, handleNavigateNewTemplate, selectedTemplate]);

  if (!templatesListWithNewOne) return null;

  return (
    <>
      <ConfirmDeletionDialog
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        onConfirm={handleOnDeleteTemplate}
        title={`Delete template "${selectedTemplate?.name}"?`}
      >
        <Typography variant='body2' color={colors.neutral[60]} sx={{ pt: '.25rem', minWidth: '30rem' }}>
          Deleting this template will also remove the assignment for all companies it has been attached to,
          which will cause any “To Send” and “In Progress” requests to be removed. You will still be able to
          access submitted responses.
        </Typography>
      </ConfirmDeletionDialog>
      <SubSectionNavigation
        title='Templates'
        RightSlot={
          <PlusIconButton sx={{ color: colors.secondary[20] }} onClick={handleNavigateNewTemplate}>
            Add New
          </PlusIconButton>
        }
      />
      <KPITemplatesNavItems
        selectedSection={selectedTemplateId ?? ''}
        sectionList={templatesListWithNewOne as IKPITemplate[]}
        parentRoute={ROUTES.KPI_TEMPLATES_FULL_ROUTE}
        onOpenConfirmDeleteModal={() => {
          setOpenDeleteModal(true);
        }}
        onDuplicateTemplate={onDuplicateTemplate}
      />
    </>
  );
};
