import { FC } from 'react';
import { Tab, Tabs, Typography } from '@mui/material';
import { NavLink, Outlet, useLocation } from 'react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ROUTES, ROUTE_TITLE_MAPPING } from '../../../../constants/RouteNameMapping';
import { GenericFallbacksWrapper } from '../../../../components/Fallback/GenericFallbacksWrapper';
import { MaggieFeatureFlags } from '../../../../util/feature-flags';

const tabs = [
  {
    path: `/${ROUTES.FINANCE2}/${ROUTES.FINANCE_RECON}`,
    title: ROUTE_TITLE_MAPPING[ROUTES.FINANCE_RECON_ROUNDS],
  },
  {
    path: `/${ROUTES.FINANCE2}/${ROUTES.FINANCE_RECON}/${ROUTES.FINANCE_RECON_METRICS}`,
    title: ROUTE_TITLE_MAPPING[ROUTES.FINANCE_RECON_METRICS],
  },
];

export const ReconSection: FC = () => {
  const { pathname } = useLocation();
  const { showMetricsRecon } = useFlags<MaggieFeatureFlags>();

  return (
    <Typography component={'section'} color='text.primary' mt='1rem'>
      {showMetricsRecon ? (
        <Tabs variant='scrollable' scrollButtons='auto' value={pathname} style={{ marginBottom: '1rem' }}>
          {tabs.map((t) => (
            <Tab
              component={NavLink}
              key={t.path}
              value={t.path}
              label={t.title}
              style={{ paddingLeft: 0, marginRight: '1rem' }}
              to={{ pathname: t.path }}
            />
          ))}
        </Tabs>
      ) : (
        <Typography component='h3'>Rounds</Typography>
      )}
      <GenericFallbacksWrapper>
        <Outlet />
      </GenericFallbacksWrapper>
    </Typography>
  );
};
