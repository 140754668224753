import { IUserDataModel, UnknownUser } from '../../data-models/user.data-model';
import { usersByIdMapAtom } from '../../services/state/AppConfigStateJ';
import { getForesightStore } from '../../util/jotai-store';
import { AvatarSize, MUIAvatar } from '../Avatar/MUIAvatar';

interface IUserAvatarProps {
  user: IUserDataModel | number;
  size?: AvatarSize;
}

export const UserAvatar = ({ user, size }: IUserAvatarProps) => {
  const usersById = getForesightStore().get(usersByIdMapAtom);
  const userModel = typeof user === 'number' ? (usersById.get(user) ?? UnknownUser) : user;

  return <MUIAvatar size={size ?? 'small'} src={null} nameParts={userModel.name.split(' ') ?? []} />;
};
