import { useCallback, useMemo } from 'react';
import { useMatch, useNavigate } from 'react-router';
import { ROUTES } from '../../../../../../../constants/RouteNameMapping';
import { NavItemType } from '../../../SubSectionNavItems';

export function useSubNavItem({
  parentRoute,
  section,
}: {
  parentRoute?: ROUTES | string;
  section: NavItemType;
}) {
  const navigate = useNavigate();
  const navItemPath = section.id;
  const patternPath = useMemo(() => {
    // Check if it is a top level nav item
    if (!parentRoute) return `/${navItemPath}`;

    // Covers nested routes
    return `/${parentRoute}/${navItemPath}/*`;
  }, [parentRoute, navItemPath]);

  const isActive = Boolean(useMatch(patternPath));

  const handleOnClick = useCallback(() => {
    // Check if it is a top level nav item
    if (!parentRoute) return navigate(`/${navItemPath}`);

    navigate(`${parentRoute}/${navItemPath}`);
  }, [navigate, parentRoute, navItemPath]);

  return {
    handleOnClick,
    isActive,
  };
}
