import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { PropsWithChildren, useMemo } from 'react';
import { kpiConfigByDisplayNameMapState } from '../../../../../services/state/KPI/KPITemplatesState';
import { KpiSection } from '../../../../../data-models/company-financials.data-model';
import { MIXED_COLORS } from '../../../../../components/Charts/MixedChart/MixedChart';
import { useProfileBasePath } from '../../../useProfileBasePath';
import { ROUTES } from '../../../../../constants/RouteNameMapping';
import { BasicDialog } from '../../../../../components/Dialog/BasicDialog';
import { selectedSummaryChartState } from '../../../state/UIState';
import { ChartPanel } from '../../../../CompanyProfiles2/Summary/ChartPanel';
import { IChartFieldParams, MixedPerfChart } from './MixedPerfChart';
import { ExpandedMixedChart } from './ExpandedPerfChart';

export function TotalRevenueChartTitle(props: PropsWithChildren) {
  const profileUrl = useProfileBasePath();
  const performanceUrl = `${profileUrl}/${ROUTES.COMPANY_PROFILE_PERFORMANCE}`;
  const setSelectedChart = useSetRecoilState(selectedSummaryChartState);

  return (
    <ChartPanel
      title={'Total Revenue'}
      showMore={{ url: performanceUrl, title: 'Go to Performance' }}
      style={{ gap: 0 }}
      onExpand={() => setSelectedChart('ttrv')}
    >
      {props.children}
    </ChartPanel>
  );
}

export function TotalRevenueChart() {
  const fields = useTotalRevenueChartFields();
  const [selectedChart, setSelectedChart] = useRecoilState(selectedSummaryChartState);
  const open = selectedChart === 'ttrv';
  return (
    <>
      {open ? (
        <BasicDialog
          open={open}
          titleComponent={'Total Revenue'}
          lightDismiss
          onClose={() => setSelectedChart(null)}
        >
          {open ? <ExpandedMixedChart kpis={fields} /> : <></>}
        </BasicDialog>
      ) : (
        <></>
      )}
      <MixedPerfChart
        fields={fields}
        title={'Total Revenue'}
        staticOptions={{
          yAxis: { splitNumber: 2, axisLabel: { fontSize: 9 } },
          tooltip: {
            confine: true,
          },
        }}
      />
    </>
  );
}

export function useTotalRevenueChartFields() {
  const kpisByName = useRecoilValue(kpiConfigByDisplayNameMapState);
  const totalRevenue = kpisByName.get('Total Revenue')!;
  const grossMargin = kpisByName.get('Gross Margin')!;

  return useMemo(() => {
    if (!totalRevenue) return [];
    const res: IChartFieldParams[] = [
      {
        field: totalRevenue,
        kpiSection: KpiSection.actual,
        options: {
          color: MIXED_COLORS[0],
          barGap: 0,
          emphasis: {
            focus: 'series',
          },
          name: 'Actual',
          showBackground: false,
          type: 'bar',
        },
      },
      {
        field: totalRevenue,
        kpiSection: KpiSection.budget,
        options: {
          color: MIXED_COLORS[1],
          barGap: 0,
          emphasis: {
            focus: 'series',
          },
          name: 'Budget',
          showBackground: false,
          type: 'bar',
        },
      },
    ] as IChartFieldParams[];
    if (grossMargin) {
      res.push({
        field: grossMargin,
        kpiSection: KpiSection.actual,
        options: {
          color: MIXED_COLORS[2],
          barGap: 0,
          emphasis: {
            focus: 'series',
          },
          name: 'Gross Margin',
          showBackground: false,
          type: 'line',
          yAxisIndex: 1,
          lineStyle: {
            type: 'dashed',
          },
        },
      });
    }
    return res;
  }, [totalRevenue, grossMargin]);
}
