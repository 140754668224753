import { Stack } from '@mui/material';
import { GetRowIdParams, GridApi, GridReadyEvent } from 'ag-grid-community';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { AgTable } from '../../../../components/AgTable/AgTable';
import { FadeInGridWrapper } from '../../../../components/grid-renderers/FadeInGridWrapper';
import { kpiTemplateAssignmentsListState } from '../../../../services/state/KPI/KPICompaniesState';
import { useExtendedExportSettings } from '../../../../components/AgTable/exportToExcelDefs';
import { ExportButtonContainer } from '../KPIRequests/components/KpiStyleDefs';
import { ExportGridButton } from '../../../../components/AgTable/ExportGridButton';
import { AssignModal } from './AllCompaniesForms/AssignModal';
import { CreateRequestModal } from './AllCompaniesForms/CreateRequestModal';
import { DeleteAssignmentModal } from './AllCompaniesForms/DeleteAssignmentModal';
import { useAllKpiCompaniesActions } from './AllCompaniesForms/useAllKpiCompaniesActions';
import { allCompaniesActionState } from './AllCompaniesState';
import { defaultColDef, defaultColGroupDef, useAssignedCompaniesColDefs } from './AssignedCompaniesColDefs';
import { IAssignedRowData, useAssignedCompaniesData } from './AssignedCompaniesData';
import { useAssignedFilter } from './hooks/useAssignedFilter';
import { CompanyColId } from './hooks/useKPICompaniesAllColDefs';

function AssignedCompaniesModals() {
  const actionState = useRecoilValue(allCompaniesActionState);
  if (!actionState) return null;
  return (
    <>
      {actionState === 'createRequest' && <CreateRequestModal />}
      {actionState === 'deleteAssignment' && <DeleteAssignmentModal />}
      {actionState === 'editAssignment' && <AssignModal />}
    </>
  );
}

function AssignedCompaniesContent() {
  return (
    <>
      <AssignedCompaniesModals />
      <AssignedCompaniesGrid />
    </>
  );
}

// prevents rerendering of the grid when the modals are opened
function ResetStateWrapper({ children }: PropsWithChildren) {
  const { resetUIState } = useAllKpiCompaniesActions();

  useEffect(() => {
    return resetUIState;
  }, [resetUIState]);

  return <>{children}</>;
}

export function AssignedCompanies() {
  const templates = useRecoilValue(kpiTemplateAssignmentsListState);

  if (!templates) {
    return null;
  }

  return (
    <ResetStateWrapper>
      <AssignedCompaniesContent />
    </ResetStateWrapper>
  );
}

export function AssignedCompaniesGrid() {
  const colDefs = useAssignedCompaniesColDefs();
  const rowData = useAssignedCompaniesData();
  const { exportParams } = useExtendedExportSettings(
    new Set([CompanyColId, 'company.sectorId', 'templateUuid', 'frequency']),
    `assigned companies`
  );

  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const { setGridFilter } = useAssignedFilter();
  const onGridReady = useCallback((e: GridReadyEvent) => {
    setGridApi(e.api);
  }, []);

  useEffect(() => {
    setGridFilter(gridApi);
  }, [gridApi, setGridFilter]);

  const getRowId = useCallback(
    (params: GetRowIdParams<IAssignedRowData>) =>
      !params.data ? '' : `${params.data.id}-${params.data.company?.id}`,
    []
  );

  const handleExport = useCallback(() => {
    gridApi?.exportDataAsExcel();
  }, [gridApi]);

  return (
    <Stack height={'100%'}>
      <div css={ExportButtonContainer}>
        <ExportGridButton onClick={handleExport} />
      </div>
      <FadeInGridWrapper>
        <AgTable
          rowData={rowData}
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          rowGroupPanelShow='always'
          groupDefaultExpanded={-1}
          suppressMovableColumns={true}
          suppressRowDrag={true}
          onGridReady={onGridReady}
          getRowId={getRowId}
          defaultCsvExportParams={exportParams}
          defaultExcelExportParams={exportParams}
          autoGroupColumnDef={defaultColGroupDef}
        />
      </FadeInGridWrapper>
    </Stack>
  );
}
