import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ROUTES } from '../../../constants/RouteNameMapping';
import { SelectedCompanyParam, SelectedTransactionParam } from '../finance-constants';
import { ITransactionDataModel } from '../../../data-models/transaction.data-model';

export function useTransactionHistoryAction() {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToTransactionHistory = useCallback(
    (transaction: ITransactionDataModel) => {
      const companyId = transaction.companyId;
      const to = location.pathname.includes(ROUTES.FINANCE_TRANSACTIONS)
        ? ROUTES.FINANCE_TRANSACTION_HISTORY
        : `${ROUTES.FINANCE_DETAILS}/${ROUTES.FINANCE_TRANSACTIONS}/${ROUTES.FINANCE_TRANSACTION_HISTORY}`;
      navigate(`${to}?${SelectedCompanyParam}=${companyId}&${SelectedTransactionParam}=${transaction.id}`);
    },
    [location.pathname, navigate]
  );

  return { navigateToTransactionHistory };
}
