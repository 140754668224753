import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { IconButton, Stack, Typography } from '@mui/material';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { useCallback, useState } from 'react';
import { Link } from 'react-router';
import { AgTable } from '../../../../../components/AgTable/AgTable';
import { ExportGridButton } from '../../../../../components/AgTable/ExportGridButton';
import { useExtendedExportSettings } from '../../../../../components/AgTable/exportToExcelDefs';
import { FadeInGridWrapper } from '../../../../../components/grid-renderers/FadeInGridWrapper';
import { useResponseAnalysisDefs } from './responseAnalysisDefs';

export function ResponseAnalysis() {
  return (
    <>
      <Stack direction='row' gap={'0.2rem'}>
        <IconButton component={Link} to={`..`}>
          <ArrowBackIosIcon color='secondary' />
        </IconButton>
        <Typography variant='h4'>Response Analysis</Typography>
      </Stack>
      <ResponseAnalysisGrid />
    </>
  );
}

function ResponseAnalysisGrid() {
  const { colDefs, rowData } = useResponseAnalysisDefs();
  const { exportParams } = useExtendedExportSettings(new Set(['companyId', 'templateUuid', 'respondent']));
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const onGridReady = useCallback((e: GridReadyEvent) => {
    setGridApi(e.api);
  }, []);
  return (
    <Stack display='grid' alignItems='start'>
      <Stack display={'grid'} alignItems={'center'} justifyContent={'end'} height='fit-content'>
        <ExportGridButton
          onClick={() => {
            gridApi?.exportDataAsExcel();
          }}
        />
      </Stack>
      <FadeInGridWrapper>
        <Stack height='80dvh'>
          <AgTable
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            defaultExcelExportParams={exportParams}
            defaultCsvExportParams={exportParams}
            onGridReady={onGridReady}
            rowData={rowData}
            rowGroupPanelShow='never'
          />
        </Stack>
      </FadeInGridWrapper>
    </Stack>
  );
}

const defaultColDef = {
  flex: 1,
  minWidth: 200,
};
