import { styled, TextField, TextFieldProps, useTheme } from '@mui/material';
import { useAtomValue } from 'jotai/index';
import { get } from 'lodash-es';
import { forwardRef, useCallback, useMemo } from 'react';
import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { KpiSection } from '../../../../../../data-models/company-financials.data-model';
import { IField } from '../../../../../../data-models/field2.data-model';
import { currencyMapByIdAtom } from '../../../../../../services/state/AppConfigStateJ';
import { Formatting } from '../../../../../../types';
import { getMetricUnit } from '../../../../../../util/field-utils';
import { MetricNameCell } from '../../../KPIMetricsNameCell';
import { IKPITableProps } from './KPITable';

export const KPITableEditableMetrics = ({
  sectionRef,
  sectionFieldRef,
  metrics,
}: Partial<IKPITableProps>) => {
  const { watch, setValue } = useFormContext();
  const currencyByIdMap = useAtomValue(currencyMapByIdAtom);

  const metricsRef = `${sectionRef}.meta.metrics`;
  const selectedCurrencyId = watch(`kpiData.currencyId`);
  const kpiSection = watch(`${sectionRef}.meta.section`);

  const selectedCurrency = useMemo(
    () => currencyByIdMap.get(selectedCurrencyId ?? 1),
    [selectedCurrencyId, currencyByIdMap]
  );

  const onUpdateLastFocusedMetricCeil = useCallback(
    (metricParentRowIdx: number, metricCeilColIdx: number) => {
      setValue('lastFocusedMetric', {
        metricParentRowIdx,
        metricCeilColIdx,
        focused: true,
      });
    },
    [setValue]
  );

  return (
    <>
      {metrics!.map((metric: IField<unknown>, index: number) => {
        const metricRef = `${metricsRef}.${index}`;
        const formatterType = watch(`${metricRef}.formMeta.formatter.type`);

        const prefix = formatterType === Formatting.Currency ? selectedCurrency?.symbol : '';
        const suffix = formatterType !== Formatting.Currency ? getMetricUnit(formatterType) : '';

        const metricRefField = `${sectionFieldRef}.${index}`;

        return (
          <tr key={metric.id}>
            <td
              style={{
                padding: '0.5rem 1.25rem',
              }}
            >
              <MetricNameCell editMode metric={metric} />
            </td>
            <EditableMetrics
              onUpdateLastFocusedMetricCeil={(metricCeilColIdx) =>
                onUpdateLastFocusedMetricCeil(index, metricCeilColIdx)
              }
              metric={metric}
              metricRefField={metricRefField}
              prefix={prefix as string}
              suffix={suffix}
              kpiSection={kpiSection as KpiSection}
            />
          </tr>
        );
      })}
    </>
  );
};

type StyledTDProps = {
  hasError: boolean;
  isValidValue: boolean;
};

export const StyledTD = styled('td')<StyledTDProps>(({ theme: { colors }, hasError, isValidValue }) => ({
  backgroundColor: hasError ? colors.critical[10] : 'initial',
  '&:focus-within': {
    padding: '0.5rem 1.25rem',
    border: hasError
      ? `thin solid ${colors.critical[40]} !important`
      : isValidValue
        ? `thin solid ${colors.success[60]} !important`
        : `thin solid ${colors.neutral[20]}`,
  },
  '&:has(.MuiInputBase-root:hover)': {
    border: `thin solid ${colors.primary[100]}`,
  },
}));

const EditableMetrics = ({
  metricRefField,
  onUpdateLastFocusedMetricCeil,
  metric,
  prefix,
  suffix,
  kpiSection,
}: {
  metricRefField: string;
  metric: IField<unknown>;
  onUpdateLastFocusedMetricCeil: (metricCeilColIdx: number) => void;
  prefix: string;
  suffix: string;
  kpiSection: KpiSection;
}) => {
  const { setValue, watch } = useFormContext();
  const { errors } = useFormState();

  const metricInputs = watch(metricRefField) ?? [];
  const lastFocusedMetric = watch('lastFocusedMetric');

  const onBlurMetricCeil = useCallback(() => {
    setValue('lastFocusedMetric', {
      ...lastFocusedMetric,
      focused: false,
    });
  }, [lastFocusedMetric, setValue]);

  return (
    <>
      {metricInputs.map((_: unknown, cellIndex: number) => {
        const metricFieldRef = `${metricRefField}.${cellIndex}`;
        const metricValues = watch(metricFieldRef);
        const hasError = get(errors, metricFieldRef as string);

        return (
          <StyledTD
            hasError={!!hasError}
            isValidValue={!!metricValues.numberFormatValue}
            key={cellIndex}
            style={{
              padding: 0,
            }}
          >
            <Controller
              name={`${metricFieldRef}.numberFormatValue`}
              rules={{ required: false }}
              render={({ field: { onChange, onBlur, name, ref, value } }) => (
                <NumericFormat
                  name={name}
                  prefix={prefix}
                  suffix={suffix}
                  decimalScale={6}
                  thousandSeparator={true}
                  getInputRef={ref}
                  customInput={CustomReadWriteTextField}
                  onFocus={() => {
                    onUpdateLastFocusedMetricCeil(cellIndex);
                  }}
                  onBlur={() => {
                    onBlurMetricCeil();
                    onBlur();
                  }}
                  onChange={onChange}
                  onValueChange={(values) => {
                    setValue(metricFieldRef, {
                      ...metricValues,
                      kpiId: metric.id,
                      numberFormatValue: values.value,
                      value: values.floatValue ?? null,
                      section: kpiSection,
                    });
                  }}
                  value={value}
                  style={{
                    width: '100%',
                  }}
                />
              )}
            />
          </StyledTD>
        );
      })}
    </>
  );
};

export const CustomReadWriteTextField = forwardRef((props: Partial<Omit<TextFieldProps, 'variant'>>, ref) => {
  const { colors } = useTheme();

  return (
    <TextField
      inputRef={ref}
      {...props}
      dir='ltr'
      InputProps={{
        ...props.InputProps,
        sx: {
          margin: 0,
        },
      }}
      sx={{
        margin: 0,
        '& .MuiInputBase-input': {
          textAlign: 'right',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent !important',
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: colors.critical[40],
        },
      }}
    />
  );
});

CustomReadWriteTextField.displayName = 'CustomReadWriteTextField';
