import { FieldPath, UseFormReturn, useWatch } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAtomValue } from 'jotai';
import { createFormFundData, FormFundData, FundData } from '../../../schemas/FundData.schema';
import { IUpdateFundDataPayload } from '../../../services/queries/MaggieFundQueries';
import { fundDataSortedByDateDescStateFP } from '../state/FPState';
import { getPlainDateString } from '../../../services/queries/MaggieMetricsQueries';
import { dateFromTimelessString } from '../../../util/date-utilts';
import { useFundDataActions } from './useFundDataActions';

interface IFundFormActionParams {
  methods: UseFormReturn<FormFundData>;
  dataByDate: Map<string, FundData>;
  fundId: number;
}

interface ISubmitFundDataParams extends IFundFormActionParams {
  fundId: number;
}
export function useSubmitFundData({ methods, dataByDate, fundId }: ISubmitFundDataParams) {
  const {
    getValues,
    trigger,
    formState: { errors },
  } = methods;
  const { handleUpdateFundData, handleCreateFundData } = useFundDataActions();

  return useCallback(async () => {
    const formData = getValues();
    const isValid = await trigger();
    if (!isValid) {
      console.error(errors);
      return;
    }
    const dateStr = getTimelessDateString(formData.date);
    const payload = { ...formData, fundId, date: dateStr };
    const current = dataByDate.get(dateStr);
    if (!current) {
      return await handleCreateFundData(payload as FundData);
    } else {
      return await handleUpdateFundData({ ...payload, id: current.id } as IUpdateFundDataPayload);
    }
  }, [getValues, trigger, fundId, dataByDate, handleCreateFundData, handleUpdateFundData, errors]);
}

const editableFields: FieldPath<FormFundData>[] = [
  'netAssets',
  'contributedSecurities',
  'deemedContributions',
];

export function useResetOnDateChange({ methods, dataByDate, fundId }: IFundFormActionParams) {
  const { control, setValue } = methods;
  const date = useWatch<FormFundData>({ control, name: 'date' });
  const [render, setRender] = useState(0);
  const sortedData = useAtomValue(fundDataSortedByDateDescStateFP(fundId));

  useEffect(() => {
    if (date && isValidDate(date)) {
      const initialValues = getInitialFundDataForDate(date as Date | string, dataByDate, sortedData);
      setRender((r) => r + 1);
      editableFields.forEach((field) => {
        setValue(field as FieldPath<FormFundData>, initialValues?.[field] ?? null, {
          shouldDirty: false,
          shouldTouch: false,
        });
      });
      setValue('fundId', fundId);
    }
  }, [dataByDate, date, fundId, setValue, sortedData]);

  return render;
}

export function useShouldDisableSubmit({ methods, dataByDate }: Omit<IFundFormActionParams, 'fundId'>) {
  const { control } = methods;
  const values = useWatch<FormFundData>({
    control,
  });

  return useMemo(() => {
    return shouldDisableSubmit({ methods, dataByDate, values });
  }, [dataByDate, methods, values]);
}

interface IShouldDisableSubmitParams extends Omit<IFundFormActionParams, 'fundId'> {
  values: Partial<FormFundData>;
}
export function shouldDisableSubmit({ methods, dataByDate, values }: IShouldDisableSubmitParams) {
  if (!methods.formState.isDirty || !values.date) {
    return true;
  }
  const dateStr = getTimelessDateString(values.date);
  const currentValues = dataByDate.get(dateStr);
  for (const field of editableFields) {
    if (!areFieldValuesEqual(currentValues?.[field], values[field])) return false;
  }
  return true;
}

function areFieldValuesEqual(a: unknown, b: unknown) {
  if ((a === undefined || a === null) && (b === undefined || b === null)) return true;
  return a === b;
}

function isValidDate(d: unknown) {
  return d instanceof Date && !isNaN(d.valueOf());
}

// Mimics what the BE is doing with existing fundData:
// if any value is missing, it will be filled with the most recent value
export function getInitialFundDataForDate(
  selectedDate: Date | string,
  dataByDate: Map<string, FundData>,
  dataSortedByDateDesc: FundData[]
) {
  const dateStr = getTimelessDateString(selectedDate);
  const dataForDate = dataByDate.get(dateStr);
  const date = dateFromTimelessString(dateStr);
  const res = dataForDate ? { ...dataForDate } : createFormFundData({ date });
  if (res.netAssets == null || res.deemedContributions == null || res.contributedSecurities == null) {
    const previousValueIdx = dataSortedByDateDesc.findIndex((fd) => {
      return new Date(fd.date).getTime() <= new Date(dateStr).getTime();
    });
    if (previousValueIdx === -1) return res;
    for (let i = previousValueIdx; i < dataSortedByDateDesc.length; i++) {
      const prevValues = dataSortedByDateDesc[i];
      if (res.netAssets == null) res.netAssets = prevValues.netAssets;
      if (res.deemedContributions == null) res.deemedContributions = prevValues.deemedContributions;
      if (res.contributedSecurities == null) res.contributedSecurities = prevValues.contributedSecurities;
      if (res.netAssets != null && res.deemedContributions != null && res.contributedSecurities != null)
        break;
    }
  }
  return res;
}

// fundData.date is a string in the format 'YYYY-MM-DD', but date picker will convert it to a date on change
function getTimelessDateString(date: Date | string) {
  return date instanceof Date ? getPlainDateString(date) : date;
}
