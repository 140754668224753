import { Button, Typography } from '@mui/material';
import { css } from '@mui/material/styles';
import { useState } from 'react';
import { flatCardStyleNewCss } from '../../../../theme/component-styles';
import { FMT } from '../../../../util/formatter-service';
import { FormVariant, IFormField } from '../../../../view-models/form.view-model';
import { BasicDialog } from '../../../Dialog/BasicDialog';
import { FieldFactory } from '../../FieldFactory';
import { FieldLabel, FormField } from '../../FormComponents';

const modalContentsCss = css`
  display: grid;
  gap: 1rem;
  padding: 0 2.5rem 1rem 2.5rem;
`;

const calculatedCardCss = css`
  align-items: center;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: max-content 1fr max-content;
`;

const actionButtonsCss = css`
  padding: 0 2.5rem 1rem 2.5rem;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: max-content 1fr max-content max-content;
`;

export interface IFieldWithModalProps<T, FormItemType = unknown> {
  calculatedValue: T | null;
  formField: IFormField<FormItemType>;
  initialValue: T | null;
  onClose: (value: T | null | undefined) => void;
}
export function FieldWithOverrideModal<T>(props: IFieldWithModalProps<T>) {
  const { formField, initialValue, onClose } = props;
  const [overrideValue, setOverrideValue] = useState(initialValue);

  const modalFormField: IFormField<unknown> = {
    ...formField,
    autoFocus: true,
    variant: 'form' as FormVariant,
  };

  const onOverrideChange = (newValue: T | null) => {
    setOverrideValue(newValue);
  };

  const onSaveOverride = () => {
    onClose(overrideValue);
  };

  const onCancel = () => {
    onClose(undefined);
  };

  const onReset = () => {
    onClose(null);
  };

  return (
    <BasicDialog
      lightDismiss={true}
      fullWidth={true}
      onClose={onCancel}
      maxWidth={'sm'}
      open={true}
      title={`Override ${formField.label}`}
    >
      <FieldOverrideModalContents
        formField={modalFormField}
        calculatedValue={props.calculatedValue}
        overrideValue={overrideValue}
        onOverrideChange={onOverrideChange}
      />
      <div css={actionButtonsCss}>
        <Button variant={'outlined'} color={'secondary'} onClick={onCancel}>
          Cancel
        </Button>
        <div />
        <Button variant={'contained'} color={'secondary'} onClick={onReset}>
          Reset to calculated value
        </Button>
        <Button variant={'contained'} color={'secondary'} onClick={onSaveOverride}>
          Apply override
        </Button>
      </div>
    </BasicDialog>
  );
}

export interface IFieldOverrideModalContentsProps<T> {
  calculatedValue: T | null;
  formField: IFormField<unknown>;
  overrideValue: T | null;
  onOverrideChange: (value: T) => void;
}

export function FieldOverrideModalContents<T>(props: IFieldOverrideModalContentsProps<T>) {
  const { calculatedValue, formField, overrideValue, onOverrideChange } = props;
  const format = FMT.get().getFormatterForFormField(formField);
  const formattedCalculatedValue = format(calculatedValue);

  return (
    <div css={modalContentsCss}>
      <div css={[flatCardStyleNewCss, calculatedCardCss]}>
        <Typography>Calculated value</Typography>
        <div />
        <Typography>{formattedCalculatedValue}</Typography>
      </div>
      <FormField key={formField.key}>
        <FieldLabel field={{ ...formField, isOverridden: overrideValue !== null }} />

        <FieldFactory
          key={formField.key}
          formField={props.formField}
          formProps={{
            value: overrideValue,
            name: '',
            onChange: onOverrideChange,
            ref: () => {},
            onBlur: () => {},
          }}
        />
      </FormField>
    </div>
  );
}
