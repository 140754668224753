import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router';
import { NavigationWrapper } from '../NavigationWrapper';
import { DashboardList } from './DashboardList';
import { PackageSelector } from './PackageSelector';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CINavigation = () => {
  const { pathname } = useLocation();
  if (!pathname.includes('ci-report')) return null;

  return (
    <NavigationWrapper title={'Competitive Intelligence'}>
      <Wrapper>
        <DashboardList />
        <PackageSelector />
      </Wrapper>
    </NavigationWrapper>
  );
};
