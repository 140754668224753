import { atom, selector } from 'recoil';
import { Option } from '../../../components/MuiSingleSelect/MuiSingleSelect';
import { IDealDataModel } from '../../../data-models/deal.data-model';
import { IFundDataModel, UnassignedFund } from '../../../data-models/fund.data-model';
import { fundsAtom, roundsAtom, sectorsAtom } from '../../../services/state/AppConfigStateJ';
import { companyState } from '../../../services/state/CompanyState';
import { DealBoardDealCategory, DealBoardViewMode, SelectItem } from '../../../types';
import { getForesightStore } from '../../../util/jotai-store';
import { ColumnStage, createColumnStage } from '../data-models/columnStage.data-model';
import { IDealStageDataModel } from '../data-models/dealStage.data-model';
import { dealsByCategoryState, dealStagesByIdMapState, dealTypesByIdMapState } from './DealboardDataState';

export const TBD_ROUND_OPTION = { id: null, value: 'TBD' };

export const isOpenDrawerState = atom<boolean>({
  key: 'isOpenDrawerState',
  default: false,
});

export const isPresentModeState = atom<boolean>({
  key: 'isPresentModeState',
  default: false,
});

export const selectedDealCategoryState = atom<SelectItem>({
  key: 'selectedDealCategoryState',
  default: {
    id: DealBoardDealCategory.CURRENT,
    value: 'Current Deals',
  },
});

export const viewModeState = atom<DealBoardViewMode>({
  key: 'viewModeState',
  default: DealBoardViewMode.KANBAN,
});

export const searchTermState = atom<string>({
  key: 'searchTermState',
  default: '',
});

export const showCommentsState = atom<boolean>({
  key: 'showCommentsState',
  default: false,
});

export const selectedDealTypeState = atom<number>({
  key: 'selectedDealTypeState',
});

export const selectedSectorsState = atom<number[]>({
  key: 'selectedSectorsState',
  default: selector<number[]>({
    key: 'selectedSectorsStateDefault',
    get: () => {
      const store = getForesightStore();
      const sectors = store.get(sectorsAtom);

      return sectors.map((sector) => sector.id);
    },
  }),
});

export const dealFiltersFundsState = selector<IFundDataModel[]>({
  key: 'dealFiltersFundsState',
  get: () => {
    const store = getForesightStore();
    const funds = store.get(fundsAtom);

    return [...funds, UnassignedFund];
  },
});

export const selectedFundsState = atom<number[]>({
  key: 'selectedFundsState',
  default: selector<number[]>({
    key: 'selectedFundsStateDefault',
    get: ({ get }) => {
      const funds = get(dealFiltersFundsState);
      return funds.map((fund) => fund.id);
    },
  }),
});

export const allRoundsOptionsState = selector<(Option | typeof TBD_ROUND_OPTION)[]>({
  key: 'allRoundsOptionsState',
  get: () => {
    const store = getForesightStore();
    const rounds = store.get(roundsAtom);

    const roundOptions = rounds.map((round) => {
      return {
        id: round.id,
        value: round.displayName,
      };
    });

    return [...roundOptions, TBD_ROUND_OPTION];
  },
});

export const selectedRoundsState = atom<(number | null)[]>({
  key: 'selectedRoundsState',
  default: selector<(number | null)[]>({
    key: 'selectedRoundsStateDefault',
    get: ({ get }) => {
      const rounds = get(allRoundsOptionsState);

      return rounds.map((round) => round.id) as (number | null)[];
    },
  }),
});

export const selectableStagesState = selector<IDealStageDataModel[]>({
  key: 'selectableStagesState',
  get: ({ get }) => {
    const selectedDealType = get(selectedDealTypeState);
    const dealTypesById = get(dealTypesByIdMapState);
    const dealStagesById = get(dealStagesByIdMapState);
    const dealStagesForDealType = dealTypesById.get(selectedDealType)?.dealStageIds ?? [];

    return dealStagesForDealType.map((stageId) => dealStagesById.get(stageId)!);
  },
});

export const selectedDealLeadState = atom<number | null>({
  key: 'selectedDealLeadState',
  default: null,
});

export const dealBoardSettingsState = selector<ColumnStage[]>({
  key: 'dealBoardState',
  get: ({ get }) => {
    const visibleDeals = get(visibleDealsState) ?? [];
    const selectableStages = get(selectableStagesState);

    return selectableStages.map((stage) => {
      return createColumnStage({
        stage,
        deals: visibleDeals.filter((deal) => {
          return deal.stageId === stage.id;
        }),
        isCollapsed: false,
      });
    });
  },
});

export const selectedDealState = atom<IDealDataModel | null>({
  key: 'setSelectedDealState',
  default: null,
});

export const selectedFieldState = atom<string | null>({
  key: 'selectedFieldState',
  default: null,
});

export const visibleDealsState = selector<IDealDataModel[] | null>({
  key: 'visibleDealsState',
  get: ({ get }) => {
    const selectedCategory = get(selectedDealCategoryState).id as DealBoardDealCategory;
    const dealsForCategory = get(dealsByCategoryState(selectedCategory));
    const selectedDealLead = get(selectedDealLeadState);
    const selectedDealType = get(selectedDealTypeState);
    const selectedRounds = new Set(get(selectedRoundsState));
    const selectedSectors = new Set(get(selectedSectorsState));
    const searchTerm = get(searchTermState).toLowerCase();
    const selectedFunds = new Set(get(selectedFundsState));

    return dealsForCategory.filter((deal) => {
      const company = get(companyState(deal.companyId));
      const hasSelectedDealLead = selectedDealLead === null ? true : deal.dealLeadId === selectedDealLead;
      const hasSelectedDealType =
        selectedCategory !== DealBoardDealCategory.CURRENT || deal.dealTypeId === selectedDealType;

      const fundIds = deal.fundIds ?? [];
      const hasSelectedFund =
        fundIds.some((fundId) => selectedFunds.has(fundId)) ||
        (fundIds.length === 0 && selectedFunds.has(UnassignedFund.id));

      const hasSelectedRound = selectedRounds.has(deal.roundId);
      const hasSelectedText = company && company.name.toLowerCase().indexOf(searchTerm) >= 0;
      const hasSelectedSector =
        company && (company.sectorId === null || selectedSectors.has(company.sectorId));
      return (
        hasSelectedDealType &&
        hasSelectedDealLead &&
        hasSelectedSector &&
        hasSelectedRound &&
        hasSelectedText &&
        hasSelectedFund
      );
    });
  },
});
