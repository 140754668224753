import { useRecoilValue } from 'recoil';
import { useAtomValue } from 'jotai';
import { FundMetrics, FundMetricsByFund, fundMetricsFormSchema } from '../../schemas/FundMetrics.schema';
import { fieldDisplayValuesFromSchema } from '../../util/schema-utils';
import { selectedDateFPState, selectedFundIdStateFP, selectedFundMetricsStateFP } from './state/FPState';

export function useFundMetricsFP() {
  const date = useAtomValue(selectedDateFPState);
  const fundId = useRecoilValue(selectedFundIdStateFP);

  const metrics = useRecoilValue(selectedFundMetricsStateFP({ date, fundId }))?.metrics?.at(0);

  return metrics as FundMetricsByFund | undefined;
}

export function getFundMetricsAdditionalData(metrics: FundMetrics) {
  const fields = fundMetricsFormSchema();
  return fieldDisplayValuesFromSchema({
    schema: fields,
    values: metrics,
    paths: ['lpContributions', 'gpContributions', 'lpDistributions', 'gpDistributions'],
  });
}
