import { capitalize, merge } from 'lodash-es';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import * as yup from 'yup';
import { RendererType } from '../data-models/field.data-model';
import { ISimpleChoice } from '../data-models/field2.data-model';
import { ISelectMeta } from '../data-models/field3.data-model';
import { KPIRequestFrequency } from '../data-models/kpi-requests.data-model';
import { ReminderThresholds } from '../data-models/template.data-model';
import { validateEmails } from '../pages/KPI/components/KPICompanies/components/KPIAssignRequestModal/emailArrayValidator';
import { kpiTemplatesListState } from '../services/state/KPI/KPITemplatesState';
import { IDisplayField } from '../view-models/display-field.view-model';
import { companyFields } from './Company.schema';

export function templateAssignmentGridSchema() {
  const { frequency, reminderThreshold, templateUuid, id } = templateAssignmentFields();
  return yup.object().shape({
    id,
    frequency,
    reminderThreshold,
    templateUuid: templateUuid.gridMeta({
      renderer: RendererType.id,
      formatter: 'kpiTemplateName',
    }),
  });
}

function respondentSchema(
  schema: yup.ArraySchema<(string | undefined | null)[] | undefined | null, yup.AnyObject, never[], 'd'>
) {
  return schema
    .default([])
    .transform((value?: string[]) => value?.map((v: string) => v.trim()).filter(Boolean))
    .test({
      name: 'invalid-email',
      message: 'Please make sure all emails are valid',
      test: (value?: (string | undefined | null)[] | null) => {
        if (!value) return true;
        return validateEmails(value as string[]);
      },
    })
    .formMeta({
      renderer: RendererType.freeLabels,
    });
}

export function manageCompanyFields() {
  const { fye, respondents, adjustHistoricKPIs, secondaryRespondents } = companyFields();
  return {
    adjustHistoricKPIs: adjustHistoricKPIs.label('Adjust those KPIs to the new FYE?'),
    fye: fye.default(12).required(),
    respondents: respondentSchema(respondents),
    secondaryRespondents: respondentSchema(secondaryRespondents),
  };
}

export function granularityDefaultFromFrequency(frequency: KPIRequestFrequency) {
  switch (frequency) {
    case KPIRequestFrequency.Annual:
      return KPIRequestFrequency.Annual;
    case KPIRequestFrequency.Quarterly:
      return KPIRequestFrequency.Quarterly;
    case KPIRequestFrequency.Monthly:
      return KPIRequestFrequency.Monthly;
    default:
      return null;
  }
}

function getGranularityValues(frequency: KPIRequestFrequency) {
  switch (frequency) {
    case KPIRequestFrequency.Annual:
      return [
        {
          value: KPIRequestFrequency.Annual,
          displayName: 'Annually',
        },
        {
          value: KPIRequestFrequency.Quarterly,
          displayName: 'Quarterly',
        },
        {
          value: KPIRequestFrequency.Monthly,
          displayName: 'Monthly',
        },
      ] as ISimpleChoice<KPIRequestFrequency>[];
    case KPIRequestFrequency.Quarterly:
      return [
        {
          value: KPIRequestFrequency.Quarterly,
          displayName: 'Quarterly',
        },
        {
          value: KPIRequestFrequency.Monthly,
          displayName: 'Monthly',
        },
      ];
    case KPIRequestFrequency.Monthly:
      return [
        {
          value: KPIRequestFrequency.Monthly,
          displayName: 'Monthly',
        },
      ] as ISimpleChoice<KPIRequestFrequency>[];
    default:
      return [];
  }
}

export function granularityField(frequency: KPIRequestFrequency) {
  return yup
    .string()
    .nullable()
    .default(null)
    .oneOf(Object.values(KPIRequestFrequency))
    .default(granularityDefaultFromFrequency(frequency))
    .formMeta({
      description:
        'Determines the level of data collected. If toggled quarterly, then respondents will be prompted to enter quarterly data.',
      disableClearable: true,
      renderer: RendererType.singleSelect,
      rendererMeta: {
        values: getGranularityValues(frequency),
      } as ISelectMeta<KPIRequestFrequency>,
    });
}

export type ManageCompanyFieldsTypes = yup.InferType<ReturnType<typeof manageSingleCompanyFormSchema>>;

export function manageCompanyFormSchema() {
  const { fye, respondents, secondaryRespondents } = manageCompanyFields();
  return yup.object().shape({
    fye,
    respondents: respondents.default([]),
    secondaryRespondents: secondaryRespondents.default([]),
  });
}

export function manageSingleCompanyFormSchema() {
  const { adjustHistoricKPIs, respondents, secondaryRespondents } = manageCompanyFields();
  return manageCompanyFormSchema().shape({
    respondents,
    secondaryRespondents,
    adjustHistoricKPIs: adjustHistoricKPIs.label('Adjust KPIs'),
  });
}

export function manageAssignCompanyFormSchema() {
  const { fye, respondents, secondaryRespondents } = manageCompanyFields();
  return yup.object().shape({
    fye,
    respondents: respondents.min(1, 'Please provide at least one email'),
    secondaryRespondents: secondaryRespondents.default([]),
  });
}

export function useAssignTemplateFormsSchema() {
  const templateUuid = useTemplateIdField('templateUuid');

  return useCallback(
    (frequencyValue = KPIRequestFrequency.Monthly) => {
      const { id, frequency, reminderThreshold } = templateAssignmentFields();

      return yup.object().shape({
        id: id.optional(),
        frequency,
        granularity: granularityField(frequencyValue),
        reminderThreshold,
        templateUuid,
      });
    },
    [templateUuid]
  );
}

export type AssignTemplateFieldTypes = yup.InferType<
  ReturnType<ReturnType<typeof useAssignTemplateFormsSchema>>
>;

export function useTemplateIdField(key: string) {
  const templateList = useRecoilValue(kpiTemplatesListState)?.filter((t) => !t.isDeleted);

  return yup
    .string()
    .nullable()
    .required()
    .default('')
    .label('Template')
    .customMeta<IDisplayField<ISelectMeta<string>>>({
      key,
      formatter: 'kpiTemplateName',
      renderer: RendererType.singleSelect,
      rendererMeta: {
        values: (templateList ?? []).map((t) => ({
          value: t.uuid,
          displayName: t.name,
        })),
      },
    });
}

export function templateAssignmentFields() {
  return {
    id: yup.number().required().default(0),
    companyId: yup.number().required().default(0),
    respondent: yup.array().of(yup.string()).required().default([]),
    frequency: yup
      .string()
      .oneOf(Object.values(KPIRequestFrequency))
      .required()
      .label('Frequency')
      .default(KPIRequestFrequency.Monthly)
      .meta({
        description:
          'Determines frequency in which this request should be generated. Monthly will generate a request to be sent every month, quarterly will generate a request to be sent every quarter, and yearly will generate a request to be sent once a year.',
        renderer: RendererType.singleSelect,
        rendererMeta: {
          values: Object.values(KPIRequestFrequency).reduce((acc, value) => {
            if (value !== KPIRequestFrequency.None)
              acc.push({
                value,
                displayName: capitalize(value),
              });
            return acc;
          }, [] as ISimpleChoice<KPIRequestFrequency>[]),
        },
      }),
    fye: yup.number().nullable().optional().default(12),
    granularity: granularityField(KPIRequestFrequency.Monthly),
    reminderThreshold: yup
      .number()
      .required()
      .default(15)
      .required()
      .label('Due Date')
      .meta({
        renderer: RendererType.singleSelect,
        rendererMeta: {
          values: [...ReminderThresholds.entries()].map(([value, label]) => ({
            value,
            displayName: label,
          })),
        },
      }),
    templateUuid: yup.string().required().default('').label('Template'),
    createdBy: yup.string().nullable().optional(),
    updatedBy: yup.string().nullable().optional(),
    createdAt: yup.string().nullable().optional(),
    updatedAt: yup.string().nullable().optional(),
  };
}

export function templateAssignmentFormField() {
  return yup.object(templateAssignmentFields());
}

export type TemplateAssignment = yup.InferType<ReturnType<typeof templateAssignmentFormField>>;

export function createTemplateAssignment(overrides: Partial<TemplateAssignment> = {}): TemplateAssignment {
  return merge({ ...templateAssignmentFormField().getDefault(), ...overrides });
}
