import { css } from '@emotion/react';
import { Box, Card, Stack, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { StatusRenderer } from '../../../../../components/AgTable/cell-renderers/StatusRenderer';
import { BasicDialog } from '../../../../../components/Dialog/BasicDialog';
import { FormFieldsContainer } from '../../../../../components/Form/FormComponents';
import { StickyFormButtons } from '../../../../../components/Form/StickyFormButtons';
import { CompanyLogoAndName } from '../../../../../components/grid-renderers/CompanyCellRenderer';
import { sectorsByIdMapAtom } from '../../../../../services/state/AppConfigStateJ';
import { cardStyles } from '../../../../../theme/component-styles';
import { pluralizeWord } from '../../../../../util/stringUtils';
import { InfoBox } from '../../../../Mappings/forms/InfoBox';
import { IKpiCompanyData } from '../AllCompaniesData';
import { selectedKpiCompaniesStateDC } from '../AllCompaniesState';
import { useSetReportingStatus } from '../hooks/useSetReportingStatus';
import { useAllKpiCompaniesActions } from './useAllKpiCompaniesActions';

const dialogContent = css`
  padding-bottom: 0;
  gap: 0.5rem;
`;
const companyCard = css({
  ...cardStyles,
  marginBottom: '0.25rem',
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  columnGap: '1rem',
  alignItems: 'center',
  minHeight: 'fit-content',
});

export function StopReportingModal() {
  const updateReportingStatus = useSetReportingStatus();
  const { selectedAction, resetUIState } = useAllKpiCompaniesActions();
  const selectedCompanies = useRecoilValue(selectedKpiCompaniesStateDC);
  const sectorsById = useAtomValue(sectorsByIdMapAtom);

  const [loading, setLoading] = useState(false);

  const handleClose = useCallback(() => {
    resetUIState();
  }, [resetUIState]);

  const handleConfirm = useCallback(async () => {
    if (!selectedCompanies?.length) return;
    setLoading(true);
    await updateReportingStatus(
      selectedCompanies.map(({ id }) => id),
      false
    );
    setLoading(false);
    handleClose();
  }, [selectedCompanies, handleClose, updateReportingStatus]);

  if (selectedAction !== 'confirmStopReporting' || !selectedCompanies?.length) return null;

  return (
    <BasicDialog title={'Stop Reporting Data?'} open onClose={handleClose}>
      <FormFieldsContainer css={dialogContent}>
        <Stack display='grid' gridTemplateRows={'1fr auto'} gap='0.5rem'>
          <Box maxHeight={'25rem'} sx={{ overflowY: 'auto' }}>
            {selectedCompanies.map((company) => {
              const { assigned } = company as IKpiCompanyData;
              const sector = sectorsById.get(company.sectorId ?? -1);
              return (
                <Card css={companyCard} key={company.id}>
                  <CompanyLogoAndName name={company.name} logoUrl={company.logoUrl ?? ''} />
                  {sector ? <Typography variant='body2'>{sector.name}</Typography> : <div />}
                  <StatusRenderer
                    value={assigned ? 'Assigned' : 'Unassigned'}
                    status={assigned ? 'success' : 'warning'}
                  />
                </Card>
              );
            })}
          </Box>
          <InfoBox
            message={`Collecting KPIs data will be suspended and the selected ${pluralizeWord('company', selectedCompanies.length)} will be marked as “Not Collecting”. You can change this later.`}
          />
        </Stack>
      </FormFieldsContainer>
      <StickyFormButtons
        onSubmit={handleConfirm}
        loading={loading}
        onCancel={handleClose}
        submitLabel='Stop Reporting'
      />
    </BasicDialog>
  );
}
