import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import SnoozeIcon from '@mui/icons-material/Snooze';
import { Icon, SvgIcon, SvgIconProps } from '@mui/material';
import { useAtomValue } from 'jotai/index';
import { FC } from 'react';
import { useRecoilValue } from 'recoil';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { useCurrentUser } from '../../../services/hooks/useCurrentUser';
import { usersByIdMapAtom } from '../../../services/state/AppConfigStateJ';
import { formatDate } from '../../../util/formatters/DateFormatters';
import { StyledTooltip } from '../../CompetitiveIntelligence/components/Tables/CellRenderers/StyledTooltip';
import { useTrackingData } from '../hooks/useTrackingData';
import { activeListState } from '../state/ListsState';
import { isFollowedByUser } from '../utils/list-utils';

interface ITrackingSignifierProps {
  company: ICompanyDataModel;
  isHidden?: boolean;
  iconProps?: SvgIconProps;
}

export const TrackingSignifier: FC<ITrackingSignifierProps> = ({ company, isHidden, iconProps }) => {
  const activeList = useRecoilValue(activeListState);
  const isTrackingList = activeList?.name === 'Tracked Companies';

  const currentUser = useCurrentUser();
  const userMap = useAtomValue(usersByIdMapAtom);

  const followedByUser = isFollowedByUser(company, currentUser.id);

  const otherFollowers =
    company?.followers?.reduce((acc, followerId) => {
      if (followerId !== currentUser.id) {
        acc.push(userMap.get(followerId)?.name ?? String(followerId));
      }
      return acc;
    }, [] as string[]) ?? [];

  const titleAccess = !company ? '' : getTrackedText(followedByUser, otherFollowers);

  if (isTrackingList) {
    return <TrackingIcon company={company} fontSize='small' {...iconProps} />;
  }

  if (followedByUser) {
    return (
      <StyledTooltip title={titleAccess} placement='top-start'>
        <HourglassFullIcon color={isHidden ? 'disabled' : 'secondary'} fontSize={'small'} />
      </StyledTooltip>
    );
  }

  return (
    <StyledTooltip title={titleAccess} placement='top-start'>
      <HourglassEmptyIcon color={isHidden ? 'disabled' : 'secondary'} fontSize={'small'} />
    </StyledTooltip>
  );
};

function getTrackedText(followedByUser: boolean, otherFollowers: string[]) {
  if (followedByUser) {
    if (otherFollowers.length === 0) {
      return 'You are following this company';
    } else {
      return `Also followed by ${otherFollowers.join(', ')}`;
    }
  } else if (otherFollowers.length > 0) {
    return `Followed by ${otherFollowers.join(', ')}`;
  } else {
    return '';
  }
}

interface ITrackingIconProps extends SvgIconProps {
  company: ICompanyDataModel;
}

export function TrackingIcon({ company, color, ...rest }: ITrackingIconProps) {
  const { hasExpired, snoozeDate } = useTrackingData(company.id);

  const iconColor = hasExpired ? 'error' : (color ?? 'action');
  const titleAccess = !snoozeDate
    ? 'Tracking'
    : hasExpired
      ? `Expired on ${formatDate(snoozeDate)}`
      : `Snoozed until ${formatDate(snoozeDate)}`;

  return (
    <SvgIcon color={iconColor} titleAccess={titleAccess} {...rest}>
      {snoozeDate ? <SnoozeIcon /> : <Icon />}
    </SvgIcon>
  );
}
