import { IFundDataModel } from '../../data-models/fund.data-model';
import { IMetricsDataModel } from '../../data-models/metrics.data-model';
import {
  isEscrowTransaction,
  isExpenseTransaction,
  isInterestOrExpenseTransaction,
  isInterestTransaction,
  isInvestmentCostTransaction,
  isRealizedCostTransaction,
  isRestructureTransaction,
  isReturnOfCapital,
  isStockProceeds,
} from '../../data-models/transaction.data-model';
import { isNoteOrSafeTransaction } from '../../pages/PortfolioOverview/components/OverviewTable/MetricsCalculator';
import {
  metricsFormulas,
  navPercentage,
} from '../../pages/PortfolioOverview/components/OverviewTable/MetricsFormulas';
import {
  cashFlowsCalculator,
  exitDateCalculator,
  fundsIncludedInTransactionsCalculator,
  initialSharesReceiptDateIterator,
  initialTransactionMetaIterator,
  noOfSharesIterator,
} from '../../pages/PortfolioOverview/components/OverviewTable/MetricsIterators';
import {
  fallBackIterator,
  lastFieldValueMatchingRule,
  lastValueMatchingRule,
  minMax,
  subtractIterator,
  sumIterator,
} from '../../pages/PortfolioOverview/components/OverviewTable/StandardIterators';
import { MetricsTransactionDataModel } from '../../schemas/MetricsTransaction.schema';
import { NoGroupFundLabel } from '../../util/formatters/FundFormatter';
import {
  CalculatorMeta,
  IAfterProcessTransactionsContext,
  ITransactionProcessResult,
  ITransactionsProcessor,
} from './metrics.type';

export function createTransactionsProcessor(meta?: CalculatorMeta) {
  const transactionIterators = {
    amountInvested: sumIterator<MetricsTransactionDataModel>('investmentAmount'),
    cashflows: cashFlowsCalculator(),
    distributions: sumIterator<MetricsTransactionDataModel>('distributions'),
    equityFmv: sumIterator<MetricsTransactionDataModel>('currentInvestment', {
      filter: (transaction) => !isNoteOrSafeTransaction(transaction),
    }),
    escrowAmount: sumIterator<MetricsTransactionDataModel>('amount', { filter: isEscrowTransaction }),
    exitDate: exitDateCalculator(meta?.company?.isMerged),
    expenses: sumIterator<MetricsTransactionDataModel>('amount', { filter: isExpenseTransaction }),
    fmv: sumIterator<MetricsTransactionDataModel>('currentInvestment'),
    includedFunds: meta?.fundsMap
      ? fundsIncludedInTransactionsCalculator(meta.fundsMap)
      : fallBackIterator<Map<number, IFundDataModel>, MetricsTransactionDataModel>(
          'No funds provided',
          new Map<number, IFundDataModel>()
        ),
    initialSharesReceiptDate: initialSharesReceiptDateIterator(),
    initialTransactionMeta: initialTransactionMetaIterator(),
    interest: sumIterator<MetricsTransactionDataModel>('amount', { filter: isInterestTransaction }),
    lastParticipatingTransaction: lastValueMatchingRule<MetricsTransactionDataModel>(
      (transaction) => isInvestmentCostTransaction(transaction) && transaction.roundId !== null
    ),
    noOfShares: noOfSharesIterator(meta?.company?.isMerged),
    pricePerShare: lastFieldValueMatchingRule<number, MetricsTransactionDataModel>(
      'pps',
      (transaction) => Boolean(transaction.pps),
      0
    ),
    realizedCost: subtractIterator<MetricsTransactionDataModel>('amount', {
      filter: isRealizedCostTransaction,
    }),
    realizedValue: subtractIterator<MetricsTransactionDataModel>('amount', {
      filter: (transaction) => isInterestOrExpenseTransaction(transaction) || isReturnOfCapital(transaction),
    }),
    restructureAmount: sumIterator('amount', { filter: isRestructureTransaction }),
    stockProceeds: sumIterator('amount', { filter: isStockProceeds }),
  };

  return {
    transactionIterators,
    processTransaction: (transaction: MetricsTransactionDataModel) => {
      const allIterators = Object.values(transactionIterators);

      allIterators.forEach((iterator) => iterator.next(transaction));
    },
    getResult(): ITransactionProcessResult {
      const eqFMVAdjusted = Number(transactionIterators.equityFmv.value.toFixed(4));

      return {
        amountInvested: transactionIterators.amountInvested.value,
        cashflows: transactionIterators.cashflows.value,
        distributions: transactionIterators.distributions.value,
        equityFmv: minMax(eqFMVAdjusted, 0),
        escrowAmount: transactionIterators.escrowAmount.value,
        exitDate: transactionIterators.exitDate.value,
        expenses: transactionIterators.expenses.value,
        fmv: minMax(transactionIterators.fmv.value, 0),
        includedFunds: transactionIterators.includedFunds.value,
        initialInvestment: transactionIterators.initialTransactionMeta.value.investmentAmount,
        initialInvestmentDate: transactionIterators.initialTransactionMeta.value.investmentDate,
        initialSharePrice: transactionIterators.initialTransactionMeta.value.initialSharePrice,
        initialSharesHeld: transactionIterators.initialTransactionMeta.value.initialSharesHeld,
        initialSharesReceiptDate: transactionIterators.initialSharesReceiptDate.value,
        interest: transactionIterators.interest.value,
        lastParticipatingTransaction: transactionIterators.lastParticipatingTransaction.value,
        noOfShares: transactionIterators.noOfShares.value,
        pricePerShare: transactionIterators.pricePerShare.value ?? 0,
        realizedCost: transactionIterators.realizedCost.value,
        restructureAmount: transactionIterators.restructureAmount.value,
        realizedValue: transactionIterators.realizedValue.value,
        stockProceeds: transactionIterators.stockProceeds.value,
      };
    },
  } satisfies ITransactionsProcessor;
}

export function afterProcessTransactions(
  metric: IMetricsDataModel & ITransactionProcessResult,
  context: IAfterProcessTransactionsContext
): Partial<IMetricsDataModel> {
  const { roundsMap } = context;
  const { includedFunds, lastParticipatingTransaction, restructureAmount } = metric;

  const res: Partial<IMetricsDataModel> = {
    followOnInvestment: metricsFormulas.followOnInvestment(metric),
    fund: Array.from(includedFunds.values())
      .map((f) => f.name)
      .join(','),
    fundType: Array.from(new Set(includedFunds.values().map((f) => f.fundType ?? NoGroupFundLabel))),
    investmentStatus: metricsFormulas.investmentStatus(metric),
    investmentAge: metricsFormulas.investmentAge(metric, context.asOfDate),
    irr: metricsFormulas.irr(metric, context.asOfDate),
    moic: metricsFormulas.moic(metric),
    realizedGL: metricsFormulas.realizedGL(metric),
    realizedMOIC: metricsFormulas.realizedMOIC(metric),
    realizedValue: metricsFormulas.realizedValueAdjusted(metric),
    realizedAmountWithEscrow: metricsFormulas.realizedAmountWithEscrow(metric),
    totalCost: metricsFormulas.totalCost(metric, restructureAmount),
    totalReturn: metricsFormulas.totalReturn(metric),
    totalValue: metricsFormulas.totalValue(metric),
    unrealizedCost: metricsFormulas.unrealizedCost(metric, restructureAmount),
    unrealizedGL: metricsFormulas.unrealizedGL(metric),
    unrealizedMOIC: metricsFormulas.unrealizedMOIC(metric),
    unrealizedValue: metricsFormulas.unrealizedValue(metric),
  };

  if (lastParticipatingTransaction) {
    const round = lastParticipatingTransaction.roundId
      ? roundsMap.get(lastParticipatingTransaction.roundId)
      : null;

    res.participatingLastRoundSeries = round?.displayName ?? '';
    res.participatingLastRoundSharePrice = lastParticipatingTransaction?.pps ?? 0;
    res.participatingLastRoundSharePriceDate = lastParticipatingTransaction?.transactionDate ?? '';
  }

  return res;
}

export function createMetricTotalProcessor() {
  const calculators = {
    fmv: sumIterator<IMetricsDataModel>('fmv'),
  };

  return {
    processMetric(metric: IMetricsDataModel) {
      calculators.fmv.next(metric);
    },
    getResult(metric: IMetricsDataModel) {
      const totalFMV = calculators.fmv.value;

      return {
        navPercentage: navPercentage(metric, totalFMV),
      };
    },
  };
}
