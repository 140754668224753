import { Stack, styled } from '@mui/material';
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { GetRowIdParams, GridApi, GridReadyEvent, RowClassParams } from 'ag-grid-community';
import { useRecoilValue } from 'recoil';
import { AgTable } from '../../../../components/AgTable/AgTable';
import { FadeInGridWrapper } from '../../../../components/grid-renderers/FadeInGridWrapper';
import { colors } from '../../../../theme/colors';
import { useExtendedExportSettings } from '../../../../components/AgTable/exportToExcelDefs';
import { ExportGridButton } from '../../../../components/AgTable/ExportGridButton';
import { ExportButtonContainer } from '../KPIRequests/components/KpiStyleDefs';
import { multiRowSelectionDefs } from '../../../../components/AgTable/rowSelectionDefs';
import { IKpiCompanyData, useAllCompaniesRowData } from './AllCompaniesData';
import { defaultColDef, useAllCompaniesColDefs } from './AllCompaniesColDefs';
import { useAllCompaniesRowSelection } from './hooks/useAllCompaniesRowSelection';
import { allCompaniesActionState } from './AllCompaniesState';
import { AssignModal } from './AllCompaniesForms/AssignModal';
import { ManageModal } from './AllCompaniesForms/ManageModal';
import { AllCompaniesBulkActions } from './AllCompaniesForms/AllCompaniesBulkActions';
import { useAllKpiCompaniesActions } from './AllCompaniesForms/useAllKpiCompaniesActions';
import { StopReportingModal } from './AllCompaniesForms/StopReportingModal';
import { TemplateAssignmentErrorsModal } from './AllCompaniesForms/TemplateAssignmentErrorsModal';

const StyledGridContainer = styled('div')`
  & .unassigned:not(.ag-pinned-right-cols-container .unassigned, .ag-pinned-left-cols-container .unassigned) {
    box-shadow: inset 2px 0 ${colors.warning[40]};
  }
  height: 100%;
`;
function AllCompaniesWrapper(props: PropsWithChildren) {
  const { resetUIState } = useAllKpiCompaniesActions();

  useEffect(() => {
    return resetUIState;
  }, [resetUIState]);

  return <>{props.children}</>;
}

export function AllCompanies() {
  return (
    <AllCompaniesWrapper>
      <AllCompaniesModals />
      <AllCompaniesGrid />
    </AllCompaniesWrapper>
  );
}

function AllCompaniesModals() {
  const actionState = useRecoilValue(allCompaniesActionState);
  return (
    <>
      <TemplateAssignmentErrorsModal />
      {actionState === 'assign' && <AssignModal />}
      {actionState === 'manage' && <ManageModal />}
      {actionState === 'confirmStopReporting' && <StopReportingModal />}
    </>
  );
}

export function AllCompaniesGrid() {
  const getColumnDefs = useAllCompaniesColDefs();
  const allCompaniesData = useAllCompaniesRowData();
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const { exportParams } = useExtendedExportSettings(new Set(['id', 'sectorId']), `all companies`);

  const onGridReady = useCallback((e: GridReadyEvent) => {
    setGridApi(e.api);
  }, []);

  const { onSelectionChanged, deselectAll } = useAllCompaniesRowSelection(gridApi);

  const columnDefs = useMemo(() => getColumnDefs(), [getColumnDefs]);

  const getRowId = useCallback(
    (data: GetRowIdParams<IKpiCompanyData>) => data.data?.id?.toString() ?? '',
    []
  );

  const handleExport = useCallback(() => {
    gridApi?.exportDataAsExcel();
  }, [gridApi]);

  return (
    <Stack height={'100%'}>
      <div css={ExportButtonContainer}>
        <ExportGridButton onClick={handleExport} />
      </div>
      <StyledGridContainer>
        <AllCompaniesBulkActions deselectAll={deselectAll} />
        <FadeInGridWrapper>
          <AgTable
            rowData={allCompaniesData}
            onGridReady={onGridReady}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowGroupPanelShow='always'
            groupDefaultExpanded={-1}
            suppressMovableColumns={true}
            suppressRowDrag={true}
            getRowClass={getRowClass}
            onSelectionChanged={onSelectionChanged}
            getRowId={getRowId}
            defaultCsvExportParams={exportParams}
            defaultExcelExportParams={exportParams}
            rowSelection={multiRowSelectionDefs}
          />
        </FadeInGridWrapper>
      </StyledGridContainer>
    </Stack>
  );
}

function getRowClass(params: RowClassParams<IKpiCompanyData>) {
  if (params.data?.assigned === false) {
    return ['unassigned'];
  }
}
