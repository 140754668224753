import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAtomValue } from 'jotai';
import { FC } from 'react';
import { UserAvatar } from '../../../../../components/UserAvatar/UserAvatar';
import { usersByIdMapAtom } from '../../../../../services/state/AppConfigStateJ';

const StyledUserCell = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  & .ellipsize {
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

interface UserCellProps {
  userId: number;
}

export const UserCell: FC<UserCellProps> = ({ userId }) => {
  const users = useAtomValue(usersByIdMapAtom);
  const owner = users.get(userId);

  if (!owner) return null;

  return (
    <StyledUserCell>
      <UserAvatar size='small' user={owner} />
      <Typography className='ellipsize' variant='body2'>
        {owner.name}
      </Typography>
    </StyledUserCell>
  );
};
