import { isValid } from 'date-fns';
import { useCallback } from 'react';
import { useRecoilCallback } from 'recoil';
import { amountField, sharesField, simpleTextField } from '../../../data-fields/CommonFields';
import { RendererType } from '../../../data-models/field.data-model';
import { ICurrencyMeta } from '../../../data-models/field3.data-model';
import { MetricsTransactionDataModel } from '../../../schemas/MetricsTransaction.schema';
import { fundsByIdMapAtom } from '../../../services/state/AppConfigStateJ';
import { companyMetricsByIdState } from '../../../services/state/CompanyMetricsByIdState';
import { formatDateNumeric } from '../../../util/formatters/DateFormatters';
import { getForesightStore } from '../../../util/jotai-store';
import { createFormField, IFormField } from '../../../view-models/form.view-model';
import { restructureKeyToFieldKey } from '../../../view-models/transaction-form.view-model';
import { useFundDateCurrencyFields } from './useFundDateCurrencyFields';

export function useShareExchangeFields(
  companyId: number,
  selectedFundId: number | null,
  transactionDate: string
) {
  const getCommonInitialFields = useFundDateCurrencyFields();
  const getRestructureIdFields = useGetRestructureIdFields();

  return useCallback(() => {
    const fields: IFormField<unknown>[] = [
      ...getCommonInitialFields(),
      sharesField({ key: 'receivedSharesNo', label: 'Received Shares', required: true }),
      amountField({ key: 'receivedPricePerShare', label: 'Received PPS', required: true }),
      simpleTextField({ key: 'security', label: 'Received Security' }),
    ];
    const restructureFields = getRestructureIdFields(companyId, selectedFundId, transactionDate);

    return [...fields, ...restructureFields];
  }, [companyId, getCommonInitialFields, getRestructureIdFields, selectedFundId, transactionDate]);
}

export function useGetRestructureIdFields() {
  return useRecoilCallback(
    ({ snapshot }) =>
      (companyId: number, selectedFundId: number | null, transactionDate: string) => {
        const store = getForesightStore();
        const metrics = snapshot.getLoadable(companyMetricsByIdState(companyId)).getValue()!;
        const fundsMap = store.get(fundsByIdMapAtom);
        const latestRestructureTransactions = getRestructureIdsMeta(
          metrics?.metrics[0]?.transactions ?? [],
          selectedFundId,
          new Date(transactionDate)
        );

        return Array.from(latestRestructureTransactions.values()).map((transaction) => {
          const { fundId, position, restructureId, transactionDate } = transaction;
          const label = `Exchanged shares for ${position ?? formatDateNumeric(transactionDate)}`;
          const fundLabel =
            fundId && fundsMap.get(fundId)?.name ? `Fund: ${fundsMap.get(fundId)?.name}, ` : '';
          const description = `${fundLabel}Acquired Date: ${formatDateNumeric(transactionDate)}`;
          return createFormField<ICurrencyMeta>({
            key: `_viewModel.exchangedSharesByRestructureId.${restructureKeyToFieldKey(restructureId ?? '')}`,
            label,
            description,
            required: true,
            renderer: RendererType.number,
          });
        });
      },
    []
  );
}

type IRestructureMeta = Pick<
  MetricsTransactionDataModel,
  'transactionDate' | 'fundId' | 'position' | 'amount'
> & {
  restructureId: string;
};

export function getRestructureIdsMeta(
  transactions: MetricsTransactionDataModel[],
  transactionFundId: number | null,
  newTransactionDate: Date
) {
  const res = new Map<string, IRestructureMeta>();
  const accumulatedMeta = new Map<string, { quantity: number; amount: number }>();

  if (transactions.length === 0 || !isValid(newTransactionDate)) {
    return res;
  }

  transactions.forEach((transaction) => {
    const { fundId, position, quantity, restructureId, transactionDate: transactionDateISO } = transaction;
    const transactionDate = new Date(transactionDateISO);
    const isBeforeNewTransactionDate = transactionDate.getTime() < newTransactionDate.getTime();

    if (transactionFundId !== null && fundId !== transactionFundId) {
      return;
    }

    if (restructureId && isBeforeNewTransactionDate) {
      const currentLatestTransaction = res.get(restructureId);
      const isLatestTransaction =
        currentLatestTransaction === undefined ||
        (transactionDate.getTime() >= new Date(currentLatestTransaction.transactionDate).getTime() &&
          quantity > 0);

      if (isLatestTransaction) {
        res.set(restructureId, {
          amount: 0,
          fundId: fundId ?? currentLatestTransaction?.fundId,
          position: position ?? currentLatestTransaction?.position,
          restructureId,
          transactionDate: transactionDateISO,
        });
      }

      const currentAcc = accumulatedMeta.get(restructureId) ?? { quantity: 0, amount: 0 };
      currentAcc.amount += transaction.amount;
      currentAcc.quantity += quantity;
      accumulatedMeta.set(restructureId, currentAcc);
    }
  });

  for (const meta of res.values()) {
    const accData = accumulatedMeta.get(meta.restructureId)!;
    if (accData.quantity <= 0) {
      res.delete(meta.restructureId);
    } else {
      res.set(meta.restructureId, { ...meta, amount: accData.amount });
    }
  }

  return res;
}
