import AddIcon from '@mui/icons-material/Add';
import { Button, IconButton, SvgIcon, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import { useRecoilState, useRecoilValue } from 'recoil';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PresentModeOff from '../../../assets/icons/present-mode-off.svg?react';
import PresentMode from '../../../assets/icons/present-mode.svg?react';
import { useModalState } from '../../../components/Modal/ModalContext';
import { ToggleChip } from '../../../components/ToggleChip/ToggleChip';
import { ROUTES } from '../../../constants/RouteNameMapping';
import { DealBoardDealCategory, DealBoardViewMode } from '../../../types';
import { isPresentModeState, selectedDealCategoryState, viewModeState } from '../state/DealboardUIState';
import Kanban from '../../../assets/icons/kanban.svg?react';
import { GenericFallbacksWrapper } from '../../../components/Fallback/GenericFallbacksWrapper';
import { DealProvider } from '../providers/DealProvider';
import { ZINDEX } from '../../../constants/styles';
import { DealModal } from './DealModal/DealModal';
import { DealAddModalContent } from './DealModal/DealAddModalContent/DealAddModalContent';
import { DealFlowNavigation } from './DealFlowNavigation';

const HeaderContainer = styled('div')<{ isOnActiveDeals: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-right: 2rem;
  padding-bottom: 0;
  ${({ isOnActiveDeals }) =>
    !isOnActiveDeals &&
    `position: relative;
     display: flex;
     flex-direction: row-reverse;
  `};
`;

const ViewButtonWrapper = styled('div')`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const FullScreenHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 67px;
  box-shadow: 0 0.5px 0 rgba(75, 82, 93, 0.24);
  background-color: white;
  padding: 0 2rem 0 3rem;
`;

const FullScreenContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: ${ZINDEX.PRESENT_MODE_HEADER};
`;

const LogoAndChips = styled('div')`
  display: flex;
  justify-content: flex-start;
  gap: 40px;
  align-items: center;
`;

const ChipWrapper = styled('div')`
  display: flex;
`;

const FullScreenButtonsWrapper = styled('div')`
  display: flex;
  gap: 16px;
`;

const ITEMS = [
  { id: DealBoardDealCategory.CURRENT, value: 'Active Deals' },
  { id: DealBoardDealCategory.PASS, value: 'Passed Deals' },
  { id: DealBoardDealCategory.MISSED, value: 'Missed Deals' },
  { id: DealBoardDealCategory.TRACK, value: 'Tracked Deals' },
  { id: DealBoardDealCategory.CLOSED, value: 'Closed Deals' },
];
export const DealFlowHeaderV2 = () => {
  const { primary } = useTheme().colors;
  const [viewMode, setViewMode] = useRecoilState(viewModeState);
  const [isPresentMode, setIsPresentMode] = useRecoilState(isPresentModeState);
  const selectedDealCategory = useRecoilValue(selectedDealCategoryState);

  const { onOpenModal } = useModalState();

  const navigate = useNavigate();

  const onCreate = () => {
    onOpenModal(
      <GenericFallbacksWrapper>
        <DealProvider>
          <DealModal title={`Add New Deal`} width='743px'>
            <DealAddModalContent />
          </DealModal>
        </DealProvider>
      </GenericFallbacksWrapper>
    );
  };

  const getChipStyle = (isChecked: boolean) => ({
    margin: '0 2px',
    height: 'auto',
    padding: '8px',
    justifyContent: 'center',
    alignItems: 'center',
    border: isChecked ? `1px solid ${primary[60]} ` : 'none',
  });

  const isOnActiveDeals = selectedDealCategory.id === DealBoardDealCategory.CURRENT;

  const flowNavigation = isOnActiveDeals && <DealFlowNavigation />;

  if (isPresentMode)
    return (
      <FullScreenContainer>
        <FullScreenHeader>
          <LogoAndChips>
            <ChipWrapper>
              {ITEMS.map((option) => (
                <ToggleChip
                  key={option.id}
                  item={option}
                  onToggle={() => navigate(`/${ROUTES.DEAL_FLOW}/${ROUTES.DEALS}/${option.id}`)}
                  checked={option.id === selectedDealCategory?.id}
                  style={getChipStyle(option.id === selectedDealCategory?.id)}
                  typographyVariant='caption'
                />
              ))}
            </ChipWrapper>
          </LogoAndChips>
          <FullScreenButtonsWrapper>
            <IconButton onClick={onCreate} size='small' className='IconButtonSecondary'>
              <AddIcon color='secondary' />
            </IconButton>
            <Button
              onClick={() => setIsPresentMode(false)}
              variant='outlined'
              color={'secondary'}
              startIcon={
                <SvgIcon color={'secondary'}>
                  <PresentModeOff />
                </SvgIcon>
              }
            >
              Present
            </Button>
          </FullScreenButtonsWrapper>
        </FullScreenHeader>
        {flowNavigation}
      </FullScreenContainer>
    );
  return (
    <HeaderContainer isOnActiveDeals={isOnActiveDeals}>
      {flowNavigation}
      <ViewButtonWrapper sx={{ mt: '2rem' }}>
        <Button
          onClick={() => setIsPresentMode(true)}
          variant='outlined'
          color='secondary'
          startIcon={
            <SvgIcon color='secondary'>
              <PresentMode />
            </SvgIcon>
          }
        >
          Present
        </Button>
        {isOnActiveDeals && (
          <>
            <IconButton
              size='small'
              onClick={() => setViewMode(DealBoardViewMode.KANBAN)}
              className={viewMode === DealBoardViewMode.KANBAN ? 'IconButtonSecondary' : 'IconButtonPrimary'}
              color='secondary'
            >
              <Kanban className='customSvgColor' />
            </IconButton>

            <IconButton
              size='small'
              onClick={() => setViewMode(DealBoardViewMode.GRID)}
              className={viewMode === DealBoardViewMode.GRID ? 'IconButtonSecondary' : 'IconButtonPrimary'}
            >
              <FormatListBulletedIcon htmlColor={primary[60]} />
            </IconButton>
          </>
        )}
      </ViewButtonWrapper>
    </HeaderContainer>
  );
};
