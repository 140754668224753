import { KPI, KPI_COMPONENTS, KpiConfig, KpiConfigMeta, KpiSourceData } from '../../../../types';
import { COMPANY_VIEW_TYPE } from '../../../../data-models/view-config.data-model';
import { IMetricsDataModel } from '../../../../data-models/metrics.data-model';
import { NoGroupFundLabel } from '../../../../util/formatters/FundFormatter';
import { IFundDataModel } from '../../../../data-models/fund.data-model';
import { MoicKpiConfig } from './MoicKpiConfig';
import { PotentialUpside } from './PotentialUpside';
import { TotalValueConfig } from './TotalValueConfig';
import { TotalInvestmentConfig } from './TotalInvestmentConfig';
import { TotalProjectedValue } from './TotalProjectedValueConfig';
import { TotalRealizedValueConfig } from './TotalRealizedValueConfig';
import { TotalUnrealizedValueConfig } from './TotalUnrealizedValueConfig';
import { CountOfActiveCompaniesConfig } from './CountOfActiveCompaniesConfig';
import { CountOfCompaniesConfig } from './CountOfCompaniesConfig';
import { NumberOfMarkupsConfig } from './NumberOfMarkupsConfig';
import { ProjectedMoicKpiConfig } from './ProjectedMoicKpiConfig';
import { IrrKpiConfig } from './IrrKpiConfig';
import { LossRatioConfig } from './LossRatioConfig';
import { TotalInvestmentConfigRoundTracker } from './round-tracker/TotalInvestmentConfigRoundTracker';
import { CountOfCompaniesConfigRoundTracker } from './round-tracker/CountOfCompaniesConfigRoundTracker';
import { CountOfDealsConfigRoundTracker } from './round-tracker/CountOfDealsConfigRoundTracker';
import { TotalCostConfig } from './TotalCostConfig';
import { RealizedCostConfig } from './RealizedCostConfig';
import { UnrealizedCostConfig } from './UnrealizedCostConfig';
import { CapitalLossRatioConfig } from './CaptalLossRatioConfig';
import { ImpairmentRatioConfig } from './ImpairmentRatioConfig';

export class KpiCalculator {
  private static kpisOrder: KpiConfig[] = [
    new TotalRealizedValueConfig(),
    new TotalUnrealizedValueConfig(),
    new TotalInvestmentConfig(),
    new TotalValueConfig(), // realized and unrealized
    new PotentialUpside(), // projected value & total unrealized value
    new TotalProjectedValue(), // potential upside & total value
    new MoicKpiConfig(), // total val & total investment amount
    new ProjectedMoicKpiConfig(), // total projected value & investment amount
    new CountOfActiveCompaniesConfig(),
    new CountOfCompaniesConfig(),
    new NumberOfMarkupsConfig(),
    new IrrKpiConfig(),
    new LossRatioConfig(),
    new TotalCostConfig(),
    new RealizedCostConfig(),
    new UnrealizedCostConfig(), // total cost - realized cost
    new CapitalLossRatioConfig(),
    new ImpairmentRatioConfig(),
  ];

  private static roundTrackerKpisOrder: KpiConfig[] = [
    new TotalInvestmentConfigRoundTracker(),
    new CountOfDealsConfigRoundTracker(),
    new CountOfCompaniesConfigRoundTracker(),
  ];

  static getKpiMetas(companyViewType: COMPANY_VIEW_TYPE | undefined): KpiConfigMeta[] {
    const kpisOrder =
      companyViewType === COMPANY_VIEW_TYPE.ROUND_TRACKER
        ? KpiCalculator.roundTrackerKpisOrder
        : KpiCalculator.kpisOrder;

    return kpisOrder.map((kpi) => kpi.getMeta());
  }

  static calculate(data: KpiSourceData, companyViewType: COMPANY_VIEW_TYPE | undefined): KPI {
    const kpisOrder =
      companyViewType === COMPANY_VIEW_TYPE.ROUND_TRACKER
        ? KpiCalculator.roundTrackerKpisOrder
        : KpiCalculator.kpisOrder;

    const kpisToUse = kpisOrder.map((kpiConfig) => kpiConfig.getMeta().id);
    const kpis = {} as KPI;

    const kpisToUseMap = kpisToUse.reduce(
      (map, kpi) => ({ ...map, [kpi]: kpi }),
      {} as Record<KPI_COMPONENTS, KPI_COMPONENTS>
    );

    kpisOrder.forEach((kpiConfig) => {
      const meta = kpiConfig.getMeta();

      if (!kpisToUseMap[meta.id]) return;

      kpis[meta.id] = kpiConfig.calculate({ data, kpis });
    });

    return kpis;
  }
}

export function filterMetricsTransactionsAndFundDataByFund(
  metrics: IMetricsDataModel[],
  selectedFunds: Set<number>,
  fundsById: Map<number, IFundDataModel>
) {
  if (!metrics) {
    return [];
  }
  return metrics.map((metric) => {
    const transactions =
      metric.transactions?.filter((transaction) => selectedFunds.has(transaction.fundId ?? -1)) ?? [];
    return {
      ...metric,
      fundType: Array.from(
        new Set(
          transactions.map(
            (transaction) => fundsById.get(transaction.fundId ?? -1)?.fundType ?? NoGroupFundLabel
          )
        )
      ),
      transactions,
      fundData: metric.fundData?.filter((data) => selectedFunds.has(data.fundId ?? -1)) ?? [],
    };
  });
}
