import { FronteggProvider } from '@frontegg/react';
import { Provider } from 'jotai';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router';
import { GlobalStyles, ThemeProvider } from '@mui/material';
import { ModuleRegistry } from 'ag-grid-community';
import { AllEnterpriseModule, LicenseManager } from 'ag-grid-enterprise';
import { AgChartsCommunityModule } from 'ag-charts-community';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClientProvider } from '@tanstack/react-query';
import { RecoilRoot } from 'recoil';
import { getForesightStore } from './util/jotai-store';
import { initYup } from './util/yup-bootstrap';
import { RoutedApp } from './AppRoutes2';
import './assets/fonts/fonts.css';
import { AuthWrapperFrontEgg } from './AuthWrapperFrontEgg';
import { LoadingBarProvider } from './components/LoadingBar/LoadingBarContext';
import { AgGridLicense, FrontEggContextOptions } from './constants/Licenses';
import { globalStyles } from './theme/GlobalStyles';
import { queryClient } from './services/MaggieQueryClient';
import { greycroftTheme } from './theme/material-custom';
import { getEnvVariable } from './util/environment';
import { getFeatureFlagsDefaults } from './util/feature-flags';

ModuleRegistry.registerModules([AllEnterpriseModule.with(AgChartsCommunityModule)]);
LicenseManager.setLicenseKey(AgGridLicense);
initYup();

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path='/*'
      element={
        <FronteggProvider
          contextOptions={FrontEggContextOptions}
          hostedLoginBox={true}
          entitlementsOptions={{ enabled: true }}
        >
          <LoadingBarProvider>
            <QueryClientProvider client={queryClient}>
              <ThemeProvider theme={greycroftTheme}>
                <GlobalStyles styles={globalStyles} />
                <AuthWrapperFrontEgg>
                  <RoutedApp />
                </AuthWrapperFrontEgg>
                {/*<SessionExpiredDialog />*/}
              </ThemeProvider>
            </QueryClientProvider>
          </LoadingBarProvider>
        </FronteggProvider>
      }
    ></Route>
  )
);

const root = createRoot(document.getElementById('root')!);

function Index() {
  return (
    <StrictMode>
      <Provider store={getForesightStore()}>
        <RecoilRoot>
          <RouterProvider router={router} />
        </RecoilRoot>
      </Provider>
    </StrictMode>
  );
}

(async () => {
  const IndexWithLD = await asyncWithLDProvider({
    clientSideID: getEnvVariable('VITE_LAUNCH_DARKLY_CLIENT_ID'),
    flags: getFeatureFlagsDefaults(),
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  });
  root.render(
    <IndexWithLD>
      <Index />
    </IndexWithLD>
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
