import { merge } from 'lodash-es';
import * as yup from 'yup';

export function baseModelFields() {
  return {
    createdAt: yup.date().nullable().default(null),
    createdBy: yup.string().nullable().default('').customMeta({
      formatter: 'userByEmail',
    }),
    updatedAt: yup.date().nullable().default(null),
    updatedBy: yup.string().nullable().default('').customMeta({
      formatter: 'userByEmail',
    }),
  };
}

export function baseModelSchema() {
  return yup.object().shape(baseModelFields());
}

export function baseModelDataModel() {
  return yup.object().shape({
    ...baseModelFields(),
    createdAt: yup.string().nullable().default(null),
    updatedAt: yup.string().nullable().default(null),
  });
}

export type BaseModel = yup.InferType<ReturnType<typeof baseModelDataModel>>;
export function createBaseModel(overrides: Partial<BaseModel> = {}): BaseModel {
  return merge({}, baseModelDataModel().getDefault(), overrides);
}

export function baseModelWithIDFields() {
  return {
    ...baseModelFields(),
    id: yup.number().nullable().required().default(0),
  };
}

export function baseModelWithIDSchema() {
  return yup.object().shape(baseModelWithIDFields());
}

export function baseModelWithIdDataModel() {
  return yup.object().shape({
    ...baseModelWithIDFields(),
    createdAt: yup.string().nullable().default(null),
    updatedAt: yup.string().nullable().default(null),
  });
}

export type BaseModelWithID = yup.InferType<ReturnType<typeof baseModelWithIdDataModel>>;

export function createBaseModelWithID(overrides: Partial<BaseModelWithID> = {}): BaseModelWithID {
  return merge({}, baseModelWithIdDataModel().getDefault(), overrides);
}
