import { useRecoilCallback, useResetRecoilState } from 'recoil';
import { useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { IScenarioDataModel } from '../../../../data-models/scenario.data-model';
import { useToastMessageState } from '../../../../components/ToastMessage/ToastMessageProvider';
import {
  createScenario,
  deleteScenario,
  updateScenario,
} from '../../../../services/queries/MaggieScenarioQueries';
import { currentScenarioState, scenariosByCompanyIdState } from '../../state/ScenariosState';
import { getErrorMessage } from '../../../../services/queryHelpers';
import { useLoadingBarState } from '../../../../components/LoadingBar/LoadingBarContext';
import { LoadingId } from '../../../../types';
import { ROUTES } from '../../../../constants/RouteNameMapping';
import { primaryCaptableState } from '../../CapTable/CapTableDataState';
import { selectedCompanyIdProfile } from '../../state/UIState';
import { useAllScenariosLocation } from './useAllScenariosLocation';

export const ProgrammaticNavigationFlag = 'programmatic';

export function useScenarioActions() {
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();
  const { actions } = useLoadingBarState();

  const navigate = useNavigate();
  const allScenariosLocation = useAllScenariosLocation();
  const { search: searchParams } = useLocation();
  const [loading, setLoading] = useState(false);
  const resetCurrent = useResetRecoilState(currentScenarioState);

  const goToAllScenarios = useCallback(() => {
    if (!allScenariosLocation) return;
    navigate(allScenariosLocation, { state: { [ProgrammaticNavigationFlag]: true } });
  }, [allScenariosLocation, navigate]);

  const handleCancel = useCallback(() => {
    resetCurrent();
    goToAllScenarios();
  }, [resetCurrent, goToAllScenarios]);

  /**  preserves search params */
  const gotoSettings = useCallback(() => {
    navigate({
      pathname: `${allScenariosLocation}/${ROUTES.COMPANY_PROFILE_SINGLE_SCENARIO}/${ROUTES.COMPANY_PROFILE_SCENARIO_SETTINGS}`,
      search: searchParams,
    });
  }, [allScenariosLocation, navigate, searchParams]);

  /**  preserves search params */
  const gotoVisualization = useCallback(() => {
    navigate({
      pathname: `${allScenariosLocation}/${ROUTES.COMPANY_PROFILE_SINGLE_SCENARIO}/${ROUTES.COMPANY_PROFILE_SCENARIO_RUN}`,
      search: searchParams,
    });
  }, [navigate, allScenariosLocation, searchParams]);

  const handleUpdateScenario = useRecoilCallback(
    ({ snapshot, set }) =>
      async (id: number, payload: Partial<IScenarioDataModel>) => {
        try {
          setLoading(true);
          actions.startLoading(LoadingId.updateScenario);
          const data = await updateScenario(id, payload);

          const { companyId } = data;

          if (snapshot.getLoadable(scenariosByCompanyIdState(companyId)).state === 'hasValue') {
            set(scenariosByCompanyIdState(companyId), (current) => {
              return current.map((scenario) => {
                if (scenario.id === data.id) {
                  return data;
                }
                return scenario;
              });
            });
          }
          pushSuccessToast({ message: 'Scenario updated successfully' });
          // if we are not going back to all scenarios, we need to set current scenario to data
          goToAllScenarios();
        } catch (err) {
          console.error(err);
          const message = getErrorMessage(err, 'Error updating scenario');
          pushErrorToast({ message });
        } finally {
          actions.stopLoading(LoadingId.updateScenario);
          setLoading(false);
        }
      },
    []
  );

  const handleCreateScenario = useRecoilCallback(
    ({ snapshot, set }) =>
      async (payload: Partial<IScenarioDataModel>) => {
        actions.startLoading(LoadingId.createScenario);
        try {
          setLoading(true);
          const data = await createScenario(payload);
          const { companyId } = data;
          if (snapshot.getLoadable(scenariosByCompanyIdState(companyId)).state === 'hasValue') {
            set(scenariosByCompanyIdState(companyId), (current) => {
              return [...current, data];
            });
          }
          // if we are not going back to all scenarios, we need to set current scenario to data
          goToAllScenarios();
          pushSuccessToast({ message: 'Scenario created successfully' });
        } catch (err) {
          console.error(err);
          const message = getErrorMessage(err, 'Error creating scenario');
          pushErrorToast({ message });
        } finally {
          actions.stopLoading(LoadingId.createScenario);
          setLoading(false);
        }
      },
    []
  );

  const handleDeleteScenario = useRecoilCallback(
    ({ snapshot, set }) =>
      async (id: number) => {
        actions.startLoading(LoadingId.deleteScenario);
        try {
          setLoading(true);
          const companyId = snapshot.getLoadable(selectedCompanyIdProfile).valueOrThrow();
          await deleteScenario(id);
          if (snapshot.getLoadable(scenariosByCompanyIdState(companyId)).state === 'hasValue') {
            set(scenariosByCompanyIdState(companyId), (current) => {
              return current.filter((scenario) => scenario.id !== id);
            });
          }
          pushSuccessToast({ message: 'Scenario deleted successfully' });
        } catch (err) {
          console.error(err);
          const message = getErrorMessage(err, 'Error deleting scenario');
          pushErrorToast({ message });
        } finally {
          actions.stopLoading(LoadingId.deleteScenario);
          setLoading(false);
        }
      },
    []
  );

  const handleUpdateScenarioCaptable = useRecoilCallback(
    ({ snapshot, set }) =>
      async (id: number, companyId: number) => {
        const primaryCaptable = await snapshot.getPromise(primaryCaptableState(companyId));
        try {
          if (!primaryCaptable) {
            throw new Error('No primary captable found');
          }
          setLoading(true);
          actions.startLoading(LoadingId.updateScenario);
          const data = await updateScenario(id, { captableId: primaryCaptable.id });

          if (snapshot.getLoadable(scenariosByCompanyIdState(companyId)).state === 'hasValue') {
            set(scenariosByCompanyIdState(companyId), (current) => {
              return current.map((scenario) => {
                if (scenario.id === data.id) {
                  return data;
                }
                return scenario;
              });
            });
          }
          pushSuccessToast({ message: 'Captable updated successfully' });
          set(currentScenarioState, (curr) => ({
            ...curr,
            captableId: data.captableId,
            outdated: data.outdated,
          }));
          return data;
        } catch (err) {
          console.error(err);
          const message = getErrorMessage(err, 'Error updating captable');
          pushErrorToast({ message });
        } finally {
          actions.stopLoading(LoadingId.updateScenario);
          setLoading(false);
        }
      },
    []
  );

  const handleRunScenario = useRecoilCallback(
    () => async () => {
      gotoVisualization();
    },
    []
  );

  return {
    handleUpdateScenario,
    handleCreateScenario,
    handleDeleteScenario,
    handleRunScenario,
    handleUpdateScenarioCaptable,
    loading,
    handleCancel,
    gotoVisualization,
    gotoSettings,
  };
}
