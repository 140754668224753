import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ROUTES } from '../../../../../constants/RouteNameMapping';
import { IListDataModel, ListType } from '../../../../../data-models/list.data-model';
import { activeCompanyListIdState, listsState } from '../../../../../pages/CompanyProfiles/state/ListsState';

export function useSyncListsFromParams() {
  const companyLists = useRecoilValue(listsState);

  const setActiveListId = useSetRecoilState(activeCompanyListIdState);
  const navigate = useNavigate();
  const { listId } = useParams();

  const mappedCompanyLists = useMemo(() => {
    if (!listsState || !companyLists?.length) return null;
    const result: Record<ListType, IListDataModel[]> = {
      [ListType.DISCOVERY]: [],
      [ListType.MANAGEMENT]: [],
      [ListType.CUSTOM]: [],
      [ListType.ARCHIVE]: [],
    };

    const today = new Date();

    companyLists.forEach((l) => {
      if (l.archiveDate) {
        // Check to see if 30 days has passed since archiving
        const diff = new Date(l.archiveDate).getTime() - today.getTime();
        const numOfDays = diff / (1000 * 3600 * 24);

        if (numOfDays < 30 && numOfDays > 0) {
          result[ListType.ARCHIVE].push(l);
        }
      } else {
        result[l.type].push(l);
      }
    });
    return result;
  }, [companyLists]);

  useEffect(() => {
    const ListIdNumeric = Number(listId);

    if (Number.isNaN(ListIdNumeric)) {
      const preselectedList = mappedCompanyLists?.[ListType.DISCOVERY]?.[0];
      if (preselectedList) {
        navigate(`/${ROUTES.COMPANIES}/${ROUTES.LISTS}/${preselectedList?.id}`, { replace: true });
      }
    } else {
      setActiveListId(ListIdNumeric);
    }
  }, [companyLists, listId, mappedCompanyLists, navigate, setActiveListId]);
}
