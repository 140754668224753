import { Alert, Button, Stack, Typography } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAtomValue, useSetAtom } from 'jotai';
import {
  isEditingFundDataState,
  fundDataByDateState,
  selectedDateFPState,
  fundDataSortedByDateDescStateFP,
} from '../state/FPState';
import { FormFundData, FundData, fundDataFormSchema } from '../../../schemas/FundData.schema';
import { schemaToFormFields } from '../../../util/schema-utils';
import { FormFieldWithLabelFactory } from '../../../components/Form/FormFieldAndLabelFactory';
import { FormLoadingButton } from '../../../components/Form/FormComponents';
import {
  getInitialFundDataForDate,
  useResetOnDateChange,
  useShouldDisableSubmit,
  useSubmitFundData,
} from './FundDataFormActions';

export function EditFundData({ fundId }: { fundId: number }) {
  const setIsEditing = useSetAtom(isEditingFundDataState);
  const dataByDate = useAtomValue(fundDataByDateState(fundId));
  const selectedDate = useAtomValue(selectedDateFPState);
  const dataSortedByDate = useAtomValue(fundDataSortedByDateDescStateFP(fundId));
  const initialValue = useMemo(() => {
    return getInitialFundDataForDate(selectedDate, dataByDate, dataSortedByDate);
  }, [dataByDate, dataSortedByDate, selectedDate]);

  const methods = useForm<FormFundData>({
    defaultValues: { ...initialValue, date: selectedDate },
    resolver: yupResolver(fundDataFormSchema()),
  });

  const disabled = useShouldDisableSubmit({ methods, dataByDate });
  const onSubmit = useSubmitFundData({ methods, dataByDate, fundId });
  const [loading, setLoading] = useState(false);
  const _onSubmit = useCallback(async () => {
    setLoading(true);
    const data = await onSubmit();
    if (data) methods.reset({ ...data } as unknown as FormFundData);
    setLoading(false);
  }, [methods, onSubmit]);

  return (
    <FormProvider {...methods}>
      <Stack width='100%' height='100%'>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography>{`Edit Fund Data`}</Typography>
          <Stack direction={'row'} gap={'1rem'}>
            <Button variant={'outlined'} color={'secondary'} onClick={() => setIsEditing(false)}>
              Cancel
            </Button>
            <FormLoadingButton disabled={disabled} onClick={_onSubmit} loading={loading}>
              Save Changes
            </FormLoadingButton>
          </Stack>
        </Stack>
        <FundDataFields dataByDate={dataByDate} methods={methods} fundId={fundId} />
      </Stack>
    </FormProvider>
  );
}

interface FundDataFieldsProps {
  dataByDate: Map<string, FundData>;
  methods: UseFormReturn<FormFundData>;
  fundId: number;
}
export function FundDataFields({ dataByDate, methods, fundId }: FundDataFieldsProps) {
  const render = useResetOnDateChange({ methods, dataByDate, fundId });

  const fields = useMemo(() => {
    return schemaToFormFields(
      fundDataFormSchema().pick(['date', 'netAssets', 'contributedSecurities', 'deemedContributions'])
    );
  }, []);

  return (
    <Stack mt='2rem' display={'grid'} gridTemplateColumns={'repeat(4, minmax(0, 1fr))'} gap={'0.5rem'}>
      {fields.map((field) => (
        <FormFieldWithLabelFactory
          key={field.key === 'date' ? field.key : `${field.key}-${render}`}
          formField={field}
        />
      ))}
      <Alert severity='warning' style={{ gridColumn: '1 / -1' }}>
        Changes to these parameters will affect data displayed in Fund Reporting
      </Alert>
    </Stack>
  );
}
