import { ValueFormatterFunc } from 'ag-grid-community';
import { useAtomValue } from 'jotai/index';
import { useCallback } from 'react';
import { usersByEmailMapAtom } from '../services/state/AppConfigStateJ';

export default function useCreatedByFormatter() {
  const usersMapByEmailMap = useAtomValue(usersByEmailMapAtom);

  const createdByFormatter = useCallback<ValueFormatterFunc>(
    (params) => {
      const user = usersMapByEmailMap.get(params.value);

      return user ? `${user.firstName} ${user.lastName}` : params.value;
    },
    [usersMapByEmailMap]
  );

  return {
    createdByFormatter,
  };
}
