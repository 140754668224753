import * as yup from 'yup';
import { RendererType } from '../data-models/field.data-model';
import { dateField, integerField, usdField } from './common-schema-defs';

export const SecurityReportSchema = {
  base() {
    return {
      companyId: yup.number().label('Company'),
      currentHolding: yup.string().label('Position'),
      escrow: usdField(),
      fmv: usdField().label('FMV'),
      fundId: yup.number().label('Fund'),
      initialHolding: yup.string(),
      initialInvestmentDate: dateField(),
      investmentAmount: usdField(),
      latestEventDate: dateField(),
      pps: usdField().label('PPS'),
      realizedValue: usdField(),
      restructureId: yup.string(),
      sharesHeld: integerField(),
    };
  },
  model() {
    return yup.object().shape(SecurityReportSchema.base());
  },
  gridSchema() {
    const {
      companyId,
      currentHolding,
      investmentAmount,
      fmv,
      realizedValue,
      escrow,
      sharesHeld,
      fundId,
      ...rest
    } = SecurityReportSchema.base();
    return yup.object().shape({
      ...rest,
      companyId: companyId.gridMeta({
        renderer: RendererType.companyId,
        enableRowGroup: true,
      }),
      currentHolding: currentHolding.gridMeta({
        enableRowGroup: true,
      }),
      investmentAmount: investmentAmount.gridMeta({
        aggFunc: 'sum',
      }),
      fmv: fmv.gridMeta({
        aggFunc: 'sum',
      }),
      fundId: fundId.gridMeta({
        renderer: RendererType.id,
        formatter: 'fund',
      }),
      realizedValue: realizedValue.gridMeta({
        aggFunc: 'sum',
      }),
      escrow: escrow.gridMeta({
        aggFunc: 'sum',
      }),
      sharesHeld: sharesHeld.gridMeta({
        aggFunc: 'sum',
      }),
    });
  },
};

export type SecurityReport = yup.InferType<ReturnType<typeof SecurityReportSchema.model>>;
