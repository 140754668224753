import { Autocomplete } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { fundsAtom, fundsByIdMapAtom } from '../../services/state/AppConfigStateJ';
import { IIdSelectorProps, popperSx, renderInput } from './commonSelectorDefs';

export const FundSelector = (props: IIdSelectorProps) => {
  const { getOptionDisabled, value, onChange, componentsProps, ...rest } = props;

  const fundsById = useAtomValue(fundsByIdMapAtom);
  const funds = useAtomValue(fundsAtom);

  const fundOptions = useMemo<number[]>(() => {
    return funds.map((f) => f.id);
  }, [funds]);

  const getOptionLabel = useCallback(
    (option: number) => {
      return fundsById.get((option as number) ?? 0)?.name ?? '';
    },
    [fundsById]
  );

  return (
    <Autocomplete
      openOnFocus
      options={fundOptions}
      value={value ?? undefined}
      renderInput={renderInput}
      getOptionLabel={getOptionLabel as (option: unknown) => string}
      onChange={(e, newValue) => onChange(newValue as number | number[] | null)}
      getOptionDisabled={getOptionDisabled as (option: unknown) => boolean}
      componentsProps={{ popper: { sx: { ...popperSx } }, ...componentsProps }}
      {...rest}
    />
  );
};
