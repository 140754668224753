import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { assignedTemplateUuidsByCompanyIdState } from '../../../../../services/state/KPI/KPICompaniesState';
import { IKPIRequestDataModel } from '../../../../../data-models/kpi-requests.data-model';
import { kpiTemplatesMapByUuidState } from '../../../../../services/state/KPI/KPITemplatesState';

export function useCanResendRequest() {
  const assignmentTemplateUuidsByCompanyId = useRecoilValue(assignedTemplateUuidsByCompanyIdState);
  const templatesByUuid = useRecoilValue(kpiTemplatesMapByUuidState);

  return useCallback(
    (request: IKPIRequestDataModel): boolean => {
      const template = templatesByUuid.get(request.templateUuid);
      return Boolean(
        template &&
          assignmentTemplateUuidsByCompanyId.get(request.companyId)?.has(request.templateUuid) &&
          !template.isDeleted
      );
    },
    [assignmentTemplateUuidsByCompanyId, templatesByUuid]
  );
}

export const DisableResendMessage =
  'The request cannot be resent because the template or template assignment was deleted';
