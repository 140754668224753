import { FormControl, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useAtomValue } from 'jotai/index';
import React, { useCallback, useEffect, useState } from 'react';
import { currenciesAtom } from '../../../services/state/AppConfigStateJ';

type SelectCurrencyProps = {
  onSelectCurrency: (id: number) => void;
  initialValue: number | null;
  disabled?: boolean;
};

export const CurrencySelector = ({
  onSelectCurrency,
  initialValue,
  disabled = false,
}: SelectCurrencyProps) => {
  const currencies = useAtomValue(currenciesAtom);

  const [selected, setSelected] = useState(initialValue);

  const handleSelectCurrency = useCallback(
    (e: React.MouseEvent, value: number) => {
      const currencyId = value;
      if (currencyId) {
        setSelected(currencyId);
        onSelectCurrency(currencyId);
      }
    },
    [onSelectCurrency]
  );

  useEffect(() => {
    if (!currencies) return;
    const preselectedCurrency = currencies.find((d) => d.id === initialValue);
    const currencyToSelect = preselectedCurrency ? preselectedCurrency : currencies[0];
    setSelected(currencyToSelect?.id);
    onSelectCurrency(currencyToSelect.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencies]);

  if (!currencies || !currencies.length) return null;

  return (
    <FormControl>
      <ToggleButtonGroup
        aria-label='select-currency'
        disabled={disabled}
        onChange={handleSelectCurrency}
        value={selected}
        exclusive
        color='secondary'
      >
        {currencies.map((currency) => (
          <ToggleButton key={currency.id} value={currency.id} disabled={disabled} style={{ width: '3.5rem' }}>
            {`${currency.code}`}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </FormControl>
  );
};
