import { PropsWithChildren, useEffect } from 'react';
import { useAuth, useLoginWithRedirect, AdminPortal } from '@frontegg/react';
import { useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router';
import { Button, Stack } from '@mui/material';
import { GenericFallbacksWrapper } from './components/Fallback/GenericFallbacksWrapper';
import { ModalProvider } from './components/Modal/ModalContext';
import { ToastMessageProvider } from './components/ToastMessage/ToastMessageProvider';
import { authenticatedUserState } from './services/state/AuthState';
import { PermissionService } from './services/PermissionService';
import { RoleKey } from './services/PermissionAndRolesKeys';

export function AuthWrapperFrontEgg(props: PropsWithChildren) {
  const { user: frontEggUser, isAuthenticated } = useAuth();
  const loginWithRedirect = useLoginWithRedirect();
  const setUser = useSetRecoilState(authenticatedUserState);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated && frontEggUser) {
      setUser(frontEggUser);
      PermissionService.initService(frontEggUser);

      if (PermissionService.get().hasRole(RoleKey.itAdmin)) {
        AdminPortal.show();
        return;
      }

      if (localStorage.getItem('previousURL')) {
        navigate(localStorage.getItem('previousURL')!);
        localStorage.removeItem('previousURL');
      }
    } else {
      setUser(undefined);
      localStorage.setItem('previousURL', `${window.location.pathname}${window.location.search ?? ''}`);
      loginWithRedirect();
    }
  }, [frontEggUser, isAuthenticated, loginWithRedirect, navigate, setUser]);

  if (!PermissionService.isInitialized()) return null;
  if (PermissionService.get().hasRole(RoleKey.itAdmin))
    return (
      <Stack>
        <Button size='large' variant='contained' color='secondary' onClick={() => AdminPortal.show()}>
          Launch Admin Portal
        </Button>
      </Stack>
    );

  return (
    <GenericFallbacksWrapper>
      <ToastMessageProvider>
        <ModalProvider>{props.children}</ModalProvider>
      </ToastMessageProvider>
    </GenericFallbacksWrapper>
  );
}
