import { WritableAtom } from 'jotai';
import { SetStateAction, useMemo } from 'react';
import { KpiSection } from '../../../../../../data-models/company-financials.data-model';
import {
  createInitialResponseFromTemplate,
  createKPIRequestResponseDataModel,
  IKPIDataValue,
  IKPIRequestDataModel,
  IKPIRequestResponse,
  IKPIResponseActionPayload,
  IKPIResponseFormSectionData,
  KPIRequestStatus,
  periodFromGranularityAndFrequency,
} from '../../../../../../data-models/kpi-requests.data-model';
import { IKPITemplate } from '../../../../../../data-models/kpi-template.data-model';
import {
  createKPIRequestResponse,
  fetchKPIRequestResponseById,
  saveResponseDraftDebounced,
  updateKPIRequestResponse,
} from '../../../../../../services/queries/KPIRequestsQueries';
import { fetchKPITemplateById } from '../../../../../../services/queries/KPITemplatesQueries';
import { fetchCompanyRaw } from '../../../../../../services/queries/MaggieCompanyQueries';
import { getAssignedKPIs } from '../../../../../../services/state/KPI/KPITemplatesState';
import { getForesightStore } from '../../../../../../util/jotai-store';

export type KPISubmissionStateAtom = WritableAtom<
  KPISubmissionState,
  [SetStateAction<KPISubmissionState>],
  unknown
>;

export interface KPISubmissionState {
  allRequests: IKPIRequestDataModel[];
  currentRequest: IKPIRequestDataModel;
  currentResponse: IKPIRequestResponse;
  currentRequestIndex: number;
  currentTemplate: IKPITemplate | null;
  done: boolean;
  isLoadingData: boolean;
  isSubmitting: boolean;
  submissionError: string | null;
}

export function useKPIRequestRecipientActions(
  stateAtom: KPISubmissionStateAtom,
  store = getForesightStore()
) {
  const { get, set } = store;

  return useMemo(() => {
    const loadCurrentRequestData = async () => {
      const { currentRequest } = get(stateAtom);
      set(stateAtom, (prev: KPISubmissionState) => ({ ...prev, isLoadingData: true }));

      const currentTemplate = await fetchKPITemplateById(currentRequest.templateId);
      const currentResponse = await fetchKPIRequestResponseByIdOrNew(currentRequest, currentTemplate);

      set(stateAtom, (prev) => ({
        ...prev,
        currentResponse,
        currentTemplate,
        isLoadingData: false,
      }));
    };

    const goToNextRequest = async () => {
      const { allRequests, currentRequestIndex } = get(stateAtom);
      const nextRequestIndex = currentRequestIndex + 1;

      if (nextRequestIndex >= allRequests.length) {
        set(stateAtom, (prev) => ({ ...prev, done: true }));
        return;
      }

      set(stateAtom, (prev) => ({
        ...prev,
        currentRequest: allRequests[nextRequestIndex],
        currentRequestIndex: nextRequestIndex,
      }));

      await loadCurrentRequestData();
    };

    const saveDraft = (updatedData: Partial<IKPIResponseActionPayload>) => {
      const prevState = get(stateAtom);
      const { currentRequest, currentResponse } = prevState;
      const nextState = {
        ...prevState,
        currentResponse: { ...currentResponse, ...updatedData },
      };

      try {
        set(stateAtom, nextState);
        saveResponseDraftDebounced(currentRequest.id.toString(), updatedData);
      } catch (err) {
        console.error('Failed to save draft', err);
        set(stateAtom, prevState);
      }
    };

    const onKPIGridDataUpdated = async (section: KpiSection, updatedData: IKPIDataValue[]) => {
      const { currentRequest, currentResponse } = get(stateAtom);
      const newKPIData = currentResponse.kpiData.values
        .filter((data) => data.section !== section)
        .concat(updatedData);

      const updatedResponse: IKPIResponseActionPayload = {
        ...currentResponse,
        kpiData: {
          currencyId: 1,
          period: periodFromGranularityAndFrequency(currentRequest.granularity, currentRequest.frequency),
          values: newKPIData,
        },
      };

      saveDraft(updatedResponse);
    };

    const onSectionDataUpdated = async (updatedData: IKPIResponseFormSectionData) => {
      const { currentResponse } = get(stateAtom);
      const updatedResponse: IKPIResponseActionPayload = {
        ...currentResponse,
        sectionData: currentResponse.sectionData.map((section) => {
          if (section.sectionId === updatedData.sectionId) {
            return updatedData;
          }
          return section;
        }),
      };

      saveDraft(updatedResponse);
    };

    const onSubmitResponse = async () => {
      const { currentRequest, currentResponse } = get(stateAtom);
      set(stateAtom, (prev) => ({ ...prev, isSubmitting: true }));

      try {
        await updateKPIRequestResponse(currentRequest.id.toString(), currentResponse);
      } catch (err) {
        set(stateAtom, (prev) => ({
          ...prev,
          submissionError: `Failed to submit response ${(err as Error)?.message}`,
        }));
      }

      set(stateAtom, (prev) => ({ ...prev, isSubmitting: false }));

      goToNextRequest();
    };

    return {
      onKPIGridDataUpdated,
      onSectionDataUpdated,
      loadRequestData: loadCurrentRequestData,
      saveDraft,
      skipKPIResponse: goToNextRequest,
      submitKPIResponse: onSubmitResponse,
    };
  }, [get, stateAtom, set]);
}
export type KPIRecipientActions = ReturnType<typeof useKPIRequestRecipientActions>;

export async function loadRecipientViewInitialData(companyId: number) {
  const company = await fetchCompanyRaw(companyId);
  const allAssignedRequests = await getAssignedKPIs();
  const requestsForCompany =
    allAssignedRequests
      .get(companyId)
      ?.filter((request) => {
        return request.status === KPIRequestStatus.Rejected || request.status === KPIRequestStatus.Sent;
      })
      .sort((requestA, requestB) => {
        return new Date(requestA.period).getTime() - new Date(requestB.period).getTime();
      }) ?? [];

  if (requestsForCompany.length > 0) {
    const firstRequest = requestsForCompany[0];
    const firstRequestTemplate = await fetchKPITemplateById(firstRequest.templateId);
    const firstRequestResponse = await fetchKPIRequestResponseByIdOrNew(firstRequest, firstRequestTemplate);

    return {
      company,
      requestsForCompany,
      firstRequestResponse,
      firstRequestTemplate,
    };
  }

  return {
    company,
    requestsForCompany,
    firstRequestResponse: createKPIRequestResponseDataModel({ id: -1 }),
    firstRequestTemplate: null,
  };
}
export type RecipientViewInitialData = Awaited<ReturnType<typeof loadRecipientViewInitialData>>;

async function fetchKPIRequestResponseByIdOrNew(
  request: IKPIRequestDataModel,
  template: IKPITemplate
): Promise<IKPIRequestResponse> {
  try {
    return await fetchKPIRequestResponseById(request.id);
  } catch (_err) {
    return await createKPIRequestResponse(
      request.id.toString(),
      createInitialResponseFromTemplate(request, template),
      true
    );
  }
}
