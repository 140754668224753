import { FC } from 'react';
import { Button } from '@mui/material';
import { createSearchParams, useLocation, useNavigate } from 'react-router';
import { ROUTES } from '../../../../constants/RouteNameMapping';
import { FullScreenParam } from '../../../../hooks/useFullScreen';

export const CreateScenarioButton: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleCreateScenario = () => {
    navigate({
      pathname: `${location.pathname}/${ROUTES.COMPANY_PROFILE_SINGLE_SCENARIO}/${ROUTES.COMPANY_PROFILE_SCENARIO_SETTINGS}`,
      search: createSearchParams({
        [FullScreenParam]: 'true',
      }).toString(),
    });
  };

  return (
    <Button
      variant='contained'
      color='secondary'
      onClick={handleCreateScenario}
      size='medium'
      style={{ justifySelf: 'end' }}
    >
      Create Scenario
    </Button>
  );
};
