import { styled } from '@mui/material/styles';
import { createContext, useCallback, useContext, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { PopupMenu } from '../../../../components/PopupMenu/PopupMenu';
import { COMPANY_VIEW_TYPE } from '../../../../data-models/view-config.data-model';
import { CHART_COMPONENTS, SelectItem } from '../../../../types';
import { SubSection } from '../../../CompanyProfiles/Summary/components/SubSection';
import { useView } from '../../hooks/useView';
import { selectedViewPF } from '../../state/ViewState';
import { FundFilterIndicator } from '../OverviewTable/Table/FundFilterIndicator';
import { ChartsList } from './ChartsList';

interface Props {
  id: string;
}

const Wrapper = styled('div')`
  margin: 1rem 2rem;
`;

type ChartItem = { id: CHART_COMPONENTS; value: string };
const commonChartTypes: ChartItem[] = [
  { id: CHART_COMPONENTS.MOST_RECENT_ROUNDS, value: 'Most Recent Rounds' },
  { id: CHART_COMPONENTS.INVESTMENT_AMOUNT_BY_STATE, value: 'Investment Amount By State' },
  { id: CHART_COMPONENTS.INVESTMENT_AMOUNT_BY_SECTOR, value: 'Investment Amount By Sector' },
  { id: CHART_COMPONENTS.INVESTMENT_AMOUNT_OVER_TIME, value: 'Investment Amount Over Time' },
];

const overviewAndReturnForecastChartTypes: ChartItem[] = [
  { id: CHART_COMPONENTS.AMOUNT_OF_DEALS_OVER_TIME, value: 'Participating Rounds' },
  { id: CHART_COMPONENTS.VALUATION_BRIDGE, value: 'Valuation Bridge' },
  { id: CHART_COMPONENTS.IRR_BY_FUND, value: 'IRR by Fund' },
  { id: CHART_COMPONENTS.IRR_BY_SECTOR, value: 'IRR By Sector' },
  { id: CHART_COMPONENTS.IRR, value: 'IRR' },
  { id: CHART_COMPONENTS.MOIC_BY_SECTOR, value: 'MOIC By Sector' },
  {
    id: CHART_COMPONENTS.METRICS_BY_USER,
    value: 'Metrics By User',
  },
  { id: CHART_COMPONENTS.MOST_RECENT_PARTICIPATING_ROUNDS, value: 'Most Recent Investments' },
  { id: CHART_COMPONENTS.MOST_RECENT_REALIZATIONS, value: 'Most Recent Realizations' },
];

const roundTrackerChartTypes: ChartItem[] = [
  { id: CHART_COMPONENTS.MOST_RECENT_ROUNDS_RT, value: 'Most Recent Rounds' },
  { id: CHART_COMPONENTS.AMOUNT_OF_DEALS_OVER_TIME_RT, value: 'Number of Rounds Over Time' },
  { id: CHART_COMPONENTS.MOST_COMMON_CO_INVESTORS, value: 'Most Common Co-Investors' },
];

export const allChartItems = new Map(
  [...overviewAndReturnForecastChartTypes, ...commonChartTypes, ...roundTrackerChartTypes].map((v) => [
    v.id,
    v,
  ])
);

const ChartSection = createContext<string>('');
export const useChartSection = (): string => useContext(ChartSection);

export const ChartsSection = ({ id }: Props) => {
  const { getComponentsOrder, setComponentsOrder } = useView();
  const selectedCharts = getComponentsOrder(id);
  const view = useRecoilValue(selectedViewPF);

  const handleChange2 = useCallback(
    (selectedItems: SelectItem[]) => {
      const updatedComponentsOrder = selectedItems.map((v) => v.id as string);
      setComponentsOrder(id, updatedComponentsOrder);
    },
    [id, setComponentsOrder]
  );

  const menuItems: SelectItem[] = useMemo(() => {
    const allOverviewCharts = [...overviewAndReturnForecastChartTypes, ...commonChartTypes];

    return view?.companyViewType === COMPANY_VIEW_TYPE.ROUND_TRACKER
      ? [...roundTrackerChartTypes].sort(({ value: a }, { value: b }) => (a > b ? 1 : b > a ? -1 : 0))
      : allOverviewCharts.sort(({ value: a }, { value: b }) => (a > b ? 1 : b > a ? -1 : 0));
  }, [view?.companyViewType]);

  const menu = useMemo(() => {
    const selectedItems: SelectItem[] = selectedCharts.map((id) => ({
      id,
      value: menuItems.find((i) => i.id === id)?.value ?? '',
    }));

    return (
      <PopupMenu
        items={menuItems}
        onChange={handleChange2}
        selectedItems={selectedItems}
        label={'Select charts'}
        style={{ marginLeft: 'auto' }}
      />
    );
  }, [handleChange2, menuItems, selectedCharts]);

  return (
    <ChartSection.Provider value={id}>
      <Wrapper>
        <SubSection
          title=' Portfolio Performance Overview'
          actionComponent={menu}
          collapseProps={{ collapsible: true }}
          noCard
          style={{ margin: 0 }}
          endAdornment={<FundFilterIndicator />}
        >
          <ChartsList sectionId={id} />
        </SubSection>
      </Wrapper>
    </ChartSection.Provider>
  );
};
