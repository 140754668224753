import { Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { styled } from '@mui/material/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Outlet } from 'react-router';
import { GenericFallbacksWrapper } from '../../../components/Fallback/GenericFallbacksWrapper';
import { useSyncListsFromParams } from '../../../components/Layout/Navbar/Navigation/hooks/useSyncListsFromParams';
import { ErrorOccurredMessage } from '../components/Messages/HelpMessage';
import { activeCompanyListIdState, companyListState, listsByIdState, listsState } from '../state/ListsState';
import { usePrepItemsData } from '../hooks/usePrepItemsData';
import { CompanyList2 } from '../Companies2/CompanyList2';
import { MaggieFeatureFlags } from '../../../util/feature-flags';
import { CreateListModal, EditListModal } from '../Companies3/ListForms2/ListFormModals';
import { CompanyListSkeletonLoader } from './CompanyListSkeletonLoader';
import { FiltersAndViewSettings } from './FiltersAndViewSettings/FiltersAndViewSettings';
import { ListExportButton } from './ActionButtons/ListExportButton';
import { ListUploadButtons } from './ActionButtons/ListUploadButtons';

const Container = styled('section')`
  width: 100%;
  margin: 1rem 2rem;
  display: grid;
  grid-template-rows: min-content min-content 1fr;
`;

const HeadingWrapper = styled('div')`
  height: 4.5rem;
  display: grid;
  grid-template-columns: repeat(2, auto);
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const ButtonsWrapper = styled('div')`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 1rem;
  justify-content: end;
  justify-items: end;
`;

export function CompanyListLoader() {
  useSyncListsFromParams();
  return (
    <>
      <ListFormModals />
      <Outlet />
    </>
  );
}

export const Companies: FC = () => {
  const { showDownloadInLists } = useFlags<MaggieFeatureFlags>();
  const companyLists = useRecoilValueLoadable(listsState);
  const listsMap = useRecoilValue(listsByIdState);
  const activeListId = useRecoilValue(activeCompanyListIdState);
  const activeList = activeListId != null ? listsMap.get(activeListId) : null;
  const companyListLoadable = useRecoilValueLoadable(companyListState(activeListId ?? null));
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { ready, error } = usePrepItemsData(activeListId);

  if (companyListLoadable.state === 'hasError' || companyLists.state === 'hasError' || error) {
    return (
      <Container>
        <ErrorOccurredMessage />
      </Container>
    );
  }

  if (companyListLoadable.state === 'loading' || companyLists.state === 'loading' || !ready) {
    return (
      <Container>
        <CompanyListSkeletonLoader />
      </Container>
    );
  }

  return (
    <Container key={activeListId}>
      <HeadingWrapper>
        <Typography variant='h4'>{activeList?.name ?? ''}</Typography>
        <ButtonsWrapper>
          <ListUploadButtons />
          {showDownloadInLists && <ListExportButton />}
        </ButtonsWrapper>
      </HeadingWrapper>
      <FiltersAndViewSettings />
      <GenericFallbacksWrapper suspenseFallback={<CompanyListSkeletonLoader />}>
        <CompanyList2 setSelectedIndex={setSelectedIndex} selectedIndex={selectedIndex} />
      </GenericFallbacksWrapper>
    </Container>
  );
};

function ListFormModals() {
  return (
    <>
      <CreateListModal />
      <EditListModal />
    </>
  );
}
