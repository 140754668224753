import * as Sentry from '@sentry/react';
import { useAtomValue } from 'jotai/index';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { createUserDataModel } from '../../data-models/user.data-model';
import { usersAtom } from '../state/AppConfigStateJ';
import { authenticatedUserState } from '../state/AuthState';

export function useCurrentUser() {
  const authenticatedUser = useRecoilValue(authenticatedUserState);
  const users = useAtomValue(usersAtom);

  return useMemo(() => {
    if (!authenticatedUser) {
      Sentry.captureMessage('No authenticated user retrieved');
      return createUserDataModel({
        name: 'Unknown',
        id: -1,
      });
    }

    const authenticatedUserEmail = authenticatedUser.email.toLocaleLowerCase();
    const user = users.find((user) => user.email === authenticatedUserEmail);

    if (!user) {
      return createUserDataModel({
        name: authenticatedUser.name,
        id: -1,
      });
    }

    return user;
  }, [authenticatedUser, users]);
}
