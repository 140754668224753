import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import { useCallback, useState } from 'react';
import { cloneDeep, isEqual } from 'lodash-es';
import { useLocation, useNavigate } from 'react-router';
import { AxiosError, AxiosResponse } from 'axios';
import {
  allDealsState,
  dealStagesByIdMapState,
  dealStagesByNameMapState,
  dealTypesByIdMapState,
} from '../state/DealboardDataState';
import { useLoadingBarState } from '../../../components/LoadingBar/LoadingBarContext';
import { selectedDealState } from '../state/DealboardUIState';
import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import { LoadingId, ToastProps } from '../../../types';
import { createDeal, dealSort, deleteDeal, updateDeal } from '../../../services/queries/MaggieDealQueries';
import { IconTypes } from '../../../components/Icon';
import { IDealStageDataModel } from '../data-models/dealStage.data-model';
import { AtomUtils } from '../../../util/atom-utils';
import { useGetCompanyData } from '../../CompanyProfiles/hooks/useGetCompanyData';
import { partialEqualsIgnoreUnsetValues } from '../../../util/partialEquals';
import { IDealDataModel } from '../../../data-models/deal.data-model';
import { getErrorMessage } from '../../../services/queryHelpers';

export interface IDealActionCallbacks {
  onComplete?: () => void;
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
}

export const useDealActions2 = () => {
  const updateStage = useUpdateStage();
  const stagesMap = useRecoilValue(dealStagesByNameMapState);
  const stages = useRecoilValue(dealStagesByIdMapState);
  const dealTypes = useRecoilValue(dealTypesByIdMapState);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const handleDelete = useDeleteDeal();

  const handleClose = useCallback(
    async (deal: IDealDataModel) => {
      await updateStage(deal, {
        ...deal,
        stageId: stagesMap.get('Closed')!.id,
      });
    },
    [stagesMap, updateStage]
  );

  const handlePass = useCallback(
    async (deal: IDealDataModel, reason: string, passComments: string | null) => {
      await updateStage(deal as IDealDataModel, {
        ...deal,
        passComments,
        stageComments: reason,
        stageId: stagesMap.get('Pass')!.id,
      });
    },
    [stagesMap, updateStage]
  );

  const handleMoveToCurrent = useCallback(
    async (deal: IDealDataModel) => {
      const dealType = dealTypes.get(deal.dealTypeId);
      const dealStages = dealType?.dealStageIds.map((id) => stages.get(id));
      (dealStages as IDealStageDataModel[]).sort((a, b) => a.sortOrder - b.sortOrder);

      await updateStage(deal as IDealDataModel, {
        ...deal,
        stageId: (dealStages as IDealStageDataModel[])[0].id,
      });
    },
    [dealTypes, stages, updateStage]
  );

  const handleMissed = useCallback(
    async (deal: IDealDataModel, reason: string, passComments: string | null) => {
      await updateStage(deal as IDealDataModel, {
        ...deal,
        stageComments: reason,
        stageId: stagesMap.get('Missed')!.id,
        passComments,
      });
    },
    [stagesMap, updateStage]
  );

  const handleTrack = useCallback(
    async (deal: IDealDataModel, reason: string, snoozeDate: Date | undefined) => {
      await updateStage(deal as IDealDataModel, {
        ...deal,
        stageComments: reason,
        snoozeDate: snoozeDate?.toISOString(),
        stageId: stagesMap.get('Track')!.id,
      });
    },
    [stagesMap, updateStage]
  );

  return {
    handleDelete,
    showConfirmDelete,
    setShowConfirmDelete,
    handleClose,
    handlePass,
    handleTrack,
    handleMissed,
    handleMoveToCurrent,
  };
};

export function useDeleteDeal() {
  const { actions } = useLoadingBarState();
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();
  const { deleteDeals } = useDealStateUpdates();

  return useCallback(
    async (dealToDelete: IDealDataModel, callbacks: IDealActionCallbacks = {}) => {
      const undo = deleteDeals([dealToDelete]);
      actions.startLoading(LoadingId.deleteDeal);

      try {
        await deleteDeal(dealToDelete.id);
        pushSuccessToast({ message: 'Deal successfully deleted' });
        callbacks.onSuccess?.();
      } catch (err) {
        undo();
        pushErrorToast({ message: 'Failed to delete deal' });
        callbacks?.onError?.(err);
      } finally {
        actions.stopLoading(LoadingId.deleteDeal);
        callbacks.onComplete?.();
      }
    },
    [actions, deleteDeals, pushErrorToast, pushSuccessToast]
  );
}

export function useUpdateStage() {
  const stagesById = useRecoilValue(dealStagesByIdMapState);
  const { actions } = useLoadingBarState();
  const { pushErrorToast, pushInfoToast } = useToastMessageState();
  const doUpdateStage = useDoUpdateStage();
  const getCompanyData = useGetCompanyData();

  return useCallback(
    async (deal: IDealDataModel, updates: Partial<IDealDataModel>, showUndoAndProgress = true) => {
      const updatedDeal: IDealDataModel = {
        ...cloneDeep(deal),
        ...updates,
        stageUpdateDate: new Date().toISOString(),
      };

      const nextStage = stagesById.get(updates.stageId!);
      const nextStageName = nextStage?.name ?? 'unknown';

      const [company] = await getCompanyData([deal.companyId]);
      if (!company) {
        pushErrorToast({ message: 'An unexpected error has occurred' });
        return;
      }
      const companyName = `"${company.name}"`;

      if (showUndoAndProgress) {
        actions.startLoading(LoadingId.updateDeal);
      }

      await doUpdateStage(deal, updatedDeal, {
        onSuccess: () => {
          if (showUndoAndProgress) {
            pushInfoToast({
              title: `THE DEAL WAS MOVED TO ${nextStageName.toUpperCase()} STAGE`,
              message: `${companyName} deal has been successfully moved to the ${nextStageName} stage`,
              buttonLabel: 'Undo',
              buttonIconType: IconTypes.UNDO,
              buttonAction: () => {
                doUpdateStage(updatedDeal, deal);
              },
            });
          }
        },
        onError: () => {
          pushErrorToast({ message: `Failed to move deal to '${nextStageName}' stage` });
        },
        onComplete: () => {
          actions.stopLoading(LoadingId.updateDeal);
        },
      });
    },
    [actions, doUpdateStage, getCompanyData, pushErrorToast, pushInfoToast, stagesById]
  );
}

export function useDoUpdateStage() {
  const { updateDeals } = useDealStateUpdates();

  return useCallback(
    async (
      dealPrevState: IDealDataModel,
      dealNextState: IDealDataModel,
      callbacks?: IDealActionCallbacks
    ) => {
      const undo = updateDeals([dealNextState]);

      try {
        await updateDeal(dealNextState);
        callbacks?.onSuccess?.();
      } catch (error) {
        undo();
        callbacks?.onError?.(error);
      } finally {
        callbacks?.onComplete?.();
      }
    },
    [updateDeals]
  );
}

export function useCreateDeal() {
  const { pushErrorToast, pushInfoToast } = useToastMessageState();
  const { actions } = useLoadingBarState();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { addNewDeal } = useDealStateUpdates();

  return useCallback(
    async (
      dealInfo: Partial<IDealDataModel>,
      byPassDuplicate = false,
      showErrorToastIfDuplicate = true
    ): Promise<IDealDataModel | undefined> => {
      actions.startLoading(LoadingId.createDeal);
      const params = byPassDuplicate ? { params: { byPassDuplicate } } : undefined;
      try {
        const newDeal = await createDeal(dealInfo, params);
        addNewDeal(newDeal);

        const toastConfig: ToastProps = {
          message: 'Deal successfully created!',
        };
        if (!pathname.includes('deal-flow')) {
          toastConfig.buttonLabel = 'Go to Board';
          toastConfig.buttonAction = () => navigate('/deal-flow');
        }
        pushInfoToast(toastConfig);
        return newDeal;
      } catch (err) {
        console.error(err);
        if (isDuplicateDealResponseError(err) && !showErrorToastIfDuplicate) {
          throw err;
        } else {
          const message = getErrorMessage(err, 'An error occurred while trying to create a deal.');
          pushErrorToast({ message });
        }
      } finally {
        actions.stopLoading(LoadingId.createDeal);
      }
    },
    [actions, addNewDeal, navigate, pathname, pushErrorToast, pushInfoToast]
  );
}

export function useUpdateDeal2() {
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();
  const updateSelectedDealIfNeeded = useUpdateSelectedDealIfNeeded();
  const { updateDeals } = useDealStateUpdates();

  return useCallback(
    async (dealToUpdate: IDealDataModel, updates: Partial<IDealDataModel>, showSuccessMessage = true) => {
      if (isEqual(dealToUpdate, { ...dealToUpdate, ...updates })) {
        return;
      }

      if (partialEqualsIgnoreUnsetValues(updates, dealToUpdate)) return;

      const updatedDeal: IDealDataModel = {
        ...cloneDeep(dealToUpdate as IDealDataModel),
        ...updates,
        stageUpdateDate: new Date().toISOString(),
      };

      const undo = updateDeals([updatedDeal]);
      updateSelectedDealIfNeeded(updatedDeal);

      try {
        await updateDeal(updatedDeal);

        if (showSuccessMessage) {
          pushSuccessToast({ message: 'Successfully updated deal' });
        }
      } catch (err) {
        undo();
        updateSelectedDealIfNeeded(dealToUpdate as IDealDataModel);
        pushErrorToast({ message: 'Failed to update deal' });
      }
    },
    [pushErrorToast, pushSuccessToast, updateDeals, updateSelectedDealIfNeeded]
  );
}

export function useDealStateUpdates() {
  return {
    updateDeals: useRecoilCallback(
      ({ set }) =>
        (updatedDeals: IDealDataModel[]) => {
          const updatedDealsMap = updatedDeals.reduce((res, deal) => {
            return res.set(deal.id, deal);
          }, new Map<number, IDealDataModel>());
          set(allDealsState, (currentDeals) => {
            return currentDeals.map((deal) => updatedDealsMap.get(deal.id) ?? deal);
          });

          return AtomUtils.instance.getUndo(allDealsState.key);
        },
      []
    ),
    deleteDeals: useRecoilCallback(
      ({ set }) =>
        (dealsToDelete: IDealDataModel[]) => {
          const dealsToDeleteMap = dealsToDelete.reduce((res, deal) => {
            return res.set(deal.id, deal);
          }, new Map<number, IDealDataModel>());
          set(allDealsState, (currentDeals) => {
            return currentDeals.filter((deal) => dealsToDeleteMap.get(deal.id) === undefined);
          });

          return AtomUtils.instance.getUndo(allDealsState.key);
        },
      []
    ),
    addNewDeal: useRecoilCallback(
      ({ set, snapshot }) =>
        (newDeal: IDealDataModel) => {
          if (snapshot.getInfo_UNSTABLE(allDealsState).isSet) {
            set(allDealsState, (currentDeals) => {
              return [...cloneDeep(currentDeals), newDeal].sort(dealSort);
            });
          }

          return AtomUtils.instance.getUndo(allDealsState.key);
        },
      []
    ),
  };
}

export function useUpdateSelectedDealIfNeeded() {
  const setSelectedDeal = useSetRecoilState(selectedDealState);

  return useCallback(
    (updatedDeal: IDealDataModel) => {
      setSelectedDeal((selectedDeal) => {
        if (selectedDeal?.id === updatedDeal.id) {
          return updatedDeal;
        }
        return selectedDeal;
      });
    },
    [setSelectedDeal]
  );
}

interface IDuplicateDealErrorResponse extends AxiosResponse {
  data: {
    deal: IDealDataModel;
  };
}
export interface IDuplicateDealError extends AxiosError {
  response: IDuplicateDealErrorResponse;
}

export function isDuplicateDealResponseError(err: unknown): err is IDuplicateDealError {
  return (
    (err as AxiosError)?.response?.status === 409 &&
    (err as IDuplicateDealError).response?.data?.deal !== undefined
  );
}
