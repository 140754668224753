import { Autocomplete, TextField } from '@mui/material';
import { useAtomValue } from 'jotai/index';
import { useMemo } from 'react';
import { Option } from '../../../components/MuiSingleSelect/MuiSingleSelect';
import { activeUsersAtom } from '../../../services/state/AppConfigStateJ';

export const DealLeadFilter = ({
  selectedDealLead,
  onChange,
}: {
  selectedDealLead: number | null;
  onChange: (newValue: number | null) => void;
}) => {
  const activeUsers = useAtomValue(activeUsersAtom);

  const users = useMemo(() => {
    return activeUsers.map((user) => ({ id: user.id, value: user.name }));
  }, [activeUsers]);

  return (
    <Autocomplete
      options={users}
      value={users.find((u) => u.id === selectedDealLead) ?? null}
      onChange={(_, newValue: Option | null) => onChange((newValue?.id as number) ?? null)}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={selectedDealLead ? '' : 'Filter by Deal Lead'}
          aria-label='no-label'
        />
      )}
      getOptionLabel={(option) => option.value}
      style={{ width: '14rem' }}
      autoHighlight
      selectOnFocus
      openOnFocus
      disablePortal
    />
  );
};
