import { ColDef } from 'ag-grid-community';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';
import { IKPIRequestDataModel, KPIRequestStatus } from '../../../../../data-models/kpi-requests.data-model';
import { kpiRequestsGridSchema } from '../../../../../data-schemas/KPIRequestSchema';
import { MaggieFeatureFlags } from '../../../../../util/feature-flags';
import { useSchemaToGrid } from '../../../../../util/schema-utils';
import ResponsesActionsCellRenderer from '../components/ResponsesActionsCellRenderer';
import { useCommonKpiColDefs } from './useCommonKpiColDefs';

export default function useKPIResponsesColDefs(status: KPIRequestStatus) {
  const {
    respondentColDef,
    periodColDef,
    templateColDef,
    frequencyColDef,
    granularityColDef,
    commonCompanyDefs,
  } = useCommonKpiColDefs();

  const schemaToGrid = useSchemaToGrid();

  const { showGranularitySelector } = useFlags<MaggieFeatureFlags>();

  const columnDefs: ColDef<IKPIRequestDataModel>[] = useMemo(() => {
    const respondedAt = schemaToGrid(kpiRequestsGridSchema(), ['kpiRequestResponse.respondedAt']).at(0);
    const res = [
      commonCompanyDefs,
      templateColDef,
      frequencyColDef,
      periodColDef,
      respondentColDef,
      {
        maxWidth: 80,
        pinned: 'right',
        sortable: false,
        resizable: false,
        cellRenderer: ResponsesActionsCellRenderer,
        suppressHeaderMenuButton: true,
      },
    ] as ColDef<IKPIRequestDataModel>[];

    if (showGranularitySelector) {
      res.splice(3, 0, granularityColDef as unknown as ColDef<IKPIRequestDataModel>);
    }

    if (status === KPIRequestStatus.Submitted) {
      res.splice(1, 0, { ...respondedAt, initialSort: 'asc', minWidth: 180, filter: false } as ColDef);
    }

    return res;
  }, [
    commonCompanyDefs,
    frequencyColDef,
    granularityColDef,
    periodColDef,
    respondentColDef,
    schemaToGrid,
    showGranularitySelector,
    status,
    templateColDef,
  ]);

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      minWidth: 120,
      resizable: true,
      sortable: true,
      filter: false,
      suppressHeaderMenuButton: true,
      getQuickFilterText: () => {
        return '';
      },
    }),
    []
  );

  return {
    columnDefs,
    defaultColDef,
  };
}
