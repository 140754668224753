import { Button, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MaggieFeatureFlags } from '../../../../../../util/feature-flags';
import { useModalState } from '../../../../../Modal/ModalContext';
import {
  ListForm,
  useListFormState,
} from '../../../../../../pages/CompanyProfiles/Companies3/ListForms2/ListsFormState';
import { UploadCompanyListForm } from './UploadCompanyListForm';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const AddCompanyList = () => {
  const { secondary } = useTheme().colors;
  const { onOpenModal } = useModalState();
  const { showListForms2 } = useFlags<MaggieFeatureFlags>();
  const { setShowListForm } = useListFormState();

  const handleClick = () => {
    if (showListForms2) {
      setShowListForm(ListForm.CreateList);
    } else {
      onOpenModal(<UploadCompanyListForm />);
    }
  };

  return (
    <Wrapper>
      <Button variant='text' sx={{ color: secondary['20'] }} onClick={handleClick}>
        Add
      </Button>
    </Wrapper>
  );
};
