import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useToastMessageState } from '../../../../../components/ToastMessage/ToastMessageProvider';
import { useInvalidateKPIRequests } from '../../KPIRequests/KPIRequestResponse/hooks/useInvalidateKPIRequests';
import { useInvalidateData } from '../../../../../hooks/useInvalidateData';
import { kpiTemplateAssignmentsListState } from '../../../../../services/state/KPI/KPICompaniesState';
import { fetchTemplatesAssignments } from '../../../../../services/queries/KPITemplatesQueries';
import { getErrorMessage } from '../../../../../services/queryHelpers';
import { createKpiRequest } from '../../../../../services/queries/KPIRequestsQueries';
import { IKPIRequestDataModel } from '../../../../../data-models/kpi-requests.data-model';
import { ROUTES } from '../../../../../constants/RouteNameMapping';

export type CreateKpiRequestPayload = Pick<
  IKPIRequestDataModel,
  'companyId' | 'period' | 'templateUuid' | 'respondent' | 'frequency' | 'reminderThreshold' | 'granularity'
>;

export function useCreateKpiRequest() {
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();
  const invalidateRequests = useInvalidateKPIRequests();
  const invalidateData = useInvalidateData();
  const navigate = useNavigate();

  return useCallback(
    async (payload: CreateKpiRequestPayload) => {
      try {
        const response = await createKpiRequest(payload);
        Promise.all([
          invalidateRequests(),
          invalidateData({ state: kpiTemplateAssignmentsListState, query: fetchTemplatesAssignments }),
        ]);
        pushSuccessToast({
          message: 'Successfully created KPI request',
          buttonAction: () => navigate(`/${ROUTES.KPI}/${ROUTES.KPI_REQUESTS}`),
        });
        return response;
      } catch (err) {
        console.error(err);
        pushErrorToast({ message: getErrorMessage(err, 'Failed to create KPI request') });
      }
    },
    [invalidateData, invalidateRequests, navigate, pushErrorToast, pushSuccessToast]
  );
}
