import { useRecoilValue } from 'recoil';
import { Typography } from '@mui/material';
import { useCallback } from 'react';
import { kpiTemplateAssignmentsByIdState } from '../../../../../services/state/KPI/KPICompaniesState';
import { kpiTemplatesMapByUuidState } from '../../../../../services/state/KPI/KPITemplatesState';
import { companyState } from '../../../../../services/state/CompanyState';
import { useTemplateAssignmentActions } from '../hooks/useTemplateAssignmentActions';
import { ConfirmDeletionDialog } from '../../../../../components/Dialog/ConfirmDialog/ConfirmDeletionDialog';
import { allCompaniesActionState, selectedAssignmentIdState } from '../AllCompaniesState';
import { useAllKpiCompaniesActions } from './useAllKpiCompaniesActions';

export function DeleteAssignmentModal() {
  const id = useRecoilValue(selectedAssignmentIdState);
  const assignmentsMap = useRecoilValue(kpiTemplateAssignmentsByIdState);
  const templatesByUuid = useRecoilValue(kpiTemplatesMapByUuidState);
  const { companyId, templateUuid } = assignmentsMap.get(id ?? -1) ?? {};
  const company = useRecoilValue(companyState(companyId ?? -1));
  const template = templatesByUuid.get(templateUuid ?? '');
  const action = useRecoilValue(allCompaniesActionState);
  const { resetUIState } = useAllKpiCompaniesActions();
  const { deleteAssignment } = useTemplateAssignmentActions();

  const onConfirmDelete = useCallback(() => {
    if (typeof id !== 'number') return;
    deleteAssignment(id);
    resetUIState();
  }, [deleteAssignment, id, resetUIState]);

  if (action !== 'deleteAssignment' || typeof id !== 'number') return null;

  return (
    <ConfirmDeletionDialog
      open
      onClose={resetUIState}
      onConfirm={onConfirmDelete}
      title={`Delete template ${template?.name ? `"${template.name}" ` : ''} assigned  to ${company?.name}?`}
    >
      <Typography variant='body2' color={'text.secondary'} sx={{ pt: '.25rem', minWidth: '30rem' }}>
        {DeleteTemplateAssignmentWarning}
      </Typography>
    </ConfirmDeletionDialog>
  );
}

const DeleteTemplateAssignmentWarning =
  'If you delete this template assignment, any ongoing requests will be moved to "deleted" and new requests will not be automatically created in the future.';
