import { ColDef, KeyCreatorParams, ValueFormatterParams } from 'ag-grid-community';
import { createUSDFormatterDataModel } from '../../../data-models/formatter.data-model';
import { FMT, FormatterService } from '../../../util/formatter-service';
import { formatDate } from '../../../util/formatters/DateFormatters';
import { formatInteger, formatNumberToFixed2dp } from '../../../util/formatters/NumericFormatters';

export const numericCellClasses = ['monospace', 'text-align-right'];
export const numericColDefs: ColDef<unknown> = {
  filter: 'agNumberColumnFilter',
  headerClass: 'ag-left-aligned-header',
  cellClass: numericCellClasses,
  enableValue: true,
  valueFormatter: (params: ValueFormatterParams) =>
    params.value ? formatNumberToFixed2dp(params.value) : '',
};

export function getNumericColdDef<T>() {
  return {
    ...numericColDefs,
  } as ColDef<T>;
}

export function getIntegerColDefs<T>() {
  return {
    ...numericColDefs,
    cellClass: [...(numericColDefs.cellClass as string[]), 'int'],
    valueFormatter: (params: ValueFormatterParams) =>
      typeof params.value === 'number' ? formatInteger(params.value) : '',
  } as ColDef<T>;
}

export function getCurrencyColDefs<T>(maxDecimals = 2, minDecimals = maxDecimals) {
  const formatterDataModel = createUSDFormatterDataModel(maxDecimals, minDecimals);
  const formatter = FMT.get().getFormatterForModel(formatterDataModel);

  return {
    ...numericColDefs,
    valueFormatter: (params: ValueFormatterParams) =>
      typeof params.value === 'number' ? formatter(params.value) : '',
    cellClass: [...(numericColDefs.cellClass as string[]), 'currencyUSD'],
  } as ColDef<T>;
}

export function getDateColDefs<T>() {
  return {
    valueFormatter: (params: ValueFormatterParams<T>) => (params.value ? formatDate(params.value) : ''),
    filter: 'agDateColumnFilter',
    cellClass: ['monospace', 'text-align-right', 'dateISO'],
  } as ColDef<T>;
}
// ensures set filter and group values have 'N/A' instead of 'Blanks', but cells remain empty
export function genericKeyCreator(params: KeyCreatorParams) {
  return params.value || 'N/A';
}

export function getCurrencyFormatter(currencyCode: string) {
  return FormatterService.get().getFormatterForModel({
    type: 'number',
    id: `${currencyCode}-long`,
    config: {
      currency: currencyCode,
      style: 'currency',
    },
  });
}

export function booleanComparator(valueA: boolean, valueB: boolean) {
  return valueA === valueB ? 0 : valueA ? 1 : -1;
}

export function booleanFormatter(params: ValueFormatterParams | KeyCreatorParams) {
  return FMT.format('yesNo', params.value ?? false);
}
