import { CompanySearchResponse } from '../schemas/CompanySearchResponse.schema';
import { BaseLocationDataModel, CompanyLocationDataModel } from '../schemas/Location.schema';
import { Sector } from '../types';
import { ISocialLinkDataModel } from './social-link.data-model';

export interface ICompanyPersonDataModel {
  id: number;
  name: string;
}

export enum CompanyType {
  discovery = 'discovery',
  pipeline = 'pipeline',
  portfolio = 'portfolio',

  // UI only
  passed = 'passed',
}

export type CustomDataType = string | number | string[];

export interface ICompanyDataModel {
  acquirer: CompanySearchResponse | null;
  adjustHistoricKPIs?: boolean;
  aliases: string[] | null;
  assignee: number | null;
  boardActiveStatus?: string | null;
  boardDirectorId?: number | null;
  categoryRanking: number | null;
  cbinsightsLoaded?: boolean;
  ceoIds: ICompanyPersonDataModel[] | null;
  ceoName: string | null;
  commonName: string | null;
  companyType: string | null;
  createdBy?: string | null;
  crunchbaseId: string | null;
  customData: Record<string, CustomDataType> | null;
  reporting?: boolean;
  description: string | null;
  dropboxFolder: string | null;
  customerType: string | null;
  dealLeadId: number | null;
  dealTeamIds: number[] | null;
  dilutionAssumption: number | null;
  domain?: string | null;
  eventsRegion?: string | null;
  email?: string;
  exitDate: string | null;
  exitOwnershipPercentage: number | null;
  exitType: string | null;
  exitValuation: number | null;
  floRegion?: string | null;
  fmvBasis: string | null;
  foundedDate?: string;
  founderIds: ICompanyPersonDataModel[] | null;
  founderName: string | null;
  founders: string[] | null;
  fullTimeEmployees: number | null;
  fullyDilutedShares: number | null;
  fye?: number | null;
  gcDescription?: string | null;
  gcId: number | null;
  gcSource?: string | null;
  googleDriveFolder?: string;
  harmonicLoaded?: boolean;
  id: number;
  initialInvestmentDate: string | null;
  initialPreMoney: number | null;
  internalDescription: string | null;
  internalSourceId: number | null;
  investmentCommon?: string[] | null;
  kpiRequests?: number[];
  lastPostMoney: number | null;
  latestFundingAmount?: number | null;
  latestFundingDate?: string | null;
  latestFundingRound?: null | string;
  latestInvestors?: string[] | null;
  latestLeadInvestor?: string[];
  legalName: string | null;
  linkedinUrl?: string | null;
  listIds: number[];
  logoUrl: string | null;
  mostRcentRound: string | null;
  mostRecetRoundDate: string | null;
  name: string;
  originalDeal?: string[] | null;
  ownerShipPercentage?: string | null;
  ownershipPercentage: number | null;
  ownershipSource: string | null;
  pitchbookDescription?: string | null;
  pitchbookId?: string | null;
  primaryLocation?: BaseLocationDataModel | null;
  projectedExitValuation: number | null;
  projectedExitTiming?: string | null;
  projectedProceeds: string | number | null;
  raisedToDate: number | null;
  reserves: number | null;
  respondents?: string[] | null;
  scoreHistory?: IScoreHistoryDataModel[] | null;
  scoreHistorySummary?: IScoreHistorySummaryDataModel | null;
  secondaryLocations?: CompanyLocationDataModel[] | null;
  secondaryRespondents?: string[] | null;
  sector: Sector | null;
  sectorId: number | null;
  shortDescription: string | null;
  socials: ISocialLinkDataModel[];
  source: string | null;
  sourceCompany: string | null;
  sourceType: string | null;
  totalPIC?: number | null;
  updatedAt: string;
  updatedBy: string | null;
  website: string | null;
  city?: string | null;
  state?: string | null;
  stateCode?: string | null;
  stockProceedsComingFrom?: string[] | null;
  country?: string | null;
  countryCode?: string | null;
  region?: string | null;
  boardStatus?: string | null;
  followers?: number[];
  isConnected: boolean;
  reachout?: string | null;
  cbSector?: string | null;
  cbIndustry?: string | null;
  cbSubIndustry?: string | null;
  isMerged?: boolean;
  impliedEquityValue?: number | null;
}

export function createCompanyDataModel(overrides: Partial<ICompanyDataModel> = {}): ICompanyDataModel {
  return {
    acquirer: null,
    adjustHistoricKPIs: false,
    aliases: [],
    assignee: null,
    boardDirectorId: null,
    categoryRanking: null,
    cbinsightsLoaded: undefined,
    ceoIds: [],
    ceoName: null,
    commonName: null,
    companyType: null,
    createdBy: null,
    crunchbaseId: null,
    customData: null,
    customerType: null,
    dealLeadId: null,
    dealTeamIds: [],
    description: null,
    dilutionAssumption: null,
    dropboxFolder: null,
    exitDate: null,
    exitOwnershipPercentage: null,
    exitType: null,
    exitValuation: null,
    fmvBasis: null,
    founderIds: [],
    founderName: null,
    founders: [],
    fullTimeEmployees: null,
    fullyDilutedShares: null,
    gcDescription: null,
    gcId: null,
    gcSource: null,
    harmonicLoaded: undefined,
    id: 0,
    initialInvestmentDate: null,
    initialPreMoney: null,
    internalDescription: '',
    internalSourceId: null,
    lastPostMoney: null,
    latestFundingAmount: null,
    latestFundingDate: null,
    latestFundingRound: null,
    latestInvestors: [],
    legalName: null,
    linkedinUrl: null,
    listIds: [],
    secondaryLocations: [],
    logoUrl: null,
    mostRcentRound: '',
    mostRecetRoundDate: null,
    name: '',
    ownershipPercentage: null,
    ownershipSource: null,
    pitchbookDescription: null,
    pitchbookId: null,
    projectedExitValuation: null,
    projectedProceeds: null,
    raisedToDate: null,
    reserves: null,
    scoreHistory: null,
    scoreHistorySummary: null,
    sector: null,
    sectorId: null,
    socials: [],
    shortDescription: null,
    source: null,
    sourceCompany: null,
    sourceType: null,
    updatedAt: '',
    updatedBy: null,
    website: '',
    isConnected: false,
    isMerged: false,
    ...overrides,
  };
}

export function isPortfolioCompany(company: ICompanyDataModel) {
  return company.companyType === 'portfolio';
}

export interface IScoreHistoryDataModel {
  capitalScore: number;
  scoreChangedAt: string;
}

export interface IScoreHistorySummaryDataModel {
  lowestScoreHistory: IScoreHistoryDataModel;
  highestScoreHistory: IScoreHistoryDataModel;
}

export function createScoreHistorySummaryDataModel(
  overrides: Partial<IScoreHistorySummaryDataModel> = {}
): IScoreHistorySummaryDataModel {
  return {
    lowestScoreHistory: createScoreHistoryDataModel(),
    highestScoreHistory: createScoreHistoryDataModel(),
    ...overrides,
  };
}

export function createScoreHistoryDataModel(
  overrides: Partial<IScoreHistoryDataModel> = {}
): IScoreHistoryDataModel {
  return {
    capitalScore: 0,
    scoreChangedAt: '',
    ...overrides,
  };
}

export interface ICompanyPersonUnlinkDataModel {
  companyId: number;
  personId: number;
}
