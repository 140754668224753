import React from 'react';
import { Link } from 'react-router';
import { styled } from '@mui/material/styles';
import { useTheme, Typography } from '@mui/material';
import { Icon, IconTypes } from '../Icon';

const BreadcrumbsContainer = styled('div')`
  display: flex;
`;

const Breadcrumb = styled('nav')`
  display: flex;
  align-items: center;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const IconWrapper = styled('div')`
  margin: 0 10px;
  & span {
    height: 100%;
    display: flex;
    align-items: center;
    cursor: auto;
  }
`;

type TBreadcrumb = {
  label: string;
  url?: string;
};

interface BreadcrumbsProps {
  breadcrumbs: TBreadcrumb[];
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ breadcrumbs }) => {
  const { primary, neutral } = useTheme().colors;

  return (
    <BreadcrumbsContainer data-testid={'breadcrumb'}>
      {breadcrumbs.map(({ label, url }, i) => {
        const isLast = i === breadcrumbs.length - 1;
        return (
          <Breadcrumb key={label}>
            {url ? (
              <StyledLink to={url} data-testid={'breadcrumb-url'}>
                <Typography variant='caption' color={primary[90]}>
                  {label}
                </Typography>
              </StyledLink>
            ) : (
              <Typography variant='caption' color={primary[60]} data-testid={'breadcrumb-label'}>
                {label}
              </Typography>
            )}
            {!isLast && (
              <IconWrapper>
                <Icon type={IconTypes.CHEVRON_RIGHT} color={neutral[60]} />
              </IconWrapper>
            )}
          </Breadcrumb>
        );
      })}
    </BreadcrumbsContainer>
  );
};
