import { FMT } from '../../../../util/formatter-service';
import { formatUSDShort } from '../../../../util/formatters/NumericFormatters';

export const KPIFormatterTypesMap = {
  currency: 'currency',
  multiple: 'multiple',
  percentage: 'percentage',
  number: 'number',
  integer: 'integer',
  numeric: 'numeric',
};

export function format(val: number, type: string): string {
  switch (type) {
    case KPIFormatterTypesMap.currency:
      return formatUSDShort(val);
    case KPIFormatterTypesMap.multiple:
      return FMT.format('multiplier', val);
    case KPIFormatterTypesMap.percentage:
      return FMT.format('percent2dpAsIs', val);
    case KPIFormatterTypesMap.number:
      return FMT.format('numeric', val);
    case KPIFormatterTypesMap.numeric:
      return FMT.format('integer', val);
    case KPIFormatterTypesMap.integer:
      return FMT.format('integer', val);
    default:
      throw new Error('Format: unsupported type');
  }
}
