import { useState, useCallback } from 'react';
import { BlockerFunction } from 'react-router';
import { NavigationBlocker } from '../../CompanyProfiles/Scenarios/SingleScenario/block-navigation/NavigationBlocker';
import { InfoBox } from '../../Mappings/forms/InfoBox';
import { ConfigStatus } from './useDisplayConfigState';

interface IExitConfigBlockerProps {
  onSave: () => Promise<void>;
  onReset: () => Promise<void>;
  editStatus: ConfigStatus;
}

export function ExitConfigBlocker({ onSave, onReset, editStatus }: IExitConfigBlockerProps) {
  const [loading, setLoading] = useState(false);
  const shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => {
      return currentLocation.pathname !== nextLocation.pathname && editStatus === 'modified';
    },
    [editStatus]
  );

  const onSaveAndProceed = async () => {
    setLoading(true);
    await onSave();
    setLoading(false);
  };

  return (
    <NavigationBlocker
      shouldBlock={shouldBlock}
      title={'Do you want to save changes before leaving?'}
      message={<InfoBox message={'You have unsaved changes'} />}
      doBeforeProceed={{ onClick: onSaveAndProceed, label: 'Save and Exit', loading }}
      proceedProps={{ onClick: () => onReset(), label: 'Leave Without Saving' }}
    />
  );
}
