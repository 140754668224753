import { Stack, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAtomValue } from 'jotai';
import { cardStyles } from '../../../theme/component-styles';
import { GenericFallbacksWrapper } from '../../../components/Fallback/GenericFallbacksWrapper';
import { isEditingFundDataState, selectedFundIdStateFP } from '../state/FPState';
import { colors } from '../../../theme/colors';
import { MaggieFeatureFlags } from '../../../util/feature-flags';
import { WaterfallModellingGrid } from './WaterfallModellingGrid';
import { WaterfallSettingsToggle, WaterfallSettings } from './WaterfallSettings';
import { EditFundData } from './EditFundData';
import { ToggleCalcsButton } from './WaterfallCalcsDisplay';
import { FundDataHistory } from './FundDataHistory';

const Card = css({
  ...cardStyles,
});

const MainContent = css`
  ${Card};
  padding: 1rem;
  &.editing {
    border-left: 4px solid ${colors.secondary[40]};
  }
`;

export function WaterfallModelling() {
  const fundId = useRecoilValue(selectedFundIdStateFP);
  const isEditingFundData = useAtomValue(isEditingFundDataState);
  const { showWaterfallFormulas } = useFlags<MaggieFeatureFlags>();
  const showToggleFormula = showWaterfallFormulas && !isEditingFundData;

  if (!fundId) return null;
  return (
    <GenericFallbacksWrapper>
      <Stack display='grid' gridTemplateColumns={'auto 1fr auto'} gap='0.5rem' alignItems={'center'}>
        <WaterfallSettingsToggle />
        <Typography variant='subtitle1'>Waterfall Modelling</Typography>
        {showToggleFormula && <ToggleCalcsButton />}
      </Stack>
      <Stack display={'grid'} gridTemplateColumns={'min-content 1fr'} mt='0.5rem'>
        <GenericFallbacksWrapper>
          <WaterfallSettings />
        </GenericFallbacksWrapper>
        <div css={MainContent} className={isEditingFundData ? 'editing' : ''} key={String(isEditingFundData)}>
          <motion.div
            animate={{ opacity: [0, 1] }}
            transition={{ duration: 0.5 }}
            key={String(isEditingFundData)}
          >
            {isEditingFundData ? (
              <>
                <EditFundData fundId={fundId} />
                <FundDataHistory fundId={fundId} />
              </>
            ) : (
              <WaterfallModellingGrid />
            )}
          </motion.div>
        </div>
      </Stack>
    </GenericFallbacksWrapper>
  );
}
