import { RowSelectionOptions } from 'ag-grid-community';

export const multiRowSelectionDefs: RowSelectionOptions = {
  headerCheckbox: true,
  checkboxes: true,
  selectAll: 'filtered',
  mode: 'multiRow',
  groupSelects: 'descendants',
  enableClickSelection: false,
};
