import { useAtomValue } from 'jotai/index';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect, useState } from 'react';
import { FieldValues, Path, useFormContext, UseFormReturn, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import { AnySchema, ObjectSchema } from 'yup';
import { FieldSettings } from '../../../data-fields/CommonFields';
import {
  useGetTransactionSubTypeField,
  useGetTransactionTypeField,
  useTransactionCategoryField,
} from '../../../data-fields/TransactionFields';
import { RendererType } from '../../../data-models/field.data-model';
import { ICurrencyMeta } from '../../../data-models/field3.data-model';
import { TransactionCategory } from '../../../data-models/transaction-type.data-model';
import { ITransactionDataModel } from '../../../data-models/transaction.data-model';
import {
  currencyMapByIdAtom,
  useGetTransactionCategoryAndSubtype,
} from '../../../services/state/AppConfigStateJ';
import { TRANSACTION_SUB_TYPES } from '../../../types';
import { MaggieFeatureFlags } from '../../../util/feature-flags';
import { FMT } from '../../../util/formatter-service';
import { createFormField, IFormField } from '../../../view-models/form.view-model';
import { ITransactionViewModel } from '../../../view-models/transaction-form.view-model';
import {
  eqBuyFields,
  eqBuyFormSchema,
} from '../../Finance/components/TransactionModal/Forms/schemas/Equity/EquityBuySchema';
import {
  eqSecondaryPurchaseFields,
  eqSecondaryPurchaseFormSchema,
} from '../../Finance/components/TransactionModal/Forms/schemas/Equity/EquitySecondaryPurchaseSchema';
import {
  eqSellFields,
  eqSellFormSchema,
} from '../../Finance/components/TransactionModal/Forms/schemas/Equity/EquitySellSchema';
import {
  eqShareExchangeFields,
  eqShareExchangeFormSchema,
} from '../../Finance/components/TransactionModal/Forms/schemas/Equity/EquityShareExchangeSchema';
import {
  escrowFields,
  escrowFormSchema,
} from '../../Finance/components/TransactionModal/Forms/schemas/Escrow/EscrowSchema';
import {
  escrowWriteOffFields,
  escrowWriteOffFormSchema,
} from '../../Finance/components/TransactionModal/Forms/schemas/Escrow/EscrowWriteOffSchema';
import {
  capitalCallFields,
  capitalCallFormSchema,
} from '../../Finance/components/TransactionModal/Forms/schemas/Fund/CapitalCallSchema';
import { miscInternalValuationFormSchema } from '../../Finance/components/TransactionModal/Forms/schemas/Misc/MiscInternalValuationSchema';
import { miscPaymentFormSchema } from '../../Finance/components/TransactionModal/Forms/schemas/Misc/MiscPaymentSchema';
import { miscWriteOffFormSchema } from '../../Finance/components/TransactionModal/Forms/schemas/Misc/MiscWriteOffSchema';
import { ownershipChangeFormSchema } from '../../Finance/components/TransactionModal/Forms/schemas/Misc/OwnershipChangeSchema';
import { stockIssuanceFormSchema } from '../../Finance/components/TransactionModal/Forms/schemas/Misc/StockIssuanceSchema';
import {
  noteConversionFields,
  noteConversionFormSchema,
} from '../../Finance/components/TransactionModal/Forms/schemas/Note/NoteConversionSchema';
import { noteExpirationFormSchema } from '../../Finance/components/TransactionModal/Forms/schemas/Note/NoteExpirationSchema';
import {
  noteIssuanceFields,
  noteIssuanceFormSchema,
} from '../../Finance/components/TransactionModal/Forms/schemas/Note/NoteIssuanceSchema';
import {
  noteSafeConversionFields,
  noteSafeConversionFormSchema,
} from '../../Finance/components/TransactionModal/Forms/schemas/Note/NoteSafeConversionSchema';
import {
  noteSafeIssuanceFields,
  noteSafeIssuanceFormSchema,
} from '../../Finance/components/TransactionModal/Forms/schemas/Note/NoteSafeIssuanceSchema';
import { optionExerciseFormSchema } from '../../Finance/components/TransactionModal/Forms/schemas/Option/OptionExerciseSchema';
import { optionIssuanceFormSchema } from '../../Finance/components/TransactionModal/Forms/schemas/Option/OptionIssuanceSchema';
import { tokenBuyFormSchema } from '../../Finance/components/TransactionModal/Forms/schemas/Token/TokenBuySchema';
import { tokenSaftConversionFormSchema } from '../../Finance/components/TransactionModal/Forms/schemas/Token/TokenSaftConversionSchema';
import { tokenSaftFormSchema } from '../../Finance/components/TransactionModal/Forms/schemas/Token/TokenSaftSchema';
import { warrantExerciseFormSchema } from '../../Finance/components/TransactionModal/Forms/schemas/Warrant/WarrantExerciseSchema';
import { warrantExpirationFormSchema } from '../../Finance/components/TransactionModal/Forms/schemas/Warrant/WarrantExpirationSchema';
import { warrantIssuanceFormSchema } from '../../Finance/components/TransactionModal/Forms/schemas/Warrant/WarrantIssuanceSchema';
import {
  getCommonExtendedSchemas,
  getDisabledFieldsForConfirmed,
  setExtendedRules,
  useExtendedFieldRules,
} from './confirmedTransactionUtils';
import { transactionCategorySelectorSchema } from './transactionFieldSchemas';
import { useEquityFields } from './useEquityFields';
import { useEscrowFields } from './useEscrowFields';
import { useFundCapitalCallFields } from './useFundCapitalCallFields';
import { useInternalValuationFields } from './useInternalValuationFields';
import { useMiscOwnershipChangeFields } from './useMiscOwnershipChangeFields';
import { useMiscPaymentFields } from './useMiscPaymentFields';
import { useMiscWriteOffFields } from './useMiscWriteOffFields';
import { useNoteFields } from './useNoteFields';
import { useOptionExerciseFields, useOptionIssuanceFields } from './useOptionFields';
import { useShareExchangeFields } from './useShareExchangeFields';
import { useGetStockIssuanceFields } from './useStockIssuanceFields';
import { useTokenFields } from './useTokenFields';
import { useWarrantFields } from './useWarrantFields';

export function useTransactionSelectorFields(): IFormField<unknown>[] {
  const { showTransactionTypeSelector2 } = useFlags<MaggieFeatureFlags>();
  const getTransactionTypeField = useGetTransactionTypeField();
  const { watch, setValue } = useFormContext<ITransactionViewModel>();
  const getTransactionSubType = useGetTransactionSubTypeField();
  const transactionTypeFields = useTransactionCategoryField();
  const selectedTransactionCategory = watch('_viewModel.transactionCategory');

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // Reset transactionTypeId whenever the category changes to empty the form fields.
      if (type === 'change' && name === '_viewModel.transactionCategory') {
        setValue('transactionTypeId', -1);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  return showTransactionTypeSelector2
    ? [getTransactionTypeField()]
    : [transactionTypeFields, getTransactionSubType(selectedTransactionCategory!)];
}

export function useTransactionFields(): IFormField<unknown>[] {
  const getTransactionCategoryAndSubtype = useGetTransactionCategoryAndSubtype();
  const { watch } = useFormContext<ITransactionViewModel>();
  const transactionTypeId = watch('transactionTypeId');
  const companyId = watch('companyId');
  const fundId = watch('fundId');
  const transactionDate = watch('transactionDate');

  const getExtendedSchema = useExtendedFieldRules();
  const { transactionCategory, transactionSubType } = getTransactionCategoryAndSubtype(transactionTypeId);
  const {
    getNoteIssuanceFields,
    getNoteConversionFields,
    getSafeConversionFields,
    getSafeFields,
    getExpirationFields,
    getSafeExpirationFields,
  } = useNoteFields();

  const { getEquityBuyFields, getEquitySellFields, getEquitySecondaryPurchaseFields } = useEquityFields();
  const { getTokenBuyFields, getTokenSaftFields, getTokenSaftConversionFields } = useTokenFields();
  const getInternalValuationFields = useInternalValuationFields(companyId);
  const getShareExchangeFields = useShareExchangeFields(companyId, fundId, transactionDate);
  const { getEscrowReceivedFields, getEscrowWriteOffFields } = useEscrowFields();
  const { getWarrantExerciseFields, getWarrantExpirationFields, getWarrantIssuanceFields } =
    useWarrantFields();
  const getStockIssuanceFields = useGetStockIssuanceFields();
  const getCapitalCallFields = useFundCapitalCallFields();
  const getMiscPaymentFields = useMiscPaymentFields();
  const getMiscOwnershipChangeFields = useMiscOwnershipChangeFields();
  const getMiscWriteOffFields = useMiscWriteOffFields();
  const getOptionIssuanceFields = useOptionIssuanceFields();
  const getOptionExerciseFields = useOptionExerciseFields();

  if (!transactionCategory || !transactionSubType) {
    return [];
  }

  const transactionFields: Record<TransactionCategory, Record<string, () => IFormField<unknown>[]>> = {
    [TransactionCategory.Escrow]: {
      [TRANSACTION_SUB_TYPES.ESCROW_RECEIVED]: getEscrowReceivedFields,
      [TRANSACTION_SUB_TYPES.ESCROW_WRITE_OFF]: getEscrowWriteOffFields,
    },
    [TransactionCategory.Equity]: {
      [TRANSACTION_SUB_TYPES.BUY]: getEquityBuyFields,
      [TRANSACTION_SUB_TYPES.SECONDARY_PURCHASE]: getEquitySecondaryPurchaseFields,
      [TRANSACTION_SUB_TYPES.SELL]: getEquitySellFields,
      [TRANSACTION_SUB_TYPES.SHARE_EXCHANGE]: getShareExchangeFields,
    },
    [TransactionCategory.Fund]: {
      [TRANSACTION_SUB_TYPES.CAPITAL_CALL]: getCapitalCallFields,
    },
    [TransactionCategory.Miscellaneous]: {
      [TRANSACTION_SUB_TYPES.INTERNAL_VALUATION]: getInternalValuationFields,
      [TRANSACTION_SUB_TYPES.STOCK_ISSUANCE]: getStockIssuanceFields,
      [TRANSACTION_SUB_TYPES.MISC_PAYMENT]: getMiscPaymentFields,
      [TRANSACTION_SUB_TYPES.MISC_OWNERSHIP_CHANGE]: getMiscOwnershipChangeFields,
      [TRANSACTION_SUB_TYPES.MISC_WRITE_OFF]: getMiscWriteOffFields,
    },
    [TransactionCategory.Options]: {
      [TRANSACTION_SUB_TYPES.OPTION_EXERCISE]: getOptionExerciseFields,
      [TRANSACTION_SUB_TYPES.OPTION_ISSUANCE]: getOptionIssuanceFields,
    },
    [TransactionCategory.Note]: {
      [TRANSACTION_SUB_TYPES.NOTE_EXPIRATION]: getExpirationFields,
      [TRANSACTION_SUB_TYPES.NOTE_CONVERSION]: getNoteConversionFields,
      [TRANSACTION_SUB_TYPES.NOTE_ISSUANCE]: getNoteIssuanceFields,
      [TRANSACTION_SUB_TYPES.SAFE_CONVERSION]: getSafeConversionFields,
      [TRANSACTION_SUB_TYPES.SAFE_ISSUANCE]: getSafeFields,
      [TRANSACTION_SUB_TYPES.SAFE_EXPIRATION]: getSafeExpirationFields,
    },
    [TransactionCategory.Token]: {
      [TRANSACTION_SUB_TYPES.TOKEN_BUY]: getTokenBuyFields,
      [TRANSACTION_SUB_TYPES.TOKEN_SAFT]: getTokenSaftFields,
      [TRANSACTION_SUB_TYPES.TOKEN_SAFT_CONVERSION]: getTokenSaftConversionFields,
    },
    [TransactionCategory.Warrants]: {
      [TRANSACTION_SUB_TYPES.WARRANT_EXERCISE]: getWarrantExerciseFields,
      [TRANSACTION_SUB_TYPES.WARRANT_EXPIRATION]: getWarrantExpirationFields,
      [TRANSACTION_SUB_TYPES.WARRANT_ISSUANCE]: getWarrantIssuanceFields,
    },
  };

  return getExtendedSchema({
    category: transactionCategory,
    subtype: transactionSubType,
    fields: transactionFields[transactionCategory]?.[transactionSubType]?.() ?? [],
  });
}

export function useGetAmountField<T extends FieldValues>(fieldToWatch: keyof T) {
  const currencies = useAtomValue(currencyMapByIdAtom);
  const { watch } = useFormContext<T>();
  const selectedCurrencyId = watch(fieldToWatch as Path<T>) as number;
  const selectedCurrencyName = currencies.get(selectedCurrencyId ?? -1)?.code ?? 'USD';

  return useCallback(
    ({ key, label = key, required = false }: FieldSettings) => {
      return createFormField<ICurrencyMeta>({
        key,
        label,
        required,
        renderer: RendererType.currency,
        rendererMeta: {
          defaultCurrency: selectedCurrencyName,
        },
      });
    },
    [selectedCurrencyName]
  );
}

export function getTransactionFormSchemas(): Record<string, AnySchema> {
  return {
    [`${TransactionCategory.Escrow}_${TRANSACTION_SUB_TYPES.ESCROW_RECEIVED}`]: escrowFormSchema().shape({
      ...(setExtendedRules(
        escrowFields,
        getDisabledFieldsForConfirmed(
          `${TransactionCategory.Escrow}_${TRANSACTION_SUB_TYPES.ESCROW_RECEIVED}`
        )
      ) as yup.ObjectShape),
    }),
    [`${TransactionCategory.Escrow}_${TRANSACTION_SUB_TYPES.ESCROW_WRITE_OFF}`]:
      escrowWriteOffFormSchema().shape({
        ...(setExtendedRules(
          escrowWriteOffFields,
          getDisabledFieldsForConfirmed(
            `${TransactionCategory.Escrow}_${TRANSACTION_SUB_TYPES.ESCROW_WRITE_OFF}`
          )
        ) as yup.ObjectShape),
      }),
    [`${TransactionCategory.Equity}_${TRANSACTION_SUB_TYPES.BUY}`]: eqBuyFormSchema().shape({
      ...(setExtendedRules(
        eqBuyFields,
        getDisabledFieldsForConfirmed(`${TransactionCategory.Equity}_${TRANSACTION_SUB_TYPES.BUY}`)
      ) as yup.ObjectShape),
    }),
    [`${TransactionCategory.Equity}_${TRANSACTION_SUB_TYPES.SELL}`]: eqSellFormSchema().shape({
      ...(setExtendedRules(
        eqSellFields,
        getDisabledFieldsForConfirmed(`${TransactionCategory.Equity}_${TRANSACTION_SUB_TYPES.SELL}`)
      ) as yup.ObjectShape),
    }),
    [`${TransactionCategory.Equity}_${TRANSACTION_SUB_TYPES.SHARE_EXCHANGE}`]:
      eqShareExchangeFormSchema().shape({
        ...(setExtendedRules(
          eqShareExchangeFields,
          getDisabledFieldsForConfirmed(
            `${TransactionCategory.Equity}_${TRANSACTION_SUB_TYPES.SHARE_EXCHANGE}`
          )
        ) as yup.ObjectShape),
      }),
    [`${TransactionCategory.Equity}_${TRANSACTION_SUB_TYPES.SECONDARY_PURCHASE}`]:
      eqSecondaryPurchaseFormSchema().shape({
        ...(setExtendedRules(
          eqSecondaryPurchaseFields,
          getDisabledFieldsForConfirmed(
            `${TransactionCategory.Equity}_${TRANSACTION_SUB_TYPES.SECONDARY_PURCHASE}`
          )
        ) as yup.ObjectShape),
      }),
    [`${TransactionCategory.Fund}_${TRANSACTION_SUB_TYPES.CAPITAL_CALL}`]: capitalCallFormSchema().shape({
      ...(setExtendedRules(
        capitalCallFields,
        getDisabledFieldsForConfirmed(`${TransactionCategory.Fund}_${TRANSACTION_SUB_TYPES.CAPITAL_CALL}`)
      ) as yup.ObjectShape),
    }),
    [`${TransactionCategory.Note}_${TRANSACTION_SUB_TYPES.NOTE_ISSUANCE}`]: noteIssuanceFormSchema().shape({
      ...(setExtendedRules(
        noteIssuanceFields,
        getDisabledFieldsForConfirmed(`${TransactionCategory.Note}_${TRANSACTION_SUB_TYPES.NOTE_ISSUANCE}`)
      ) as yup.ObjectShape),
    }),
    [`${TransactionCategory.Note}_${TRANSACTION_SUB_TYPES.NOTE_CONVERSION}`]:
      noteConversionFormSchema().shape({
        ...(setExtendedRules(
          noteConversionFields,
          getDisabledFieldsForConfirmed(
            `${TransactionCategory.Note}_${TRANSACTION_SUB_TYPES.NOTE_CONVERSION}`
          )
        ) as yup.ObjectShape),
      }),
    [`${TransactionCategory.Note}_${TRANSACTION_SUB_TYPES.SAFE_CONVERSION}`]:
      noteSafeConversionFormSchema().shape({
        ...(setExtendedRules(
          noteSafeConversionFields,
          getDisabledFieldsForConfirmed(
            `${TransactionCategory.Note}_${TRANSACTION_SUB_TYPES.SAFE_CONVERSION}`
          )
        ) as yup.ObjectShape),
      }),
    [`${TransactionCategory.Note}_${TRANSACTION_SUB_TYPES.SAFE_ISSUANCE}`]:
      noteSafeIssuanceFormSchema().shape({
        ...(setExtendedRules(
          noteSafeIssuanceFields,
          getDisabledFieldsForConfirmed(`${TransactionCategory.Note}_${TRANSACTION_SUB_TYPES.SAFE_ISSUANCE}`)
        ) as yup.ObjectShape),
      }),
    [`${TransactionCategory.Note}_${TRANSACTION_SUB_TYPES.NOTE_EXPIRATION}`]:
      noteExpirationFormSchema().shape({
        ...getCommonExtendedSchemas(),
      }),
    [`${TransactionCategory.Note}_${TRANSACTION_SUB_TYPES.SAFE_EXPIRATION}`]:
      noteExpirationFormSchema().shape({
        ...getCommonExtendedSchemas(),
      }),
    [`${TransactionCategory.Options}_${TRANSACTION_SUB_TYPES.OPTION_ISSUANCE}`]:
      optionIssuanceFormSchema().shape({
        ...getCommonExtendedSchemas(),
      }),
    [`${TransactionCategory.Options}_${TRANSACTION_SUB_TYPES.OPTION_EXERCISE}`]:
      optionExerciseFormSchema().shape({
        ...getCommonExtendedSchemas(),
      }),
    [`${TransactionCategory.Token}_${TRANSACTION_SUB_TYPES.TOKEN_SAFT}`]: tokenSaftFormSchema().shape({
      ...getCommonExtendedSchemas(),
    }),
    [`${TransactionCategory.Token}_${TRANSACTION_SUB_TYPES.TOKEN_BUY}`]: tokenBuyFormSchema().shape({
      ...getCommonExtendedSchemas(),
    }),
    [`${TransactionCategory.Token}_${TRANSACTION_SUB_TYPES.TOKEN_SAFT_CONVERSION}`]:
      tokenSaftConversionFormSchema().shape({
        ...getCommonExtendedSchemas(),
      }),
    [`${TransactionCategory.Miscellaneous}_${TRANSACTION_SUB_TYPES.INTERNAL_VALUATION}`]:
      miscInternalValuationFormSchema()
        .shape({
          ...getCommonExtendedSchemas(),
        })
        .omit(['fundId']),
    [`${TransactionCategory.Miscellaneous}_${TRANSACTION_SUB_TYPES.STOCK_ISSUANCE}`]:
      stockIssuanceFormSchema()
        .shape({
          ...getCommonExtendedSchemas(),
        })
        .omit(['fundId']),
    [`${TransactionCategory.Miscellaneous}_${TRANSACTION_SUB_TYPES.MISC_PAYMENT}`]: miscPaymentFormSchema()
      .shape({
        ...getCommonExtendedSchemas(),
      })
      .omit(['fundId']),
    [`${TransactionCategory.Miscellaneous}_${TRANSACTION_SUB_TYPES.MISC_OWNERSHIP_CHANGE}`]:
      ownershipChangeFormSchema()
        .shape({
          ...getCommonExtendedSchemas(),
        })
        .omit(['fundId']),
    [`${TransactionCategory.Miscellaneous}_${TRANSACTION_SUB_TYPES.MISC_WRITE_OFF}`]: miscWriteOffFormSchema()
      .shape({
        ...getCommonExtendedSchemas(),
      })
      .omit(['fundId']),
    [`${TransactionCategory.Warrants}_${TRANSACTION_SUB_TYPES.WARRANT_EXPIRATION}`]:
      warrantExpirationFormSchema().shape({
        ...getCommonExtendedSchemas(),
      }),
    [`${TransactionCategory.Warrants}_${TRANSACTION_SUB_TYPES.WARRANT_EXERCISE}`]:
      warrantExerciseFormSchema().shape({
        ...getCommonExtendedSchemas(),
      }),
    [`${TransactionCategory.Warrants}_${TRANSACTION_SUB_TYPES.WARRANT_ISSUANCE}`]:
      warrantIssuanceFormSchema().shape({
        ...getCommonExtendedSchemas(),
      }),
  };
}

export function getSchemaForTransaction(
  transactionCategory: string | null,
  transactionSubType: TRANSACTION_SUB_TYPES | null
) {
  const transactionType = `${transactionCategory}_${transactionSubType}`;

  return (getTransactionFormSchemas()[transactionType] ??
    transactionCategorySelectorSchema()) as ObjectSchema<Partial<ITransactionViewModel>>;
}

const transactionSubtypesWithConversionRatio = new Set<TRANSACTION_SUB_TYPES>([
  TRANSACTION_SUB_TYPES.BUY,
  TRANSACTION_SUB_TYPES.SECONDARY_PURCHASE,
  TRANSACTION_SUB_TYPES.SAFE_CONVERSION,
  TRANSACTION_SUB_TYPES.NOTE_CONVERSION,
]);
function hasConversionRatioField(transactionTypeId?: number) {
  return (
    transactionTypeId != null &&
    transactionSubtypesWithConversionRatio.has(
      FMT.format('transactionSubType', transactionTypeId) as TRANSACTION_SUB_TYPES
    )
  );
}

export function useConversionRatioField() {
  const { control, getValues, setValue } = useFormContext<ITransactionDataModel>();
  const [renderCount, setRenderCount] = useState(0);

  const transactionTypeId = useWatch<ITransactionDataModel>({
    name: 'transactionTypeId',
    control: control,
  });

  useEffect(() => {
    if (!transactionTypeId) return;
    setConversionRatio({ transactionTypeId: transactionTypeId as number, setValue, getValues });
    setRenderCount((prev) => prev + 1);
  }, [getValues, setValue, transactionTypeId]);

  return renderCount;
}

interface ISetConversionRatioParams {
  transactionTypeId: number;
  setValue: UseFormReturn<ITransactionDataModel>['setValue'];
  getValues: UseFormReturn<ITransactionDataModel>['getValues'];
}
export function setConversionRatio({ transactionTypeId, setValue, getValues }: ISetConversionRatioParams) {
  if (!hasConversionRatioField(transactionTypeId as number)) {
    setValue('conversionRatio', null, { shouldDirty: true });
  } else if (getValues('conversionRatio') == null) {
    setValue('conversionRatio', 1, { shouldDirty: true });
  }
}
