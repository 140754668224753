import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router';
import { useRecoilCallback } from 'recoil';
import { cloneDeep } from 'lodash-es';
import { ScenariosByIdMapState, currentScenarioState } from '../../state/ScenariosState';
import { selectedCompanyIdProfile } from '../../state/UIState';
import { ActiveScenarioIdParamName, DuplicateParam } from '../constants';
import {
  initialScenarioFormValues,
  prepareScenarioForDuplication,
} from '../SingleScenario/form/scenarioInitialValues';
import { primaryCaptableState } from '../../CapTable/CapTableDataState';

export function useSetScenarioStateFromPath() {
  const [search] = useSearchParams();
  const [ready, setReady] = useState(false);

  const setInitialScenarioState = useRecoilCallback(({ set, snapshot }) => async () => {
    const companyId = snapshot.getLoadable(selectedCompanyIdProfile).valueOrThrow();
    const activeScenarioId = search.get(ActiveScenarioIdParamName);
    const shouldDuplicate = search.get(DuplicateParam);
    const primaryCaptable = await snapshot.getPromise(primaryCaptableState(companyId!));

    let current;
    if (!activeScenarioId) {
      current = initialScenarioFormValues({
        captableId: primaryCaptable?.id,
        companyId,
      });
    } else {
      const map = await snapshot.getPromise(ScenariosByIdMapState(companyId!));
      current = map?.get(parseInt(activeScenarioId)) ?? null;

      if (current && shouldDuplicate) {
        current = prepareScenarioForDuplication(current);
      } else if (current) {
        current = cloneDeep(current);
      }
    }
    set(currentScenarioState, current);
  });

  useEffect(() => {
    if (ready) return;
    setInitialScenarioState().then(() => setReady(true));
  }, [ready, setInitialScenarioState]);

  return ready;
}
