import { Outlet } from 'react-router';
import { Stack, Typography } from '@mui/material';
import { css } from '@emotion/react';
import { Loader } from '../../../components/Loader/Loader';
import { ProfileCustomization } from './ProfileCustomization';
import { useLoadDummyData } from './DummyData';

const mainContainer = css`
  padding-top: 1rem;
  padding-right: 2rem;
  padding-left: 2rem;
`;

export function DataDisplay() {
  const isReady = useLoadDummyData();

  return isReady ? (
    <Typography color='text.primary' component={Stack} height='100%' width='100%' css={mainContainer}>
      <Outlet />
    </Typography>
  ) : (
    <Loader />
  );
}

export function DataDisplayContent() {
  return (
    <>
      <Typography variant='h3'>Data Display</Typography>
      <ProfileCustomization />
    </>
  );
}
