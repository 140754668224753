import { Autocomplete } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { roundsAtom, roundsByIdMapAtom } from '../../services/state/AppConfigStateJ';
import { IIdSelectorProps, popperSx, renderInput } from './commonSelectorDefs';

const TbdRoundId = 0;

interface IRoundSelectorProps extends IIdSelectorProps {
  showTbd?: boolean;
}

export const RoundSelector = (props: IRoundSelectorProps) => {
  const { getOptionDisabled, value, onChange, componentsProps, showTbd = true, ...rest } = props;

  const roundsById = useAtomValue(roundsByIdMapAtom);
  const rounds = useAtomValue(roundsAtom);

  const roundOptions = useMemo<number[]>(() => {
    const opts = rounds.map((f) => f.id);
    if (showTbd) {
      opts.unshift(TbdRoundId);
    }
    return opts;
  }, [rounds, showTbd]);

  const getOptionLabel = useCallback(
    (option: number) => {
      if (showTbd && option === TbdRoundId) return 'TBD';
      return roundsById.get((option as number) ?? -1)?.displayName ?? '';
    },
    [roundsById, showTbd]
  );

  return (
    <Autocomplete
      openOnFocus
      options={roundOptions}
      value={value || TbdRoundId}
      renderInput={renderInput}
      getOptionLabel={getOptionLabel as (option: unknown) => string}
      onChange={(e, newValue) => onChange((newValue || null) as number | number[] | null)}
      getOptionDisabled={getOptionDisabled as (option: unknown) => boolean}
      componentsProps={{ popper: { sx: { ...popperSx } }, ...componentsProps }}
      {...rest}
    />
  );
};
