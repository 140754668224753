import { FC, useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Chip, IconButton, Stack, Typography, useTheme } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CachedIcon from '@mui/icons-material/Cached';
import { useFormContext } from 'react-hook-form';
import { FormFieldsContainer, Label } from '../../../../../components/Form/FormComponents';
import { getCaptableSourceLabel } from '../../../CapTable/captable-utils';
import { useScenarioActions } from '../../hooks/useScenarioActions';
import { currentScenarioState } from '../../../state/ScenariosState';
import { primaryCaptableState } from '../../../CapTable/CapTableDataState';
import { useCanEditScenario } from '../../hooks/useCanEditScenario';
import { BasicDialog, IFormDialogProps } from '../../../../../components/Dialog/BasicDialog';
import { ICaptableDataModel } from '../../../../../data-models/captable2.data-model';
import { StyledColumnBox } from '../../../../Integrations/Airtable/components/CommonStyledComponents';
import { StickyFormButtons } from '../../../../../components/Form/StickyFormButtons';
import { FormatterService } from '../../../../../util/formatter-service';
import { DateFormattersConfig } from '../../../../../util/formatters/DateFormatters';
import { IScenarioDataModel } from '../../../../../data-models/scenario.data-model';
import { LabeledFieldBox } from '../../../../../components/LabeledField';
import { ICaptableDetailsProps } from './CapTableDetails';

export const CaptableField: FC<ICaptableDetailsProps> = ({ captable, setCaptable }) => {
  const { setValue } = useFormContext<IScenarioDataModel>();

  const currentScenario = useRecoilValue(currentScenarioState);
  const outdated = currentScenario?.outdated ?? false;

  const primaryCaptable = useRecoilValue(primaryCaptableState(currentScenario?.companyId ?? -1));
  const { handleUpdateScenarioCaptable } = useScenarioActions();
  const canEditScenario = useCanEditScenario(currentScenario!);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const onRefreshCaptable = useCallback(() => {
    if (!currentScenario!.id || !currentScenario!.companyId) return;
    setShowConfirmationDialog(true);
  }, [currentScenario]);

  const onUpdate = useCallback(async () => {
    if (!currentScenario!.id || !currentScenario!.companyId) return;
    const data = await handleUpdateScenarioCaptable(currentScenario!.id, currentScenario!.companyId);
    setShowConfirmationDialog(false);

    if (data) {
      setValue('outdated', data.outdated);
      setValue('captableId', data.captableId);
      setCaptable(primaryCaptable);
    }
  }, [currentScenario, handleUpdateScenarioCaptable, primaryCaptable, setCaptable, setValue]);

  if (!captable || !currentScenario || !primaryCaptable) return null;

  return (
    <>
      <LabeledFieldBox>
        <Label>Cap Table</Label>
        <Typography
          variant={'body2'}
          color={'text.primary'}
          display={'flex'}
          alignItems={'center'}
          gap='0.2rem'
          component='div'
        >
          {outdated && <ErrorOutlineIcon fontSize='small' />}
          {getCaptableSourceLabel(captable)}
          {outdated ? (
            <Stack direction={'row'} alignItems={'center'}>
              (Outdated)
              {canEditScenario && (
                <IconButton size='small' onClick={onRefreshCaptable} title='Update cap table'>
                  <CachedIcon fontSize='small' color='secondary' />
                </IconButton>
              )}
            </Stack>
          ) : (
            <Chip label={'Primary'} color='primary' style={{ marginLeft: '0.25rem' }} />
          )}
        </Typography>
      </LabeledFieldBox>
      {showConfirmationDialog && (
        <UpdateCaptableDialog
          open={true}
          onClose={() => setShowConfirmationDialog(false)}
          staleCaptable={captable}
          primaryCaptable={primaryCaptable}
          onUpdate={onUpdate}
        />
      )}
    </>
  );
};

interface IUpdateCaptableDialogProps extends IFormDialogProps {
  onClose: () => void;
  staleCaptable: ICaptableDataModel;
  primaryCaptable: ICaptableDataModel;
  onUpdate: () => Promise<void>;
}
export function UpdateCaptableDialog({
  open,
  onClose,
  staleCaptable,
  primaryCaptable,
  onUpdate,
}: IUpdateCaptableDialogProps) {
  const [loading, setLoading] = useState(false);
  const dateFormatter = FormatterService.get().getFormatterForModel(DateFormattersConfig.date);
  const { colors } = useTheme();

  const onUpdateCaptable = useCallback(async () => {
    setLoading(true);
    await onUpdate();
    setLoading(false);
  }, [onUpdate]);

  return (
    <BasicDialog open={open} onClose={onClose} title={'Update to Primary Cap Table?'} lightDismiss>
      <>
        <FormFieldsContainer>
          <Stack display={'grid'} my={'0.5rem'} gap={'0.5rem'}>
            <Typography variant={'body2'}>{`${
              staleCaptable.name ?? getCaptableSourceLabel(staleCaptable)
            } cap table will be updated to the primary captable:`}</Typography>
            <StyledColumnBox style={{ display: 'grid', gridTemplateColumns: '1fr auto 1fr' }}>
              <Typography variant={'body2'}>{getCaptableSourceLabel(primaryCaptable)}</Typography>
              <Chip label={'Primary'} color={'primary'} />
              <Typography
                variant={'body2'}
                color={colors.neutral[40]}
                style={{ justifySelf: 'end' }}
              >{`Last Update: ${dateFormatter(primaryCaptable.updatedAt)}`}</Typography>
            </StyledColumnBox>
          </Stack>
        </FormFieldsContainer>
        <StickyFormButtons
          onSubmit={onUpdateCaptable}
          loading={loading}
          onCancel={onClose}
          submitLabel={'Update'}
        />
      </>
    </BasicDialog>
  );
}
