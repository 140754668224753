import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { ROUTES, ROUTE_TITLE_MAPPING } from '../constants/RouteNameMapping';

const financeRegex = new RegExp(/\/finance\/\d/);
const portfolioRegex = new RegExp(/\/portfolio\/\d/);
const companyProfileRegex = new RegExp(/\/company-profiles\/*/i);

export const useTitle = () => {
  const [title, setTitle] = useState('Portfolio Reporting');
  const { pathname } = useLocation();

  useEffect(() => {
    if (financeRegex.test(pathname)) {
      return setTitle(ROUTE_TITLE_MAPPING[ROUTES.FINANCE_HISTORY]);
    }
    if (pathname.startsWith(`/${ROUTES.FINANCE2}/`)) {
      return setTitle(ROUTE_TITLE_MAPPING[ROUTES.FINANCE2]);
    }
    if (pathname.startsWith(`/${ROUTES.INTEGRATIONS}/`)) {
      return setTitle(ROUTE_TITLE_MAPPING[ROUTES.INTEGRATIONS]);
    }
    if (portfolioRegex.test(pathname)) {
      return setTitle(ROUTE_TITLE_MAPPING[ROUTES.PORTFOLIO]);
    }
    if (companyProfileRegex.test(pathname)) {
      return setTitle(ROUTE_TITLE_MAPPING[ROUTES.COMPANY_PROFILES]);
    }
    if (pathname.startsWith('/company-comparison')) {
      return setTitle(ROUTE_TITLE_MAPPING[ROUTES.COMPANY_COMPARISON]);
    }
    if (pathname.includes('deal-flow')) {
      return setTitle(ROUTE_TITLE_MAPPING[ROUTES.DEAL_FLOW]);
    }
    if (pathname.includes('admin-panel')) {
      return setTitle(ROUTE_TITLE_MAPPING[ROUTES.ADMIN_PANEL]);
    }
    if (pathname.includes('kpis')) {
      return setTitle(ROUTE_TITLE_MAPPING[ROUTES.KPI]);
    }
    if (pathname.includes('companies')) {
      return setTitle(ROUTE_TITLE_MAPPING[ROUTES.COMPANIES]);
    }

    setTitle(
      ROUTE_TITLE_MAPPING[pathname] ?? ROUTE_TITLE_MAPPING[pathname.slice(1)] ?? 'Portfolio Reporting'
    );
  }, [pathname]);

  return title;
};
