import { css } from '@emotion/react';
import { Avatar, ButtonBase, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { format } from 'date-fns';
import { useAtomValue } from 'jotai/index';
import { FC, useCallback, useState } from 'react';
import { Icon, IconTypes } from '../../../../../../components/Icon';
import { UnknownUser } from '../../../../../../data-models/user.data-model';
import { usersByEmailMapAtom } from '../../../../../../services/state/AppConfigStateJ';
import { cardStyles } from '../../../../../../theme/component-styles';
import { IActivityListItem } from '../../../../../../view-models/activity-list-item';
import NoteActionButtons from './NoteActionsButtons';

const NoteItemStyles = css({
  ...cardStyles,
  justifyContent: 'space-between',
  position: 'relative',
  width: '100%',
});

const NoteDetails = styled('div')`
  display: grid;
  grid-template-rows: min-content min-content;
  row-gap: 0.5rem;
  width: 100%;
`;

const Header = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
`;

const Body = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export interface INoteItemProps {
  note: IActivityListItem;
  onClick: (note: IActivityListItem) => void;
  onDelete: (note: IActivityListItem) => void;
}

export const NoteItem: FC<INoteItemProps> = (props) => {
  const { note, onClick, onDelete } = props;
  const { title, createdBy, createdAt } = note;
  const [showDotsBtn, setShowDotsBtn] = useState<boolean>(false);
  const { primary, neutral } = useTheme().colors;
  const usersByEmail = useAtomValue(usersByEmailMapAtom);
  const { firstName, lastName } = usersByEmail.get(createdBy) ?? UnknownUser;

  const _onDelete = useCallback(() => {
    onDelete(note);
  }, [note, onDelete]);

  return (
    <ButtonBase
      component='div'
      css={NoteItemStyles}
      onMouseEnter={() => setShowDotsBtn(true)}
      onMouseLeave={() => setShowDotsBtn(false)}
    >
      {showDotsBtn && <NoteActionButtons deleteNote={_onDelete} />}
      <NoteDetails data-testid='noteitem' onClick={() => onClick(note)}>
        <Header>
          <Icon type={IconTypes.NOTE} color={neutral['70']} />
          <Typography variant={'body1'} color={primary['100']}>
            {title}
          </Typography>
        </Header>
        <Body>
          <Avatar sx={{ width: 24, height: 24, fontSize: 12, background: primary['10'] }}>
            <Typography variant={'caption'} color={primary['100']}>
              {firstName.slice(0, 1)}
              {lastName.slice(0, 1)}
            </Typography>
          </Avatar>
          <Typography variant={'caption'} color={neutral['60']}>
            {createdAt ? format(new Date(createdAt), 'LLLL do, y') : ''}
          </Typography>
        </Body>
      </NoteDetails>
    </ButtonBase>
  );
};
