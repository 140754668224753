import { useMemo, useState } from 'react';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useRecoilValue } from 'recoil';
import { Button, ButtonProps } from '@mui/material';
import { useNavigate } from 'react-router';
import { MenuButton } from '../../../Finance2/common-grid-defs/MenuButton';
import { captablesByCompanyState } from '../CapTableDataState';
import { selectedCompanyIdProfile } from '../../state/UIState';
import { isDuplicateSource, isUserCreated } from '../captable-utils';
import { ROUTES } from '../../../../constants/RouteNameMapping';
import { DuplicateDialog } from './DuplicateDialog';

export function AllCaptablesMenu() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const allCaptables = useRecoilValue(captablesByCompanyState(companyId ?? -1));
  const [showImportDialog, setShowImportDialog] = useState(false);
  const navigate = useNavigate();

  const showCreateNewOption = useMemo(() => allCaptables.every((c) => !isUserCreated(c)), [allCaptables]);
  const showImportOption = allCaptables.some((c) => isDuplicateSource(c));

  const menuItems = useMemo(() => {
    const options = [];
    if (showImportOption)
      options.push({
        name: 'Copy',
        icon: <ContentCopyIcon />,
        onClick: () => {
          setShowImportDialog(true);
        },
      });
    if (showCreateNewOption) {
      options.push({
        name: 'Create New',
        icon: <BorderColorIcon />,

        onClick: () => {
          navigate(ROUTES.CAP_TABLE_FORM);
        },
      });
    }
    return options;
  }, [navigate, showCreateNewOption, showImportOption]);

  if (menuItems.length === 0) return null;
  return (
    <>
      <DuplicateDialog open={showImportDialog} onClose={() => setShowImportDialog(false)} />
      <MenuButton
        menuItems={menuItems}
        label='Actions'
        buttonComponent={AddCaptableButton}
        menuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        }}
      />
    </>
  );
}

export function AddCaptableButton(props: ButtonProps) {
  return (
    <Button size='small' variant='contained' color='secondary' startIcon={<AddIcon />} {...props}>
      Add Cap Table
    </Button>
  );
}
