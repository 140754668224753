import { FC, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { FormProvider, useForm } from 'react-hook-form';
import { IconButton, Stack, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { yupResolver } from '@hookform/resolvers/yup';
import { NavLink } from 'react-router';
import { ObjectSchema } from 'yup';
import { useCanEditScenario } from '../../hooks/useCanEditScenario';
import { currentScenarioState } from '../../../state/ScenariosState';
import { ScenarioCancelButton } from '../ScenarioCancelButton';
import {
  MainContainerWithAlert,
  ScenarioDataContainer,
  UnstyledFieldset,
} from '../../components/commonStyledComponents';
import { useViewMode } from '../../hooks/useViewMode';
import { ScenarioTitle } from '../visualization/ScenarioTitle';
import { RunScenarioAction } from '../RunScenarioAction';
import { IScenarioDataModel } from '../../../../../data-models/scenario.data-model';
import { useAllScenariosLocation } from '../../hooks/useAllScenariosLocation';
import { GenericLoadingFallback } from '../../../../../components/Fallback/GenericLoadingFallback';
import { scenarioFormSchema } from '../../../../../schemas/Scenario.schema';
import { ScenarioNameField } from './ScenarioNameField';
import { getInitialScenarioValues } from './scenarioInitialValues';
import { ScenarioTransactions } from './ScenarioTransactions';
import { ScenarioTabs } from './ScenarioTabs';
import { CapTableDetails } from './CapTableDetails';
import { InvestmentDetails } from './InvestmentDetails';
import { useScenarioCaptableData } from './useScenarioCaptableData';

export const ScenarioForm: FC = () => {
  const currScenario = useRecoilValue(currentScenarioState);
  const canEdit = useCanEditScenario(currScenario!);
  const isViewMode = useViewMode();
  const disabled = !canEdit || isViewMode;
  const { captable, setCaptable, loading } = useScenarioCaptableData();

  const schema = useMemo(() => {
    if (!captable) return undefined;
    return scenarioFormSchema(captable);
  }, [captable]);

  const formMethods = useForm<Partial<IScenarioDataModel>>({
    defaultValues: getInitialScenarioValues(currScenario),
    resolver: schema && yupResolver(schema as ObjectSchema<Partial<IScenarioDataModel>>),
    mode: 'all',
  });
  const allScenariosPath = useAllScenariosLocation();

  if (loading) return <GenericLoadingFallback />;

  return (
    <MainContainerWithAlert>
      <FormProvider {...formMethods}>
        <form>
          <Stack direction='row' alignItems={'center'} justifyContent={'space-between'} mb='1rem'>
            {disabled ? (
              <ScenarioTitle scenario={currScenario as IScenarioDataModel} />
            ) : (
              <>
                <Stack direction='row' alignItems={'center'} gap='0.5rem'>
                  <IconButton component={NavLink} to={allScenariosPath ?? ''} title='Back to all scenarios'>
                    <ChevronLeftIcon color='secondary' />
                  </IconButton>
                  <ScenarioNameField style={{ marginBottom: '-1rem' }} />
                </Stack>
                <Stack direction='row' gap='0.75rem'>
                  <ScenarioCancelButton />
                  <RunScenarioAction />
                </Stack>
              </>
            )}
          </Stack>
          {disabled && <ScenarioTabs />}
          <ScenarioDataContainer>
            <CapTableDetails captable={captable} setCaptable={setCaptable} />
            {captable && <InvestmentDetails captable={captable} />}

            <Stack direction='row' alignItems={'center'} justifyContent={'space-between'}>
              <Typography color='text.primary' variant='body2'>
                {disabled
                  ? 'Applied hypothetical values'
                  : 'Please add details for a hypothetical Round and/or Exit Scenario'}
              </Typography>
            </Stack>
            <UnstyledFieldset disabled={disabled}>
              {captable && <ScenarioTransactions captable={captable} />}
            </UnstyledFieldset>
          </ScenarioDataContainer>
        </form>
      </FormProvider>
    </MainContainerWithAlert>
  );
};
