import { atom, selectorFamily } from 'recoil';
import { ICompanyDataModel } from '../../../../data-models/company.data-model';
import { IListItemDataModel } from '../../../../data-models/list-item.data-model';
import { ListType } from '../../../../data-models/list.data-model';
import { sectorsAtom, usersAtom, usersByEmailMapAtom } from '../../../../services/state/AppConfigStateJ';
import { authenticatedUserState } from '../../../../services/state/AuthState';
import { companyState } from '../../../../services/state/CompanyState';
import { getForesightStore } from '../../../../util/jotai-store';
import {
  activeCompanyListIdState,
  companyListState,
  listsByIdState,
  togglePreviousDealsState,
} from '../../state/ListsState';
import { compareItems } from '../../utils/list-utils';
import { getFilteredList } from '../../utils/listFilteringUtils';

export const listSearchQueryState = atom<string>({
  key: 'listSearchQueryState',
  default: '',
});

export const listSectorFilterState = atom<number[] | null>({
  key: 'listSectorFilterState',
  default: null,
});

export const listOwnerFilterState = atom<number[] | null>({
  key: 'listOwnerFilterState',
  default: null,
});

export const companiesByListIdState = selectorFamily<Map<number, ICompanyDataModel>, number>({
  key: 'companiesByListIdState',
  get:
    (listId) =>
    ({ get }) => {
      const listItems = get(companyListState(listId))?.items;
      if (!listItems) return new Map<number, ICompanyDataModel>();

      const companiesMap = listItems.reduce((acc, curr) => {
        const comp = get(companyState(curr.companyId));
        if (comp) {
          acc.set(curr.companyId, comp);
        }
        return acc;
      }, new Map<number, ICompanyDataModel>());
      return companiesMap;
    },
});

export const filteredListState = selectorFamily<IListItemDataModel[] | null, number>({
  key: 'filteredListState',
  get:
    (listId) =>
    ({ get }) => {
      const store = getForesightStore();
      const activeListId = get(activeCompanyListIdState);
      const listMap = get(listsByIdState);
      const activeList = listMap.get(activeListId ?? -1);
      const listItems = get(companyListState(listId))?.items;
      if (!listItems || !activeList) return null;

      const companiesMap = get(companiesByListIdState(listId));
      const showPreviousDeals = get(togglePreviousDealsState) || activeList.type !== ListType.DISCOVERY;

      const filteredSectors = get(listSectorFilterState);
      const filteredOwners = get(listOwnerFilterState);
      const searchQuery = get(listSearchQueryState);
      const sectors = store.get(sectorsAtom);
      const users = store.get(usersAtom);

      return getFilteredList({
        items: listItems,
        searchQuery,
        filteredSectors,
        sectors,
        filteredOwners,
        users,
        companiesMap,
        showPreviousDeals,
      });
    },
});

export const filteredAndSortedListsState = selectorFamily<IListItemDataModel[] | null, number>({
  key: 'filteredAndSortedListsState',
  get:
    (listId) =>
    ({ get }) => {
      const store = getForesightStore();
      const filteredList = get(filteredListState(listId));
      if (!filteredList) return null;
      const currentUser = get(authenticatedUserState);
      const usersByEmailMap = store.get(usersByEmailMapAtom);

      return [...filteredList].sort((a, b) => {
        const aComp = get(companyState(a.companyId));
        const bComp = get(companyState(b.companyId));
        return compareItems(a, b, aComp, bComp, usersByEmailMap.get(currentUser?.email ?? '')?.id);
      });
    },
});
