import { Check } from '@mui/icons-material';
import { Chip, Link, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAtomValue } from 'jotai';
import { FC } from 'react';
import { objectiveCategoriesAtom } from '../../../../../services/state/AppConfigStateJ';
import { Circle } from '../../../../DealFlow2/components/DealModal/DealAddModalContent/Circle';
import { CenterAlignedCell } from '../Objectives/ObjectiveComponent';
import { UserCell } from '../Objectives/UserCell';
import { ClosedFieldContainer, ClosedSubFormContainer } from './StyledFormComponents';

const DataContainer = styled('div')`
  display: grid;
  grid-template-columns: 35% 20% 35% 10%;
  border-radius: 4px;
`;

interface ObjectiveDataProps {
  name: string;
  categoryId: number;
  ownerId: number;
  onEdit: () => void;
}

export const ObjectiveData: FC<ObjectiveDataProps> = ({ name, categoryId, ownerId, onEdit }) => {
  const { colors } = useTheme();

  const objectiveCategories = useAtomValue(objectiveCategoriesAtom);

  return (
    <ClosedSubFormContainer>
      <Typography component='h6' variant='h6' sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
        <Circle isChecked={true} style={{ background: colors.success[20] }}>
          <Check htmlColor={colors.success[60]} />
        </Circle>
        Objective
      </Typography>

      <ClosedFieldContainer>
        <DataContainer>
          <CenterAlignedCell>
            <Typography variant='body2'>{name}</Typography>
          </CenterAlignedCell>
          <CenterAlignedCell>
            <Chip
              label={objectiveCategories.find((c) => c.id === categoryId)?.displayName ?? ''}
              sx={{
                borderRadius: '4px',
                backgroundColor: colors.primary[10],
                color: colors.primary[60],
              }}
            />
          </CenterAlignedCell>
          <CenterAlignedCell>
            <UserCell userId={ownerId} />
          </CenterAlignedCell>
          <CenterAlignedCell>
            <Link component='button' onClick={onEdit} underline={'none'}>
              Edit
            </Link>
          </CenterAlignedCell>
        </DataContainer>
      </ClosedFieldContainer>
    </ClosedSubFormContainer>
  );
};
