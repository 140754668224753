import { FC, useCallback, useEffect } from 'react';
import { Tab, Tabs, Typography } from '@mui/material';
import { useSearchParams } from 'react-router';
import { GenericFallbacksWrapper } from '../../../components/Fallback/GenericFallbacksWrapper';
import { Loader } from '../../../components/Loader/Loader';
import { EstablishedSync } from './EstablishedSync/EstablishedSync';
import { AirTableConfiguration } from './Configuration/AirTableConfiguration';

enum AirTableTab {
  configuration = 'configuration',
  establishedSync = 'established-sync',
}
const AirTableTabSearchParam = 'tab';

export const AirtableSync: FC = () => {
  const [search, setSearch] = useSearchParams();
  useEffect(() => {
    if (!search.get(AirTableTabSearchParam)) {
      setSearch((prev) => ({ ...prev, [AirTableTabSearchParam]: AirTableTab.configuration }));
    }
  }, [search, setSearch]);

  return (
    <GenericFallbacksWrapper suspenseFallback={<Loader />}>
      <Typography variant='h4' className='section-title' mb='1rem'>
        Airtable
      </Typography>
      <AirTableTabs />
      {search.get(AirTableTabSearchParam) === AirTableTab.configuration && <AirTableConfiguration />}
      {search.get(AirTableTabSearchParam) === AirTableTab.establishedSync && <EstablishedSync />}
    </GenericFallbacksWrapper>
  );
};

export const tabs = [
  { path: AirTableTab.configuration, title: 'Configuration' },
  {
    path: AirTableTab.establishedSync,
    title: 'Established Sync',
  },
];

const AirTableTabs: FC = () => {
  const [search, setSearch] = useSearchParams();

  const onChangeTab = useCallback(
    (path: AirTableTab) => {
      setSearch((prev) => ({ ...prev, [AirTableTabSearchParam]: path }));
    },
    [setSearch]
  );

  return (
    <Tabs
      variant='scrollable'
      scrollButtons='auto'
      value={search.get(AirTableTabSearchParam) || AirTableTab.configuration}
      onChange={(e, tab) => onChangeTab(tab)}
    >
      {tabs.map((t) => (
        <Tab key={t.path} value={t.path} label={t.title} style={{ paddingLeft: 0, marginRight: '1rem' }} />
      ))}
    </Tabs>
  );
};
