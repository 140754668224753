import { yupResolver } from '@hookform/resolvers/yup';
import {
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { useAtomValue } from 'jotai/index';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { get } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { Controller, DefaultValues, FormProvider, useForm, useWatch } from 'react-hook-form';
import { getColorStatusRendererMeta } from '../../../components/Form/ColorStatus/ColorStatus';
import { SelectConfig } from '../../../components/Form/Configuration/SelectConfig';
import {
  ErrorMessage,
  Form,
  FormDivider,
  FormField,
  FormFieldsContainer,
  Label,
} from '../../../components/Form/FormComponents';
import { StickyFormButtons } from '../../../components/Form/StickyFormButtons';
import { MultiSelect } from '../../../CoreComponents/base/MultiSelect';
import { RendererType } from '../../../data-models/field.data-model';
import {
  BasicFormConfig,
  FieldEntity,
  FormOptions,
  GridRef,
  IField,
} from '../../../data-models/field2.data-model';
import { createFormatterDataModel } from '../../../data-models/formatter.data-model';
import { PermissionKey } from '../../../services/PermissionAndRolesKeys';
import { PermissionService } from '../../../services/PermissionService';
import { currencyMapByCodeAtom, currencyMapByIdAtom } from '../../../services/state/AppConfigStateJ';
import { MaggieFeatureFlags } from '../../../util/feature-flags';
import { pluralizeWord } from '../../../util/stringUtils';
import { CurrencySelector } from '../../Finance/form-components/CurrencySelector';
import { InfoBox } from '../../Mappings/forms/InfoBox';
import { getCustomTextFieldInitialValues } from './initialValues/CustomFieldInitialValues';
import { createCustomFieldSchema } from './schemas/createCustomFieldSchema';

interface CustomFieldFormProps {
  onSubmit: (data: Partial<IField<unknown>>) => void;
  editMode?: boolean;
  initialValues?: Partial<IField<unknown>>;
  onClose: () => void;
}

export function CustomFieldForm({
  initialValues,
  onSubmit,
  onClose,
  editMode,
}: CustomFieldFormProps): JSX.Element {
  const canViewDeal = PermissionService.get().hasPermission(PermissionKey.canViewDeal);
  const initialVals = initialValues ?? getCustomTextFieldInitialValues();
  const formMethods = useForm<IField<unknown>>({
    defaultValues: initialVals as DefaultValues<IField<unknown>>,
    resolver: yupResolver(createCustomFieldSchema),
    mode: 'all',
  });
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    control,
    formState: { errors, isSubmitting, isDirty, isValid },
    trigger,
  } = formMethods;

  const currencyMapById = useAtomValue(currencyMapByIdAtom);
  const currencyMapByCode = useAtomValue(currencyMapByCodeAtom);
  const [prevType, setPrevType] = useState('');
  const watchRendererType = watch('formMeta.renderer.type');
  const { showSecurityView } = useFlags<MaggieFeatureFlags>();

  useEffect(() => {
    const newType = watchRendererType;

    if (prevType === RendererType.colorStatus && newType !== RendererType.colorStatus) {
      setValue('formMeta.renderer.config', {});
    }
    // reset formatter if renderer type is changed
    if (newType !== RendererType.currency) {
      setValue('formMeta.formatter', createFormatterDataModel());
    }
    if (newType === RendererType.colorStatus) {
      setValue('formMeta.renderer.id', RendererType.colorStatus);
      setValue('formMeta.renderer.config', getColorStatusRendererMeta() as unknown as BasicFormConfig);
    }

    //trigger validation to reset errors, doesn't happen automatically and we can otherwise get an off-by-one error
    trigger('formMeta.renderer.config.values');
    setPrevType(newType);
  }, [getValues, watchRendererType, trigger, setValue, prevType]);

  const entity = useWatch({ control, name: 'entity' });
  const renderer = useWatch({ control, name: 'formMeta.renderer.type' });

  const [gridRefs, setGridRefs] = useState<GridRef[]>(initialVals.systemMeta?.gridRefs ?? []);
  const handleColumnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setGridRefs((curr) => [...curr, e.target.name as GridRef]);
    } else {
      setGridRefs((curr) => curr.filter((item) => item !== e.target.name));
    }
  }, []);

  useEffect(() => {
    setValue('systemMeta.gridRefs', gridRefs, { shouldDirty: true });
  }, [gridRefs, setValue]);

  const nErrors = Object.keys(errors).length;
  const errorMessage =
    nErrors && !isValid
      ? `Please fill out or edit ${nErrors} ${pluralizeWord('field', nErrors)} in the form`
      : '';

  const showFundCompanyInfo = entity === FieldEntity.fundCompany;
  const showAdditionalColumnOptions = entity !== FieldEntity.deal;
  const showCurrencySelector = renderer === RendererType.currency && Boolean(currencyMapByCode);

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormFieldsContainer>
          <FormField>
            <Label required>Name</Label>
            <TextField
              {...register('displayName')}
              required
              error={!!errors.displayName}
              placeholder='Enter name'
              helperText={errors.displayName ? <>{errors.displayName?.message}</> : ''}
            />
          </FormField>
          <FormField>
            <Label required>Description</Label>
            <TextField
              {...register('description')}
              required
              placeholder='Short description'
              error={!!errors.description}
              helperText={errors.description ? <>{errors.description?.message}</> : ''}
            />
          </FormField>
          <FormField>
            <Label required>Type</Label>
            <Select
              {...register('formMeta.renderer.type')}
              required
              defaultValue={initialVals.formMeta?.renderer?.type}
              disabled={editMode}
            >
              <MenuItem value={RendererType.text}>Text</MenuItem>
              <MenuItem value={RendererType.singleSelect}>Select</MenuItem>
              <MenuItem value={RendererType.multiSelect}>Multiselect</MenuItem>
              <MenuItem value={RendererType.number}>Number</MenuItem>
              <MenuItem value={RendererType.percent}>Percentage</MenuItem>
              <MenuItem value={RendererType.currency}>Currency</MenuItem>
              <MenuItem value={RendererType.date}>Date</MenuItem>
              <MenuItem value={RendererType.colorStatus}>Color Status</MenuItem>
            </Select>
          </FormField>
          {showCurrencySelector && (
            <CurrencySelector
              onSelectCurrency={(val) => {
                setValue(
                  'formMeta.formatter',
                  createFormatterDataModel({ config: { currency: currencyMapById.get(val)?.code ?? '' } }),
                  { shouldDirty: true }
                );
                setValue('formMeta.formatter.extends', 'currency', { shouldDirty: true });
              }}
              initialValue={
                currencyMapByCode.get(
                  (get(initialVals, 'formMeta.formatter.config.currency') as string) ?? ''
                )?.id ?? null
              }
            />
          )}
          {(renderer === RendererType.singleSelect || renderer === RendererType.multiSelect) && (
            <FormField>
              <Label required>Options</Label>
              <SelectConfig valuesPath={'formMeta.renderer.config.values'} />
            </FormField>
          )}
          <FormDivider />
          <FormField style={showFundCompanyInfo ? undefined : { marginBottom: '-1rem' }}>
            <Label required>Entity</Label>
            <Controller
              control={control}
              name='entity'
              render={({ field: { onChange, value } }) => (
                <ToggleButtonGroup
                  color='secondary'
                  exclusive
                  aria-label='Entity'
                  value={value}
                  onChange={(event) => {
                    onChange(event);
                    setValue('systemMeta.formRefs', []);
                    setGridRefs([]);
                  }}
                  disabled={editMode}
                >
                  <ToggleButton value={FieldEntity.company}>Company</ToggleButton>
                  <ToggleButton value={FieldEntity.fundCompany}>Company & Fund</ToggleButton>
                  {canViewDeal && <ToggleButton value={FieldEntity.deal}>Deal</ToggleButton>}
                  {<ToggleButton value={FieldEntity.round}>Round</ToggleButton>}
                  {showSecurityView && (
                    <ToggleButton value={FieldEntity.captableInvestment}>Security</ToggleButton>
                  )}
                </ToggleButtonGroup>
              )}
            />
            <ErrorMessage>{errors.entity && <>{errors.entity?.message}</>}</ErrorMessage>
          </FormField>
          <Collapse in={showFundCompanyInfo}>
            {showFundCompanyInfo ? (
              <InfoBox
                message={
                  'A "Company & Fund" field means that it can have different values for different funds. Only relevant for portfolio companies'
                }
              />
            ) : (
              <div />
            )}
          </Collapse>
          <FormField>
            <Label>Display</Label>
            <Controller
              control={control}
              name='systemMeta.formRefs'
              render={({ field: { onChange, value } }) => (
                <MultiSelect
                  value={value ?? []}
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                  options={getEntityOptions(entity as FieldEntity, canViewDeal)}
                  renderInput={(params) => <TextField {...params} error={!!errors?.systemMeta} />}
                  disableCloseOnSelect
                  openOnFocus
                  getOptionLabel={(option) => option}
                />
              )}
            />
          </FormField>

          <Collapse in={showAdditionalColumnOptions}>
            {showAdditionalColumnOptions ? (
              <>
                <FormDivider />
                <FormField key={entity}>
                  <Label>Additional Column</Label>
                  <FormGroup>
                    {(entity === FieldEntity.company ||
                      entity === FieldEntity.fundCompany ||
                      entity === FieldEntity.round) && (
                      <FormControlLabel
                        sx={{ '& .MuiTypography-root': { fontSize: '.875rem' } }}
                        label='Add a column to Portfolio Reporting'
                        control={
                          <Checkbox
                            name={GridRef.portfolioReporting}
                            onChange={handleColumnChange}
                            defaultChecked={initialVals?.systemMeta?.gridRefs?.includes(
                              GridRef.portfolioReporting
                            )}
                          />
                        }
                      />
                    )}
                    {(entity === FieldEntity.round || entity === FieldEntity.company) && (
                      <FormControlLabel
                        sx={{ '& .MuiTypography-root': { fontSize: '.875rem' } }}
                        label='Add a column to Round Tracker'
                        control={
                          <Checkbox
                            name={GridRef.roundTracker}
                            onChange={handleColumnChange}
                            defaultChecked={initialVals?.systemMeta?.gridRefs?.includes(GridRef.roundTracker)}
                          />
                        }
                      />
                    )}
                    {entity === FieldEntity.captableInvestment && (
                      <FormControlLabel
                        sx={{ '& .MuiTypography-root': { fontSize: '.875rem' } }}
                        label='Add a column to Security Overview'
                        control={
                          <Checkbox
                            name={GridRef.securityOverview}
                            onChange={handleColumnChange}
                            defaultChecked={initialVals?.systemMeta?.gridRefs?.includes(
                              GridRef.securityOverview
                            )}
                          />
                        }
                      />
                    )}
                  </FormGroup>
                </FormField>
              </>
            ) : (
              <div />
            )}
          </Collapse>
        </FormFieldsContainer>

        <StickyFormButtons
          onSubmit={() => handleSubmit(onSubmit)()}
          onCancel={onClose}
          disabled={isSubmitting || !isDirty}
          loading={isSubmitting}
          submitLabel={editMode ? 'Save Changes' : 'Create'}
          errorMessage={errorMessage}
        />
      </Form>
    </FormProvider>
  );
}

function getEntityOptions(currentEntity: FieldEntity, canViewDeals: boolean) {
  switch (currentEntity) {
    case FieldEntity.captableInvestment:
      return [FormOptions.addingCaptableSecurity];
    case FieldEntity.company:
      return canViewDeals
        ? [FormOptions.addingTransaction, FormOptions.addingDeal, FormOptions.companyProfile]
        : [FormOptions.addingTransaction, FormOptions.companyProfile];
    case FieldEntity.fundCompany:
      return [FormOptions.companyProfile];
    case FieldEntity.round:
      return [FormOptions.addingRound];
    default:
      return canViewDeals ? [FormOptions.addingDeal] : [];
  }
}
