import { useMemo } from 'react';
import { createCompanyDataModel } from '../../../data-models/company.data-model';
import { IDashboardTransactionDataModel } from '../../../data-models/dashboard-transaction.data-model';
import { FMT } from '../../../util/formatter-service';
import { useGetCompanyIfSet } from '../../CompanyProfiles/hooks/useGetCompanyData';
import { MostRecentChartData } from './useMostRecentRounds';

export const useMostRecentRealizations = (data: IDashboardTransactionDataModel[]): MostRecentChartData[] => {
  // FIXME: once https://github.com/ForesightData/basic-portfolio-reporting-ui/pull/4296 is merged, use companyStateJ instead
  const getCompany = useGetCompanyIfSet();
  return useMemo(() => {
    if (!data) return [];
    return data.reduce((acc, d) => {
      if (!d.date) return acc;
      const company =
        getCompany(d.companyId) ??
        createCompanyDataModel({ name: d.companyName, logoUrl: d.companyLogoUrl ?? '' });
      acc.push({
        company,
        dealLead: company.dealLeadId ? FMT.format('user', company.dealLeadId) : '',
        date: new Date(d.date!),
        formattedValue: FMT.format('usdShort', d.value),
      });
      return acc;
    }, [] as MostRecentChartData[]);
  }, [data, getCompany]);
};
