import { Button, Stack, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import { useRecoilValue } from 'recoil';
import { MessageScreen } from '../../../../../components/MessageScreen/MessageScreen';
import ArchiveCheckIconSvg from '../../../../../assets/icons/archive-doc-check.svg?react';
import { isKPIPendingSubmission } from '../../../../../data-models/kpi-requests.data-model';
import { assignedKPIRequestsStateByCompany } from '../../../../../services/state/KPI/KPITemplatesState';

export function KPIThankYouScreen() {
  const location = useLocation();
  const companyId = Number(location.state?.companyId ?? -1);
  const navigate = useNavigate();
  const allRequests = useRecoilValue(assignedKPIRequestsStateByCompany);
  const hasPendingRequests =
    allRequests.get(companyId)?.some((request) => isKPIPendingSubmission(request.status)) ?? false;

  const goToPendingRequest = () => {
    navigate(`/kpis/pending-requests/${companyId}`);
  };

  return (
    <MessageScreen>
      <Stack alignItems={'center'} justifyContent={'flex-end'} gap={'1rem'}>
        <ArchiveCheckIconSvg />
        <Typography variant='h1'>Thank you!</Typography>
        {hasPendingRequests && (
          <>
            <Typography variant='h6' mt={'1rem'}>
              {`You still have pending requests`}
            </Typography>
            <Button color={'secondary'} variant={'contained'} onClick={goToPendingRequest}>
              Go to pending request
            </Button>
          </>
        )}
      </Stack>
    </MessageScreen>
  );
}
