import { Dialog, DialogContent, DialogTitle, IconButton, SxProps, Typography, useTheme } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog/Dialog';
import { PropsWithChildren, ReactNode, useCallback } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

const Title = styled('span')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export interface IFormDialogProps extends PropsWithChildren {
  lightDismiss?: boolean;
  disableRestoreFocus?: boolean;
  fullWidth?: DialogProps['fullWidth'];
  maxWidth?: DialogProps['maxWidth'];
  onClose?: () => void;
  open: boolean;
  showTitleBar?: boolean;
  sx?: SxProps;
  title?: string;
  titleComponent?: ReactNode;
}

export function BasicDialog(props: IFormDialogProps) {
  const {
    open,
    children,
    fullWidth,
    maxWidth = false,
    onClose,
    title,
    titleComponent,
    sx,
    disableRestoreFocus = false,
    lightDismiss = false,
    showTitleBar = true,
  } = props;
  const { colors } = useTheme();

  const _onClose = useCallback(() => {
    if (!lightDismiss) return;
    onClose?.();
  }, [lightDismiss, onClose]);

  return (
    <Dialog
      disableRestoreFocus={disableRestoreFocus}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={_onClose}
      open={open}
      sx={sx}
    >
      {showTitleBar && (
        <DialogTitle sx={{ paddingX: '2.5rem', paddingTop: '1rem' }}>
          <Title>
            {titleComponent ? titleComponent : <Typography>{title}</Typography>}
            {onClose ? (
              <IconButton
                aria-label='close'
                onClick={onClose}
                sx={{
                  color: colors.neutral[40],
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </Title>
        </DialogTitle>
      )}
      <DialogContent sx={{ padding: 0 }}>{children}</DialogContent>
    </Dialog>
  );
}
