import { FC } from 'react';
import { Outlet } from 'react-router';
import { useSetScenarioStateFromPath } from '../hooks/useSetScenarioStateFromPath';

export const SingleScenario: FC = () => {
  const ready = useSetScenarioStateFromPath();
  if (!ready) return null;

  return <Outlet />;
};
