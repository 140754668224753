import { styled, Typography, useTheme } from '@mui/material';
import { useAtomValue } from 'jotai/index';
import * as React from 'react';
import { useWatch } from 'react-hook-form';
import { IField } from '../../../data-models/field2.data-model';
import { currencyMapByIdAtom } from '../../../services/state/AppConfigStateJ';
import { Formatting } from '../../../types';
import { getMetricUnit } from '../../../util/field-utils';

const CellWrapper = styled('div')`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr min-content;
`;

export interface IMetricNameCellProps {
  metric: IField<unknown>;
  editMode?: boolean;
}
export function MetricNameCell(props: IMetricNameCellProps) {
  const theme = useTheme();
  const { metric, editMode } = props;

  const inputCurrencyId = useWatch({
    name: `kpiData.currencyId`,
  });

  const isCurrency = metric.formMeta?.renderer?.type === 'currency';
  const currencyId =
    isCurrency && metric.formMeta?.renderer?.id !== 'currency' ? metric.formMeta?.renderer?.id : 1;

  const currencyIdForm = editMode ? Number(inputCurrencyId) : currencyId;
  const currencyMapById = useAtomValue(currencyMapByIdAtom);

  const metricCurrency = React.useMemo(
    () => currencyMapById.get(Number(currencyIdForm)) ?? null,
    [currencyIdForm, currencyMapById]
  );

  return (
    <CellWrapper>
      <Typography variant={'body2'}>{metric.displayName}</Typography>
      <Typography color={theme.colors.neutral[40]}>
        {isCurrency ? metricCurrency?.symbol : getMetricUnit(metric.formMeta?.renderer?.id as Formatting)}
      </Typography>
    </CellWrapper>
  );
}
