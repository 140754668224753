import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ROUTES } from '../constants/RouteNameMapping';
import { MaggieFeatureFlags } from '../util/feature-flags';
import { PermissionService } from '../services/PermissionService';
import { RoleKey } from '../services/PermissionAndRolesKeys';

export function useHasAdditionalNav() {
  const { pathname } = useLocation();
  const { showReconciliation, showAllSecurities } = useFlags<MaggieFeatureFlags>();

  return useMemo(() => {
    const loc = pathname.split('/')[1];
    return (
      !PermissionService.get().hasRole(RoleKey.managedServices) ||
      loc === ROUTES.PORTFOLIO ||
      loc === ROUTES.DEAL_FLOW ||
      pathname.includes('ci-report') ||
      loc === ROUTES.COMPANIES ||
      loc === ROUTES.ADMIN_PANEL ||
      loc === ROUTES.KPI ||
      ((showReconciliation || showAllSecurities) && loc === ROUTES.FINANCE2)
    );
  }, [pathname, showAllSecurities, showReconciliation]);
}
