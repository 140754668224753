import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Divider, styled, Tooltip } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { useAtomValue } from 'jotai/index';
import { useMemo } from 'react';
import { MUIAvatar } from '../../../../components/Avatar/MUIAvatar';
import { MUIAvatarGroup } from '../../../../components/Avatar/MUIAvatarGroup';
import { IContactActivityDataModel, IPersonDataModel } from '../../../../data-models/person.data-model';
import { IUserRelationDataModel } from '../../../../data-models/user-relation.data-model';
import { usersByIdMapAtom } from '../../../../services/state/AppConfigStateJ';
import { ActivityDetails } from '../../../CompanyProfiles2/Header/ActivityDetails';
import { getEngagementSummary } from '../../../CompanyProfiles2/utils/getPersonActivityData';
import { StrongestConnections } from './StrongestConnection';

const Cell = styled('div')`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr min-content;
  height: 100%;
  width: 100%;
`;

export function ConnectionsRenderer(params: ICellRendererParams<IPersonDataModel>) {
  const activities = params.data?.activity ?? null;
  const relationships = params.data?.relationships ?? [];
  const users = useAtomValue(usersByIdMapAtom);

  if (relationships.length === 0) {
    return null;
  }

  const relationElems = relationships
    .map((relation) => {
      const { userId } = relation;
      const user = users.get(userId);
      return user !== undefined ? (
        <MUIAvatar
          key={relation.userId}
          nameParts={[user.firstName, user.lastName]}
          size={'medium'}
          src={null}
        />
      ) : undefined;
    })
    .filter((elem) => elem !== undefined);

  return (
    <Cell>
      <MUIAvatarGroup max={4} size={'medium'} sx={{ height: '100%', alignItems: 'center' }}>
        {relationElems}
      </MUIAvatarGroup>
      <Tooltip
        componentsProps={{
          tooltip: { sx: { color: 'black', minWidth: 'fit-content', padding: '1rem' } },
        }}
        placement='top-end'
        title={<ConnectionDetails relationships={relationships} activity={activities} />}
        leaveDelay={200} // allow time for user to hover over tooltip
      >
        <InfoOutlinedIcon color={'secondary'} fontSize='small' />
      </Tooltip>
    </Cell>
  );
}

export interface IConnectionDetailsProps {
  activity: IContactActivityDataModel | null;
  relationships: IUserRelationDataModel[];
}

export function ConnectionDetails(props: IConnectionDetailsProps) {
  const { activity, relationships } = props;
  const engagementSummary = useMemo(() => getEngagementSummary(activity), [activity]);
  return (
    <div>
      <StrongestConnections relationships={relationships} />
      <Divider sx={{ marginTop: '.5rem', marginBottom: '.5rem', opacity: '0.6' }} />
      {props.activity && (
        <ActivityDetails engagementSummary={engagementSummary} style={{ gridRowGap: '0.5rem' }} />
      )}
    </div>
  );
}
