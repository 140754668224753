import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditIcon from '@mui/icons-material/Edit';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import ShareIcon from '@mui/icons-material/Share';
import { Icon as MuiIcon, Typography, useTheme } from '@mui/material';
import TouchRipple, { TouchRippleActions } from '@mui/material/ButtonBase/TouchRipple';
import { styled } from '@mui/material/styles';
import { useAtomValue } from 'jotai/index';
import { ReactElement, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { VIEW_TYPE } from '../../../../../data-models/view-config.data-model';
import { ShareViewModal } from '../../../../../pages/PortfolioOverview/components/ShareViewModal/ShareViewModal';
import { useRenameView } from '../../../../../pages/PortfolioOverview/hooks/useViewActions';
import { selectedViewPF } from '../../../../../pages/PortfolioOverview/state/ViewState';
import { activeUsersAtom } from '../../../../../services/state/AppConfigStateJ';
import { ViewAction, ViewActionName } from '../../../../../types';
import { BasicDialog } from '../../../../Dialog/BasicDialog';
import { EditableTypography } from '../../../../EditableTypography/EditableTypography';
import { Icon } from '../../../../Icon';
import { ViewMenu } from './ViewMenu';

interface Props {
  text: string;
  onClick: () => void;
  isSelected?: boolean;
  isDraggable: boolean;
  viewTypeIcon?: string;
  viewType: VIEW_TYPE;
  suppressMenu?: boolean;
}

const IconWrapper = styled('div')`
  min-width: 35px;
  margin-bottom: -3px;
`;
const LabelWrapper = styled('div')<{ isShared: boolean }>`
  ${({ isShared }) => (isShared ? `padding-left: 5px;` : '')}
`;

const ViewTypeIconWrapper = styled('div')`
  margin-bottom: -3px;
  svg {
    width: 15px;
    height: 15px;
    stroke: white;
  }
`;

const StyledOption = styled('li')<{ isSelected: boolean }>`
  height: 32px;
  padding-left: 3px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  ${({ isSelected }) => (isSelected ? 'background-color: #2D3277;' : '')}
  cursor: pointer;
`;

const OptionContentWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.1rem;
`;

const EditableTypographyWrapper = styled('div')``;

export const ViewOption = ({
  text,
  onClick,
  isSelected = false,
  isDraggable,
  viewTypeIcon,
  viewType,
  suppressMenu,
}: Props): ReactElement => {
  const [showMenu, setShowMenu] = useState(false);
  const [options, setOptions] = useState<ViewAction[]>([]);
  const [editable, setEditable] = useState(false);
  const [shareModelIsOpen, setShareModalIsOpen] = useState(false);
  const users = useAtomValue(activeUsersAtom);
  const { colors } = useTheme();
  const renameView = useRenameView();
  const selectedView = useRecoilValue(selectedViewPF);
  const rippleRef = useRef<null | TouchRippleActions>(null);

  const onRippleStart = (e: SyntheticEvent) => {
    rippleRef.current?.start(e);
  };
  const onRippleStop = (e: SyntheticEvent) => {
    rippleRef.current?.stop(e);
  };

  const handleMouseEnter = useCallback(() => {
    setShowMenu(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setShowMenu(false);
  }, []);

  const onModalClose = useCallback(() => {
    setShareModalIsOpen(false);
  }, []);

  const handleDoubleClick = () => {
    if (isSelected) setEditable(true);
  };

  const onSubmit = async (newName: string) => {
    if (!selectedView || newName === selectedView.label) {
      setEditable(false);
      return;
    }

    await renameView(selectedView, newName);
    setEditable(false);
  };

  useEffect(() => {
    switch (viewType) {
      case VIEW_TYPE.PERSONAL:
        setOptions([
          {
            id: ViewActionName.RENAME,
            value: 'Rename',
            icon: <EditIcon fontSize='small' />,
          },
          {
            id: ViewActionName.DUPLICATE,
            value: 'Duplicate View',
            icon: <ContentCopyIcon fontSize='small' />,
          },
          {
            id: ViewActionName.RESET,
            value: 'Reset to Default',
            icon: <RotateLeftIcon fontSize='small' />,
          },
          {
            id: ViewActionName.SHARE,
            value: 'Share View',
            icon: <ShareIcon fontSize='small' />,
          },
          {
            id: ViewActionName.DELETE,
            value: 'Delete View',
            icon: <DeleteOutlineIcon fontSize='small' />,
            hasSeparator: true,
          },
        ]);
        break;
      case VIEW_TYPE.COMPANY:
        setOptions([
          {
            id: ViewActionName.DUPLICATE,
            value: 'Duplicate View',
            icon: <ContentCopyIcon fontSize='small' />,
          },
        ]);
        break;
      case VIEW_TYPE.SHARED:
        setOptions([
          {
            id: ViewActionName.DUPLICATE,
            value: 'Duplicate View',
            icon: <ContentCopyIcon fontSize='small' />,
          },
          {
            id: ViewActionName.DELETE,
            value: 'Delete View',
            icon: <DeleteOutlineIcon fontSize='small' />,
            hasSeparator: true,
          },
        ]);
        break;
    }
  }, [viewType]);

  return (
    <StyledOption
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseDown={onRippleStart}
      onMouseUp={onRippleStop}
      role='option'
      onClick={onClick}
      isSelected={isSelected}
    >
      <TouchRipple ref={rippleRef} center={false} />
      <OptionContentWrapper>
        <>
          {isDraggable ? (
            <DragIndicatorIcon sx={{ fill: colors.primary[30] }} fontSize='small' />
          ) : (
            <MuiIcon fontSize='small' />
          )}
        </>
        <ViewTypeIconWrapper>
          {viewTypeIcon && <Icon type={viewTypeIcon} color={colors.secondary[20]} />}
        </ViewTypeIconWrapper>
        {viewType === VIEW_TYPE.PERSONAL ? (
          <EditableTypographyWrapper onDoubleClick={handleDoubleClick}>
            <EditableTypography
              variant='caption'
              color={colors.secondary[10]}
              content={text}
              editable={editable}
              handleSubmit={onSubmit}
              inputColor={colors.secondary[10]}
              width={150}
            />
          </EditableTypographyWrapper>
        ) : (
          <LabelWrapper isShared={viewType === VIEW_TYPE.SHARED}>
            <Typography variant='caption' color={colors.secondary[10]}>
              {text}
            </Typography>
          </LabelWrapper>
        )}
      </OptionContentWrapper>
      {suppressMenu
        ? null
        : isSelected &&
          showMenu && (
            <IconWrapper>
              <ViewMenu
                onRename={() => setEditable(true)}
                openShareDialog={() => setShareModalIsOpen(true)}
                options={options}
              />
            </IconWrapper>
          )}
      <BasicDialog open={shareModelIsOpen} onClose={onModalClose} title={`Share view "${selectedView.name}"`}>
        <ShareViewModal users={users} onCloseClick={onModalClose} />
      </BasicDialog>
    </StyledOption>
  );
};
