import { PrimitiveType } from '../../data-models/field3.data-model';
import { createFormatterDataModel, IFormatterDataModel } from '../../data-models/formatter.data-model';

export interface IArrayFormatConfig {
  itemType: PrimitiveType;
  itemFormatter?: IFormatterDataModel<unknown> | string;
}

export enum ArrayFormatterId {
  numberArray = 'numberArray',
  percentArray = 'percentArray',
  stringArray = 'stringArray',
  usdArray = 'usdArray',
}

export const ArrayFormattersConfig: Record<ArrayFormatterId, IFormatterDataModel<IArrayFormatConfig>> = {
  numberArray: createFormatterDataModel({
    id: 'numberArray',
    config: {
      itemType: 'number',
      itemFormatter: 'numeric',
    },
    type: 'array',
  }),
  percentArray: createFormatterDataModel({
    id: 'percentArray',
    config: {
      itemType: 'number',
      itemFormatter: 'percentRound',
    },
    type: 'array',
  }),
  stringArray: createFormatterDataModel({
    id: 'stringArray',
    config: {
      itemType: 'string',
      itemFormatter: 'string',
    },
    type: 'array',
  }),
  usdArray: createFormatterDataModel({
    id: 'usdArray',
    config: {
      itemType: 'number',
      itemFormatter: 'usd',
    },
    type: 'array',
  }),
};
