import { Publish } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Button, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useFormik } from 'formik';
import { useAtomValue } from 'jotai';
import upperFirst from 'lodash-es/upperFirst';
import { FC, FormEvent, SyntheticEvent, useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import {
  createListDataModel,
  IListDataModel,
  ListSharingType,
} from '../../../../../../data-models/list.data-model';
import {
  activeCompanyListIdState,
  listsByIdState,
} from '../../../../../../pages/CompanyProfiles/state/ListsState';
import { FormWrapper } from '../../../../../../pages/Finance/components/TransactionModal/Forms/TransactionForms/Equity/EquityBuyForm';
import {
  activeUsersAtom,
  teamsMapAtom,
  usersByIdMapAtom,
} from '../../../../../../services/state/AppConfigStateJ';
import { CompanyAutocomplete } from '../../../../../CompanyAutocomplete/CompanyAutocomplete';
import { FieldGroup } from '../../../../../FieldGroup/FieldGroup';
import { FormField } from '../../../../../FormField/FormField';
import { useModalState } from '../../../../../Modal/ModalContext';
import { CompanyListSchema as validationSchema } from './CompanyListSchema';

const InnerFormWrapper = styled('div')<{ showBorder: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  ${({ showBorder }) => showBorder && 'border: 1px solid #e0e0e0;'}
  ${({ showBorder }) => showBorder && '  padding: 0 2rem;'}
  border-radius: 4px;
`;

const ButtonWrapper = styled('div')`
  display: flex;
  gap: 12px;
  justify-content: flex-start;
  padding-bottom: 2rem;
`;

interface IProps {
  isEdit?: boolean;
  onSubmit: (list: Partial<IListDataModel>) => Promise<void>;
}

const sourceTypes = [
  { id: 1, value: 'Referral' },
  { id: 2, value: 'Direct' },
  { id: 3, value: 'Outbound' },
];

export const CompanyListForm: FC<IProps> = ({ isEdit = false, onSubmit }) => {
  const activeListId = useRecoilValue(activeCompanyListIdState);
  const listsMap = useRecoilValue(listsByIdState);
  const activeList = activeListId != null ? listsMap.get(activeListId) : null;

  const usersMap = useAtomValue(usersByIdMapAtom);
  const activeUsers = useAtomValue(activeUsersAtom);
  const allUserIds: number[] = useMemo(() => {
    return activeUsers.map((user) => user.id);
  }, [activeUsers]);

  const teamsMap = useAtomValue(teamsMapAtom);
  const allTeamIds: number[] = useMemo(() => {
    return Array.from(teamsMap.values()).map((team) => team.id);
  }, [teamsMap]);

  const {
    handleChange,
    values,
    handleBlur,
    touched,
    errors,
    setTouched,
    setFieldValue,
    isValid,
    handleSubmit,
    isSubmitting,
  } = useFormik<Partial<IListDataModel>>({
    initialValues: createListDataModel(isEdit ? { ...activeList } : {}),
    validationSchema,
    onSubmit,
  });

  const { onCloseModal } = useModalState();

  const handleError = (field: keyof IListDataModel) => (touched[field] ? errors[field] : '');

  const onSharingWithChange = useCallback(
    (event: React.MouseEvent, value: string) => {
      setFieldValue('shareWithTeamIds', []);
      setFieldValue('shareWithUserIds', []);
      setFieldValue('sharing', value);
    },
    [setFieldValue]
  );

  const onSourceCompanyChange = useCallback(
    (newValue: string | null) => {
      if (newValue === null) return;
      setFieldValue('sourceCompany', newValue);
    },
    [setFieldValue]
  );

  const onTeamChange = useCallback(
    (event: SyntheticEvent<Element, Event>, newValue: number[]) => {
      setFieldValue('shareWithTeamIds', newValue);
    },
    [setFieldValue]
  );

  const onUsersChange = useCallback(
    (event: SyntheticEvent<Element, Event>, newValue: number[]) => {
      setFieldValue('shareWithUserIds', newValue);
    },
    [setFieldValue]
  );

  return (
    <FormWrapper
      onSubmit={(e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <InnerFormWrapper showBorder={!isEdit}>
        <FieldGroup hasBorder={false}>
          <FormField label={'List Name'} required error={handleError('name')}>
            <TextField
              name={'name'}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              variant='outlined'
            />
          </FormField>
        </FieldGroup>
        <FieldGroup>
          <FormField label={'Source Type'} error={handleError('sourceType')}>
            <Autocomplete
              options={sourceTypes}
              disablePortal
              renderInput={(params) => <TextField {...params} name={`sourceType`} id={`sourceType`} />}
              getOptionLabel={(option) => option.value}
              value={sourceTypes?.find(({ value }) => value == values.sourceType) ?? null}
              onChange={(_, option) => setFieldValue('sourceType', option?.value ?? null)}
              onFocus={() => setTouched({ ...touched, sourceType: true })}
            />
          </FormField>
          <FormField label={'Internal Source'} error={handleError('internalSource')}>
            <Autocomplete
              options={allUserIds}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={`internalSource`}
                  id={`internalSource`}
                  placeholder='Select Internal Source'
                  sx={{ marginTop: '0.5rem' }}
                />
              )}
              disablePortal
              value={Number(values.internalSource)}
              getOptionLabel={(id) => usersMap.get(Number(id))?.name || ''}
              isOptionEqualToValue={(id, value) => id === value}
              onChange={(_, option) => setFieldValue('internalSource', option ?? '')}
              onFocus={() => setTouched({ ...touched, internalSource: true })}
            />
          </FormField>
          <FormField label={'Source Company'} error={handleError('sourceCompany')}>
            <CompanyAutocomplete
              value={values.sourceCompany}
              onChange={onSourceCompanyChange}
              onBlur={handleBlur}
              onFocus={() => setTouched({ ...touched, sourceCompany: true })}
            />
          </FormField>
          <FormField label={'Source'} error={handleError('source')}>
            <TextField
              name={'source'}
              value={values.source}
              onChange={handleChange}
              onBlur={handleBlur}
              variant='outlined'
            />
          </FormField>
        </FieldGroup>
        <FieldGroup>
          <FormField label={'Share with'} required>
            <ToggleButtonGroup
              aria-label='select share with'
              color={'secondary'}
              exclusive
              onChange={onSharingWithChange}
              value={values.sharing}
              fullWidth={true}
            >
              <ToggleButton value={ListSharingType.PRIVATE} aria-label='share with  none'>
                {upperFirst(ListSharingType.PRIVATE)}
              </ToggleButton>
              <ToggleButton value={ListSharingType.INDIVIDUAL} aria-label='share with individuals'>
                {upperFirst(ListSharingType.INDIVIDUAL)}
              </ToggleButton>
              <ToggleButton value={ListSharingType.TEAM} aria-label='share with team'>
                {upperFirst(ListSharingType.TEAM)}
              </ToggleButton>
              <ToggleButton value={ListSharingType.PUBLIC} aria-label='share with everyone'>
                {upperFirst(ListSharingType.PUBLIC)}
              </ToggleButton>
            </ToggleButtonGroup>
            {values.sharing === ListSharingType.INDIVIDUAL && (
              <Autocomplete
                multiple
                options={allUserIds}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name={'shareWithUserIds'}
                    placeholder={`Select a user`}
                    sx={{ marginTop: '0.5rem' }}
                    size='small'
                  />
                )}
                disablePortal
                getOptionLabel={(option) => usersMap.get(option)?.name || ''}
                isOptionEqualToValue={(option, value) => option === value}
                value={values.shareWithUserIds ?? []}
                onChange={onUsersChange}
              />
            )}
            {values.sharing === ListSharingType.TEAM && (
              <Autocomplete
                multiple
                options={allTeamIds}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name={'shareWithTeamIds'}
                    placeholder={`Select a team`}
                    sx={{ marginTop: '0.5rem' }}
                    size='small'
                  />
                )}
                disablePortal
                getOptionLabel={(option) => teamsMap.get(option)?.name || ''}
                isOptionEqualToValue={(option, value) => option === value}
                value={values.shareWithTeamIds ?? []}
                onChange={onTeamChange}
              />
            )}
          </FormField>
        </FieldGroup>
        <ButtonWrapper>
          <LoadingButton
            variant={'contained'}
            color='secondary'
            disabled={!isValid || isSubmitting}
            type={'submit'}
            loading={isSubmitting}
            endIcon={<Publish />}
            loadingPosition='end'
          >
            {isEdit ? 'Save' : 'Create List'}
          </LoadingButton>
          <Button variant={'outlined'} color='secondary' onClick={onCloseModal}>
            Cancel
          </Button>
        </ButtonWrapper>
      </InnerFormWrapper>
    </FormWrapper>
  );
};
