import { addYears, endOfDay, startOfDay } from 'date-fns';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { CalendarShortcut } from '../components/DatePicker/Calendar';
import { getColorStatusRendererMeta } from '../components/Form/ColorStatus/ColorStatus';
import { SelectType } from '../components/Form/Display/FieldSelect';
import { RendererType } from '../data-models/field.data-model';
import { ISimpleChoice } from '../data-models/field2.data-model';
import {
  ICurrencyMeta,
  IDateMeta,
  INumberMeta,
  ISelectMeta,
  ITextMeta,
} from '../data-models/field3.data-model';
import { sortGroupedFunds } from '../pages/PortfolioOverview/state/FundFilterState';
import { Fund } from '../schemas/Fund.schema';
import {
  currenciesAtom,
  fundsAtom,
  roundsAtom,
  sectorsAtom,
  usersAtom,
} from '../services/state/AppConfigStateJ';
import {
  createFormField,
  IFormField,
  IFormFieldSelectMeta,
  IFormFieldTextMeta,
} from '../view-models/form.view-model';

export type FieldSettings = Pick<
  IFormField<unknown>,
  'description' | 'key' | 'label' | 'required' | 'disabled'
>;

export function useRoundIdField({
  key = 'roundId',
  label = 'Stage',
  required = true,
  disabled = false,
}: Partial<FieldSettings> = {}) {
  const rounds = useAtomValue(roundsAtom);

  return createFormField<ISelectMeta<number>>({
    key,
    label,
    required,
    disabled,
    dataType: 'string',
    renderer: RendererType.singleSelect,
    rendererMeta: {
      values: rounds.map((round) => {
        return { value: round.id, displayName: round.displayName };
      }),
      multi: false,
    },
  });
}

export function useFundIdField({
  key = 'fundId',
  label = 'Fund',
  required = true,
  disabled = false,
  selectableFunds = undefined,
}: Partial<FieldSettings> & { selectableFunds?: Fund[] } = {}) {
  const defaultFunds = useAtomValue(fundsAtom);
  const funds = selectableFunds ?? sortGroupedFunds(defaultFunds);

  return createFormField<ISelectMeta<number>>({
    key,
    label,
    required,
    dataType: 'number',
    renderer: RendererType.singleSelect,
    rendererMeta: {
      values: funds.reduce((res, fund) => {
        res.push({ value: fund.id, displayName: fund.name });

        return res;
      }, [] as ISimpleChoice<number>[]),
      multi: false,
    },
    disabled,
  });
}

export function useGetCurrencyIdField() {
  const currencies = useAtomValue(currenciesAtom);

  return useCallback(
    (key: string, label = key, required = false, disabled = false) => {
      return createFormField<IFormFieldSelectMeta<number>>({
        key,
        label,
        required,
        disabled,
        dataType: 'number',
        renderer: RendererType.singleSelect,
        rendererMeta: {
          values: (currencies ?? []).map((currency) => {
            return { value: currency.id, displayName: currency.code };
          }),
          multi: false,
        },
      });
    },
    [currencies]
  );
}

export function amountField({
  description,
  key,
  label = key,
  required = false,
  disabled = false,
}: FieldSettings) {
  return createFormField<ICurrencyMeta>({
    description,
    disabled,
    key,
    label,
    required,
    renderer: RendererType.currency,
    rendererMeta: {
      defaultCurrency: 'USD',
    },
  });
}

export function conversionRatioField8dp({
  key = 'conversionRatio',
  label = 'Conversion Ratio',
  required = false,
  description,
  disabled = false,
}: Partial<FieldSettings> = {}) {
  return createFormField<INumberMeta>({
    key,
    label,
    required,
    description,
    disabled,
    renderer: RendererType.number,
    formatter: 'multiplierHighPrecision',
  });
}

export function sharesField({ key, label = key, required = false, disabled = false }: FieldSettings) {
  return createFormField<INumberMeta>({
    key,
    label,
    required,
    disabled,
    renderer: RendererType.integer,
  });
}

export function simpleTextField({ key, label = key, required = false, disabled = false }: FieldSettings) {
  return createFormField<ITextMeta>({
    key,
    label,
    required,
    disabled,
    renderer: RendererType.text,
  });
}

export function booleanField({ key, label = key, required = false, disabled = false }: FieldSettings) {
  return createFormField<unknown>({
    key,
    label,
    required,
    disabled,
    renderer: RendererType.boolean,
  });
}

export function percentField({ key, label = key, required = false, disabled = false }: FieldSettings) {
  return createFormField<INumberMeta>({
    key,
    label,
    required,
    disabled,
    renderer: RendererType.percent,
  });
}

export function dateField({ key, label = key, required = false, disabled = false }: FieldSettings) {
  return createFormField<IDateMeta>({
    key,
    label,
    required,
    disabled,
    renderer: RendererType.date,
  });
}

export function futureDateField({ key, label = key, required = false, disabled = false }: FieldSettings) {
  return {
    ...dateField({ key, label, required, disabled }),
    rendererMeta: {
      min: startOfDay(new Date()).toISOString(),
      max: endOfDay(addYears(new Date(), 30)).toISOString(),
      presets: [
        CalendarShortcut.in3Years,
        CalendarShortcut.in5Years,
        CalendarShortcut.in10Years,
        CalendarShortcut.in30Years,
      ],
    },
  };
}

export function simpleMultilineTextField({
  key,
  label = key,
  required = false,
  disabled = false,
}: FieldSettings) {
  return createFormField<IFormFieldTextMeta>({
    key,
    label,
    required,
    disabled,
    renderer: RendererType.text,
    rendererMeta: {
      maxLength: -1,
      multiline: true,
    },
  });
}
export function useSectorIdField({
  key = 'sectorId',
  label = 'Sector',
  required = false,
  disabled = false,
}: Partial<FieldSettings>) {
  const sectors = useAtomValue(sectorsAtom);

  return createFormField<IFormFieldSelectMeta<number>>({
    key,
    label,
    required,
    disabled,
    renderer: RendererType.singleSelect,
    rendererMeta: {
      values: sectors.map(({ id, displayName }) => ({
        value: id,
        displayName,
      })),
      multi: false,
    },
  });
}

export function useUserIdField({
  key,
  label = key,
  required = false,
  multi = false,
}: FieldSettings & { multi?: boolean }) {
  const users = useAtomValue(usersAtom);

  return createFormField<IFormFieldSelectMeta<number>>({
    key,
    label,
    required,
    renderer: multi ? RendererType.multiSelect : RendererType.singleSelect,
    rendererMeta: {
      values: users.map(({ id, firstName, lastName }) => ({
        value: id,
        displayName: `${firstName} ${lastName}`,
      })),
      multi,
    },
  });
}

export function companyWebsiteField({
  key = 'website',
  label = 'Website',
  required = false,
  disabled = false,
}: Partial<FieldSettings> = {}) {
  return createFormField<ITextMeta>({
    key,
    label,
    required,
    disabled,
    renderer: RendererType.companyWebsite,
  });
}

export function colorStatusField({
  key,
  label = key,
  required,
  disabled,
  inlineLabel = false,
  rendererMeta = {},
}: FieldSettings & {
  inlineLabel?: boolean;
  rendererMeta?: Partial<ISelectMeta<string>>;
}) {
  return createFormField<IFormFieldSelectMeta<SelectType | string>>({
    key,
    label,
    required,
    disabled,
    renderer: inlineLabel ? RendererType.colorStatusWithLabel : RendererType.colorStatus,
    variant: 'form-inline',
    rendererMeta: {
      ...getColorStatusRendererMeta(),
      ...rendererMeta,
    },
  });
}
