import { DndContext, DragEndEvent, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { SelectItem, ViewItem } from '../../../../../types';
import { selectedViewPF } from '../../../../../pages/PortfolioOverview/state/ViewState';
import { ViewTypeByData } from '../../../../../data-models/view-config.data-model';
import { MaggieFeatureFlags } from '../../../../../util/feature-flags';
import { CollapsibleViewSection } from '../NavigationWrapper';
import { Options } from './Options';

interface Props {
  items: ViewItem[];
  onItemSelect: (item: SelectItem) => void;
  onReorder: (reorderedItems: SelectItem[]) => void;
}

export const ViewOptions = ({ items, onItemSelect, onReorder }: Props) => {
  const [itemsState, setItemsState] = useState(items);
  const selectedView = useRecoilValue(selectedViewPF);
  const { showFundReporting, showMultiPeriodReporting, showPeopleReporting, showSecurityView } =
    useFlags<MaggieFeatureFlags>();

  const companyItems = useMemo(
    () => itemsState.filter((i) => i.viewType === ViewTypeByData.company),
    [itemsState]
  );

  const fundItems = useMemo(() => itemsState.filter((i) => i.viewType === ViewTypeByData.fund), [itemsState]);

  const transactionItems = useMemo(
    () => itemsState.filter((i) => i.viewType === ViewTypeByData.transaction),
    [itemsState]
  );

  const peopleItems = useMemo(
    () => itemsState.filter((i) => i.viewType === ViewTypeByData.people),
    [itemsState]
  );

  const multiperiodItems = useMemo(
    () => itemsState.filter((i) => i.viewType === ViewTypeByData.multiperiod),
    [itemsState]
  );

  const securityItems = useMemo(
    () => itemsState.filter((i) => i.viewType === ViewTypeByData.security),
    [itemsState]
  );

  const handleReorder = (e: DragEndEvent) => {
    const { active, over } = e;
    if (active.id !== over?.id) {
      const oldIndex = itemsState.findIndex((sc) => sc.id === active.id);
      const newIndex = itemsState.findIndex((sc) => sc.id === over?.id);
      const reorderedItems = arrayMove(itemsState, oldIndex, newIndex);
      setItemsState(reorderedItems);
      onReorder(reorderedItems);
    }
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  useEffect(() => {
    setItemsState(items);
  }, [items]);

  const selectedViewId = selectedView?.viewId;
  if (!selectedView) return <></>;

  return (
    <DndContext onDragEnd={handleReorder} sensors={sensors}>
      {showFundReporting && (
        <ViewList
          items={fundItems}
          title={'FUNDS VIEWS'}
          onItemSelect={onItemSelect}
          selectedViewId={selectedViewId}
        />
      )}
      <ViewList
        items={companyItems}
        title={'COMPANY VIEWS'}
        onItemSelect={onItemSelect}
        selectedViewId={selectedViewId}
      />
      <ViewList
        items={transactionItems}
        title={'ROUND VIEWS'}
        onItemSelect={onItemSelect}
        selectedViewId={selectedViewId}
      />
      {showSecurityView && (
        <ViewList
          items={securityItems}
          title={'SECURITY VIEWS'}
          onItemSelect={onItemSelect}
          selectedViewId={selectedViewId}
        />
      )}
      {showPeopleReporting && (
        <ViewList
          items={peopleItems}
          title={'TALENT VIEWS'}
          onItemSelect={onItemSelect}
          selectedViewId={selectedViewId}
        />
      )}
      {showMultiPeriodReporting && (
        <ViewList
          items={multiperiodItems}
          title={'MULTI-PERIOD REPORTING'}
          onItemSelect={onItemSelect}
          selectedViewId={selectedViewId}
        />
      )}
    </DndContext>
  );
};

interface IViewListProps extends Omit<Props, 'onReorder'> {
  selectedViewId: string;
  title: string;
}

function ViewList({ title, items, onItemSelect, selectedViewId }: IViewListProps) {
  const defaultExpanded = useMemo(() => {
    return items.some((item) => item.id === selectedViewId);
  }, [items, selectedViewId]);

  if (items.length === 0) return null;

  return (
    <CollapsibleViewSection title={title} defaultExpanded={defaultExpanded}>
      <Options
        items={items}
        onItemSelect={onItemSelect}
        style={{ maxHeight: 'fit-content', paddingBlock: '0.5rem' }}
      />
    </CollapsibleViewSection>
  );
}
