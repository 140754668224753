import { styled } from '@mui/material/styles';
import { Outlet, useLocation } from 'react-router';
import { useRecoilValue } from 'recoil';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { GenericFallbacksWrapper } from './components/Fallback/GenericFallbacksWrapper';
import { PortfolioReportingNavigation } from './components/Layout/Navbar/Navigation/PortfolioReporting/PortfolioReportingNavigation';
import { CINavigation } from './components/Layout/Navbar/Navigation/CompetitiveIntelligence/CINavigation';
import { DealflowNavigation } from './components/Layout/Navbar/Navigation/Dealflow/DealflowNavigation';
import { ROUTES } from './constants/RouteNameMapping';
import { Navbar } from './components/Layout/Navbar/Navbar';
import { CPNavigation } from './components/Layout/Navbar/Navigation/CompanyProfiles/CPNavigation';
import { isPresentModeState } from './pages/DealFlow2/state/DealboardUIState';
import { AdminPanelNavigation } from './components/Layout/Navbar/Navigation/AdminPanel/AdminPanelNavigation';
import { KPINavigation } from './components/Layout/Navbar/Navigation/KPI/KPINavigation';
import { useFullScreen } from './hooks/useFullScreen';
import { FinanceNavigation } from './components/Layout/Navbar/Navigation/Finance/FinanceNavigation';
import { MaggieFeatureFlags } from './util/feature-flags';
import { ContainerName } from './constants/styles';
import { Header } from './components/Layout/Header/Header';
import { Header2 } from './components/Layout/Header/Header2';
import { PermissionService } from './services/PermissionService';
import { RoleKey } from './services/PermissionAndRolesKeys';

const StyledWrapper = styled('div')<{ oneColumn: boolean }>`
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns: min-content 1fr;
  ${({ oneColumn }) => oneColumn && 'grid-template-columns: 1fr;'}
`;

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  flex-grow: 1;
`;

const HeaderContainer = styled('div')`
  container-type: inline-size;
  container-name: ${ContainerName.headerContainer};
`;

const ChildrenContainer = styled('div')`
  display: flex;
  overflow: auto;
  flex: 1;
  background: ${({ theme }) => theme.gradients.primary};
  background-size: cover;
`;

const MenuWrapper = styled('div')<{ isHidden: boolean }>`
  display: grid;
  grid-template-columns: 72px min-content;
  ${({ isHidden: hidden }) => hidden && 'display: none;'}
`;

export function NavWrapper() {
  const { pathname } = useLocation();
  const InnerNavComponent = useInnerNavComponent(pathname);
  const InnerHeaderMiddleSlot = getHeaderChildrenByLocation(pathname);
  const presentModeOn = useRecoilValue(isPresentModeState);
  const { isFullScreenMode } = useFullScreen();
  const showHeader =
    !pathname.includes(ROUTES.BULK_UPLOAD_REPORT) &&
    !PermissionService.get().hasRole(RoleKey.managedServices);

  return (
    <StyledWrapper oneColumn={presentModeOn}>
      <MenuWrapper isHidden={presentModeOn}>
        <Navbar />
        <GenericFallbacksWrapper suspenseFallback={<></>}>
          {InnerNavComponent && <InnerNavComponent />}
        </GenericFallbacksWrapper>
      </MenuWrapper>
      <GenericFallbacksWrapper>
        <Container>
          {!isFullScreenMode && showHeader && (
            <HeaderContainer>
              <Header>{InnerHeaderMiddleSlot && <InnerHeaderMiddleSlot />}</Header>
            </HeaderContainer>
          )}

          <ChildrenContainer>
            <Outlet />
          </ChildrenContainer>
        </Container>
      </GenericFallbacksWrapper>
    </StyledWrapper>
  );
}

function getHeaderChildrenByLocation(location: string) {
  const isKPITemplatesLocation = location.includes(`${ROUTES.KPI}/${ROUTES.KPI_TEMPLATES}`);

  if (isKPITemplatesLocation) return null;

  return Header2;
}

function useInnerNavComponent(location: string) {
  const urlSegments = location.split('/');
  const loc = urlSegments[1];

  const { showReconciliation, showAllSecurities } = useFlags<MaggieFeatureFlags>();

  if (PermissionService.get().hasRole(RoleKey.managedServices)) return null;
  if (location.includes('ci-report')) return CINavigation;

  switch (loc) {
    case ROUTES.PORTFOLIO:
      return PortfolioReportingNavigation;
    case ROUTES.DEAL_FLOW:
      return DealflowNavigation;
    case ROUTES.ADMIN_PANEL:
      return AdminPanelNavigation;
    case ROUTES.KPI:
      return KPINavigation;
      break;
    case ROUTES.COMPANIES:
      return CPNavigation;
    case ROUTES.FINANCE2:
      return showReconciliation || showAllSecurities ? FinanceNavigation : null;
    default:
      return null;
  }
}
