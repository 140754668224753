import { Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAtomValue } from 'jotai/index';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { Comment } from '../../../../components/Comment/Comment';
import { CommentCreation } from '../../../../components/Comment/CommentCreation';
import { IDealDataModel } from '../../../../data-models/deal.data-model';
import { UnknownUser } from '../../../../data-models/user.data-model';
import { PermissionKey } from '../../../../services/PermissionAndRolesKeys';
import { PermissionService } from '../../../../services/PermissionService';
import { usersByEmailMapAtom } from '../../../../services/state/AppConfigStateJ';
import { DealComment as IComment } from '../../data-models/comment.data-model';
import { useCreateComment } from '../../hooks/useCreateComment';
import { useDeleteComment } from '../../hooks/useDeleteComment';
import { useUpdateComment } from '../../hooks/useUpdateComment';

interface IProps {
  comments: IComment[];
  deal: IDealDataModel;
}

const Wrapper = styled('div')`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export function DealCardComments({ comments, deal }: IProps) {
  const [showMore, setShowMore] = useState(false);
  const { primary } = useTheme().colors;
  const usersByEmail = useAtomValue(usersByEmailMapAtom);
  const canEditDeal = PermissionService.get().hasPermission(PermissionKey.canEditDeal);

  const { createDealComment, isLoading } = useCreateComment();
  const deleteComment = useDeleteComment();
  const { updateDealComment, isLoading: isLoadingUpdate, loadingId } = useUpdateComment();

  const handleCreate = (comment: string) => {
    const commentPayload = { dealId: deal.id, comment };
    createDealComment(commentPayload);
  };

  const handleDelete = useCallback(
    (commentId: number) => {
      deleteComment({ commentId, dealId: deal.id });
    },
    [deal.id, deleteComment]
  );

  const handleUpdate = useCallback(
    async (comment: string, commentId: number) => {
      updateDealComment({
        comment,
        commentId,
        dealId: deal.id,
      });
    },
    [deal.id, updateDealComment]
  );

  const sortedCommentsWithLoadingStatus = useMemo(() => {
    if (!comments.length) return [];
    const dataCopy = comments.map((c) => ({ ...c, isLoading: loadingId === c.id }));
    dataCopy.sort(({ createdAt: a }, { createdAt: b }) => new Date(b).getTime() - new Date(a).getTime());
    return dataCopy;
  }, [comments, loadingId]);

  const firstComment = useMemo(() => {
    const firstItem = sortedCommentsWithLoadingStatus[0];
    if (firstItem) {
      const author = usersByEmail.get(firstItem.createdBy) ?? UnknownUser;

      return (
        <Comment
          key={firstItem.id}
          authorFirstName={author.firstName}
          authorLastName={author.lastName}
          authorEmail={author.email}
          comment={firstItem.comment}
          id={firstItem.id}
          createdAt={new Date(firstItem.createdAt)}
          isLoading={firstItem.isLoading}
          onDelete={handleDelete}
          onUpdate={handleUpdate}
        />
      );
    }
    return null;
  }, [handleDelete, handleUpdate, sortedCommentsWithLoadingStatus, usersByEmail]);
  const restOfTheComments = useMemo(
    () => sortedCommentsWithLoadingStatus?.slice(1, comments.length) ?? [],
    [sortedCommentsWithLoadingStatus, comments.length]
  );

  return (
    <Wrapper
      onClick={(e: MouseEvent<HTMLElement>) => {
        e.stopPropagation();
      }}
      data-testid={'deal-card-comments'}
    >
      {canEditDeal && <CommentCreation onCreate={handleCreate} isLoading={isLoading} />}
      {firstComment}
      {Boolean(restOfTheComments.length) &&
        showMore &&
        restOfTheComments.map(({ comment, createdAt, createdBy, id, isLoading }) => {
          const author = usersByEmail.get(createdBy) ?? UnknownUser;

          return (
            <Comment
              authorFirstName={author.firstName}
              authorLastName={author.lastName}
              authorEmail={author.email}
              comment={comment}
              createdAt={new Date(createdAt)}
              key={id}
              id={id}
              isLoading={isLoading || isLoadingUpdate}
              onDelete={handleDelete}
              onUpdate={handleUpdate}
            />
          );
        })}
      {sortedCommentsWithLoadingStatus.length > 1 && (
        <span onClick={() => setShowMore((prevState) => !prevState)} data-testid={'comments-show-more'}>
          <Typography variant={'caption'} color={primary['60']}>
            {showMore ? 'Show Less' : 'Show All'}
          </Typography>
        </span>
      )}
    </Wrapper>
  );
}
