import { useCallback } from 'react';
import { atom, selector, useResetRecoilState } from 'recoil';
import { fundsAtom } from '../../../services/state/AppConfigStateJ';
import { metricsFundDataByFundIdState } from '../../../services/state/CompanyMetricsByIdState';
import { companyState } from '../../../services/state/CompanyState';
import { getForesightStore } from '../../../util/jotai-store';
import { ShareClass } from '../../../view-models/captable.view-model';
import { selectedCompanyIdProfile } from '../state/UIState';
import { getInitialClientInvestmentRows } from './captable-utils';
import { sortShareClasses } from './investment-utils';
import { ExcludedShareClasses, IInvestmentGridRow, IOtherInvestmentsGridRow } from './InvestmentGridDefs';
import { generateInitialInvestmentRows } from './OtherInvestmentsDefs';

export const shareClassesState = atom<ShareClass[]>({
  key: 'shareClassesState',
  default: [],
});

export const sortedShareClassesState = selector({
  key: 'sortedShareClassesState',
  get: ({ get }) => {
    const shareClasses = get(shareClassesState);
    return sortShareClasses(shareClasses);
  },
});

export const currentStepState = atom<number>({
  key: 'addCapTableCurrentStepState',
  default: 0,
});

export function useResetCapTableFormState() {
  const resetShareClassesState = useResetRecoilState(shareClassesState);
  const resetCurrentStepState = useResetRecoilState(currentStepState);
  const resetClientInvestmentsState = useResetRecoilState(clientInvestmentsState);
  const resetOtherInvestmentsState = useResetRecoilState(otherInvestmentsState);

  return useCallback(() => {
    resetCurrentStepState();
    resetShareClassesState();
    resetClientInvestmentsState();
    resetOtherInvestmentsState();
  }, [
    resetClientInvestmentsState,
    resetCurrentStepState,
    resetOtherInvestmentsState,
    resetShareClassesState,
  ]);
}

export const clientInvestmentsState = atom<IInvestmentGridRow[]>({
  key: 'clientInvestmentsState',
  default: selector({
    key: 'clientInvestmentsState/Default',
    get: ({ get }) => {
      const store = getForesightStore();
      const funds = store.get(fundsAtom);
      const shareClasses = get(shareClassesState);
      const companyId = get(selectedCompanyIdProfile);
      const company = get(companyState(companyId))!;
      const companyFundIds = get(metricsFundDataByFundIdState(companyId))?.keys();

      const rows = getInitialClientInvestmentRows({
        company,
        funds,
        companyFundIds,
        shareClasses,
        excludedShareClasses: ExcludedShareClasses,
      });

      const dummyRow = getDummyClientInvestmentRow();
      return [...rows, { ...dummyRow }];
    },
  }),
});

export function getDummyClientInvestmentRow(): IInvestmentGridRow {
  return { fundId: null };
}

export const otherInvestmentsState = atom<IOtherInvestmentsGridRow[]>({
  key: 'otherInvestmentsState',
  default: selector({
    key: 'otherInvestmentsState/Default',
    get: ({ get }) => {
      const shareClasses = get(shareClassesState);
      return generateInitialInvestmentRows(shareClasses);
    },
  }),
});
