import { Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAtomValue } from 'jotai';
import { FC } from 'react';
import { RoundRenderer } from '../../../../components/AgTable/cell-renderers/RoundRenderer';
import { Icon, IconTypes } from '../../../../components/Icon';
import { ITransactionDataModel } from '../../../../data-models/transaction.data-model';
import { roundsByIdMapAtom, transactionTypesByIdMapAtom } from '../../../../services/state/AppConfigStateJ';

type WithActive = {
  isActive?: boolean;
};

const CardContainer = styled('div')<WithActive>`
  border-radius: 4px;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.primary[10] : theme.colors.neutral[10]};
  padding: 12px 16px;
  cursor: pointer;
  &:not(:last-of-type) {
    margin-bottom: 12px;
  }
`;

const Wrapper = styled('div')`
  position: relative;
  padding-left: 24px;
`;

const OrderNumber = styled('div')`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.tertiary[30]};
  border-radius: 4px;
  margin-right: 4px;
  position: absolute;
  top: 0;
  left: 0;
`;

const DateWrapper = styled('div')`
  margin-bottom: 8px;
`;

const InfoWrapper = styled('div')`
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const CheckMark = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
`;

interface TransactionCardProps {
  transaction: ITransactionDataModel;
  order?: number;
  isActive?: boolean;
  onSelectTransaction: (id: number) => void;
}

export const TransactionCard: FC<TransactionCardProps> = ({
  transaction,
  order,
  isActive,
  onSelectTransaction,
}) => {
  const { primary, secondary } = useTheme().colors;
  const transactionTypesMap = useAtomValue(transactionTypesByIdMapAtom);
  const transactionType = transactionTypesMap.get(transaction?.transactionTypeId ?? -1);
  const roundsById = useAtomValue(roundsByIdMapAtom);

  const dateString = new Date(transaction?.transactionDate)?.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  return (
    <CardContainer
      isActive={isActive}
      onClick={() => {
        onSelectTransaction(transaction?.id);
      }}
    >
      <Wrapper>
        {isActive && (
          <CheckMark>
            <Icon type={IconTypes.CHECK} color={secondary[60]} />
          </CheckMark>
        )}
        <OrderNumber>
          <Typography variant='caption' color={primary[100]}>
            {order}
          </Typography>
        </OrderNumber>
        <DateWrapper>
          <Typography variant='subtitle2' color={secondary[60]}>
            {dateString}
          </Typography>
        </DateWrapper>
        <Typography variant='subtitle2' color={primary[100]}>
          {transaction?.fund?.name}
        </Typography>
        <InfoWrapper>
          <Typography variant='caption' color={primary[100]}>
            {transactionType?.name}
          </Typography>
          <RoundRenderer
            value={roundsById.get(transaction?.roundId ?? -1)}
            style={{ border: `1px solid ${primary[0]}`, width: '69px', fontSize: 12, margin: 0 }}
          />
        </InfoWrapper>
      </Wrapper>
    </CardContainer>
  );
};
