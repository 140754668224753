import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAtomValue } from 'jotai/index';
import { CSSProperties, FC } from 'react';
import { useRecoilValue } from 'recoil';
import { IDealDataModel } from '../../../../../data-models/deal.data-model';
import { UnknownUser } from '../../../../../data-models/user.data-model';
import { usersByEmailMapAtom } from '../../../../../services/state/AppConfigStateJ';
import { companyState } from '../../../../../services/state/CompanyState';
import { formatDate } from '../../../../../util/formatters/DateFormatters';

const InfoBox = styled('div')`
  margin-top: 1rem;
  background: white;
  padding: 2rem;
  border-radius: 4px;
  display: grid;
  gap: 1rem;
`;

const InfoDetails = styled('div')`
  border: 1px solid ${({ theme }) => theme.colors.neutral[20]};
  border-radius: 4px;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  gap: 1rem;
  padding: 0.5rem;
`;

const StyledLogo = styled('img')`
  box-shadow: 0px 4px 6px rgba(16, 37, 62, 0.06);
  margin-right: 0.5rem;
`;

interface IDuplicateDealInfoProps {
  deal: IDealDataModel;
  onCancel: () => void;
  onConfirm: () => void;
  showTitle?: boolean;
  style?: CSSProperties;
}

export const DuplicateDealInfo: FC<IDuplicateDealInfoProps> = ({
  deal,
  onCancel,
  onConfirm,
  showTitle = true,
  style,
}) => {
  const company = useRecoilValue(companyState(deal.companyId));
  const { createdAt, createdBy } = deal;
  const userMap = useAtomValue(usersByEmailMapAtom);
  const { colors } = useTheme();

  if (!company) return null;

  return (
    <InfoBox style={style}>
      {showTitle && (
        <Typography sx={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
          <ReportProblemIcon sx={{ color: colors.critical[50] }} fontSize='small' />
          Deal already exists
        </Typography>
      )}
      <Box sx={{ background: `${colors.critical[10]}77`, borderRadius: '4px', padding: '1rem' }}>
        <Typography variant='caption' sx={{ fontWeight: '600' }}>
          Duplicate
        </Typography>
        <InfoDetails>
          <Typography variant='body2' sx={{ display: 'flex', alignItems: 'center', fontWeight: '600' }}>
            {company.logoUrl && (
              <StyledLogo
                loading='lazy'
                src={company.logoUrl}
                width={'24'}
                height={'24'}
                alt={company.name}
              />
            )}
            {company.name}
          </Typography>
          <Typography variant='body2'>{`Created ${Boolean(createdAt) && `on ${formatDate(createdAt!)} `}by ${
            (userMap.get(createdBy) ?? UnknownUser).name
          }`}</Typography>
        </InfoDetails>
      </Box>
      <Typography variant='body2'>
        This will create a duplicate deal. Are you sure you want to proceed?
      </Typography>
      <Stack direction='row' justifyContent='flex-end' gap='1rem'>
        <Button variant='outlined' color='secondary' onClick={onCancel} aria-label='cancel' size='medium'>
          No
        </Button>
        <Button variant='contained' color='error' onClick={onConfirm} aria-label='confirm' size='medium'>
          Yes, proceed
        </Button>
      </Stack>
    </InfoBox>
  );
};
