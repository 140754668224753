import { useCallback } from 'react';
import { useLocation } from 'react-router';
import { GridApi } from 'ag-grid-community';
import { CompanyColId } from './useKPICompaniesAllColDefs';

export function useAssignedFilter() {
  const companyFilter = useLocation().state?.companyName;

  const setGridFilter = useCallback(
    (gridApi: GridApi | null) => {
      gridApi?.getColumnFilterInstance(CompanyColId).then((companyFilterModel) => {
        if (companyFilterModel && companyFilter) {
          companyFilterModel.setModel({ values: [companyFilter] });
          gridApi.onFilterChanged();
        }
      });
    },
    [companyFilter]
  );

  return { setGridFilter };
}
