import { BasicDialog } from '../../../../components/Dialog/BasicDialog';
import { FormFieldsContainer } from '../../../../components/Form/FormComponents';
import { IListDataModel } from '../../../../data-models/list.data-model';
import { fromListDataModel } from '../../../../schemas/CompanyList.schema';
import { CreateListForm, EditListForm } from './ListForms';
import { ListForm, useListFormState } from './ListsFormState';

export function CreateListModal() {
  const { showListForm, setShowListForm, setActiveListId } = useListFormState();
  const onClose = () => {
    setShowListForm(null);
  };
  if (!open) return null;
  return (
    <BasicDialog title='Create List' open={showListForm === ListForm.CreateList} onClose={onClose}>
      <FormFieldsContainer>
        <CreateListForm
          onClose={onClose}
          onCreate={(list: IListDataModel) => {
            setActiveListId(list.id);
          }}
        />
      </FormFieldsContainer>
    </BasicDialog>
  );
}

export function EditListModal() {
  const { showListForm, setShowListForm, selectedList, setSelectedList } = useListFormState();
  const onClose = () => {
    setShowListForm(null);
    setSelectedList(null);
  };
  if (!open || !selectedList) return null;
  return (
    <BasicDialog title='Edit List' open={showListForm === ListForm.EditList} onClose={onClose}>
      <FormFieldsContainer>
        <EditListForm onClose={onClose} defaultValues={fromListDataModel(selectedList)} />
      </FormFieldsContainer>
    </BasicDialog>
  );
}
