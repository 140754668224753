import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { Box, Collapse, Divider, Icon, Stack, SxProps, Tooltip, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties, FC, PropsWithChildren } from 'react';
import { MaxModalFormHeight, MaxModalFormWidth, ModalFormWidth, ZINDEX } from '../../constants/styles';
import { colors } from '../../theme/colors';
import { IFormField } from '../../view-models/form.view-model';

export const FormXPaddingInRems = 2.5;

export const LabelText = styled(Typography)<{ component?: string }>`
  display: flex;
  align-items: center;
  justify-content: start;
`;

interface ILabelProps extends PropsWithChildren {
  required?: boolean;
  style?: CSSProperties;
  description?: string;
}

/** @deprecated use {@link #FormLabelText} or relevant sub-components */
export const Label: FC<ILabelProps> = ({ children, required, style, description }) => {
  const { colors } = useTheme();

  return (
    <Stack direction={'row'} flexWrap={'nowrap'} gap={'0.25rem'} minWidth={0} className='field-label'>
      <LabelText component={'label'} variant='caption' color='text.secondary' noWrap style={{ ...style }}>
        {children}
        {required && (
          <Typography variant='caption' color={colors.critical[50]}>
            &nbsp;*
          </Typography>
        )}
      </LabelText>
      {description && <FormLabelToolTip tooltipText={description} />}
    </Stack>
  );
};

export type FieldLabelProps = Pick<
  IFormField<unknown>,
  'key' | 'label' | 'isCalculated' | 'description' | 'required'
> & {
  isOverridden?: boolean;
};

export function FieldLabel(props: { field: FieldLabelProps }) {
  const { label, key, description, isCalculated, isOverridden, required } = props.field;

  return (
    <Stack direction={'row'} flexWrap={'nowrap'} gap={'0.25rem'} minWidth={0} className='field-label'>
      <FormLabelText label={label ?? key} required={required} />
      {description && <FormLabelToolTip tooltipText={description} />}
      {isCalculated && <FieldFxLabel isOverridden={isOverridden} />}
    </Stack>
  );
}

export function FieldFxLabel({ isOverridden = false }: { isOverridden?: boolean }) {
  return (
    <Typography
      variant='caption'
      sx={{ fontStyle: 'italic', fontWeight: 'bold', transition: 'color .5s' }}
      color={isOverridden ? colors.tertiary[60] : colors.primary[95]}
    >
      fx
    </Typography>
  );
}

export interface IFormLabelTextProps {
  label: string;
  required?: boolean;
}

export function FormLabelText(props: IFormLabelTextProps) {
  const { label, required } = props;

  return (
    <Typography component={'label'} variant='caption' color='text.secondary' noWrap>
      {label}
      {required && (
        <Typography variant='caption' color={colors.critical[50]}>
          &nbsp;*
        </Typography>
      )}
    </Typography>
  );
}

function FormLabelToolTip(props: { tooltipText: string }) {
  return (
    <Tooltip
      title={
        <Typography variant='caption' color='text.secondary' style={{ whiteSpace: 'pre-line' }}>
          {props.tooltipText}
        </Typography>
      }
      placement='bottom-start'
      aria-label={props.tooltipText}
    >
      <InfoOutlinedIcon fontSize='small' htmlColor={colors.neutral[60]} />
    </Tooltip>
  );
}

export const Form = styled('form')``;

export const ContainerWithVerticalScrollShadow = styled('div')`
  overflow-y: auto;
  background-color: white;
  background:
		/* Shadow covers */
    linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-size:
    100% 50px,
    100% 50px,
    100% 20px,
    100% 20px;
  background-attachment: local, local, scroll, scroll;
`;
export const FormFieldsContainer = styled(ContainerWithVerticalScrollShadow)`
  display: grid;
  gap: 1.25rem;

  & .MuiTextField-root {
    margin: 0;
  }

  width: ${ModalFormWidth};

  & .MuiInputBase-input {
    font-size: 0.875rem;
  }

  padding: 0 2.5rem;
  max-width: ${MaxModalFormWidth};
  max-height: ${MaxModalFormHeight};

  overflow: auto;
`;

export const StepperFormFieldsContainer = styled(FormFieldsContainer)`
  padding: 0 0 1rem;
`;

export const FormField = styled('div')`
  display: grid;
  grid-template-rows: min-content 1fr;
`;

export const FormWrapper = styled('div')`
  display: grid;
  gap: 1rem;
`;

export const FormActionButtons = styled('div')`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;

export const ErrorMessage: FC<PropsWithChildren> = ({ children }) => {
  const { colors } = useTheme();
  return (
    <Typography variant='caption' color={colors.critical[50]}>
      {children}
    </Typography>
  );
};

export const FormDivider = styled(Divider)`
  margin: 0.5rem 0;
`;

interface IErrorTextProps extends PropsWithChildren {
  sx?: SxProps;
}
export const ErrorText: FC<IErrorTextProps> = ({ children, sx }) => {
  const { colors } = useTheme();
  return (
    <Collapse in={!!children}>
      <Typography variant='caption' color={colors.critical[50]} component={'p'} sx={{ ...(sx ?? {}) }}>
        {children}
      </Typography>
    </Collapse>
  );
};

export interface IFormTitleProps {
  title: string;
}
export function FormTitle(props: IFormTitleProps) {
  return (
    <Typography variant='body1' component={'p'} sx={{ mb: '1rem' }}>
      {props.title}
    </Typography>
  );
}

export const StepperSx: SxProps = {
  px: 0,
  mx: 0,
  mt: '-0.75rem',
  position: 'sticky',
  top: 0,
  background: colors.primary[0],
  paddingBottom: '1.5rem',
  zIndex: ZINDEX.FORM_STEPPER,
  minWidth: '100%',
  '& svg.MuiSvgIcon-root.Mui-active , & svg.MuiSvgIcon-root.Mui-completed': {
    color: colors.primary[60],
  },
};

export const StepperBox: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box
      sx={{
        minHeight: '50vh',
        paddingX: '2.5rem',
        paddingY: '1rem',
        display: 'grid',
        gridTemplateRows: 'auto 1fr auto',
      }}
    >
      {children}
    </Box>
  );
};

export const StepSx: SxProps = {
  '&:first-of-type': { pl: 0 },
};
export const StepperContainer = styled('div')`
  padding: 0 2.5rem 1rem 2rem;
`;
export const FormContainer = styled(FormFieldsContainer)`
  margin-top: 1rem;
  padding: 0 0.25rem 0 0.25rem;
  align-self: start;
  gap: 1rem;
  max-height: 30rem;
`;

interface IFormLoadingButtonProps
  extends Pick<LoadingButtonProps, 'onClick' | 'loading' | 'disabled' | 'children'> {}
export function FormLoadingButton({ onClick, loading, disabled, children }: IFormLoadingButtonProps) {
  return (
    <LoadingButton
      onClick={onClick}
      variant={'contained'}
      color={'secondary'}
      loadingPosition='start'
      startIcon={loading ? <Icon /> : <></>}
      disabled={disabled}
      loading={loading}
    >
      {children}
    </LoadingButton>
  );
}
