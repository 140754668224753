import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { SelectWithCreatableOptions } from '../../../CoreComponents/base/SelectWithCreatableOptions';
import { fundsAtom } from '../../../services/state/AppConfigStateJ';

export interface IFundTypeSelectProps {
  initialValue?: string;
  handleChange: (newValue: string) => void;
  fullWidth?: boolean;
}

export function FundTypeSelect(props: IFundTypeSelectProps) {
  const { initialValue = 'Primary', handleChange, fullWidth } = props;
  const funds = useAtomValue(fundsAtom);
  const options = useMemo(() => {
    const fundTypes = new Set(funds.map((fund) => fund.fundType));

    return Array.from(fundTypes.values());
  }, [funds]);

  return (
    <SelectWithCreatableOptions
      initialValue={initialValue}
      options={options}
      handleChange={handleChange}
      fullWidth={fullWidth}
    />
  );
}
