import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { IconButton, Stack, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router';
import { StepperFormButtons } from '../../Finance2/Forms/StepperFormButtons';
import { SubSection } from '../Summary/components/SubSection';
import { ICaptableDataModel } from '../../../data-models/captable2.data-model';
import { ROUTES } from '../../../constants/RouteNameMapping';
import { Loader } from '../../../components/Loader/Loader';
import { useProfileBasePath } from '../useProfileBasePath';
import { currentStepState, sortedShareClassesState } from './CapTableFormState';
import { FormCard } from './commonStyledComponents';
import { ShareClassList } from './AddShareClasses';
import { usePreviewCaptable } from './CapTableActions';
import { useCreateOrUpdateCaptable } from './useCreateOrUpdateCaptable';
import { CaptableSummaryGrid } from './SummaryGrid/CaptableSummaryGrid';
import { useCancelButtonProps } from './CapTableForm';

const listStyle = {
  maxHeight: '30rem',
  overflow: 'auto',
  background: `
    linear-gradient(
      white 30%,
      rgba(255, 255, 255, 0)
    ) center top,
    
    linear-gradient(
      rgba(255, 255, 255, 0), 
      white 70%
    ) center bottom,
    
    radial-gradient(
      farthest-side at 50% 0,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    ) center top,
    
    radial-gradient(
      farthest-side at 50% 100%,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    ) center bottom
  `,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 40px, 100% 40px, 100% 14px, 100% 14px',
  backgroundAttachment: 'local, local, scroll, scroll',
};

export function ReviewAndUpload() {
  const shareClasses = useRecoilValue(sortedShareClassesState);
  const navigate = useNavigate();
  const { captablePayload, createOrUpdateCaptable } = useCreateOrUpdateCaptable();
  const cancelButtonProps = useCancelButtonProps();
  const profilesBasePath = useProfileBasePath();

  const onUpload = useCallback(async () => {
    const data = await createOrUpdateCaptable(() => {
      navigate(`${profilesBasePath}/${ROUTES.COMPANY_PROFILE_CAP_TABLE}`);
    });
    if (data) {
      return Promise.resolve(true);
    }

    return Promise.resolve(false);
  }, [createOrUpdateCaptable, navigate, profilesBasePath]);

  return (
    <>
      <FormCard>
        <Typography variant='subtitle1'>{'Review and upload your cap table.'}</Typography>

        <ReviewSubSection step={0} title='Investments'>
          <ShareClassList shareClasses={shareClasses} style={listStyle} />
        </ReviewSubSection>

        <PreviewGrid payload={captablePayload} />
      </FormCard>

      <div>
        <StepperFormButtons
          stepState={currentStepState}
          stepIsValid={onUpload}
          nextButtonLabel='Save'
          secondaryActionProps={cancelButtonProps}
        />
      </div>
    </>
  );
}

function PreviewGrid({ payload }: { payload: Partial<ICaptableDataModel> }) {
  const previewCaptableHandler = usePreviewCaptable();
  const [preview, setPreview] = useState<ICaptableDataModel | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    previewCaptableHandler(payload).then((data) => {
      if (data) {
        setPreview(data);
      }
      setLoading(false);
    });
  }, [payload, previewCaptableHandler]);

  return (
    <ReviewSubSection step={1} title='Investment Details'>
      <Stack minHeight={'30rem'} height={'30rem'} maxHeight={'30rem'} overflow={'hidden'}>
        {loading && <Loader />}
        {preview && <CaptableSummaryGrid data={preview} />}
      </Stack>
    </ReviewSubSection>
  );
}

interface IReviewSubSectionProps extends PropsWithChildren {
  step: number;
  title: string;
}
export function ReviewSubSection({ step, title, children }: IReviewSubSectionProps) {
  const setCurrentStep = useSetRecoilState(currentStepState);

  return (
    <SubSection
      style={{ margin: 0, padding: 0 }}
      title={title}
      noCard
      actionComponent={
        <IconButton onClick={() => setCurrentStep(step)}>
          <EditIcon fontSize='small' color='secondary' />
        </IconButton>
      }
      childrenContainerStyle={{ maxHeight: '30rem', marginBottom: '1rem' }}
    >
      {children}
    </SubSection>
  );
}
