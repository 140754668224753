import { Publish } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import { Button, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useFormik } from 'formik';
import { useAtomValue } from 'jotai';
import React, { FormEvent, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { CompanyAutocomplete } from '../../../../../components/CompanyAutocomplete/CompanyAutocomplete';
import { FieldGroup } from '../../../../../components/FieldGroup/FieldGroup';
import {
  CustomFieldsFormSection,
  ICustomFieldsFormSectionProps,
} from '../../../../../components/Form/CustomFieldsFormSection';
import { FormField } from '../../../../../components/FormField/FormField';
import { LocationSearch } from '../../../../../components/GooglePlacesAutocomplete/LocationSearch';
import { MuiSingleSelect } from '../../../../../components/MuiSingleSelect/MuiSingleSelect';
import {
  createCompanySearchResultDataModel,
  ICompanySearchResultDataModel,
} from '../../../../../data-models/company-search-result.data-model';
import { ICompanyDataModel } from '../../../../../data-models/company.data-model';
import { FieldEntity } from '../../../../../data-models/field2.data-model';
import { BaseLocationDataModel } from '../../../../../schemas/Location.schema';
import { useCreateCompanyAndUpdateState } from '../../../../../services/hooks/useCreateCompanyAndUpdateState';
import { useUpdateCompanyAndState } from '../../../../../services/hooks/useUpdateCompanyAndState';
import { fetchCompaniesByWebsite } from '../../../../../services/queries/MaggieCompanyQueries';
import { customFieldsByEntity } from '../../../../../services/state/AdminPanel/CustomFieldsState';
import { activeUserOptionsAtom, usersByIdMapAtom } from '../../../../../services/state/AppConfigStateJ';
import { companyState } from '../../../../../services/state/CompanyState';
import { partialEqualsIgnoreUnsetValues } from '../../../../../util/partialEquals';
import { SectorSelect } from '../../../../Finance/form-components/SectorSelect';
import { sourceTypes, SourceTypeSelector } from '../../../../Finance/form-components/SourceTypeSelector';
import { filterEmptyValues } from '../../../../Finance/utils/filterEmptyValues';
import { useDeal } from '../../../providers/DealProvider';
import { CompanyInitialValues as initialValues } from './companyInitialValues';
import { CompanySchema as validationSchema } from './CompanySchema';
import { CompanySuggestion } from './CompanySuggestion';

const Wrapper = styled('form')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: start;
  gap: 12px;
  margin-left: 145px;
`;

const ActionsWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const CEOInput = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;
`;

export function CompanyForm({ scrollUp }: { scrollUp: () => void }) {
  const userOptions = useAtomValue(activeUserOptionsAtom);
  const userMap = useAtomValue(usersByIdMapAtom);
  const [submitLabel, setSubmitLabel] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [suggestedCompany, setSuggestedCompany] = useState<ICompanyDataModel | null>(null);
  const {
    setCompanyInfo,
    companyInfo,
    companyAdditionalInfo,
    setCompanyAdditionalInfo,
    setActiveStep,
    dealInfo,
    resetState,
    companyInput,
  } = useDeal();
  const updateCompany = useUpdateCompanyAndState();
  const createCompany = useCreateCompanyAndUpdateState();
  const [ceos, setCeos] = useState<string[]>(['']);

  const company = useRecoilValueLoadable(
    companyState(Number(companyInfo?.id ?? companyAdditionalInfo?.id ?? -1))
  ).valueMaybe();

  useEffect(() => {
    if (!company) return;
    setCompanyAdditionalInfo(company);
  }, [company, setCompanyAdditionalInfo]);

  const handleCeoChange = (ceoName: string, index: number) => {
    setCeos((prevState) => {
      const prevStateCopy = [...prevState];
      prevStateCopy.splice(index, 1, ceoName);
      return prevStateCopy;
    });
  };

  const handleAddCEO = () => {
    setCeos((prevState) => [...prevState, '']);
  };

  const handleRemoveCEO = (index: number) => {
    setCeos((prevState) => {
      const prevStateCopy = [...prevState];
      prevStateCopy.splice(index, 1);
      return prevStateCopy;
    });
  };

  const creatingNewCompany = useMemo(() => companyInfo?.id === undefined, [companyInfo?.id]);

  const {
    values,
    handleChange,
    setFieldValue,
    handleBlur,
    errors,
    touched,
    setTouched,
    handleSubmit,
    validateForm,
    setFieldError,
    isSubmitting,
  } = useFormik<Partial<ICompanyDataModel>>({
    initialValues,
    validationSchema,
    onSubmit: async (val: Partial<ICompanyDataModel>) => {
      if (company) {
        if (dataChanged) {
          updateCompany(Number(company.id), val);
        }
        setActiveStep(2);
      } else {
        const newComp = await createCompany(val);
        if (!newComp) return;
        setCompanyAdditionalInfo(newComp);
        setCompanyInfo(
          createCompanySearchResultDataModel({
            companyName: newComp.name,
            id: newComp.id,
            website: newComp.website ?? '',
            logo: newComp.logoUrl ?? '',
          })
        );
        setActiveStep(2);
      }
    },
  });

  const handleCustomFieldsUpdates = useCallback(
    (data: Record<string, unknown>) => {
      setFieldValue('customData', { ...(data.customData as Pick<ICompanyDataModel, 'customData'>) });
    },
    [setFieldValue]
  );

  const dataChanged = useMemo(() => {
    return !partialEqualsIgnoreUnsetValues(filterEmptyValues(values), company);
  }, [company, values]);

  const onLocationChange = useCallback(
    (newValue: BaseLocationDataModel | BaseLocationDataModel[] | null) => {
      if (newValue === null) return;
      const { city, state, country } = newValue as BaseLocationDataModel;
      setCompanyAdditionalInfo((current) => {
        return {
          ...(current as ICompanyDataModel),
          city,
          state,
          country,
        };
      });
    },
    [setCompanyAdditionalInfo]
  );

  const onSourceCompanyChange = useCallback(
    (newValue: string | null) => {
      if (newValue === null) return;
      setFieldValue('sourceCompany', newValue);
    },
    [setFieldValue]
  );

  const onCancel = useCallback(() => {
    if (!dealInfo) {
      resetState();
      return;
    }
    setActiveStep(2);
    scrollUp();
  }, [dealInfo, resetState, scrollUp, setActiveStep]);

  const handleError = (field: keyof typeof initialValues) => (touched[field] ? errors[field] : '');
  // when adding a company, companyAdditionalInfo is null, so this resolves to undefined
  const areFieldsDisabled = companyAdditionalInfo?.crunchbaseId != null;

  const selectedLocation: BaseLocationDataModel | undefined = useMemo(() => {
    if (companyAdditionalInfo?.city) {
      const { city, state, country } = companyAdditionalInfo;
      setFieldValue('city', companyAdditionalInfo.city);

      return { city, state, country };
    }
    return undefined;
  }, [companyAdditionalInfo, setFieldValue]);
  const disableLocationEdit = !!companyAdditionalInfo?.city && !!companyAdditionalInfo?.crunchbaseId;

  useEffect(() => {
    if (!companyInfo) return;
    const { companyName, website } = companyInfo;
    setFieldValue('name', companyName);
    setFieldValue('website', website);
  }, [companyInfo, setFieldValue]);

  const onWebsiteBlur = useCallback(
    async (e: React.FocusEvent<unknown>) => {
      handleBlur(e);

      if (values.website && !errors.website) {
        const companies = await fetchCompaniesByWebsite(values.website);
        if (companies && companies.length) {
          setSuggestedCompany(companies[0]);
          setFieldError('website', 'Company already exists');
        }
      }
    },
    [errors.website, handleBlur, setFieldError, values.website]
  );

  const onCancelSuggestion = useCallback(() => {
    setSuggestedCompany(null);
    setFieldValue('website', '');
  }, [setFieldValue]);

  const onSelectSuggestion = useCallback(() => {
    if (suggestedCompany) {
      setCompanyAdditionalInfo(suggestedCompany);
      const newCompanyInfo = {
        id: suggestedCompany.id,
        companyName: suggestedCompany.name,
        website: suggestedCompany.website,
        logo: suggestedCompany.logoUrl ?? '',
      } as ICompanySearchResultDataModel;
      setCompanyInfo(newCompanyInfo);
      setSuggestedCompany(null);
    }
  }, [setCompanyAdditionalInfo, setCompanyInfo, suggestedCompany]);

  useEffect(() => {
    if (companyInput) {
      setFieldValue('name', companyInput);
    }
    if (!companyAdditionalInfo) {
      return;
    }
    const {
      ceoName,
      city,
      country,
      internalSourceId,
      name,
      sectorId,
      shortDescription,
      source,
      sourceCompany,
      sourceType,
      state,
      website,
    } = companyAdditionalInfo;

    if (name) setFieldValue('name', name);
    if (website) setFieldValue('website', website);
    if (sectorId) setFieldValue('sectorId', sectorId);
    if (ceoName) {
      setFieldValue('ceoName', ceoName);
      setCeos(ceoName.split(','));
    }
    setFieldValue('shortDescription', String(shortDescription));
    if (source) setFieldValue('source', source);
    if (sourceCompany) setFieldValue('sourceCompany', sourceCompany);
    const sourceTypeToUpdate = sourceTypes.find((ct) => ct.name === sourceType);
    if (sourceTypeToUpdate) setFieldValue('sourceType', sourceTypeToUpdate.name);
    if (city) {
      setFieldValue('city', city);
    }
    if (state) setFieldValue('state', state);
    if (country) setFieldValue('country', country);
    if (internalSourceId) setFieldValue('internalSourceId', internalSourceId);
  }, [companyAdditionalInfo, setFieldValue, companyInput]);

  useEffect(() => {
    if (submitting) return;
    if (creatingNewCompany) {
      setSubmitLabel('Create Company');
    } else if (dataChanged) {
      setSubmitLabel('Save Company Changes');
    } else {
      setSubmitLabel('Confirm');
    }
  }, [companyInfo, creatingNewCompany, dataChanged, submitting]);

  useEffect(() => {
    validateForm();
  }, [validateForm, values.name]);

  const internalSourceValue = useMemo(() => {
    if (!values.internalSourceId) return null;
    const user = userMap.get(values.internalSourceId);
    if (!user) return null;
    return { id: user.id, value: user.name };
  }, [userMap, values.internalSourceId]);

  return (
    <Wrapper
      onSubmit={(e: FormEvent<HTMLFormElement>) => {
        setSubmitting(true);
        e.preventDefault();
        setFieldValue('ceoName', ceos.join(','));
        handleSubmit();
      }}
    >
      <FieldGroup hasBorder={false}>
        <FormField required label={'Company Name'} error={handleError('name')} labelWidth='12rem'>
          <TextField
            name={'name'}
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            variant='outlined'
            disabled={areFieldsDisabled}
          />
        </FormField>
        <FormField label={'Website'} error={handleError('website')} labelWidth='12rem'>
          <TextField
            name={'website'}
            value={values.website}
            onChange={handleChange}
            onBlur={onWebsiteBlur}
            variant='outlined'
            disabled={areFieldsDisabled}
          />
        </FormField>
        {suggestedCompany && (
          <CompanySuggestion
            company={suggestedCompany}
            onCancel={onCancelSuggestion}
            onSelect={onSelectSuggestion}
          />
        )}
      </FieldGroup>
      <FieldGroup>
        <FormField label={'Sector'} labelWidth='12rem'>
          <SectorSelect
            onChange={(sectorId) => setFieldValue('sectorId', sectorId)}
            value={values.sectorId}
            onFocus={() => setTouched({ ...touched, sectorId: true })}
          />
        </FormField>
        <FormField label={'Location'} error={handleError('city')} labelWidth='12rem'>
          <LocationSearch
            initialValue={selectedLocation ?? undefined}
            onChange={onLocationChange}
            disabled={disableLocationEdit}
          />
        </FormField>
        {ceos.map((ceoName, i) => (
          <Fragment key={i}>
            <FormField label={`CEO Name #${i + 1}`} labelWidth={'12rem'}>
              <CEOInput data-testid={`ceo-name-${i + 1}`}>
                <TextField
                  name={'ceoName'}
                  value={ceos[i]}
                  fullWidth
                  onChange={(e) => handleCeoChange(e.target.value, i)}
                  onBlur={handleBlur}
                />
                {((i === ceos.length - 1 && Boolean(ceos[ceos.length - 1])) || ceos.length !== 1) && (
                  <ActionsWrapper>
                    {i === ceos.length - 1 && Boolean(ceos[ceos.length - 1]) && (
                      <AddIcon
                        onClick={handleAddCEO}
                        color={'primary'}
                        style={{ cursor: 'pointer' }}
                        data-testid={'ceo-name-add'}
                      />
                    )}
                    {ceos.length !== 1 && (
                      <DeleteIcon
                        onClick={() => handleRemoveCEO(i)}
                        color={'error'}
                        style={{ cursor: 'pointer' }}
                        data-testid={`ceo-name-delete-${i + 1}`}
                      />
                    )}
                  </ActionsWrapper>
                )}
              </CEOInput>
            </FormField>
          </Fragment>
        ))}
        <FormField label={`Short Description`} error={handleError('shortDescription')} labelWidth='12rem'>
          <TextField
            name={'shortDescription'}
            value={values.shortDescription}
            onChange={handleChange}
            onBlur={handleBlur}
            variant='outlined'
          />
        </FormField>
      </FieldGroup>
      <FieldGroup>
        <FormField label={'Source Type'} error={handleError('sourceType')} labelWidth='12rem'>
          <SourceTypeSelector
            key={values.sourceType}
            buttonsGap='10px'
            buttonsWidth='106px'
            name={'sourceType'}
            value={values.sourceType ?? undefined}
            onChange={(val) => setFieldValue('sourceType', val)}
            onFocus={() => setTouched({ ...touched, sourceType: true })}
            alignButtons='flex-start'
          />
        </FormField>

        <FormField label={'Source Company'} error={handleError('sourceCompany')} labelWidth='12rem'>
          <CompanyAutocomplete
            value={values.sourceCompany ?? undefined}
            onChange={onSourceCompanyChange}
            onBlur={handleBlur}
            onFocus={() => setTouched({ ...touched, sourceCompany: true })}
          />
        </FormField>
        <FormField label={'Source'} error={handleError('source')} labelWidth='12rem'>
          <TextField
            name={'source'}
            value={values.source}
            onChange={handleChange}
            onBlur={handleBlur}
            variant='outlined'
          />
        </FormField>
        <FormField label={'Internal Source'} labelWidth='12rem'>
          <MuiSingleSelect
            options={userOptions}
            value={internalSourceValue}
            onChange={(e, val) => {
              setFieldValue('internalSourceId', val?.id ?? null);
            }}
            disablePortal
            fieldPlaceholder='Select Internal Source'
            fieldName='internalSourceId'
          />
        </FormField>
      </FieldGroup>

      <FieldGroup>
        <CompanyFormCustomFieldsSection
          handleChange={handleCustomFieldsUpdates}
          data={{ customData: company?.customData ?? {} }}
        />
      </FieldGroup>

      <ButtonWrapper>
        <LoadingButton
          variant={'contained'}
          color='secondary'
          disabled={isSubmitting}
          type={'submit'}
          data-testid={'company-form-submit'}
          loading={isSubmitting}
          endIcon={<Publish />}
          loadingPosition='end'
        >
          {submitLabel}
        </LoadingButton>
        <Button variant='outlined' color={'secondary'} onClick={onCancel}>
          Cancel
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
}

export function CompanyFormCustomFieldsSection(
  props: Pick<ICustomFieldsFormSectionProps, 'handleChange' | 'data'>
) {
  const customFields = useRecoilValue(customFieldsByEntity);
  const companyFields = customFields.get(FieldEntity.company) ?? [];

  return (
    <CustomFieldsFormSection
      data={props.data}
      variant={'legacy-form'}
      customFields={companyFields}
      handleChange={props.handleChange}
    />
  );
}
