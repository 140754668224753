import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAtomValue } from 'jotai';
import { CSSProperties } from 'react';
import { IRoundDataModel } from '../../../data-models/round.data-model';
import { roundsByNameMapAtom } from '../../../services/state/AppConfigStateJ';
import { colors } from '../../../theme/colors';

type PillProps = {
  background: string;
  color: string;
};

const Pill = styled(Typography)<PillProps>`
  margin: 0 auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${({ color }) => color};
  background: ${({ background }) => background};
  width: 84%;
  min-width: 2rem;
  height: 1.5rem;
  border-radius: 4px;
`;

type RoundRendererProps = {
  value: IRoundDataModel | null | undefined;
  style?: CSSProperties;
  className?: string;
  showTBD?: boolean;
};

const ROUND_COLORS = [
  { background: colors.success[20], color: colors.success[70] },
  { background: colors.primary[10], color: colors.primary[60] },
  { background: colors.secondary[10], color: colors.secondary[60] },
  { background: colors.warning[10], color: colors.warning[60] },
  { background: colors.critical[10], color: colors.critical[50] },
];
const TBD_COLORS = { background: colors.neutral[10], color: colors.neutral[70] };

export function getRoundColor(roundId?: number, muted?: boolean): PillProps {
  if (!roundId) return { background: colors.primary[0], color: colors.neutral[40] };
  if (muted) return TBD_COLORS;

  return ROUND_COLORS[roundId % ROUND_COLORS.length];
}

export const RoundRenderer = ({ value, style, className, showTBD }: RoundRendererProps) => {
  const muted = className === 'muted';
  if ((!value?.name && !showTBD) || value === undefined) return null;
  if (value === null && showTBD)
    return (
      <Pill {...TBD_COLORS} style={{ ...style }} noWrap>
        TBD
      </Pill>
    );
  return (
    <Pill {...getRoundColor(value?.id, muted)} style={{ ...style }} noWrap>
      {value?.name || '-'}
    </Pill>
  );
};

interface IRoundNameRendererParams {
  name: string;
  style?: CSSProperties;
  showTBD?: boolean;
}
export function RoundNameRenderer({ name, style }: IRoundNameRendererParams) {
  const roundsByName = useAtomValue(roundsByNameMapAtom);
  const round = roundsByName.get(name);
  if (round) return <RoundRenderer value={round} style={style} />;
  const hash = hashCode(name);
  return name.length ? (
    <Pill {...getRoundColor(hash)} style={style} noWrap>
      {name || ''}
    </Pill>
  ) : (
    <span />
  );
}

function hashCode(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + 31 * hash;
  }
  return hash;
}
