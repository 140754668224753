import { Container, Stack, styled, Typography, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useRecoilState, useRecoilValue } from 'recoil';
import { cloneDeep } from 'lodash-es';
import { IField } from '../../../../../data-models/field2.data-model';
import { KPIItemFactory } from '../../Sections/FormItem/KPIItemFactory';
import { PermissionKey } from '../../../../../services/PermissionAndRolesKeys';
import { PermissionService } from '../../../../../services/PermissionService';
import { companyState } from '../../../../../services/state/CompanyState';
import {
  kpisRequestByIdState,
  kpisRequestResponseByIdState,
} from '../../../../../services/state/KPI/KPIRequestsState';
import {
  assignedKPIRequestsState,
  kpisTemplateByIdState,
} from '../../../../../services/state/KPI/KPITemplatesState';
import { GenericFallbacksWrapper } from '../../../../../components/Fallback/GenericFallbacksWrapper';
import {
  periodFromGranularityAndFrequency,
  IKPIRequestDataModel,
  IKPIResponseForm,
  IKPIResponseFormSectionData,
  KPIRequestFrequency,
  KPIRequestStatus,
} from '../../../../../data-models/kpi-requests.data-model';
import { MessageScreen } from '../../../../../components/MessageScreen/MessageScreen';
import { authenticatedUserState } from '../../../../../services/state/AuthState';
import {
  getMetricsSectionData,
  kpiDataValuesToFormStructure,
  prepareKpiResponsePayload,
  sortTemplateSections,
} from '../../../utils';
import AlertMui from '../../../../../components/AlertMui/AlertMui';
import {
  IKPITemplateSectionDataModel,
  isKpiTableDataModel,
} from '../../../../../data-models/kpi-template.data-model';
import { useKPIRequestResponseActions } from './hooks/useKPIRequestResponseActions';
import { KPIRequestResponseHeader } from './KPIRequestResponseHeader';
import { KPIThankYouScreen } from './KPIThankYouScreen';

const RequestResponseFormWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100px 1fr;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: ${({ theme }) => theme.gradients.primary};
`;

const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  height: 100%;
  width: 100%;
`;

const NotValidRequestScreen = () => {
  return (
    <MessageScreen>
      <Stack alignItems={'center'} justifyContent={'flex-end'} gap={'1rem'}>
        <Typography variant='h1'>Invalid Link!</Typography>
        <Typography variant='h6' mt={'1rem'}>
          Please verify the link is valid
        </Typography>
      </Stack>
    </MessageScreen>
  );
};
interface IKPIRequestResponseProps {
  reqId?: number;
  readOnly?: boolean;
  showHeader?: boolean;
  style?: CSSProperties;
}
export function KPIRequestResponse({ reqId, readOnly, style, showHeader = true }: IKPIRequestResponseProps) {
  const navigate = useNavigate();
  const { markTemplateAsCompleted } = useManageAssignedKPIRequests();
  const { requestId } = useParams<{ requestId: string }>();
  const requestIdAsNum = Number(requestId ?? '-1');
  const { colors } = useTheme();
  const permSvc = PermissionService.get();
  const currentUser = useRecoilValue(authenticatedUserState);
  const kpiRequest = useRecoilValue(kpisRequestByIdState(Number(requestId ?? reqId)));
  const company = useRecoilValue(companyState(kpiRequest?.companyId as number));
  const kpiTemplate = useRecoilValue(kpisTemplateByIdState(kpiRequest?.templateId as number));
  const kpiResponse = useRecoilValue(kpisRequestResponseByIdState(kpiRequest?.id as number));
  const { createRequestResponse, updateRequestResponse } = useKPIRequestResponseActions();
  const [showThankYouScreen, setShowThankYouScreen] = useState(false);
  const userCanRead = permSvc.hasPermission(PermissionKey.canReadDataCollection);
  const userCanWrite = permSvc.hasPermission(PermissionKey.canWriteDataCollection);
  const userIsAdmin = userCanRead && userCanWrite;
  const isRespondent = kpiRequest?.respondent.includes(currentUser!.email) && userCanRead;
  const isRespondentOrAdmin = isRespondent || userIsAdmin;

  useEffect(() => {
    if (
      isRespondent &&
      kpiResponse &&
      (kpiRequest?.status == KPIRequestStatus.Submitted || kpiRequest?.status == KPIRequestStatus.Accepted)
    ) {
      setShowThankYouScreen(true);
    }
  }, [isRespondent, kpiRequest?.status, kpiResponse]);

  const requestTitle = company?.name ?? '';
  const kpiTemplateSections = useMemo(() => sortTemplateSections(kpiTemplate)?.sections, [kpiTemplate]);

  useEffect(() => {
    if (!requestTitle) return;
    document.title = `Foresight - ${requestTitle}`;
  }, [requestTitle]);

  const methods = useForm<IKPIResponseForm>({
    mode: 'onChange',
    values: {
      lastFocusedMetric: {
        metricParentRowIdx: 0,
        metricCeilColIdx: 0,
        focused: false,
      },
      sections: kpiTemplateSections ?? [],
      kpiData: {
        currencyId: 1,
        period: periodFromGranularityAndFrequency(
          kpiRequest?.granularity,
          kpiRequest?.frequency ?? KPIRequestFrequency.Monthly
        ),
        values: [],
      },
      sectionData: [],
      // FIXME: https://foresightdata.atlassian.net/browse/MAGGIE-5684
      // cast to unknown and IKPIRequest to avoid weird type error
      kpiRequest: kpiRequest as unknown as IKPIRequestDataModel,
    },
  });

  const sectionsBySortOrder = useMemo(() => {
    return cloneDeep(kpiTemplate?.sections ?? []).sort(
      (a, b) => (a.meta.sortOrder ?? 0) - (b.meta.sortOrder ?? 0)
    );
  }, [kpiTemplate]);

  useEffect(() => {
    if (!kpiResponse?.id) {
      methods.setValue(
        'sectionData',
        sectionsBySortOrder.map((section) => {
          return {
            sectionId: section.id,
            value: isKpiTableDataModel(section.meta) ? [] : undefined,
          } as IKPIResponseFormSectionData;
        })
      );
      return;
    }

    let metricsSectionData: Record<number, IKPIResponseFormSectionData> = {};
    if (kpiResponse?.kpiData?.values && kpiTemplate) {
      metricsSectionData = getMetricsSectionData(kpiResponse.kpiData.values, kpiTemplate);
    }
    const sectionData = kpiResponse.sectionData.map((section) => {
      return {
        ...section,
        value: metricsSectionData[section.sectionId]?.value ?? section.value,
      };
    });

    const sectionDataSet = new Map(sectionData.map((section) => [section.sectionId, section]));

    const completeSectionData: IKPIResponseFormSectionData[] = [];
    sectionsBySortOrder.forEach((section) => {
      completeSectionData.push(
        sectionDataSet.get(section.id ?? -1) ?? {
          sectionId: section.id as number,
          value: isKpiTableDataModel(section.meta) ? [] : undefined,
        }
      );
    });

    const kpiResponseWithNumberFormat = {
      ...kpiResponse,
      sectionData: completeSectionData,
      kpiData: {
        ...kpiResponse.kpiData,
        values: kpiResponse.kpiData.values.map((v) => ({
          ...v,
          numberFormatValue: `${v.value}`,
        })),
      },
    };

    const kpiDataWithFormStructure = {
      ...kpiResponseWithNumberFormat.kpiData,
      values: kpiDataValuesToFormStructure(kpiResponseWithNumberFormat.kpiData.values),
    };

    // using setValue instead of reset to avoid redundant re-validation trigger and unexpected behavior.
    methods.setValue('kpiData', { ...kpiDataWithFormStructure });
    methods.setValue('sectionData', [...kpiResponseWithNumberFormat.sectionData]);
  }, [kpiResponse, kpiTemplate, kpiTemplate?.sections, methods, sectionsBySortOrder]);

  const { status } = kpiRequest ?? {};
  const onSubmit = useCallback(async () => {
    const data = methods.getValues();
    const payload = prepareKpiResponsePayload(kpiTemplate!, data, requestId);

    let response;
    if (status === KPIRequestStatus.Rejected) {
      response = await updateRequestResponse(payload);
    } else {
      response = await createRequestResponse(payload);
    }

    if (response) {
      methods.reset();
      const nexTemplateToFill = markTemplateAsCompleted(requestIdAsNum);

      if (nexTemplateToFill) {
        navigate(`/kpis/response/${nexTemplateToFill.id}`);
      } else {
        setShowThankYouScreen(true);
      }
    }
  }, [
    methods,
    kpiTemplate,
    requestId,
    status,
    updateRequestResponse,
    createRequestResponse,
    markTemplateAsCompleted,
    requestIdAsNum,
    navigate,
  ]);

  const isValidRequest = kpiRequest?.status !== KPIRequestStatus.NotSent && kpiRequest;
  const validRequestAndPerms = isValidRequest && isRespondentOrAdmin;

  return showThankYouScreen ? (
    <KPIThankYouScreen />
  ) : (
    <GenericFallbacksWrapper>
      {validRequestAndPerms ? (
        <RequestResponseFormWrapper>
          <FormProvider {...methods}>
            {showHeader && (
              <KPIRequestResponseHeader
                onSubmit={methods.handleSubmit(onSubmit)}
                company={company}
                kpiResponse={kpiResponse}
                kpiRequest={kpiRequest}
              />
            )}
            <Container style={{ maxWidth: style?.maxWidth ?? '1200px' }}>
              <ContentWrapper style={style}>
                {kpiRequest?.status === KPIRequestStatus.Submitted && (
                  <AlertMui
                    severity='warning'
                    sx={{
                      background: colors.warning[10],
                      color: colors.warning[60],
                      width: 'calc(100% - (220px + 2rem))',
                      '& .MuiAlert-icon': {
                        color: colors.warning[60],
                      },
                    }}
                  >
                    Accepted data will impact the company KPIs and metrics
                  </AlertMui>
                )}
                {kpiTemplate && (
                  <TemplateSections kpiTemplateSections={kpiTemplateSections} readOnly={readOnly} />
                )}
              </ContentWrapper>
            </Container>
          </FormProvider>
        </RequestResponseFormWrapper>
      ) : (
        <NotValidRequestScreen />
      )}
    </GenericFallbacksWrapper>
  );
}

const TemplateSections = ({
  kpiTemplateSections,
  readOnly,
}: {
  kpiTemplateSections: IKPITemplateSectionDataModel[] | null | undefined;
  readOnly?: boolean;
}) => {
  const { fields } = useFieldArray({ name: 'sections' });
  const templateSectionsForm = fields as unknown as IField<unknown>[];

  if (!kpiTemplateSections) return null;

  return (
    <>
      {templateSectionsForm.map((_section, index) => {
        const sectionRef = `sections.${index}`;

        return (
          <KPIItemFactory
            editSectionIndex={index}
            key={sectionRef}
            sectionRef={sectionRef}
            editMode
            readOnly={readOnly}
          />
        );
      })}
    </>
  );
};

function useManageAssignedKPIRequests() {
  const [assignedKPIRequests, setAssignedKPIRequests] = useRecoilState(assignedKPIRequestsState);

  const markTemplateAsCompleted = useCallback(
    (requestId: number) => {
      const updatedTemplates = assignedKPIRequests.map((kpiRequest) => {
        if (kpiRequest.id === requestId) {
          return {
            ...kpiRequest,
            status: KPIRequestStatus.Submitted,
          };
        }

        return kpiRequest;
      });

      const templatesToFill = updatedTemplates.filter((template) => {
        return template.status === KPIRequestStatus.Sent || template.status === KPIRequestStatus.Rejected;
      });
      const nextTemplateToFill = templatesToFill[0];

      setAssignedKPIRequests(updatedTemplates);

      return nextTemplateToFill;
    },
    [assignedKPIRequests, setAssignedKPIRequests]
  );

  return {
    markTemplateAsCompleted,
  };
}
