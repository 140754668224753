import { ICellRendererParams } from 'ag-grid-community';
import { useAtomValue } from 'jotai';
import React, { FC, memo, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { IFinanceRoundDataModel } from '../../../data-models/finance-round.data-model';
import { ITransactionDataModel } from '../../../data-models/transaction.data-model';
import { allInvestmentRoundsMapState } from '../../../pages/Finance2/state/FinanceState';
import { roundsByIdMapAtom } from '../../../services/state/AppConfigStateJ';
import { getTextWidth } from '../../../util/getTextWidth';
import { RoundRenderer } from './RoundRenderer';

export const RoundCellRenderer: FC<
  ICellRendererParams<ITransactionDataModel | IFinanceRoundDataModel, string>
> = memo(function RoundCellRenderer({
  value,
  data,
}: ICellRendererParams<ITransactionDataModel | IFinanceRoundDataModel, string>): React.JSX.Element {
  const roundsByIdMap = useAtomValue(roundsByIdMapAtom);
  const round = !data ? null : roundsByIdMap.get(data.roundId ?? -1);
  const style = useMemo(() => getRoundPillStyles(round?.name ?? ''), [round]);

  return round ? <RoundRenderer value={round} style={style} /> : <span>{value}</span>;
});

export const RoundCellRendererName = 'RoundCellRenderer';

// renderer for Transactions in Finance 1.5 (we need a special renderer for the Transactions tab because here we're using investmentRoundId instead of roundId/roundNormalized as source)
export const RoundFromInvestmentCellRenderer: FC<ICellRendererParams<ITransactionDataModel, string>> = memo(
  function RoundFromInvestmentCellRenderer({
    value,
    data,
    node,
  }: ICellRendererParams<ITransactionDataModel, string>): React.JSX.Element {
    const investmentsById = useRecoilValue(allInvestmentRoundsMapState);
    const investment = investmentsById.get(data?.investmentRoundId ?? -1);
    const roundsById = useAtomValue(roundsByIdMapAtom);

    const round = roundsById.get(investment?.roundId ?? -1);

    const style = useMemo(() => getRoundPillStyles(round?.name ?? ''), [round]);

    if (node.group && value) return <>{value}</>;
    if (!investment) return <></>;

    return round ? <RoundRenderer value={round} style={style} /> : <span>-</span>;
  }
);

export function getRoundPillStyles(name: string) {
  const width = Math.max(getTextWidth(name, '14px Wotfard Regular'), 70);
  return {
    border: '1px solid white',
    padding: '0.5rem 2rem',
    fontSize: 14,
    margin: 0,
    maxWidth: `${width + 56}px`,
  };
}
