import { selector } from 'recoil';
import { ColourSchemeGenerator, ItemsColourScheme } from '../../util/ColourSchemeGenerator';
import { getForesightStore } from '../../util/jotai-store';
import { sectorsAtom } from './AppConfigStateJ';

export const sectorsColourState = selector<ItemsColourScheme<string>>({
  key: 'sectorsColourState',
  get: () => {
    const store = getForesightStore();
    const sectors = store.get(sectorsAtom);
    const sectorNames = sectors.map((sector) => sector.displayName);

    return ColourSchemeGenerator.getInstance().generateScheme<string>('sectorColours', sectorNames);
  },
});
