import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { FieldPath } from 'react-hook-form';
import { ROUTES } from '../../../constants/RouteNameMapping';
import { CompanyFundMetricsDataModel } from '../../../schemas/CompanyFundMetrics.schema';
import { InvestmentMetricsPanel } from '../../CompanyProfiles2/Summary/InvestmentMetricsPanel';
import { CompanyDetailsPanel } from '../../CompanyProfiles2/Summary/CompanyDetailsPanel';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { DummyCompanyId } from './DummyData';
import { EditSectionOverlay } from './EditSectionOverlay';
import { useLoadSelectedDetailsFields, useLoadSelectedMetricsFields } from './data-display-utils';

export function EditConfigsLoader() {
  const { loaded: loadedMetricsFields, selectedFields: selectedMetricsFields } =
    useLoadSelectedMetricsFields();
  const { loaded: loadedDetailsFields, selectedFields: selectedDetailsFields } =
    useLoadSelectedDetailsFields();

  if (!loadedMetricsFields || !loadedDetailsFields) return null;

  return (
    <>
      <EditDisplayConfigs
        metricsFieldsToShow={selectedMetricsFields!}
        detailsFieldsToShow={selectedDetailsFields!}
      />
    </>
  );
}
export interface IEditDisplayProps {
  metricsFieldsToShow: FieldPath<CompanyFundMetricsDataModel>[];
  detailsFieldsToShow: FieldPath<ICompanyDataModel>[];
}

export function EditDisplayConfigs({ metricsFieldsToShow, detailsFieldsToShow }: IEditDisplayProps) {
  const navigate = useNavigate();

  const onEditMetricsFields = useCallback(() => {
    navigate(`./${ROUTES.METRICS_CONFIG}`);
  }, [navigate]);

  const onEditDetails = useCallback(() => {
    navigate(`./${ROUTES.DETAILS_CONFIG}`);
  }, [navigate]);

  return (
    <>
      <EditSectionOverlay onEdit={onEditDetails} title='Company Details'>
        <CompanyDetailsPanel companyId={DummyCompanyId} editable={false} fieldsToShow={detailsFieldsToShow} />
      </EditSectionOverlay>
      <EditSectionOverlay onEdit={onEditMetricsFields} title='Investment Metrics'>
        <InvestmentMetricsPanel
          companyId={DummyCompanyId}
          editable={false}
          fieldsToShow={metricsFieldsToShow}
        />
      </EditSectionOverlay>
    </>
  );
}
