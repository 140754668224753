import { MenuItem, Select, SelectProps, Typography, useTheme } from '@mui/material';
import { useAtomValue } from 'jotai';
import { sectorsAtom } from '../../services/state/AppConfigStateJ';

export function SectorSelector(props: SelectProps) {
  const { value: selectedSectorId, onChange, ...rest } = props;
  const sectors = useAtomValue(sectorsAtom);
  const { colors } = useTheme();
  const sectorItems = [];
  sectorItems.push(
    <MenuItem disabled value='' key={'-1'}>
      <Typography variant='body2' color={colors.neutral[40]}>
        Select Sector
      </Typography>
    </MenuItem>
  );
  sectorItems.push(
    sectors.map((sector) => {
      return (
        <MenuItem key={sector.id} value={sector.id}>
          {sector.name}
        </MenuItem>
      );
    })
  );

  return (
    <Select
      sx={{
        fontSize: 14,
        width: '100%',
      }}
      size={'small'}
      id='sector-selector'
      value={selectedSectorId ?? ''}
      displayEmpty
      onChange={onChange}
      {...rest}
    >
      {sectorItems}
    </Select>
  );
}
