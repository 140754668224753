import { CSSProperties, FC } from 'react';
import { styled } from '@mui/material/styles';
import { List, ListItem, Tooltip, Typography } from '@mui/material';

interface ListWithMoreChipProps {
  items: string[];
  title?: string;
  style?: CSSProperties;
  firstItemStyle?: CSSProperties;
  chipStyle?: CSSProperties;
}

export const MoreChip = styled('div')`
  border-radius: 4px;
  min-height: 1.5rem;
  min-width: fit-content;
  padding-inline: 0.25rem;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.primary[60]};
  background-color: ${({ theme }) => theme.colors.primary[0]};
  border: thin solid ${({ theme }) => theme.colors.neutral[10]};
  margin: 0 0.5rem;
  font-size: 0.8rem;
  &:hover {
    color: ${({ theme }) => theme.colors.primary[0]};
    background-color: ${({ theme }) => theme.colors.primary[60]};
  }
  transition: all 0.3s;
`;

const Container = styled('div')`
  display: flex;
  align-items: center;
`;

const TooltipContent = styled('div')`
  padding: 1rem 1rem 0;
  max-height: 20rem;
  overflow-y: auto;
`;

export const ListWithMoreChip: FC<ListWithMoreChipProps> = ({
  items,
  title,
  style,
  firstItemStyle,
  chipStyle,
}) => {
  const [first, ...rest] = items;

  if (!items.length) return null;
  return (
    <Container style={style} className='list-with-more-chip'>
      <Typography variant='body2' style={firstItemStyle} role='listitem' color={'text.primary'}>
        {first}
      </Typography>
      {rest.length ? (
        <Tooltip
          placement='top-start'
          componentsProps={{
            tooltip: { sx: { color: 'black' } },
          }}
          title={
            <TooltipContent>
              {title ? <Typography component='h6'>{`Other ${title} (${rest.length})`}</Typography> : null}
              <List>
                {rest.map((item, i) => (
                  <ListItem key={i} sx={{ paddingLeft: 0, height: '2.2rem' }}>
                    <Typography variant='body2' component='span' noWrap>
                      {item}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </TooltipContent>
          }
        >
          <MoreChip style={chipStyle} aria-label='more details'>
            {`+${rest.length}`}
          </MoreChip>
        </Tooltip>
      ) : null}
    </Container>
  );
};
