import AddIcon from '@mui/icons-material/Add';
import { Box, LinearProgress, Link, Typography, useTheme } from '@mui/material';
import { FormikHelpers } from 'formik';
import { isEqual } from 'lodash-es';
import { FC, useCallback, useState } from 'react';
import { ConfirmDeletionDialog } from '../../../../../components/Dialog/ConfirmDialog/ConfirmDeletionDialog';
import { useToastMessageState } from '../../../../../components/ToastMessage/ToastMessageProvider';
import { IKeyResultDataModel } from '../../../../../data-models/key-result.data-model';
import { IObjectiveDataModel } from '../../../../../data-models/objective.data-model';
import {
  EditKeyResultPayload,
  KeyResultPayload,
} from '../../../../../services/queries/MaggieObjectivesQueries';
import { getErrorMessage } from '../../../../../services/queryHelpers';
import { FMT } from '../../../../../util/formatter-service';
import { formatDate } from '../../../../../util/formatters/DateFormatters';
import { StyledTooltip } from '../../../../CompetitiveIntelligence/components/Tables/CellRenderers/StyledTooltip';
import { useObjectiveActions } from '../../../hooks/useObjectiveActions';
import { EditActions } from '../EditActions/EditActions';
import { ModalWrapper } from '../ModalWrapper';
import { KeyResultForm } from '../OkrForms/KeyResultForm';
import { KeyResultInitialValues } from '../OkrForms/keyResultInitialValues';
import { StatusComponent } from '../StatusComponent/StatusComponent';
import { CenterAlignedCell, ObjectiveRow } from './ObjectiveComponent';

interface KeyResultsProps {
  objective: IObjectiveDataModel;
  keyResults: IKeyResultDataModel[];
}

export const KeyResults: FC<KeyResultsProps> = ({ keyResults, objective }) => {
  const { colors } = useTheme();
  const { handleCreateKeyResult, handleEditKeyResult, handleDeleteKeyResult } = useObjectiveActions();
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showKeyResultForm, setShowKeyResultForm] = useState(false);
  const [selectedKeyResult, setSelectedKeyResult] = useState<IKeyResultDataModel | null>(null);

  const handleCloseModal = useCallback(() => {
    setShowKeyResultForm(false);
    setShowConfirmDelete(false);
    setSelectedKeyResult(null);
  }, []);

  const onDelete = useCallback(async () => {
    if (!selectedKeyResult) return;
    try {
      await handleDeleteKeyResult(objective.companyId, selectedKeyResult);
      pushSuccessToast({ message: 'Key result deleted successfully' });
    } catch (err) {
      console.error(err);
      pushErrorToast({ message: getErrorMessage(err, 'Failed to delete key result') });
    } finally {
      setSelectedKeyResult(null);
      setShowConfirmDelete(false);
    }
  }, [handleDeleteKeyResult, objective.companyId, pushErrorToast, pushSuccessToast, selectedKeyResult]);

  const onClickDelete = useCallback(
    (id: number) => {
      const selected = keyResults.find((kr) => kr.id === id) ?? null;
      if (selected) {
        setSelectedKeyResult(selected);
        setShowConfirmDelete(true);
      }
    },
    [keyResults]
  );

  const handleLocalEditKeyResult = useCallback(
    (id: number) => {
      const selected = keyResults.find((kr) => kr.id === id) ?? null;
      if (selected) {
        setSelectedKeyResult(selected);
        setShowKeyResultForm(true);
      }
    },
    [keyResults]
  );

  const handleAddOrEditKeyResult = useCallback(
    async (values: KeyResultInitialValues, helpers: FormikHelpers<KeyResultInitialValues>) => {
      helpers.setSubmitting(true);

      const payload = {
        ...values,
        objectiveId: objective.id,
        ownerId: objective.ownerId,
        meta: {},
      } as KeyResultPayload;

      const action = selectedKeyResult ? 'edit' : 'add';

      if (
        action === 'edit' &&
        isEqual(getKeyResultPayload(selectedKeyResult as IKeyResultDataModel), values)
      ) {
        setShowKeyResultForm(false);
        setSelectedKeyResult(null);
        return;
      }

      try {
        if (action === 'add') await handleCreateKeyResult(objective.companyId, payload);
        else await handleEditKeyResult(objective.companyId, payload as EditKeyResultPayload);
        pushSuccessToast({ message: `Key result ${action}ed successfully` });
      } catch (err) {
        pushErrorToast({ message: getErrorMessage(err, `Failed to ${action} key result`) });
        setShowKeyResultForm(false);
      } finally {
        helpers.setSubmitting(false);
        setShowKeyResultForm(false);
        setSelectedKeyResult(null);
      }
    },
    [
      handleCreateKeyResult,
      handleEditKeyResult,
      objective.companyId,
      objective.id,
      objective.ownerId,
      pushErrorToast,
      pushSuccessToast,
      selectedKeyResult,
    ]
  );

  return (
    <>
      <ObjectiveRow style={{ gridTemplateRows: '2.5rem' }}>
        <CenterAlignedCell
          indent
          hasDivider
          hasGap
          style={{ gridColumn: '1 / -1', cursor: 'pointer', fontSize: '0.75rem' }}
        >
          <AddIcon fontSize='inherit' />
          <Link
            underline='hover'
            sx={{ display: 'flex', color: colors.primary[60] }}
            component={'button'}
            onClick={() => setShowKeyResultForm(true)}
          >
            <Typography variant='body2'>Key Result</Typography>
          </Link>
        </CenterAlignedCell>
      </ObjectiveRow>
      {keyResults.map(({ id, name, status, value, updatedAt, goal }, i) => (
        <ObjectiveRow key={id} className='componentWithActionOnHover'>
          <CenterAlignedCell hasDivider={i < keyResults.length - 1} indent={true}>
            <StyledTooltip title={name} enterDelay={500} placement='top-start'>
              <Typography variant='body2'>{name}</Typography>
            </StyledTooltip>
          </CenterAlignedCell>
          <CenterAlignedCell hasDivider={i < keyResults.length - 1}>
            <StatusComponent status={status} />
          </CenterAlignedCell>
          <CenterAlignedCell
            hasDivider={i < keyResults.length - 1}
            className='monospace'
            hasGap
            indent={value == null}
          >
            {value != null ? (
              <>
                <Box sx={{ width: '50%' }}>
                  <LinearProgress
                    variant='determinate'
                    value={getProgressValue(value, goal) * 100}
                    sx={{
                      backgroundColor: colors.neutral[20],
                      borderRadius: '4px',
                      '& .MuiLinearProgress-bar1Determinate': {
                        backgroundColor: colors.primary[40],
                      },
                    }}
                  />
                </Box>
                {FMT.format('decimalPercent', getProgressValue(value, goal))}
              </>
            ) : (
              '-'
            )}
          </CenterAlignedCell>
          <CenterAlignedCell hasDivider={i < keyResults.length - 1}></CenterAlignedCell>
          <CenterAlignedCell hasDivider={i < keyResults.length - 1}>
            <Typography variant='body2'>{updatedAt && formatDate(updatedAt)}</Typography>
          </CenterAlignedCell>
          <CenterAlignedCell hasDivider={i < keyResults.length - 1}></CenterAlignedCell>
          <EditActions onEdit={() => handleLocalEditKeyResult(id)} onDelete={() => onClickDelete(id)} />
        </ObjectiveRow>
      ))}
      <ModalWrapper
        open={showKeyResultForm}
        onClose={handleCloseModal}
        title={`${selectedKeyResult ? 'Edit Key Result' : 'Add Key Result'}`}
      >
        <KeyResultForm
          initialValues={selectedKeyResult ? getKeyResultPayload(selectedKeyResult) : undefined}
          onSubmit={handleAddOrEditKeyResult}
          onClose={handleCloseModal}
        />
      </ModalWrapper>
      <ConfirmDeletionDialog
        onClose={handleCloseModal}
        open={showConfirmDelete}
        onConfirm={onDelete}
        title='Delete this Key Result?'
      >
        <Typography variant='body2' color={colors.neutral[60]}>
          Are you sure you want to permanently delete this key result?
        </Typography>
      </ConfirmDeletionDialog>
    </>
  );
};

function getKeyResultPayload(keyResult: IKeyResultDataModel): KeyResultInitialValues {
  return {
    id: keyResult.id,
    objectiveId: keyResult.objectiveId,
    name: keyResult.name,
    status: keyResult.status,
    displayType: keyResult.displayType,
    value: keyResult.value,
    goal: keyResult.goal ?? 0,
  };
}

// a temporary solution, TBC
function getProgressValue(val: number, goal: number) {
  if (val === goal) return 1;
  else if (val < goal) return val / goal;
  else return goal / val;
}
