import { Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAtomValue } from 'jotai/index';
import { usersByEmailMapAtom } from '../../../services/state/AppConfigStateJ';
import { formatDate } from '../../../util/formatters/DateFormatters';

const DateAndEditorContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
`;

type Props = {
  createdBy?: string | null;
  createdAt?: string | null;
};

export const UpdatesHistoryTableHeading = ({ createdBy, createdAt }: Props) => {
  const { colors } = useTheme();
  const usersByEmailMap = useAtomValue(usersByEmailMapAtom);

  return (
    <DateAndEditorContainer>
      {createdAt && (
        <Typography variant='subtitle2' color={colors.primary[100]}>
          {formatDate(createdAt)}
        </Typography>
      )}
      {createdBy && (
        <Typography variant='subtitle2' color={colors.primary[100]}>
          {usersByEmailMap.get(createdBy)?.name}
        </Typography>
      )}
    </DateAndEditorContainer>
  );
};
