import { useCallback } from 'react';
import { atom, useAtom } from 'jotai';
import { useAsync } from '../../../../../util/hook-utils';
import {
  fetchAllSlackChannels,
  fetchSlackSummary,
  syncSlackChannel,
  updateSlackSummary,
} from '../../../../../services/queries/MaggieIntegrationsQueries';
import { useToastMessageState } from '../../../../../components/ToastMessage/ToastMessageProvider';
import { getErrorMessage, getErrorStatusCode } from '../../../../../services/queryHelpers';

export const refetchSummaryKey = atom<number>(0);

export function useSlackIntegration(companyId: number) {
  const [refetchKey, setRefetchKey] = useAtom(refetchSummaryKey);
  const { pushErrorToast, pushInfoToast } = useToastMessageState();

  const { data, loading, error } = useAsync(
    useCallback(async () => {
      try {
        return await fetchSlackSummary(companyId);
      } catch (err) {
        console.error(err);
        const status = getErrorStatusCode(err);
        if (status == 404) return null;
        throw err;
      }
    }, [companyId]),
    { id: String(refetchKey) }
  );

  const remapChannel = useCallback(
    async (channelId: string) => {
      try {
        await syncSlackChannel({
          companyId,
          channelId,
        });
        setRefetchKey((prev) => prev + 1);
      } catch (err) {
        console.error(err);
        pushErrorToast({ message: getErrorMessage(err, 'Error syncing Slack channel') });
      }
    },
    [companyId, pushErrorToast, setRefetchKey]
  );

  const refreshSummary = useCallback(async () => {
    try {
      const { updated } = await updateSlackSummary(companyId);
      if (!updated) {
        pushInfoToast({ message: 'No new company information available to update AI summary' });
        return;
      }
      setRefetchKey((prev) => prev + 1);
    } catch (err) {
      console.error(err);
      pushErrorToast({ message: getErrorMessage(err, 'Error refreshing Slack channel') });
    }
  }, [companyId, pushErrorToast, pushInfoToast, setRefetchKey]);

  return { data, loading, error, remapChannel, refreshSummary };
}

export function useAllSlackChannels() {
  return useAsync(
    useCallback(async () => {
      return await fetchAllSlackChannels();
    }, []),
    {
      id: String(refetchSummaryKey),
    }
  );
}
