import { Box, Button, Paper, Stack, styled, Typography, useTheme } from '@mui/material';
import { GetRowIdParams, GridApi, SelectionChangedEvent } from 'ag-grid-community';
import { upperFirst } from 'lodash-es';
import { FC, memo, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { AgTable } from '../../../../components/AgTable/AgTable';
import { MUIAvatar } from '../../../../components/Avatar/MUIAvatar';
import { ConfirmDeletionDialog } from '../../../../components/Dialog/ConfirmDialog/ConfirmDeletionDialog';
import { FadeInGridWrapper } from '../../../../components/grid-renderers/FadeInGridWrapper';
import { useLoadingBarState } from '../../../../components/LoadingBar/LoadingBarContext';
import { ICompanyDataModel } from '../../../../data-models/company.data-model';
import { IKPIRequestDataModel, KPIRequestStatus } from '../../../../data-models/kpi-requests.data-model';
import {
  filterKPIRequestsByStatuses,
  kpisSelectedRequestToDeleteModalState,
} from '../../../../services/state/KPI/KPIRequestsState';
import { FMT, FormatterService } from '../../../../util/formatter-service';
import { useExtendedExportSettings } from '../../../../components/AgTable/exportToExcelDefs';
import { ExportGridButton } from '../../../../components/AgTable/ExportGridButton';
import { multiRowSelectionDefs } from '../../../../components/AgTable/rowSelectionDefs';
import { portCosByIdMapState } from '../../../../services/state/PortCosState';
import { useKPIRequestsNotSentActions } from './hooks/useKPIRequestsNotSentActions';
import useKPIRequestsNotSentColDefs from './hooks/useKPIRequestsNotSentColDefs';
import { RequestConfirmDialog } from './KPIRequestResponse/RequestConfirmDialog';
import { ExportButtonContainer } from './components/KpiStyleDefs';

export const ActionButtonWrapper = styled('div')`
  position: relative;
  z-index: 1;
`;

export const ActionButtonStack = styled(Stack)`
  position: absolute;
  z-index: 1;
  right: 1rem;
  padding: 0.5rem 0.25rem 0.25rem;
  gap: 1rem;
`;
const CompanyPaperContainer = styled(Paper)`
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  padding: 1rem 1.5rem;
  background-color: transparent;
`;

const CellContainer = styled('div')`
  align-items: center;
  column-gap: 0.75rem;
  display: grid;
  grid-template-columns: min-content 1fr;
  height: 100%;
`;

const RowTypography: FC<PropsWithChildren> = ({ children }) => {
  const { colors } = useTheme();

  return (
    <Typography variant='body2' color={colors.neutral[70]} sx={{ pt: '.25rem' }} noWrap>
      {children}
    </Typography>
  );
};

export const RequestsCompaniesList = ({ selectedRows }: { selectedRows: IKPIRequestDataModel[] | null }) => {
  const formatRespondents = FormatterService.get().getFormatterForId('userByEmail');
  const portcosById = useRecoilValue(portCosByIdMapState);
  if (!selectedRows?.length) return null;

  return (
    <>
      {selectedRows?.map((row) => {
        const company = portcosById.get(row.companyId ?? -1);
        return (
          <CompanyPaperContainer key={row.id} elevation={1}>
            <CellContainer>
              <MUIAvatar
                nameParts={company?.name?.split(' ') ?? []}
                src={company?.logoUrl as string}
                size={'medium'}
              />
              <Typography variant={'body2'}>{company?.name}</Typography>
            </CellContainer>
            <RowTypography>{FMT.format('kpiTemplateName', row.templateUuid)}</RowTypography>
            <RowTypography>{upperFirst(row.frequency)}</RowTypography>
            <RowTypography>{formatRespondents(row.respondent)}</RowTypography>
          </CompanyPaperContainer>
        );
      })}
    </>
  );
};

export function UnsentKpiRequestsGrid() {
  const { colors } = useTheme();
  const {
    state: { isLoading: isUpdatingOrDeleting },
  } = useLoadingBarState();
  const [kpisSelectedRequestToDeleteModal, setKpisSelectedRequestToDeleteModal] = useRecoilState(
    kpisSelectedRequestToDeleteModalState
  );
  const requestsNotSent: IKPIRequestDataModel[] | null =
    useRecoilValue(filterKPIRequestsByStatuses([KPIRequestStatus.NotSent])) ?? null;
  const { columnDefs, defaultColDef } = useKPIRequestsNotSentColDefs();
  const { sendKPIRequestAction, deleteKPIRequestAction } = useKPIRequestsNotSentActions();

  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [selectedRows, setSelectedRows] = useState<IKPIRequestDataModel[] | null>(null);
  const [showSentModal, setShowSentModal] = useState(false);
  const { exportParams } = useExtendedExportSettings(
    new Set(['companyId', 'templateUuid']),
    'kpi-requests-not-sent'
  );
  const portcosById = useRecoilValue(portCosByIdMapState);

  const onGridReady = (params: { api: GridApi }) => {
    setGridApi(params.api);
  };

  const handleExport = useCallback(() => {
    gridApi?.exportDataAsExcel();
  }, [gridApi]);

  const selectedRequestCompanies = useMemo(() => {
    const selectedCompanies = new Map<number, ICompanyDataModel>();
    selectedRows?.forEach((row) => {
      const company = portcosById.get(row.companyId ?? -1);
      if (company && !selectedCompanies.has(company?.id)) {
        selectedCompanies.set(company.id, company);
      }
    });

    return selectedCompanies;
  }, [portcosById, selectedRows]);

  const handleSelectionChanged = useCallback(
    (e: SelectionChangedEvent) => {
      const selectedRows = e.api.getSelectedRows();
      setSelectedRows(selectedRows);
    },
    [setSelectedRows]
  );

  const handleOnConfirmSendAll = useCallback(async () => {
    if (!gridApi || !selectedRows) return;
    const selectedRowsIds = selectedRows.map((row) => row.id);

    const payload = {
      requestsIds: selectedRowsIds as number[],
      totalCompanies: selectedRequestCompanies.size,
    };

    await sendKPIRequestAction(payload);
    setShowSentModal(false);

    gridApi?.deselectAll();
    setSelectedRows(null);
  }, [gridApi, selectedRequestCompanies.size, selectedRows, sendKPIRequestAction]);

  const handleOnSendAll = useCallback(() => {
    setShowSentModal(true);
  }, []);

  const handleOnCancelAll = () => {
    gridApi?.deselectAll();
  };

  const handleCloseDeleteModal = useCallback(() => {
    setKpisSelectedRequestToDeleteModal(0);
  }, [setKpisSelectedRequestToDeleteModal]);

  const handleOnDeleteRequest = useCallback(async () => {
    await deleteKPIRequestAction(kpisSelectedRequestToDeleteModal);
    handleCloseDeleteModal();
  }, [deleteKPIRequestAction, handleCloseDeleteModal, kpisSelectedRequestToDeleteModal]);

  const selectedRequest = useMemo(
    () => requestsNotSent?.find((fr) => fr.id === kpisSelectedRequestToDeleteModal) ?? null,
    [kpisSelectedRequestToDeleteModal, requestsNotSent]
  );

  const getRowId = useCallback((params: GetRowIdParams<IKPIRequestDataModel>) => {
    return params.data.id.toString();
  }, []);

  return (
    <Stack height={'100%'}>
      {showSentModal && (
        <RequestConfirmDialog
          open={showSentModal}
          onClose={() => setShowSentModal(false)}
          loading={isUpdatingOrDeleting}
          onConfirm={handleOnConfirmSendAll}
          requests={selectedRows ?? []}
          message={` Selected ${selectedRequestCompanies.size} companies:`}
          title='Confirm All Requests'
        />
      )}
      <ConfirmDeletionDialog
        open={!!kpisSelectedRequestToDeleteModal}
        loading={isUpdatingOrDeleting}
        onClose={handleCloseDeleteModal}
        onConfirm={handleOnDeleteRequest}
        title={`Delete Request for ${portcosById.get(selectedRequest?.companyId ?? -1)?.name}?`}
      >
        <Typography variant='body2' color={colors.neutral[60]} sx={{ pt: '.25rem', minWidth: '30rem' }}>
          This action will skip the request for the selected period.
        </Typography>
      </ConfirmDeletionDialog>
      <div css={ExportButtonContainer}>
        <ExportGridButton onClick={handleExport} />
      </div>
      {!requestsNotSent?.length ? (
        <Box
          sx={{
            backgroundColor: 'white',
            width: '100%',
            p: '.75rem',
            borderRadius: '4px',
            borderLeft: '6px solid',
            borderColor: 'green',
          }}
        >
          All assigned requests are sent
        </Box>
      ) : (
        <FadeInGridWrapper>
          <ActionButtons selectedRows={selectedRows} actions={[handleOnSendAll, handleOnCancelAll]} />

          <AgTable
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            groupDefaultExpanded={-1}
            onGridReady={onGridReady}
            onSelectionChanged={handleSelectionChanged}
            rowData={requestsNotSent}
            rowGroupPanelShow='always'
            suppressMovableColumns={true}
            suppressRowDrag={true}
            defaultExcelExportParams={exportParams}
            defaultCsvExportParams={exportParams}
            rowSelection={multiRowSelectionDefs}
          />
        </FadeInGridWrapper>
      )}
    </Stack>
  );
}

interface ActionButtonsProps {
  selectedRows: IKPIRequestDataModel[] | null;
  actions: (() => void)[];
}

const ActionButtons = memo(function ActionButtons({ actions, selectedRows }: ActionButtonsProps) {
  const { colors } = useTheme();
  const {
    state: { isLoading },
  } = useLoadingBarState();
  const [handleOnSendAll, handleOnCancelAll] = actions;

  return (
    <ActionButtonWrapper>
      {selectedRows?.length ? (
        <ActionButtonStack direction='row'>
          <Button disabled={isLoading} variant='contained' color='secondary' onClick={handleOnSendAll}>
            Send Selected {selectedRows?.length ? `(${selectedRows.length})` : ''}
          </Button>
          <Button
            variant='outlined'
            color='secondary'
            sx={{
              backgroundColor: colors.primary[5],
            }}
            onClick={handleOnCancelAll}
          >
            Cancel
          </Button>
        </ActionButtonStack>
      ) : null}
    </ActionButtonWrapper>
  );
});
