import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { EditorState, SerializedEditorState } from 'lexical';
import { debounce } from 'lodash-es';
import { INoteDataModel } from '../../../data-models/note.data-model';

export interface ILexicalProviderProps {
  companyId: number;
  note?: INoteDataModel<SerializedEditorState>;
  onExit: () => void;
  editorState?: EditorState | null;
}

export type LexicalContextType = {
  noteData: INoteDataModel<SerializedEditorState> | undefined;
  setNoteData: React.Dispatch<React.SetStateAction<INoteDataModel<SerializedEditorState> | undefined>>;
  isSaving: boolean;
  setIsSaving: (newValue: boolean) => void;
  onExit: () => void;
  companyId: number;
  linkedCompanyIds: number[];
  editorStateRef: React.MutableRefObject<EditorState | null>;
  debounceHideSuccessText: () => void;
  lastSuccessUpdateOnNotes: string;
  setLastSuccessUpdateOnNotes: React.Dispatch<React.SetStateAction<string>>;
} & ILexicalProviderProps;

const LexicalContext = createContext<LexicalContextType | null>(null);

export function LexicalProvider({
  children,
  companyId,
  note,
  onExit,
  editorState = null,
}: {
  children: React.ReactNode;
} & ILexicalProviderProps) {
  const [title, setTitle] = useState(note?.title);
  const [linkedCompanyIds] = useState<number[]>(note?.companyIds ?? []);
  const [noteData, setNoteData] = useState(note);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const editorStateRef = useRef<EditorState | null>(editorState);
  const [lastSuccessUpdateOnNotes, setLastSuccessUpdateOnNotes] = useState<string>('');

  const debounceHideSuccessText = useRef(
    debounce(() => {
      return setTimeout(() => {
        setLastSuccessUpdateOnNotes('');
      }, 1000);
    })
  ).current;

  useEffect(() => {
    if (!lastSuccessUpdateOnNotes) return;

    debounceHideSuccessText();
  }, [lastSuccessUpdateOnNotes, debounceHideSuccessText]);

  const value = useMemo(() => {
    return {
      title,
      setTitle,
      noteData,
      setNoteData,
      isSaving,
      setIsSaving,
      onExit,
      companyId,
      linkedCompanyIds,
      editorStateRef,
      debounceHideSuccessText,
      lastSuccessUpdateOnNotes,
      setLastSuccessUpdateOnNotes,
    };
  }, [
    companyId,
    debounceHideSuccessText,
    isSaving,
    lastSuccessUpdateOnNotes,
    linkedCompanyIds,
    noteData,
    onExit,
    title,
  ]);

  return <LexicalContext.Provider value={value}>{children}</LexicalContext.Provider>;
}

export const useLexicalContext = () => {
  const lexicalContext = useContext(LexicalContext);

  if (!lexicalContext) {
    throw new Error('useLexicalContext cannot be used outside of LexicalProvider');
  }

  return lexicalContext;
};
