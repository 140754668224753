import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { IDealsGroupedDataModel } from '../../../data-models/deal-conversion.data-model';
import { IRoundDataModel } from '../../../data-models/round.data-model';
import { UnknownUser } from '../../../data-models/user.data-model';
import { roundsByNameMapAtom, usersByIdMapAtom } from '../../../services/state/AppConfigStateJ';
import { GroupByFilterState, GroupByOptions } from '../state/DealConversionFilterState';

export const MAX_BARS_FOR_GROUP_BY_LOCATION = 10;
export function useDealsBreakdownData(dealsGrouped: IDealsGroupedDataModel[]) {
  const groupByFilter = useRecoilValue(GroupByFilterState);
  const usersMap = useAtomValue(usersByIdMapAtom);
  const roundsByNameMap = useAtomValue(roundsByNameMapAtom);

  const { data, categories } = useMemo(() => {
    const result: {
      data: { name: string | number; value: number; percentageDifference: number | null }[];
      categories: (string | number)[];
    } = { data: [], categories: [] };

    let sortedDeals = sortDeals(groupByFilter, [...dealsGrouped], roundsByNameMap);

    if (groupByFilter === GroupByOptions.DEAL_LEAD) {
      return sortedDeals.reduce((acc, item) => {
        const userName = (usersMap.get(Number(item.name)) ?? UnknownUser)?.name;
        acc.data.push({ ...item, name: userName });
        acc.categories.push(userName);

        return acc;
      }, result);
    }

    if (groupByFilter === GroupByOptions.LOCATION) {
      sortedDeals = sortedDeals.slice(0, MAX_BARS_FOR_GROUP_BY_LOCATION);
    }

    return { data: sortedDeals, categories: sortedDeals.map((item) => item.name) };
  }, [dealsGrouped, groupByFilter, roundsByNameMap, usersMap]);

  return { data, categories };
}

function sortDeals(
  groupByFilter: GroupByOptions,
  deals: IDealsGroupedDataModel[],
  roundsByNameMap: Map<string, IRoundDataModel>
) {
  if (groupByFilter === GroupByOptions.ROUND) {
    return deals.sort((deal1, deal2) => {
      const roundA = roundsByNameMap.get(deal1.name);
      const roundB = roundsByNameMap.get(deal2.name);
      return (roundA?.sortOrder ?? -1) - (roundB?.sortOrder ?? -1);
    });
  }

  return deals.sort((deal1, deal2) => {
    return deal2.value - deal1.value;
  });
}
