import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { css } from '@emotion/react';
import { Link } from 'react-router';
import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
  rubricConfigState,
  mostRecentRubricsByCompanyIdState,
} from '../../../../../services/state/RubricsState';
import { SubSection } from '../../../Summary/components/SubSection';
import { selectedMetricsDateCPState } from '../../../state/CompanyFinancialsDateState';
import { getPlainDateString } from '../../../../../services/queries/MaggieMetricsQueries';
import { ContainerName } from '../../../../../constants/styles';
import { ROUTES } from '../../../../../constants/RouteNameMapping';
import { HelpMessage } from '../../../components/Messages/HelpMessage';
import { RubricList } from './RubricList';

const RubricsContainer = css`
  container-name: ${ContainerName.rubricSectionContainer};
  container-type: inline-size;
`;

interface IRubricSectionProps {
  companyId: number;
}
export function RubricSection({ companyId }: IRubricSectionProps) {
  const config = useRecoilValue(rubricConfigState);
  const date = useRecoilValue(selectedMetricsDateCPState(companyId));
  const rubrics = useRecoilValue(
    mostRecentRubricsByCompanyIdState({ companyId, date: getPlainDateString(date) })
  );
  const sortedRubrics = useMemo(() => {
    return [...rubrics].sort((a, b) => {
      const aTime = a.createdAt ? new Date(a.createdAt).getTime() : 0;
      const bTime = b.createdAt ? new Date(b.createdAt).getTime() : 0;
      return aTime - bTime;
    });
  }, [rubrics]);

  return (
    <div css={RubricsContainer}>
      <SubSection
        title={'Performance Assessment'}
        collapseProps={{ collapsible: true, defaultExpanded: true }}
        key={`${companyId}-${getPlainDateString(date)}`}
      >
        {config?.length ? <RubricList companyId={companyId} rubrics={sortedRubrics} /> : <NoRubricsMessage />}
      </SubSection>
    </div>
  );
}

function NoRubricsMessage() {
  return (
    <Box>
      <HelpMessage severity='info' message='No rubrics exist.' />
      <Button
        variant='outlined'
        color='secondary'
        endIcon={<AddIcon />}
        component={Link}
        to={`/${ROUTES.ADMIN_PANEL}/${ROUTES.ADMIN_PANEL_RUBRICS}`}
        style={{ marginTop: '1rem' }}
      >
        Create rubric
      </Button>
    </Box>
  );
}
