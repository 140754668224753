import { SyntheticEvent, useRef, DragEvent } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import { css } from '@emotion/react';
import { colors } from '../../../theme/colors';
import { IFileMeta } from '../../../data-models/field3.data-model';
import { convertFileSize } from '../../../pages/KPI/utils';
import { UnstyledFieldset } from '../../../pages/CompanyProfiles/Scenarios/components/commonStyledComponents';
import { IBaseFieldProps } from './types';

const FileInputBox = css`
  width: 100%;
  min-height: 3rem;
  border-radius: 4px;
  display: grid;
  align-items: center;
  padding-inline: 1rem;
  grid-template-columns: 1fr;
  transition: all 0.5s;
`;

const EmptyFileInputBox = css`
  ${FileInputBox};
  border: thin dashed ${colors.neutral[30]};
  &:disabled {
    pointer-events: none;
  }
`;

const UploadedFilesBox = css`
  border-radius: 4px;
  min-height: 3rem;
  border: thin solid ${colors.neutral[30]};
  display: grid;
`;
const UploadedFileWrapper = css`
  padding: 0.2rem 1rem;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  background-color: transparent;
  transition: all 0.2s;

  &:hover {
    background-color: ${colors.secondary[5]}88;
  }
`;

const FileInputButton = css`
  all: unset;
  position: relative;
`;

const InputDescContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;

  input[type=file], /* FF, IE7+, chrome (except button) */
  input[type=file]::-webkit-file-upload-button {
    /* chromes and blink button */
    cursor: pointer;
  }
  & input {
    all: unset;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
  }
`;

export const FieldFile = (props: IBaseFieldProps<IFileMeta>) => {
  const { formField, formProps } = props;
  const { acceptTypes, multiple } = formField.rendererMeta ?? {};
  const defaultValue = multiple ? [] : null;
  const { value = defaultValue, onChange } = formProps;
  const { disabled = false } = formField ?? {};
  const fileInputRef = useRef<HTMLInputElement>(null);

  const _onChange = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!fileInputRef?.current?.files) return;
    if (multiple) {
      onChange(Array.from(fileInputRef.current.files));
    } else {
      onChange(fileInputRef.current.files[0]);
    }
  };

  const handleDrag = (e: SyntheticEvent) => {
    e.preventDefault();
  };

  const handleDrop = (e: DragEvent) => {
    e.preventDefault();

    if (multiple) {
      const validFiles = Array.from(e.dataTransfer.files).filter(
        (file) => !acceptTypes || acceptTypes.includes(file.type)
      );
      onChange([...(value ?? []), ...validFiles]);
    } else {
      const selected = e.dataTransfer.files[0];
      if (selected && (!acceptTypes || acceptTypes.includes(selected.type))) onChange(selected);
    }
  };

  const handleRemove = (file: File) => {
    if (multiple) {
      onChange((value as File[]).filter((f) => f !== file));
    } else {
      onChange(null);
    }
  };

  const valueAsArray: File[] = multiple ? (value ?? []) : value ? [value] : [];

  if (valueAsArray.length > 0) {
    return (
      <div
        css={UploadedFilesBox}
        className={valueAsArray.length > 1 ? 'animated' : ''}
        onDragOver={handleDrag}
        onDragEnter={handleDrag}
        onDrop={handleDrop}
      >
        {valueAsArray.map((file) => (
          <Box css={UploadedFileWrapper} key={file.name}>
            <Typography variant='body2' color='text.primary' noWrap>
              {`${file.name} ${convertFileSize(file.size)}`}
            </Typography>
            {!disabled && (
              <IconButton onClick={() => handleRemove(file)} title='remove'>
                <ClearIcon htmlColor={colors.neutral[50]} fontSize='small' />
              </IconButton>
            )}
          </Box>
        ))}
      </div>
    );
  }

  return (
    <UnstyledFieldset disabled={disabled}>
      <div css={EmptyFileInputBox} onDragOver={handleDrag} onDragEnter={handleDrag} onDrop={handleDrop}>
        <div css={InputDescContainer}>
          <CloudUploadOutlinedIcon htmlColor={colors.neutral[50]} fontSize='small' />
          <Typography variant='body2' color='text.secondary'>
            &nbsp;Drag and drop or&nbsp;
          </Typography>
          <button css={FileInputButton} type='button'>
            <Typography variant='body2' color='secondary'>
              Browse your files
            </Typography>
            <input
              type='file'
              ref={fileInputRef}
              onChange={_onChange}
              multiple={multiple}
              accept={acceptTypes?.join(', ')}
            />
          </button>
        </div>
      </div>
    </UnstyledFieldset>
  );
};
