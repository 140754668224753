import { FC, useMemo } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { styled } from '@mui/material/styles';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  financeTransactionsByCompanyState,
  transactionHistoryByCompanyState,
  transactionsByIdState,
} from '../state/FinanceState';
import { useTransactionHistoryColumns } from '../../FinanceHistory/hooks/useTransactionHistoryColumns';
import { Loader } from '../../../components/Loader/Loader';
import { ErrorOccurredMessage } from '../../CompanyProfiles/components/Messages/HelpMessage';
import { FinanceHistoryPage } from '../../FinanceHistory/components/FinanceHistoryContent';
import { companyMetricsByIdState } from '../../../services/state/CompanyMetricsByIdState';
import { companyState } from '../../../services/state/CompanyState';
import { PANEL_WIDTH_IN_PX } from '../../FinanceHistory/components/CompanyTransactionsPanel/CompanyTransactionsPanel';
import { ROUTES } from '../../../constants/RouteNameMapping';
import { SelectedCompanyParam } from '../finance-constants';

const ViewContainer = styled('div')`
  position: relative;
  width: 100%;
  max-width: 100%;
  display: grid;
  grid-template-columns: ${PANEL_WIDTH_IN_PX}px 1fr;
  max-height: calc(100vh - 12rem);
  overflow: auto;
`;

export const TransactionHistory: FC = () => {
  const [search] = useSearchParams();

  const companyParam = search.get('company');
  const companyId = companyParam ? parseInt(companyParam) : -1;
  const company = useRecoilValue(companyState(companyId));

  const transactionId = search.get('transaction');
  const transactionsById = useRecoilValue(transactionsByIdState(companyId));
  const selectedTransaction = transactionsById.get(transactionId ?? '');

  const historyData = useRecoilValueLoadable(transactionHistoryByCompanyState(companyId));
  const transactionsLoadable = useRecoilValueLoadable(financeTransactionsByCompanyState(companyId));
  const transactionHistory = historyData.valueMaybe();

  const transactions = transactionsLoadable.valueMaybe();

  const companyMetricsData = useRecoilValueLoadable(companyMetricsByIdState(companyId));
  const companyMetrics = companyMetricsData.valueMaybe();

  const getTransactionHistoryColumns = useTransactionHistoryColumns();

  const tableColumns = useMemo(
    () => (!selectedTransaction ? [] : getTransactionHistoryColumns(selectedTransaction)),
    [selectedTransaction, getTransactionHistoryColumns]
  );

  const breadcrumbs = useMemo(() => {
    if (!company) return [];
    const transactionsTabUrlSegment = window.location.pathname.includes(ROUTES.FINANCE_TRANSACTIONS_3)
      ? ROUTES.FINANCE_TRANSACTIONS_3
      : ROUTES.FINANCE_TRANSACTIONS;
    return [
      <Link
        key='transactions'
        component={RouterLink}
        color='secondary'
        variant='caption'
        underline='none'
        to={`/${ROUTES.FINANCE2}/${ROUTES.FINANCE2_OVERVIEW}/${ROUTES.FINANCE_DETAILS}/${transactionsTabUrlSegment}?${SelectedCompanyParam}=${companyId}`}
      >
        Transactions
      </Link>,

      <Typography key='audits' color='text.primary' variant='caption'>
        {`${company.name} Transactions History`}
      </Typography>,
    ];
  }, [company, companyId]);

  if (
    historyData.state === 'loading' ||
    transactionsLoadable.state === 'loading' ||
    companyMetricsData.state === 'loading'
  )
    return <Loader />;
  if (
    historyData.state === 'hasError' ||
    transactionsLoadable.state === 'hasError' ||
    companyMetricsData.state === 'hasError'
  ) {
    return <ErrorOccurredMessage />;
  }

  if (
    !transactionHistory ||
    !transactions ||
    !companyMetrics ||
    companyMetrics.metrics.length === 0 ||
    !company
  )
    return null;

  return (
    <ViewContainer>
      <FinanceHistoryPage
        transactions={transactions}
        selectedTransaction={selectedTransaction ?? null}
        colDefs={tableColumns}
        companyMetrics={{ ...companyMetrics.metrics[0], company }}
        transactionHistory={transactionHistory}
        breadCrumbsComponent={
          <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} style={{ marginBottom: '0.5rem' }}>
            {breadcrumbs}
          </Breadcrumbs>
        }
        sidebarStyles={{ height: 'calc(100vh - 12rem)' }}
      />
    </ViewContainer>
  );
};
