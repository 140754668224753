import { useMatch } from 'react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ROUTES, ROUTE_TITLE_MAPPING } from '../../../../../constants/RouteNameMapping';
import { NavigationWrapper } from '../NavigationWrapper';
import { SubSectionNavigation } from '../../SubSectionNavigation/SubSectionNavigation';
import SubSectionNavItems from '../../SubSectionNavigation/SubSectionNavItems';
import { MaggieFeatureFlags } from '../../../../../util/feature-flags';

export function FinanceNavigation() {
  const match = useMatch(`/${ROUTES.FINANCE2}/*`);
  const { showAllTransactions, showAllSecurities } = useFlags<MaggieFeatureFlags>();
  if (match === null) return null;

  const FinanceSections = [
    {
      id: ROUTES.FINANCE2_OVERVIEW,
      value: showAllTransactions ? 'All Transactions' : 'Dashboard',
    },
    {
      id: ROUTES.FINANCE_RECON,
      value: ROUTE_TITLE_MAPPING[ROUTES.FINANCE_RECON],
    },
  ];
  if (showAllSecurities) {
    FinanceSections.splice(1, 0, {
      id: ROUTES.FINANCE_ALL_SECURITIES,
      value: ROUTE_TITLE_MAPPING[ROUTES.FINANCE_ALL_SECURITIES],
    });
  }

  return (
    <NavigationWrapper title='Finance'>
      <SubSectionNavigation title='Overview'>
        <SubSectionNavItems sectionList={FinanceSections} parentRoute={ROUTES.FINANCE2} />
      </SubSectionNavigation>
    </NavigationWrapper>
  );
}
