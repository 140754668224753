import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { IMetricsDataModel } from '../../../data-models/metrics.data-model';
import { sectorsByIdMapAtom } from '../../../services/state/AppConfigStateJ';
import { useGetCompanyIfSet } from '../../CompanyProfiles/hooks/useGetCompanyData';
import { InvestmentAmount } from './useInvestmentAmountByState';

export function useInvestmentAmount(filteredData: IMetricsDataModel[]): InvestmentAmount {
  const sectorMap = useAtomValue(sectorsByIdMapAtom);
  const getCompany = useGetCompanyIfSet();

  return useMemo(() => {
    let total = 0;

    const groupedData = filteredData?.reduce(
      (a, metrics) => {
        const company = getCompany(metrics.companyId);
        const sectorId = company?.sectorId;
        const sector = sectorMap.get(sectorId ?? -1)?.name ?? 'Other';
        if (a[sector]) a[sector] += metrics.amountInvested;
        else a[sector] = metrics.amountInvested;
        total += metrics.amountInvested;
        return a;
      },
      {} as Record<string, number>
    );

    const groupedArray = Object.entries(groupedData as Record<string, number>).map(([key, value]) => ({
      name: key,
      value: Number(value.toFixed(2)),
      id: key,
    }));

    groupedArray.sort((a, b) => (a.value > b.value ? -1 : b.value > a.value ? 1 : 0));

    return { data: groupedArray, total };
  }, [filteredData, getCompany, sectorMap]);
}
