import * as yup from 'yup';
import { RendererType } from '../../../data-models/field.data-model';
import { IFormFieldSelectMeta } from '../../../view-models/form.view-model';
import { requiredMsg } from '../../Finance/components/TransactionModal/Forms/utils/validationMessages';
import { FieldEntity, GridRef, ISimpleChoice } from '../../../data-models/field2.data-model';
import { PermissionService } from '../../../services/PermissionService';
import { PermissionKey } from '../../../services/PermissionAndRolesKeys';

export const RendererTypeOptions: Partial<Record<RendererType, string>> = {
  [RendererType.text]: 'Text',
  [RendererType.singleSelect]: 'Select',
  [RendererType.multiSelect]: 'Multiselect',
  [RendererType.number]: 'Number',
  [RendererType.currency]: 'Currency',
  [RendererType.percent]: 'Percent',
  [RendererType.date]: 'Date',
  [RendererType.colorStatus]: 'Color Status',
};

export const FieldEntityOptions: Partial<Record<FieldEntity, string>> = {
  [FieldEntity.company]: 'Company',
  [FieldEntity.fundCompany]: 'Company & Fund',
  [FieldEntity.deal]: 'Deal',
  [FieldEntity.round]: 'Round',
  [FieldEntity.captableInvestment]: 'Security',
};

export const CustomFieldViewModelSchema = {
  base() {
    return {
      rendererType: yup
        .string()
        .oneOf(Object.values(RendererType))
        .default(RendererType.text)
        .label('Type')
        .required(),
      currencyConfig: yup.string().nullable().optional().label('Currency'),
      selectConfigValues: yup
        .array()
        .of(yup.string())
        .nullable()
        .optional()
        .default(undefined)
        .label('Options'),

      // gridRefs (conditional on entity)
      [GridRef.portfolioReporting]: yup
        .boolean()
        .nullable()
        .optional()
        .default(false)
        .label('Add a column to Company Views'),
      [GridRef.roundTracker]: yup
        .boolean()
        .nullable()
        .optional()
        .default(false)
        .label('Add a column to Round Views'),
      [GridRef.securityOverview]: yup
        .boolean()
        .nullable()
        .optional()
        .default(false)
        .label('Add a column to Security Views'),
      // formRefs (also conditional on entity)
      addingCaptableSecurity: yup
        .boolean()
        .nullable()
        .optional()
        .default(false)
        .label('Adding Captable Security'),
      addingTransaction: yup.boolean().nullable().optional().default(false).label('Adding Transaction'),
      addingDeal: yup.boolean().nullable().optional().default(false).label('Adding Deal'),
      addingRound: yup.boolean().nullable().optional().default(false).label('Adding Round'),
      companyLists: yup.boolean().nullable().optional().default(false).label('Company Lists'),
      companyProfile: yup.boolean().nullable().optional().default(false).label('Company Profile').customMeta({
        description: 'You can reorder and customize fields displayed later in Data Display',
      }),
    };
  },
  model() {
    return yup.object(this.base());
  },
  formSchema() {
    const { rendererType, selectConfigValues, currencyConfig, ...baseFields } = this.base();
    return yup.object({
      ...baseFields,
      rendererType: rendererType.formMeta({
        renderer: RendererType.singleSelect,
        rendererMeta: {
          values: Object.keys(RendererTypeOptions).map((value) => ({
            displayName: RendererTypeOptions[value as keyof typeof RendererTypeOptions] ?? value,
            value,
          })),
        },
      }),
      //formMeta.formatter.config.currency (the value is currency.code)
      currencyConfig: currencyConfig
        .when('rendererType', {
          is: RendererType.currency,
          then: (schema) => schema.required(),
        })
        .formMeta({
          renderer: RendererType.singleSelect,
        }),
      // formMeta.renderer.config.values
      selectConfigValues: selectConfigValues
        .test('rendererType', requiredMsg('Options'), function (value, ctx) {
          const rendererType = ctx.parent.rendererType;
          if (rendererType === RendererType.singleSelect || rendererType === RendererType.multiSelect) {
            return value != undefined && value.length > 0;
          }
          return true;
        })
        .formMeta<IFormFieldSelectMeta<string>>({
          renderer: RendererType.multiSelect,
          rendererMeta: {
            values: [],
            allowCustomAdd: true,
          },
        }),
    });
  },
};

export type CustomFieldFormViewModel = yup.InferType<
  ReturnType<typeof CustomFieldViewModelSchema.formSchema>
>;

export const CustomFieldSchema = {
  base() {
    if (!PermissionService.get().hasPermission(PermissionKey.canViewDeal)) {
      delete FieldEntityOptions[FieldEntity.deal];
    }
    return {
      description: yup.string().label('Description').required(),
      displayName: yup.string().label('Name').required(),
      entity: yup.string().label('Entity').required().default(FieldEntity.company),
      entityField: yup.string().label('Entity Field').optional(),
      formMeta: yup.object().nullable().optional(),
      gridMeta: yup.object().nullable().optional(),
      id: yup.number(),
      systemMeta: yup.object().nullable().optional(),
      type: yup.string(),
      _viewModel: CustomFieldViewModelSchema.model(),
    };
  },
  formSchema(showSecurityView = false) {
    const { entity, ...baseFields } = this.base();
    return yup.object({
      ...baseFields,
      entity: entity.formMeta<IFormFieldSelectMeta<string>>({
        label: 'Entity',
        renderer: RendererType.singleSelect,
        rendererMeta: {
          values: Object.entries(FieldEntityOptions).reduce((acc, [key, value]) => {
            if (key === FieldEntity.captableInvestment && !showSecurityView) return acc;
            acc.push({ displayName: value, value: key });
            return acc;
          }, [] as ISimpleChoice<string>[]),
        },
      }),
      _viewModel: CustomFieldViewModelSchema.formSchema(),
    });
  },
};

export type CustomFieldFormType = yup.InferType<ReturnType<typeof CustomFieldSchema.formSchema>>;
