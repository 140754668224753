import { useMemo } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { FormProvider, useForm } from 'react-hook-form';
import { cloneDeep } from 'lodash-es';
import { yupResolver } from '@hookform/resolvers/yup';
import { Divider, Typography } from '@mui/material';
import { FieldEntity, IField } from '../../../data-models/field2.data-model';
import { FormFactoryWithStandardLayout } from '../../../components/Form/FormFactory';
import { FDMap } from '../../../util/data-structure/FDMap';
import { createForm } from '../../../view-models/form.view-model';
import { StepperFormButtons } from '../../Finance2/Forms/StepperFormButtons';
import { useCustomFieldsActions } from '../CustomFields/hooks/useCustomFieldsActions';
import { CustomFieldFormType, CustomFieldSchema } from './CustomField.schema';
import { CustomFieldFormCurrentStepState, CustomFieldFormDataState } from './CustomFieldFormState';
import { isEditMode, useCustomFieldsStep2Fields } from './customFieldFormUtils';
import { fromFieldViewModel } from './custom-field-form.view-model';
import { CustomFieldsFormContainer } from './CustomFieldsFormContainer';

export function CustomFieldFormStep2() {
  const [formData, setFormData] = useRecoilState(CustomFieldFormDataState);
  const setStep = useSetRecoilState(CustomFieldFormCurrentStepState);
  const { createField, updateField } = useCustomFieldsActions();
  const editMode = Boolean(formData) && isEditMode(formData!);

  const methods = useForm<CustomFieldFormType>({
    defaultValues: cloneDeep(formData!),
    mode: 'onChange',
    resolver: yupResolver(CustomFieldSchema.formSchema()),
  });

  const handlePrevious = async () => {
    setFormData(methods.getValues());
    setStep((prev) => prev - 1);
    return true;
  };

  const handleSubmit = async () => {
    const isValid = await methods.trigger();
    if (isValid) {
      const payload = fromFieldViewModel(methods.getValues() as CustomFieldFormType & IField<unknown>);
      if (editMode) {
        await updateField(payload);
      } else {
        await createField(payload);
      }
      return true;
    } else {
      return false;
    }
  };

  if (!formData) return <>No form data</>;

  return (
    <FormProvider {...methods}>
      <CustomFieldsFormContainer>
        <Typography>Set Field Display</Typography>
        <FormStep2 entity={formData!.entity as FieldEntity} />
      </CustomFieldsFormContainer>
      <StepperFormButtons
        stepIsValid={handleSubmit}
        stepState={CustomFieldFormCurrentStepState}
        handleGoBack={handlePrevious}
        nextButtonLabel={editMode ? 'Update' : 'Create'}
      />
    </FormProvider>
  );
}

function FormStep2({ entity }: { entity: FieldEntity }) {
  const fields = useCustomFieldsStep2Fields(entity);
  const form1 = useMemo(
    () =>
      createForm({
        fields: FDMap.fromArray(
          fields.filter((f) => formRefFields.has(f.key)),
          'key'
        ),
      }),
    [fields]
  );

  const form2 = useMemo(
    () =>
      createForm({
        fields: FDMap.fromArray(
          fields.filter((f) => !formRefFields.has(f.key)),
          'key'
        ),
      }),
    [fields]
  );

  return (
    <>
      <FormFactoryWithStandardLayout form={form1} />
      <Divider />
      <FormFactoryWithStandardLayout form={form2} />
    </>
  );
}

const formRefFields = new Set([
  `_viewModel.addingCaptableSecurity`,
  `_viewModel.addingTransaction`,
  `_viewModel.addingDeal`,
  `_viewModel.addingRound`,
  `_viewModel.companyLists`,
  `_viewModel.companyProfile`,
]);
