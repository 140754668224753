import { endOfToday, sub } from 'date-fns';
import { atom, selector } from 'recoil';
import { sectorsAtom } from '../../../services/state/AppConfigStateJ';
import { getForesightStore } from '../../../util/jotai-store';
import { dealBoardConfigState } from './DealboardDataState';

export enum GroupByOptions {
  ROUND = 'round',
  DEAL_LEAD = 'dealLead',
  LOCATION = 'location',
  SOURCE_TYPE = 'sourceType',
  SECTOR = 'sector',
  SOURCE_COMPANY = 'sourceCompany',
  INVESTMENT_STATUS = 'investmentStatus',
}

export const GroupByFilterState = atom<GroupByOptions>({
  key: 'groupByFilterState',
  default: GroupByOptions.ROUND,
});

export enum PresetPeriods {
  L1M = 1,
  L3M = 3,
  L12M = 12,
}

export const toDateFilterStateDA = atom<Date>({
  key: 'toDateFilterStateDA',
  default: endOfToday(),
});

export const monthsBackState = atom<PresetPeriods>({
  key: 'monthsBackState',
  default: PresetPeriods.L1M,
});

export const fromDateFilterStateDA = selector<Date>({
  key: 'fromDateFilterStateDA',
  get: ({ get }) => {
    const period = get(monthsBackState);
    const toDate = get(toDateFilterStateDA);

    if (period === PresetPeriods.L1M) {
      return sub(toDate, { months: 1 });
    } else if (period === PresetPeriods.L3M) {
      return sub(toDate, { months: 3 });
    } else if (period === PresetPeriods.L12M) {
      return sub(toDate, { years: 1 });
    } else {
      throw new Error('Invalid period');
    }
  },
});

export const dealTypeFilterStateDA = atom<number>({
  key: 'dealTypeFilterStateDA',
  default: selector<number>({
    key: 'dealTypeFilterStateDefault',
    get: ({ get }) => {
      const dealTypes = get(dealBoardConfigState).dealTypes;
      return dealTypes.find((dealType) => dealType.name === 'New Deals')!.id;
    },
  }),
});

export const sectorFilterState = atom<number[]>({
  key: 'sectorsFilterState',
  default: selector<number[]>({
    key: 'sectorsFilterStateDefault',
    get: () => {
      const store = getForesightStore();
      const allSectors = store.get(sectorsAtom);
      return allSectors.map((sector) => sector.id);
    },
  }),
});

export const dealLeadFilterState = atom<number | null>({
  key: 'dealLeadFilterState',
  default: null,
});
