import { useCallback } from 'react';
import { useRecoilCallback } from 'recoil';
import { useNavigate } from 'react-router';
import { ICaptableDataModel } from '../../../data-models/captable2.data-model';
import {
  createCaptable,
  deleteCaptable,
  duplicateCaptable,
  makeCaptablePrimary,
  updateCaptable,
} from '../../../services/queries/MaggieCapTableQueries';
import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import { getErrorMessage } from '../../../services/queryHelpers';
import { fromCaptableDataModel } from '../../../view-models/captable.view-model';
import { ROUTES } from '../../../constants/RouteNameMapping';
import { clientInvestmentsState, otherInvestmentsState, shareClassesState } from './CapTableFormState';
import { CaptableIdParam } from './useSyncCaptableFormFromPath';
import { useInvalidateCaptables } from './useInvalidateCaptables';

export function usePreviewCaptable() {
  const { pushErrorToast } = useToastMessageState();

  return useCallback(
    async (payload: Partial<ICaptableDataModel>) => {
      if (!payload.companyId) {
        pushErrorToast({ message: 'Missing company ID' });
        return;
      }
      try {
        const data = await createCaptable(payload, true);
        return data;
      } catch (err) {
        console.error(err);
        const message = getErrorMessage(err, 'Failed to preview cap table');
        pushErrorToast({ message });
      }
    },
    [pushErrorToast]
  );
}

export function useCaptableActions() {
  const { pushErrorToast } = useToastMessageState();
  const invalidateCaptables = useInvalidateCaptables();

  const createCaptableHandler = useCallback(
    async (companyId: number, payload: Partial<ICaptableDataModel>) => {
      if (!payload.companyId) {
        pushErrorToast({ message: 'Missing company ID' });
        return;
      }
      try {
        const data = await createCaptable(payload, false);
        invalidateCaptables(companyId, data.id);
        return data;
      } catch (err) {
        console.error(err);
        const message = getErrorMessage(err, 'Failed to create cap table');
        pushErrorToast({ message });
      }
    },
    [invalidateCaptables, pushErrorToast]
  );

  const updateCaptableHandler = useCallback(
    async (payload: Partial<ICaptableDataModel>) => {
      if (!payload.id || !payload.companyId) {
        pushErrorToast({ message: 'Missing id or companyId' });
        return;
      }
      try {
        const data = await updateCaptable(payload);
        invalidateCaptables(payload.companyId, data.id);
        return data;
      } catch (err) {
        console.error(err);
        const message = getErrorMessage(err, 'Failed to update cap table');
        pushErrorToast({ message });
      }
    },
    [invalidateCaptables, pushErrorToast]
  );

  const deleteCaptableHandler = useCallback(
    async (captable: ICaptableDataModel) => {
      try {
        await deleteCaptable(captable.id);
        invalidateCaptables(captable.companyId);
      } catch (err) {
        console.error(err);
        const message = getErrorMessage(err, 'Failed to delete cap table');
        pushErrorToast({ message });
      }
    },
    [invalidateCaptables, pushErrorToast]
  );

  return { createCaptableHandler, updateCaptableHandler, deleteCaptableHandler };
}

export function useEditCaptable() {
  const navigate = useNavigate();

  return useCallback(
    (captable: ICaptableDataModel) => {
      const { id } = captable;

      navigate(`${ROUTES.CAP_TABLE_FORM}?${CaptableIdParam}=${id}`);
    },
    [navigate]
  );
}

export function useSetCaptableFormState() {
  return useRecoilCallback(
    ({ set }) =>
      (captable: ICaptableDataModel) => {
        const viewModel = fromCaptableDataModel(captable);
        set(shareClassesState, viewModel._viewModel.shareClasses);
        set(clientInvestmentsState, viewModel._viewModel.clientInvestments);
        set(otherInvestmentsState, viewModel._viewModel.otherInvestments);
      },
    []
  );
}

export function useMakeCaptablePrimary() {
  const { pushErrorToast } = useToastMessageState();
  const invalidateCaptables = useInvalidateCaptables();

  return useCallback(
    async (id: number) => {
      try {
        const data = await makeCaptablePrimary(id);
        invalidateCaptables(data.companyId, data.id);
      } catch (err) {
        console.error(err);
        const message = getErrorMessage(err, 'Failed to make cap table primary');
        pushErrorToast({ message });
      }
    },
    [invalidateCaptables, pushErrorToast]
  );
}

// we need to await both delete and make primary before invalidating the cache, hence the separate hook
export function useMakeOtherPrimaryAndDelete() {
  const { pushErrorToast } = useToastMessageState();
  const invalidateCaptables = useInvalidateCaptables();

  return useCallback(
    async (newPrimaryId: number, toDeleteId: number) => {
      try {
        const data = await makeCaptablePrimary(newPrimaryId);
        await deleteCaptable(toDeleteId);
        invalidateCaptables(data.companyId, data.id);
      } catch (err) {
        console.error(err);
        const message = getErrorMessage(err, 'Failed to update/delete cap table');
        pushErrorToast({ message });
      }
    },
    [invalidateCaptables, pushErrorToast]
  );
}

export function useDuplicateCaptable() {
  const { pushErrorToast } = useToastMessageState();
  const invalidateCaptables = useInvalidateCaptables();

  return useCallback(
    async (captableId: number) => {
      try {
        const data = await duplicateCaptable(captableId);
        invalidateCaptables(data.companyId, data.id);
      } catch (err) {
        console.error(err);
        const message = getErrorMessage(err, 'Failed to duplicate cap table');
        pushErrorToast({ message });
      }
    },
    [invalidateCaptables, pushErrorToast]
  );
}
