import { FC } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { useNavigate } from 'react-router';
import { useRecoilValue } from 'recoil';
import { ROUTES } from '../../../constants/RouteNameMapping';
import { IRoundReconDataModel } from '../../../data-models/finance-round-recon.data-model';
import { companyState } from '../../../services/state/CompanyState';
import { LinkButtonRenderer } from './LinkButtonRenderer';

export const RoundsByCompanyLink: FC<ICellRendererParams<IRoundReconDataModel | undefined>> = ({ node }) => {
  const id = node?.data?.round?.companyId;
  const company = useRecoilValue(companyState(id ?? -1));
  const navigate = useNavigate();
  if (!id) return null;

  return (
    <LinkButtonRenderer
      onClick={() =>
        navigate(
          `/${ROUTES.FINANCE2}/${ROUTES.FINANCE2_OVERVIEW}/${ROUTES.FINANCE_DETAILS}/${ROUTES.FINANCE_ROUNDS}?company=${id}`,
          {}
        )
      }
      title={`View ${company?.name}'s rounds`}
    />
  );
};
