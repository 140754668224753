import { useAtomValue } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { createCompanyDataModel, CustomDataType } from '../../../data-models/company.data-model';
import { RendererType } from '../../../data-models/field.data-model';
import { field2ToFormField, FieldEntity } from '../../../data-models/field2.data-model';
import {
  createMetricsDataModel,
  createMetricsFundDataModel,
  createMetricsResponseDataModel,
} from '../../../data-models/metrics.data-model';
import { createMetricsTransactionDataModel } from '../../../schemas/MetricsTransaction.schema';
import { customFieldsByEntity } from '../../../services/state/AdminPanel/CustomFieldsState';
import { fundsAtom, usersAtom } from '../../../services/state/AppConfigStateJ';
import { companyMetricsByIdState } from '../../../services/state/CompanyMetricsByIdState';
import { companyState } from '../../../services/state/CompanyState';

export const DummyCompanyId = -100;

export function useDummyCompanyData() {
  const customFields = useRecoilValue(customFieldsByEntity);
  const users = useAtomValue(usersAtom);

  return useMemo(() => {
    const threeUsers = users.slice(0, 3).map((user) => user?.id ?? -1);
    const customDataFields =
      customFields.get(FieldEntity.company)?.map((field) => field2ToFormField(field)) ?? [];
    const customDataValuesMap = customDataFields.reduce(
      (acc, field) => {
        const key = field.key?.split('.')[1];
        if (!key) return acc;
        acc[key] = getDummyDataForRendererType(field.renderer);
        return acc;
      },
      {} as Record<string, unknown>
    );
    return createCompanyDataModel({
      id: DummyCompanyId,
      dealLeadId: threeUsers[0],
      internalSourceId: threeUsers[1],
      dealTeamIds: [threeUsers[2]],
      boardDirectorId: threeUsers[1],
      customData: { ...customDataValuesMap } as Record<string, CustomDataType>,
    });
  }, [customFields, users]);
}

function getDummyDataForRendererType(rendererType: RendererType) {
  switch (rendererType) {
    case RendererType.text:
      return '-';
    case RendererType.singleSelect:
      return '-';
    case RendererType.multiSelect:
      return [];
    case RendererType.number:
      return 1000;
    case RendererType.percent:
      return 0;
    case RendererType.currency:
      return 0;
    case RendererType.date:
      return '2024-02-01';
    case RendererType.colorStatus:
      return 'green';
    default:
      return '-';
  }
}

export function useDummyMetricsData() {
  const customFields = useRecoilValue(customFieldsByEntity);
  const customDataFields =
    customFields.get(FieldEntity.fundCompany)?.map((field) => field2ToFormField(field)) ?? [];
  const customDataValuesMap = customDataFields.reduce(
    (acc, field) => {
      const key = field.key?.split('.')[1];
      if (!key) return acc;
      acc[key] = getDummyDataForRendererType(field.renderer);
      return acc;
    },
    {} as Record<string, unknown>
  );

  const funds = useAtomValue(fundsAtom);
  const fundId = funds.at(0)?.id;
  return useMemo(() => {
    return createMetricsDataModel({
      companyId: DummyCompanyId,
      transactions: [createMetricsTransactionDataModel({ fundId, companyId: DummyCompanyId })],
      fundData: [
        createMetricsFundDataModel({
          amountInvested: 13012500.27,
          companyId: DummyCompanyId,
          customData: { ...customDataValuesMap } as Record<string, CustomDataType>,
          fmv: 5961518.329999988,
          fundId,
          id: 611,
          impliedEquityValue: 54800.67040184573,
          initialInvestmentRound: 'Acorns Grow, Inc. (Bridge Convertible Note 2015-01)',
          initialPreMoney: 66000000,
          irr: 10.25,
          moic: 0.46,
          noOfShares: 217571,
          ownerShipPercentage: 80.55,
          projectedMOIC: 0,
          projectedProceeds: 195813900000,
          reserves: 0,
        }),
      ],
    });
  }, [customDataValuesMap, fundId]);
}

export function useLoadDummyData() {
  const [isReady, setIsReady] = useState(false);
  const setDummyCompany = useSetRecoilState(companyState(DummyCompanyId));
  const setDummyMetrics = useSetRecoilState(companyMetricsByIdState(DummyCompanyId));
  const dummyCompany = useDummyCompanyData();
  const dummyMetrics = useDummyMetricsData();

  useEffect(() => {
    setDummyCompany(dummyCompany);
    setDummyMetrics(createMetricsResponseDataModel({ metrics: [dummyMetrics], included: [dummyCompany] }));
    setIsReady(true);
  }, [dummyCompany, dummyMetrics, setDummyCompany, setDummyMetrics]);

  return isReady;
}
