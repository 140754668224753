import { useMemo } from 'react';
import { AgTable } from '../../../../components/AgTable/AgTable';
import { ICaptableDataModel } from '../../../../data-models/captable2.data-model';
import { isClientInvestment } from '../captable-utils';
import { ICaptableSummaryRowData, useCaptableSummaryRowData } from './CaptableSummaryRowData';
import { CaptableSummaryDefaultColDefs, useCaptableSummaryColDefs } from './CaptableSummaryColDefs';
import { CaptableGridContainer } from './CaptableGridContainer';

export enum StakeholderType {
  client = 'Client',
  external = 'External',
}

export interface ICaptableSummaryGridProps {
  data: ICaptableDataModel;
  quickFilterText?: string;
  investmentFilter?: StakeholderType;
}

export function CaptableSummaryGrid({ data, quickFilterText, investmentFilter }: ICaptableSummaryGridProps) {
  const captableRowData = useCaptableSummaryRowData(data ?? null);
  const rowData = useMemo(() => {
    if (investmentFilter) {
      return filterOutClientInvestments(captableRowData, investmentFilter);
    }
    return captableRowData;
  }, [captableRowData, investmentFilter]);

  const colDefs = useCaptableSummaryColDefs(data ?? null);

  return (
    <CaptableGridContainer style={{ height: '30rem', maxHeight: '30rem' }}>
      <AgTable
        quickFilterText={quickFilterText}
        rowData={rowData}
        columnDefs={colDefs}
        grandTotalRow='bottom'
        rowGroupPanelShow='never'
        defaultColDef={CaptableSummaryDefaultColDefs}
      />
    </CaptableGridContainer>
  );
}

export function filterOutClientInvestments(
  data: ICaptableSummaryRowData[],
  investmentFilter: StakeholderType
): ICaptableSummaryRowData[] {
  return data.filter((row) => {
    if (investmentFilter === StakeholderType.client) {
      return isClientInvestment(row);
    } else if (investmentFilter === StakeholderType.external) {
      return !isClientInvestment(row);
    } else {
      return true;
    }
  });
}
