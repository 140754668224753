import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { Option } from '../../../components/MuiSingleSelect/MuiSingleSelect';
import { roundsAtom } from '../../../services/state/AppConfigStateJ';

export const useRoundSelector = (showTBD = false) => {
  const roundsList = useAtomValue(roundsAtom);

  const roundsOptions: Option[] = useMemo(() => {
    if (!roundsList) return [];

    const rounds = roundsList?.map((sRound) => ({
      id: sRound.id,
      value: sRound.name,
    }));

    const TBD = { id: null, value: 'TBD' };

    return showTBD ? [TBD, ...rounds] : rounds;
  }, [roundsList, showTBD]);

  return {
    roundsList,
    roundsOptions,
  };
};
