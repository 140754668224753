import { css } from '@emotion/react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Link as MuiLink, Stack, Typography } from '@mui/material';
import { GridApi, GridReadyEvent, RowSelectionOptions, SelectionChangedEvent } from 'ag-grid-community';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router';
import { useRecoilValue } from 'recoil';
import { AgTable } from '../../../../components/AgTable/AgTable';
import { ExportGridButton } from '../../../../components/AgTable/ExportGridButton';
import { useExtendedExportSettings } from '../../../../components/AgTable/exportToExcelDefs';
import { multiRowSelectionDefs } from '../../../../components/AgTable/rowSelectionDefs';
import { FadeInGridWrapper } from '../../../../components/grid-renderers/FadeInGridWrapper';
import { ROUTES } from '../../../../constants/RouteNameMapping';
import { IKPIRequestDataModel, KPIRequestStatus } from '../../../../data-models/kpi-requests.data-model';
import { filterKPIRequestsByStatuses } from '../../../../services/state/KPI/KPIRequestsState';
import { cardStyles } from '../../../../theme/component-styles';
import { MaggieFeatureFlags } from '../../../../util/feature-flags';
import { BulkActionButtons } from './BulkAcceptResponse';
import { ExportButtonContainer } from './components/KpiStyleDefs';
import useKPIResponsesColDefs from './hooks/useKPIRequestsResponsesColDefs';
import { useKPIRequestResponseActions } from './KPIRequestResponse/hooks/useKPIRequestResponseActions';
import { RequestConfirmDialog } from './KPIRequestResponse/RequestConfirmDialog';

export function KPIResponsesGrid({ status }: { status: KPIRequestStatus }) {
  const responses: IKPIRequestDataModel[] | null =
    useRecoilValue(filterKPIRequestsByStatuses([status])) ?? null;
  const { columnDefs, defaultColDef } = useKPIResponsesColDefs(status);

  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [selectedItems, setSelectedItems] = useState<IKPIRequestDataModel[]>([]);
  const { exportParams } = useExtendedExportSettings(
    new Set(['companyId', 'templateUuid']),
    `${status} kpi responses`
  );

  const onSelectionChanged = useCallback((e: SelectionChangedEvent) => {
    setSelectedItems(e.api.getSelectedRows());
  }, []);

  const { handleBulkAccept } = useKPIRequestResponseActions();
  const [loading, setLoading] = useState(false);

  const onBulkAccept = useCallback(async () => {
    setLoading(true);
    await handleBulkAccept(selectedItems.map((r) => r.id));
    setLoading(false);
    gridApi?.deselectAll();
    setShowConfirmDialog(false);
  }, [gridApi, handleBulkAccept, selectedItems]);

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const onGridReady = useCallback((event: GridReadyEvent) => {
    setGridApi(event.api);
  }, []);

  const handleExport = useCallback(() => {
    gridApi?.exportDataAsExcel();
  }, [gridApi]);

  const selection: RowSelectionOptions | undefined = useMemo(() => {
    return status === KPIRequestStatus.Submitted ? multiRowSelectionDefs : undefined;
  }, [status]);

  const { showDataCollectionQualitativeResponses } = useFlags<MaggieFeatureFlags>();

  return (
    <Stack height={'100%'}>
      {showDataCollectionQualitativeResponses && status === KPIRequestStatus.Accepted ? (
        <Stack
          display={'grid'}
          gridTemplateColumns={'1fr auto'}
          alignItems={'center'}
          gap={'0.5rem'}
          mb={'1rem'}
        >
          <LinkToResponseAnalysis />
          <Box mt='0.5rem'>
            <ExportGridButton onClick={handleExport} />
          </Box>
        </Stack>
      ) : (
        <div css={ExportButtonContainer}>
          <ExportGridButton onClick={handleExport} />
        </div>
      )}

      {selectedItems.length > 0 && (
        <BulkActionButtons
          onAction={() => setShowConfirmDialog(true)}
          onCancel={() => gridApi?.deselectAll()}
          actionLabel={`Accept Selected (${selectedItems.length})`}
        />
      )}
      {showConfirmDialog && (
        <RequestConfirmDialog
          open={showConfirmDialog}
          onClose={() => {
            setShowConfirmDialog(false);
            gridApi?.deselectAll();
          }}
          requests={selectedItems}
          onConfirm={onBulkAccept}
          loading={loading}
          title='Confirm Bulk Accept'
          message="Please confirm you want to accept all selected responses. Accepted data will reflect in the company's financials."
        />
      )}

      <FadeInGridWrapper key={status}>
        <AgTable
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          groupDefaultExpanded={-1}
          onGridReady={onGridReady}
          onSelectionChanged={onSelectionChanged}
          rowData={responses}
          suppressMovableColumns={true}
          defaultCsvExportParams={exportParams}
          defaultExcelExportParams={exportParams}
          rowSelection={selection}
        />
      </FadeInGridWrapper>
    </Stack>
  );
}

const LinkToAnalysis = css({
  ...cardStyles,
  border: 'none',
  marginBottom: '-0.5rem',
  paddingBlock: '0.75rem',
  paddingInline: '0.5rem',
});

function LinkToResponseAnalysis() {
  return (
    <Stack css={LinkToAnalysis} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
      <Typography
        variant='body2'
        color='text.secondary'
        display={'flex'}
        alignItems={'center'}
        gap={'0.2rem'}
      >
        <InfoOutlinedIcon fontSize='small' /> Analyze all qualitative responses in one place
      </Typography>
      <MuiLink
        component={Link}
        to={`./${ROUTES.KPI_RESPONSES_ANALYSIS}`}
        fontSize={'0.875rem'}
        underline='hover'
        color='secondary'
      >
        Analyze
      </MuiLink>
    </Stack>
  );
}
