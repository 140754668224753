import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { IMetricsDataModel } from '../../../data-models/metrics.data-model';
import { getMetricsWithCompany } from '../../../services/queries/MaggieMetricsQueries';
import {
  fundsByIdMapAtom,
  metricsConfigAtom,
  roundsByIdMapAtom,
} from '../../../services/state/AppConfigStateJ';
import { companyMetricsByIdState } from '../../../services/state/CompanyMetricsByIdState';
import { MetricsCalculator } from '../../PortfolioOverview/components/OverviewTable/MetricsCalculator';
import { useGetCompanyIfSet } from './useGetCompanyData';

export interface IInvestmentsMeta {
  isLoading: boolean;
  companyMetrics?: IMetricsDataModel;
  data: IMetricsDataModel[];
}

export const useCompanyInvestments = (companyId: number): IInvestmentsMeta => {
  const companyMetricsLoadable = useRecoilValueLoadable(companyMetricsByIdState(companyId));
  const companyMetrics = companyMetricsLoadable.valueMaybe();
  const fundsMap = useAtomValue(fundsByIdMapAtom);
  const roundsMap = useAtomValue(roundsByIdMapAtom);
  const metricsConfig = useAtomValue(metricsConfigAtom);
  const getCompany = useGetCompanyIfSet();

  const filteredData = useMemo(() => {
    if (!companyMetrics?.metrics.length || !companyMetrics?.included.length) {
      return [];
    }

    const companyData = getMetricsWithCompany(companyMetrics?.metrics[0], companyMetrics) ?? {};
    const transactions = companyMetrics?.metrics[0].transactions ?? [];
    const fundData = companyMetrics?.metrics[0].fundData ?? [];

    if (transactions.length === 0) {
      return [];
    }

    return fundData.map(({ fundId: id }) => {
      const filteredTransactions = transactions.filter(({ fundId }) => fundId === id);
      return { ...companyData, transactions: filteredTransactions };
    });
  }, [companyMetrics]);

  return useMemo(() => {
    if (!filteredData)
      return {
        isLoading: companyMetricsLoadable.state === 'loading',
        companyMetrics: companyMetrics?.metrics[0],
        data: [],
      };

    const metricsCalc = new MetricsCalculator();

    return {
      isLoading: companyMetricsLoadable.state === 'loading',
      companyMetrics: companyMetrics?.metrics[0],
      data: filteredData.map((data) => {
        const [calculatedVal] = metricsCalc.recalculateKpis(
          fundsMap,
          roundsMap,
          [{ ...data, companyId: data.companyId }],
          new Date(),
          true,
          getCompany,
          metricsConfig,
          [],
          false
        );
        return calculatedVal as IMetricsDataModel;
      }),
    };
  }, [
    filteredData,
    companyMetricsLoadable.state,
    companyMetrics?.metrics,
    fundsMap,
    roundsMap,
    getCompany,
    metricsConfig,
  ]);
};
