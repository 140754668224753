import { atom, useResetRecoilState } from 'recoil';
import { SelectItem } from '../../../types';
import { defaultDateRange } from './useAllTransactionsData';

export const fundFilterState = atom<SelectItem[] | null>({
  key: 'fundFilterState',
  default: null,
});

export const transactionTypeFilterState = atom<SelectItem[] | null>({
  key: 'transactionTypeFilterState',
  default: null,
});

export const transactionsDateFilterState = atom<{ fromDate: string; toDate: string }>({
  key: 'transactionsDateFilterState',
  default: {
    fromDate: defaultDateRange().fromDate,
    toDate: defaultDateRange().toDate,
  },
});

export function useResetTransactionFilters() {
  const resetFundFilter = useResetRecoilState(fundFilterState);
  const resetTransactionTypeFilter = useResetRecoilState(transactionTypeFilterState);
  const resetTransactionsDateFilter = useResetRecoilState(transactionsDateFilterState);

  return () => {
    resetFundFilter();
    resetTransactionTypeFilter();
    resetTransactionsDateFilter();
  };
}
