import { styled } from '@mui/material/styles';
import { useFormik } from 'formik';
import { useAtomValue } from 'jotai';
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { FieldGroup } from '../../../../../components/FieldGroup/FieldGroup';
import { FormField } from '../../../../../components/FormField/FormField';
import { NumericInput } from '../../../../../components/Inputs/NumericInput';
import { LabelInput } from '../../../../../components/LabelInput/LabelInput';
import { Loader } from '../../../../../components/Loader/Loader';
import { MuiMultiselect } from '../../../../../components/MuiMultiselect/MuiMultiselect';
import { MuiSingleSelect, Option } from '../../../../../components/MuiSingleSelect/MuiSingleSelect';
import { IDealDataModel } from '../../../../../data-models/deal.data-model';
import { useDealBoardConfig } from '../../../../../services/queries-hooks/MaggieDealQueriesHooks';
import { activeUsersAtom, fundsAtom } from '../../../../../services/state/AppConfigStateJ';
import { DealTypeSelect } from '../../../../Finance/form-components/DealTypeSelect';
import { useRoundSelector } from '../../../../Finance/hooks/useRoundSelector';
import { useLabelActions } from '../../../hooks/useLabelActions';
import { useDeal } from '../../../providers/DealProvider';
import { dealLabelsState } from '../../../state/DealboardDataState';
import { dealInitialValues as initialValues } from './dealInitialValues';
import { DealSchema as validationSchema } from './DealSchema';

const Container = styled('div')``;

const FieldWrapper = styled('div')`
  margin-bottom: 15px;
`;

const FormWrapper = styled('form')`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  background-color: ${({ theme }) => theme.colors.primary[0]};
  border: 1px solid ${({ theme }) => theme.colors.primary[20]};
`;

const DoubleFieldWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const GcMinInputWrapper = styled('div')`
  width: 56%;
`;

const GcMaxInputWrapper = styled('div')`
  width: 41%;
`;

export const DealForm = () => {
  const { setDealInfo, setIsDealFormValid, dealInfo } = useDeal();
  const { data: dealBoardConfigData, isLoading: isLoadingDealBoardConfig } = useDealBoardConfig();
  const users = useAtomValue(activeUsersAtom).map((u) => ({ id: u.id, value: u.name }));
  const { roundsOptions } = useRoundSelector(true);
  const funds = useAtomValue(fundsAtom);
  const [selectedFunds, setSelectedFunds] = useState<Option[]>([]);

  const labelsLoadable = useRecoilValueLoadable(dealLabelsState);
  const allLabels = labelsLoadable.valueMaybe();

  const labelOptions = useMemo(() => {
    if (!allLabels || !allLabels.length) return [];
    return allLabels.map((l) => ({ id: l.name, value: l.name }));
  }, [allLabels]);
  const { handleCreateLabel: createAndUpdateLabels } = useLabelActions();

  const sortedFundOptions: { id: number; value: string }[] = useMemo(() => {
    if (!funds) return [];
    return [...funds]
      .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }))
      .map(({ id, name }) => ({ id, value: name }));
  }, [funds]);

  const { handleSubmit, values, setFieldValue, errors, touched, setTouched, isValid } = useFormik<
    Partial<IDealDataModel>
  >({
    initialValues: dealInfo ? dealInfo : initialValues,
    validationSchema,
    onSubmit: (val) => {
      setDealInfo(val);
    },
  });

  const onChangeDealLead = useCallback(
    (e: SyntheticEvent, newVal: Option | null) => {
      const user = users?.find((u) => u.id === newVal?.id);
      setFieldValue('dealLeadId', user?.id);
    },
    [setFieldValue, users]
  );

  const onChangeDealTeam = useCallback(
    (e: SyntheticEvent, vals: Option[] | undefined) => {
      if (!vals) {
        setFieldValue('dealTeamIds', []);
        return;
      }
      setFieldValue(
        'dealTeamIds',
        vals.map((v) => v.id)
      );
    },
    [setFieldValue]
  );
  const onChangeLabels = useCallback(
    (vals: string[]) => {
      setFieldValue('labels', vals);
    },
    [setFieldValue]
  );

  const onCreateLabel = useCallback(
    async (val: string) => {
      const currentLabels = values?.labels;
      onChangeLabels([...(currentLabels ?? []), val]);
      const newLabel = await createAndUpdateLabels(val);
      if (!newLabel) onChangeLabels(currentLabels ?? []);
    },
    [createAndUpdateLabels, onChangeLabels, values?.labels]
  );

  const handleDisplayError = (key: keyof typeof initialValues) => {
    return touched[key] ? errors[key] : '';
  };

  useEffect(() => {
    setFieldValue(
      'fundIds',
      selectedFunds.map(({ id }) => id)
    );
  }, [selectedFunds, setFieldValue]);

  useEffect(() => {
    setDealInfo(values);
  }, [setDealInfo, values]);

  useEffect(() => {
    setIsDealFormValid(isValid);
  }, [isValid, setIsDealFormValid]);

  if (!users || !dealBoardConfigData || isLoadingDealBoardConfig) return <Loader height={'30rem'} />;

  return (
    <Container>
      <FormWrapper onSubmit={handleSubmit}>
        <FieldGroup hasBorder={false} label='General Information'>
          <FieldWrapper>
            <FormField
              required
              label={'Deal Type'}
              error={handleDisplayError('dealTypeId')}
              labelWidth='12rem'
            >
              <DealTypeSelect
                value={values.dealTypeId ?? null}
                onChange={(val) => setFieldValue('dealTypeId', val)}
                onFocus={() => setTouched({ ...touched, dealTypeId: true })}
              />
            </FormField>
          </FieldWrapper>
          <FormField required label={'Round'} error={handleDisplayError('roundId')} labelWidth='12rem'>
            <MuiSingleSelect
              disablePortal
              options={roundsOptions}
              value={roundsOptions?.find((sround) => sround.id === values.roundId) ?? null}
              onChange={(_, val) => setFieldValue('roundId', val?.id)}
              fieldName='roundId'
              onFocus={() => setTouched({ ...touched, roundId: true })}
              fieldPlaceholder='Select Round'
              disableClearable
            />
          </FormField>
          <FormField label={'Funds'} error={handleDisplayError('fundIds')} labelWidth='12rem'>
            <MuiMultiselect
              options={sortedFundOptions}
              value={selectedFunds}
              onChange={(_, val) => {
                setSelectedFunds(val as Option[]);
              }}
              disablePortal
              onFocus={() => setTouched({ ...touched, fundIds: true })}
              fieldName={'fundIds'}
            />
          </FormField>
          <FormField required label={'Deal Lead'} error={handleDisplayError('dealLeadId')} labelWidth='12rem'>
            <MuiSingleSelect
              options={users}
              value={users.find((u) => u.id === values.dealLeadId) ?? null}
              onChange={onChangeDealLead}
              disablePortal
              onFocus={() => setTouched({ ...touched, dealLeadId: true })}
              fieldPlaceholder='Select Deal Lead'
              fieldName='dealLeadId'
            />
          </FormField>
          <FormField label={'Deal Team'} error={handleDisplayError('dealTeamIds')} labelWidth='12rem'>
            <MuiMultiselect
              options={users}
              fieldName={'dealTeamIds'}
              value={users.filter((u) => values?.dealTeamIds?.includes(u.id)) ?? []}
              onChange={onChangeDealTeam}
              onFocus={() => setTouched({ ...touched, dealTeamIds: true })}
              fieldPlaceholder='Select Deal Team'
              fullWidth
              disablePortal
            />
          </FormField>
        </FieldGroup>
        <FieldGroup label='Financial Information'>
          <FormField label={`Raise Amount`} error={handleDisplayError('raiseAmount')} labelWidth='12rem'>
            <NumericInput
              name={'raiseAmount'}
              value={values.raiseAmount}
              onChange={(e) => {
                setFieldValue('raiseAmount', e.target.value ? parseFloat(e.target.value) : null);
              }}
              prefix='$'
              onBlur={() => setTouched({ ...touched, raiseAmount: true })}
            />
          </FormField>
          <DoubleFieldWrapper>
            <GcMinInputWrapper>
              <FormField label={`GC Amount`} error={handleDisplayError('gcAmountMin')} labelWidth='248px'>
                <NumericInput
                  name={'gcAmountMin'}
                  value={values.gcAmountMin}
                  onChange={(e) => {
                    setFieldValue('gcAmountMin', e.target.value ? parseFloat(e.target.value) : null);
                  }}
                  prefix='$'
                  onBlur={() => setTouched({ ...touched, gcAmountMin: true })}
                />
              </FormField>
            </GcMinInputWrapper>
            <GcMaxInputWrapper>
              <FormField label={`to`} error={handleDisplayError('gcAmountMax')} labelWidth='2.3rem'>
                <NumericInput
                  name={'gcAmountMax'}
                  value={values.gcAmountMax}
                  onChange={(e) => {
                    setFieldValue('gcAmountMax', e.target.value ? parseFloat(e.target.value) : null);
                  }}
                  prefix='$'
                  onBlur={() => setTouched({ ...touched, gcAmountMax: true })}
                />
              </FormField>
            </GcMaxInputWrapper>
          </DoubleFieldWrapper>
          <FormField label={`Total PIC`} error={handleDisplayError('totalPic')} labelWidth='12rem'>
            <NumericInput
              name={'totalPic'}
              value={values.totalPic}
              onChange={(e) => {
                setFieldValue('totalPic', e.target.value ? parseFloat(e.target.value) : null);
              }}
              prefix='$'
              onBlur={() => setTouched({ ...touched, totalPic: true })}
            />
          </FormField>
        </FieldGroup>
        <FieldGroup labelMargin={'0'}>
          <FormField label={`Labels`} error={handleDisplayError('labels')} labelWidth='12rem'>
            <LabelInput
              options={labelOptions}
              onCreateLabel={onCreateLabel}
              fieldName='labels'
              value={values?.labels ?? []}
              onChange={onChangeLabels}
              onFocus={() => setTouched({ ...touched, labels: true })}
              fieldPlaceholder='Add Deal Labels'
              disablePortal
              loading={labelsLoadable.state === 'loading'}
            />
          </FormField>
        </FieldGroup>
      </FormWrapper>
    </Container>
  );
};
