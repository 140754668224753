import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import { IUpdateFundFields, updateFund } from '../../../services/queries/MaggieFundQueries';
import { getPlainDateString } from '../../../services/queries/MaggieMetricsQueries';
import { getErrorMessage } from '../../../services/queryHelpers';
import { fundsAtom } from '../../../services/state/AppConfigStateJ';
import { useInvalidateFundMetrics } from '../Waterfall/useInvalidateFundMetrics';
import { selectedDateFPState } from './FPState';

export function useUpdateFund() {
  const { pushErrorToast } = useToastMessageState();
  const invalidateFundMetrics = useInvalidateFundMetrics();
  const selectedDate = useAtomValue(selectedDateFPState);
  const setFunds = useSetAtom(fundsAtom);

  return useCallback(
    async (fund: IUpdateFundFields) => {
      try {
        const updatedFund = await updateFund(fund);
        setFunds((curr) => curr.map((f) => (f.id === updatedFund.id ? updatedFund : f)));
        await invalidateFundMetrics(getPlainDateString(selectedDate), updatedFund.id);
        return updatedFund;
      } catch (err) {
        console.error(err);
        pushErrorToast({ message: getErrorMessage(err, 'Failed to update fund') });
      }
    },
    [setFunds, invalidateFundMetrics, selectedDate, pushErrorToast]
  );
}
