import { CustomCellRendererProps } from 'ag-grid-react';
import { Link } from 'react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Stack, Typography } from '@mui/material';
import { IFundMetricsByFund } from '../../../../../../../data-models/fund-metrics.data-model';
import { ROUTES } from '../../../../../../../constants/RouteNameMapping';
import { FMT } from '../../../../../../../util/formatter-service';
import { MaggieFeatureFlags } from '../../../../../../../util/feature-flags';

export function FundProfileLinkRenderer(params: CustomCellRendererProps<IFundMetricsByFund, number>) {
  const { showFundProfiles } = useFlags<MaggieFeatureFlags>();
  const { value } = params;
  if (!value) return null;

  const fundName = FMT.format('fund', value);
  if (!showFundProfiles) {
    return (
      <Stack direction={'row'} alignItems={'center'} height={'100%'}>
        <Typography variant='body2'>{fundName}</Typography>
      </Stack>
    );
  }
  const url = `/${ROUTES.FUND_PROFILES}/${value}`;
  return <Link to={url}>{fundName}</Link>;
}
