import { merge } from 'lodash-es';
import * as yup from 'yup';
import { AcquisitionCellRenderer } from '../pages/PortfolioOverview/components/OverviewTable/columns/TypeBasedConfigs/TypeCellRenderers/AcquirerRenderer';

export const CompanySearchResponseSchema = {
  base() {
    return {
      id: yup.string().default(''),
      fields: yup.object().shape({
        name: yup.string().required().default(''),
        logo: yup.string().nullable().default(''),
        website: yup.string().nullable().default(''),
        description: yup.string().nullable().default(''),
        type: yup.string().optional().default(''),
      }),
    };
  },
  formSchema() {
    const { id, ...rest } = this.base();
    return yup.object({ id: id.required(), ...rest });
  },
  gridSchema() {
    return yup.object(this.base()).gridMeta({
      cellRenderer: AcquisitionCellRenderer,
    });
  },
};

export type CompanySearchResponse = yup.InferType<ReturnType<typeof CompanySearchResponseSchema.formSchema>>;

export type CompanySearchResponseWithRealId = Omit<CompanySearchResponse, 'id'> & { id: number };

export function createCompanySearchResponse(
  overrides: Partial<CompanySearchResponse> = {}
): CompanySearchResponse {
  return merge(CompanySearchResponseSchema.formSchema().getDefault(), overrides);
}

export function createCompanySearchResponseWithRealId(
  overrides: Partial<CompanySearchResponse> = {}
): CompanySearchResponseWithRealId {
  return {
    ...createCompanySearchResponse(overrides),
    id: Number(overrides.id ?? -1),
  };
}
