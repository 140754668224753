import { yupResolver } from '@hookform/resolvers/yup';
import OneSchemaImporter from '@oneschema/react';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { FormFactoryWithStandardLayout } from '../../../../components/Form/FormFactory';
import { StickyFormButtons } from '../../../../components/Form/StickyFormButtons';
import { useToastMessageState } from '../../../../components/ToastMessage/ToastMessageProvider';
import { IListDataModel } from '../../../../data-models/list.data-model';
import { useFocusElement } from '../../../../hooks/useFocusElement';
import {
  CompanyListFormData,
  companyListFormSchema,
  toUserCreatedCompanyList,
} from '../../../../schemas/CompanyList.schema';
import { getEnvVariable, isDevelopment } from '../../../../util/environment';
import { schemaToFormFields } from '../../../../util/schema-utils';
import { createFormFromFieldsArray } from '../../../../view-models/form.view-model';
import { useCreateList, useUpdateList } from '../../../../services/hooks/useListActions';

interface ICreateListFormProps {
  onClose: () => void;
  onCreate: (list: IListDataModel) => void;
}

export function CreateListForm({ onClose, onCreate }: ICreateListFormProps) {
  const schema = companyListFormSchema();
  const [showUpload, setShowUpload] = useState(false);
  const [token, setToken] = useState('');
  const [list, setList] = useState<IListDataModel | null>(null);
  const [loading, setLoading] = useState(false);
  const createList = useCreateList();
  const methods = useForm<CompanyListFormData>({
    defaultValues: schema.getDefault(),
    resolver: yupResolver(schema as ObjectSchema<CompanyListFormData>),
  });

  async function onSubmit() {
    const isValid = await methods.trigger();
    if (!isValid) return;
    setLoading(true);
    const res = await createList(
      toUserCreatedCompanyList(schema.cast(methods.getValues()) as CompanyListFormData)
    );
    if (res) {
      setToken(res.token);
      setShowUpload(true);
      setList(res.list);
      //  keep loading true until OneSchemaImporter is closed
    } else {
      setLoading(false);
    }
  }

  return (
    <>
      <FormProvider {...methods}>
        <CompanyListFields />
        <StickyFormButtons
          loading={loading}
          onSubmit={onSubmit}
          submitLabel='Upload List'
          onCancel={onClose}
          style={{ padding: '0.5rem 0 2rem' }}
        />
      </FormProvider>
      <UploadList
        isOpen={showUpload}
        onClose={() => {
          setShowUpload(false);
          onCreate(list as IListDataModel);
          setLoading(false);
          onClose();
        }}
        token={token}
      />
    </>
  );
}

function CompanyListFields() {
  const fields = schemaToFormFields(companyListFormSchema(), [
    'name',
    'sourceType',
    'internalSource',
    'sourceCompany',
    'source',
    'sharing',
  ]);
  const form = createFormFromFieldsArray(fields);
  useFocusElement('company-list-fields', 'input');
  return (
    <div id='company-list-fields'>
      <FormFactoryWithStandardLayout form={form} />
    </div>
  );
}

interface IUploadListProps {
  isOpen: boolean;
  onClose: () => void;
  token: string;
}
function UploadList({ isOpen, onClose, token }: IUploadListProps) {
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();

  if (!token || !isOpen) {
    return null;
  }
  const oneSchemaClientId = getEnvVariable('VITE_ONESCHEMA_CLIENT_ID');
  const webhookKey = getEnvVariable('VITE_ONESCHEMA_WEBHOOK_KEY');

  const onSuccess = () => {
    pushSuccessToast({ message: `Company list uploaded successfully` });
    onClose();
  };

  const onError = () => {
    pushErrorToast({ message: 'An error occurred while uploading the file' });
  };

  return (
    <OneSchemaImporter
      isOpen={isOpen}
      onRequestClose={onClose}
      clientId={oneSchemaClientId}
      userJwt={token}
      templateKey='company_lists_upload'
      importConfig={{ type: 'webhook', key: webhookKey }}
      devMode={isDevelopment()}
      className='oneschema-importer'
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
      }}
      onSuccess={onSuccess}
      onError={onError}
    />
  );
}

interface IEditListFormProps extends Omit<ICreateListFormProps, 'onCreate'> {
  defaultValues: CompanyListFormData & { id: number };
}
export function EditListForm({ defaultValues, onClose }: IEditListFormProps) {
  const schema = companyListFormSchema();
  const updateList = useUpdateList();
  const methods = useForm<CompanyListFormData>({
    defaultValues,
    resolver: yupResolver(schema as ObjectSchema<CompanyListFormData>),
  });

  async function onSubmit() {
    const isValid = await methods.trigger();
    if (!isValid) return;
    await updateList(toUserCreatedCompanyList(schema.cast(methods.getValues()) as IListDataModel));
    onClose();
  }

  return (
    <>
      <FormProvider {...methods}>
        <CompanyListFields />
        <StickyFormButtons
          onSubmit={onSubmit}
          submitLabel='Save Changes'
          onCancel={onClose}
          style={{ padding: '0.5rem 0 2rem' }}
        />
      </FormProvider>
    </>
  );
}
