import { IUserDataModel, UnknownUser } from '../../data-models/user.data-model';
import { IInitializedFormatter } from './IInitializedFormatter';

export class UserFormatter implements IInitializedFormatter<Map<number, IUserDataModel>, number | number[]> {
  #usersById: Map<number, IUserDataModel> = new Map();
  #usersByEmail: Map<string, IUserDataModel> = new Map();

  init(usersById: Map<number, IUserDataModel>) {
    this.#usersById = usersById;
    this.#usersByEmail = [...usersById.values()].reduce((map, user) => {
      map.set(user.email, user);
      return map;
    }, new Map<string, IUserDataModel>());
    return this;
  }

  format = (value: number | number[]) => {
    if (Array.isArray(value)) {
      return value.map(this.#format).join(', ');
    }
    return this.#format(value);
  };

  #format = (value: number) => {
    return this.#usersById.get(value)?.name ?? UnknownUser.name;
  };

  formatByEmail = (value: string | string[]) => {
    if (Array.isArray(value)) {
      return value.map(this.#formatByEmail).join(', ');
    }
    return this.#formatByEmail(value);
  };

  #formatByEmail = (value: string) => {
    return this.#usersByEmail.get(value)?.name ?? value;
  };
}
