import { CellClassParams, ColDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { BooleanRenderer } from '../../../components/AgTable/cell-renderers/BooleanRenderer';
import { RoundFromInvestmentCellRenderer } from '../../../components/AgTable/cell-renderers/RoundCellRenderer';
import { getExcelClassForCurrency } from '../../../components/AgTable/exportToExcelDefs';
import { UNKNOWN_GROUP_NAME } from '../../../components/AgTable/tableConstants';
import { ITransactionDataModel } from '../../../data-models/transaction.data-model';
import {
  currencyMapByIdAtom,
  fundsByIdMapAtom,
  roundsByIdMapAtom,
} from '../../../services/state/AppConfigStateJ';
import { selectedCompanyIdStateFinance } from '../../../services/state/PortCosState';
import { FMT } from '../../../util/formatter-service';
import {
  booleanComparator,
  booleanFormatter,
  genericKeyCreator,
  getCurrencyFormatter,
  getDateColDefs,
  numericColDefs,
} from '../common-grid-defs/commonColDefs';
import { TransactionsMenuCell } from '../common-grid-defs/TransactionsMenuCell';
import { companyRoundsByIdState } from '../state/FinanceState';

export type TColDefKey =
  | keyof ITransactionDataModel
  | 'transactionTypeId'
  | 'transactionSubtype'
  | 'round'
  | 'menu';

export function useTransactionsColDefs() {
  const selectedCompanyId = useRecoilValue(selectedCompanyIdStateFinance);
  const investmentsById = useRecoilValue(companyRoundsByIdState(selectedCompanyId ?? -1));
  const roundsById = useAtomValue(roundsByIdMapAtom);
  const fundsById = useAtomValue(fundsByIdMapAtom);
  const currencyMap = useAtomValue(currencyMapByIdAtom);

  const commonNumericColDefs = numericColDefs as ColDef<ITransactionDataModel>;

  const currencyValueFormatter = useCallback(
    (params: ValueFormatterParams<ITransactionDataModel>) => {
      const currencyCode = currencyMap.get(params.data?.currencyid ?? -1)?.code ?? 'USD';
      if (!params.value) return '';
      return getCurrencyFormatter(currencyCode)?.(params.value) ?? '';
    },
    [currencyMap]
  );

  const currencyColumnDefs = useMemo(() => {
    return {
      ...commonNumericColDefs,
      valueFormatter: currencyValueFormatter,
      cellClass: (params: CellClassParams) => {
        const currencyCode = currencyMap.get(params.data?.currencyid ?? -1)?.code ?? 'USD';
        return [getExcelClassForCurrency(currencyCode), 'monospace', 'text-align-right'];
      },
    };
  }, [commonNumericColDefs, currencyMap, currencyValueFormatter]);

  const columnDefsMap: Partial<Record<TColDefKey, ColDef<ITransactionDataModel>>> = useMemo(() => {
    return {
      currencyid: {
        headerName: 'Currency',
        field: 'currencyid',
        valueFormatter: ({ data }) => currencyMap.get(data?.currencyid ?? -1)?.code ?? '',
        cellClass: ['text-align-right'],
      },
      investmentAmount: {
        field: 'investmentAmount',
        headerName: 'Amount',
        ...currencyColumnDefs,
      },
      fundId: {
        headerName: 'Fund',
        field: 'fundId',
        valueFormatter: ({ data }) => fundsById.get(data?.fundId ?? -1)?.name ?? '',
        keyCreator: ({ data }) => fundsById.get(data?.fundId ?? -1)?.name ?? UNKNOWN_GROUP_NAME,
        minWidth: 200,
        enableRowGroup: true,
      },
      investmentRoundId: {
        field: 'investmentRoundId',
        headerName: 'Investment',
        valueFormatter: (params) => investmentsById.get(params.data?.investmentRoundId ?? -1)?.name ?? '',
      },
      investmentType: {
        headerName: 'Investment Type',
        field: 'investmentType',
      },
      menu: {
        headerName: '',
        maxWidth: 70,
        flex: 0,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        cellRenderer: TransactionsMenuCell,
        pinned: 'right',
      },
      noOfShares: {
        headerName: 'Number Of Shares',
        field: 'noOfShares',
        ...commonNumericColDefs,
        valueFormatter: ({ value }) => (!value ? '' : FMT.format('integer', value)),
      },
      notes: {
        headerName: 'Notes',
        field: 'notes',
        filter: 'agTextColumnFilter',
      },
      pricePerShare: {
        headerName: 'Price Per Share',
        field: 'pricePerShare',
        ...currencyColumnDefs,
      },
      round: {
        headerName: 'Round',
        valueGetter: (params: ValueGetterParams<ITransactionDataModel>) => {
          const investment = investmentsById.get(params.data?.investmentRoundId ?? -1);
          if (!investment) return '';
          const round = roundsById.get(investment.roundId ?? -1);
          return round?.name ?? '';
        },
        cellRenderer: RoundFromInvestmentCellRenderer,
        keyCreator: genericKeyCreator,
      },
      security: {
        headerName: 'Security',
        field: 'security',
      },
      status: { headerName: 'Status', field: 'status' },
      transactionDate: {
        headerName: 'Transaction Date',
        field: 'transactionDate',
        valueGetter: ({ data }) => {
          return data?.transactionDate ? new Date(data?.transactionDate) : null;
        },
        ...getDateColDefs(),
      },
      valuationImpact: {
        headerName: 'Valuation Impact',
        field: 'valuationImpact',
        valueGetter: ({ data }) => data?.valuationImpact ?? false,
        cellRenderer: BooleanRenderer,
        comparator: booleanComparator,
        valueFormatter: booleanFormatter,
        keyCreator: booleanFormatter,
      },
    };
  }, [commonNumericColDefs, currencyColumnDefs, currencyMap, fundsById, investmentsById, roundsById]);

  return { columnDefsMap, currencyValueFormatter, currencyColumnDefs };
}

export function getMenuColDef<T>() {
  return {
    headerName: '',
    maxWidth: 70,
    flex: 0,
    suppressHeaderMenuButton: true,
    cellRenderer: TransactionsMenuCell,
    pinned: 'right',
    filter: false,
  } as ColDef<T>;
}
