import { css } from '@emotion/react';
import { ButtonBase, Chip, Stack, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useMemo } from 'react';
import { NavLink } from 'react-router';
import { useRecoilValueLoadable } from 'recoil';
import { ROUTES } from '../../../../../constants/RouteNameMapping';
import { IListDataModel, ListSubtype, ListType } from '../../../../../data-models/list.data-model';
import { listsState } from '../../../../../pages/CompanyProfiles/state/ListsState';
import {
  archivedListSort,
  discoveryListSort,
  listSort,
} from '../../../../../pages/CompanyProfiles/utils/listSortingUtils';
import { PermissionKey } from '../../../../../services/PermissionAndRolesKeys';
import { PermissionService } from '../../../../../services/PermissionService';
import { colors } from '../../../../../theme/colors';
import { MaggieFeatureFlags } from '../../../../../util/feature-flags';
import { NavigationWrapper } from '../NavigationWrapper';
import { CPLists } from './CPLists';
import { AddCompanyList } from './UploadCompanyList/AddCompanyList';

const TitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface INavSectionProps {
  title: string;
  items: IListDataModel[];
  showAddButton?: boolean;
}

const NavSection: FC<INavSectionProps> = ({ title, items, showAddButton = false }) => {
  const { secondary } = useTheme().colors;

  return (
    <div>
      <TitleWrapper>
        <Typography variant='subtitle2' color={secondary[10]}>
          {title}
        </Typography>
        {showAddButton && <AddCompanyList />}
      </TitleWrapper>
      <CPLists items={items} />
    </div>
  );
};

export const CPNavigation = () => {
  const companyLists = useRecoilValueLoadable(listsState).valueMaybe();
  const canEditLists = PermissionService.get().hasPermission(PermissionKey.canEditList);

  const mappedCompanyLists = useMemo(() => {
    if (!listsState || !companyLists?.length) return null;
    const result: Record<ListType, IListDataModel[]> = {
      [ListType.DISCOVERY]: [],
      [ListType.MANAGEMENT]: [],
      [ListType.CUSTOM]: [],
      [ListType.ARCHIVE]: [],
    };
    const today = new Date();

    companyLists.forEach((l) => {
      if (l.archiveDate) {
        // Check to see if 30 days has passed since archiving
        const diff = new Date(l.archiveDate).getTime() - today.getTime();
        const numOfDays = diff / (1000 * 3600 * 24);

        if (numOfDays < 30 && numOfDays > 0) {
          result[ListType.ARCHIVE].push(l);
        }
      } else {
        result[l.type].push(l);
      }
    });

    Object.keys(result).forEach((key) => {
      if (key === ListType.DISCOVERY) {
        result[key].toSorted(discoveryListSort);
      } else if (key === ListType.ARCHIVE) {
        result[key as ListType].toSorted(archivedListSort);
      } else {
        result[key as ListType].toSorted(listSort);
      }
    });

    return result;
  }, [companyLists]);

  const discoveryItems = useMemo(() => {
    if (!mappedCompanyLists || !mappedCompanyLists[ListType.DISCOVERY]) return [];
    const discItems = mappedCompanyLists[ListType.DISCOVERY];
    const dataScienceItems = [];
    const upladedItems = [];
    for (const item of discItems) {
      if (item.subtype === ListSubtype.DATA_SCIENCE) {
        dataScienceItems.push(item);
      }
      if (item.subtype === ListSubtype.UPLOADED) {
        upladedItems.push(item);
      }
    }
    return [...dataScienceItems, ...upladedItems];
  }, [mappedCompanyLists]);

  if (!mappedCompanyLists) return null;

  return (
    <NavigationWrapper title={'Companies'}>
      <MinerNavigation />
      {Boolean(discoveryItems.length) && (
        <NavSection title='DISCOVERY' items={discoveryItems} showAddButton={canEditLists} />
      )}
      {Boolean(mappedCompanyLists[ListType.MANAGEMENT].length) && (
        <NavSection title='MANAGEMENT' items={mappedCompanyLists[ListType.MANAGEMENT]} />
      )}
      {Boolean(mappedCompanyLists[ListType.CUSTOM].length) && (
        <NavSection title={'WATCHLISTS'} items={mappedCompanyLists[ListType.CUSTOM]} />
      )}
      {Boolean(mappedCompanyLists[ListType.ARCHIVE].length) && (
        <NavSection title='Recently Deleted' items={mappedCompanyLists[ListType.ARCHIVE]} />
      )}
    </NavigationWrapper>
  );
};

const NavLinkStyles = css`
  align-self: start;
  height: 2rem;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  border-radius: 4px;
  padding-left: 1rem;
  &.active {
    background-color: ${colors.primary[80]}aa;
  }
`;

const MinerBaseRoute = `/${ROUTES.COMPANIES}/${ROUTES.MINER}`;

const MinerNavItems = [{ label: 'Overview', route: `${MinerBaseRoute}/${ROUTES.MINER_OVERVIEW}` }];

function MinerNavigation() {
  const { colors } = useTheme();
  const { showMiner } = useFlags<MaggieFeatureFlags>();

  if (!showMiner) return null;
  return (
    <>
      <Stack
        display={'grid'}
        gridTemplateColumns={'max-content max-content'}
        gap={'0.2rem'}
        alignItems={'center'}
      >
        <Typography variant='subtitle2' color={colors.secondary[10]}>
          FORESIGHT MINER
        </Typography>
        <Chip label='beta' size='small' sx={{ background: colors.secondary[10], fontSize: '0.75rem' }} />
      </Stack>
      <Stack gap={'0.25rem'} mt='-0.5rem'>
        {MinerNavItems.map((item) => (
          <ButtonBase key={item.label}>
            <NavLink
              to={item.route}
              css={NavLinkStyles}
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              <Typography variant='caption' color={colors.secondary[10]}>
                {item.label}
              </Typography>
            </NavLink>
          </ButtonBase>
        ))}
      </Stack>
    </>
  );
}
