import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { set } from 'lodash-es';
import { ShareClass } from '../../../view-models/captable.view-model';
import { ViewModelInvestmentType } from '../../../schemas/common-schema-defs';
import { getAgColDefFromGridField } from '../../../components/AgTable/cell-editors/AgColDefFactory';
import { RendererType } from '../../../data-models/field.data-model';
import { createGridField } from '../../../view-models/grid.view-model';
import { sortedShareClassesState } from './CapTableFormState';
import { IOtherInvestmentsGridRow } from './InvestmentGridDefs';
import { getGroupedInvestmentsColDefs } from './investment-utils';

export enum OtherInvestorsDefaultValues {
  otherInvestors = 'Other Investors',
  grantedOptions = 'Individuals - Granted Options',
}

export const InitialInvestorFieldValues = Object.values(OtherInvestorsDefaultValues);

export function generateInitialInvestmentRows(shareClasses: ShareClass[]): IOtherInvestmentsGridRow[] {
  const initialInvestorValues = [...InitialInvestorFieldValues];
  if (!shareClasses.some((shareClass) => shareClass.type === ViewModelInvestmentType.options)) {
    initialInvestorValues.splice(1);
  }
  return initialInvestorValues.map((investor) => {
    const row = { investor };

    const filteredShareClasses = getShareClassesForOtherInvestments(shareClasses);

    filteredShareClasses.forEach((shareClass) => {
      set(row, shareClass.name, null);
    });
    return row;
  });
}

export function getShareClassesForOtherInvestments(shareClasses: ShareClass[]) {
  return [...shareClasses];
}

export function useOtherInvestmentsColDefs() {
  const shareClasses = useRecoilValue(sortedShareClassesState);

  return useCallback(() => {
    const filteredShareClasses = [...getShareClassesForOtherInvestments(shareClasses)];

    const shareClassesColDefs = getGroupedInvestmentsColDefs(filteredShareClasses);

    return [
      {
        ...getAgColDefFromGridField(
          createGridField({
            key: 'investor',
            label: 'Investor',
            renderer: RendererType.text,
            editable: true,
            width: 200,
          })
        ),
        headerClass: 'investment-header',
      },
      ...shareClassesColDefs,
    ];
  }, [shareClasses]);
}

export function getInputLabelByInvestmentType(investmentType: ViewModelInvestmentType) {
  switch (investmentType) {
    case ViewModelInvestmentType.notes:
      return '(invested amount)';
    default:
      return '(# shares)';
  }
}
