import { useRecoilValue } from 'recoil';
import { useParams } from 'react-router';
import { useCallback } from 'react';
import { kpisRequestsMapByIdState } from '../../../../../../services/state/KPI/KPIRequestsState';
import { companyState } from '../../../../../../services/state/CompanyState';
import { kpiTemplatesListState } from '../../../../../../services/state/KPI/KPITemplatesState';
import { sanitizeAsFileName } from '../../../../../../util/stringUtils';

export function useResponseFileName() {
  const { requestId } = useParams();
  const request = useRecoilValue(kpisRequestsMapByIdState)?.get(Number(requestId));
  const companyName = useRecoilValue(companyState(request?.companyId ?? -1))?.name;
  const template = useRecoilValue(kpiTemplatesListState)?.find((t) => request?.templateId == t.id);

  return useCallback(
    (fieldTitle: string) => {
      return sanitizeAsFileName(`${companyName}_${fieldTitle ?? template?.name}_${request?.period}`);
    },
    [companyName, request?.period, template?.name]
  );
}
