export const companyProfileFinancialsDefaultColDef = {
  flex: 1,
  minWidth: 170,
  resizable: true,
  filter: false,
  enableRowGroup: true,
  suppressHeaderMenuButton: true,
  suppressHeaderFilterButton: true,
  cellStyle: {
    textAlign: 'right',
  },
  sortable: false,
};
