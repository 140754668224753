import { useAtomValue } from 'jotai';
import { useCallback, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { IGenericSortable } from '../../../../../data-models/app-config.data-model';
import { ICompanyDataModel } from '../../../../../data-models/company.data-model';
import {
  createKPIRequestDataModel,
  IKPIRequestDataModel,
} from '../../../../../data-models/kpi-requests.data-model';
import { IKPITemplate } from '../../../../../data-models/kpi-template.data-model';
import { TemplateAssignment } from '../../../../../schemas/template-assignment-schema';
import { sectorsByIdMapAtom } from '../../../../../services/state/AppConfigStateJ';
import { kpiTemplatesMapByUuidState } from '../../../../../services/state/KPI/KPITemplatesState';
import { portCosByIdMapState } from '../../../../../services/state/PortCosState';

/** @deprecated: used only in KPICompaniesAllAssignedGrid */
export interface IKpiRequestGridData
  extends Omit<IKPIRequestDataModel, 'fye' | 'granularity'>,
    Omit<TemplateAssignment, 'fye' | 'granularity'> {
  sector?: IGenericSortable;
  company?: ICompanyDataModel;
  template?: IKPITemplate;
  fye?: string | number;
}
function createKpiRequestGridData(overrides: Partial<IKpiRequestGridData> = {}): IKpiRequestGridData {
  return { ...createKPIRequestDataModel(overrides as IKPIRequestDataModel), ...overrides };
}

export function useKPIRequestsFilters(requestData: (IKPIRequestDataModel | TemplateAssignment)[] | null) {
  const portfolioCompaniesByIdMap = useRecoilValue(portCosByIdMapState) ?? null;
  const kpisTemplatesMapByUuid = useRecoilValue(kpiTemplatesMapByUuidState) ?? null;
  const sectorsMap = useAtomValue(sectorsByIdMapAtom);
  const [selectedSectors, setSelectedSectors] = useState<number[] | null>(null);
  const [selectedTemplates, setSelectedTemplates] = useState<string[] | null>(null);
  const [selectedFrequencies, setSelectedFrequencies] = useState<string[] | null>(null);

  const onSelectTemplateChange = useCallback((value: string[] | null) => {
    setSelectedTemplates(value);
  }, []);

  const onSelectFrequenciesChange = useCallback((value: string[] | null) => {
    setSelectedFrequencies(value);
  }, []);

  const onSelectSectorsChange = useCallback((value: number[] | null) => {
    if (value?.length === 0) setSelectedSectors(null);
    setSelectedSectors(value);
  }, []);

  const filteredRequests = useMemo(
    () =>
      requestData?.reduce((prev, request) => {
        const newRequest: IKpiRequestGridData = createKpiRequestGridData({
          ...(request as Partial<IKpiRequestGridData>),
        });
        newRequest.company = portfolioCompaniesByIdMap?.get(request.companyId ?? -1);
        newRequest.template = kpisTemplatesMapByUuid?.get(request.templateUuid ?? '');
        newRequest.sector = sectorsMap.get(newRequest.company?.sectorId as number) as IGenericSortable;

        const passesSectorFilter =
          !selectedSectors?.length || selectedSectors?.includes(newRequest.sector?.id as number);

        const passesTemplateFilter =
          !selectedTemplates?.length || selectedTemplates?.includes(newRequest.template?.uuid as string);

        const passesFrequencyFilter =
          !selectedFrequencies?.length || selectedFrequencies?.includes(request.frequency);

        if (passesFrequencyFilter && passesTemplateFilter && passesSectorFilter) {
          (prev as IKpiRequestGridData[]).push(newRequest);
        }
        return prev as IKpiRequestGridData[];
      }, [] as IKpiRequestGridData[]),
    [
      requestData,
      portfolioCompaniesByIdMap,
      kpisTemplatesMapByUuid,
      sectorsMap,
      selectedSectors,
      selectedTemplates,
      selectedFrequencies,
    ]
  );

  return {
    filteredRequests,
    selectedTemplates,
    selectedFrequencies,
    selectedSectors,
    onSelectSectorsChange,
    onSelectTemplateChange,
    onSelectFrequenciesChange,
  };
}
