import { ColDef } from 'ag-grid-community';
import { useMemo } from 'react';
import { FieldPath } from 'react-hook-form';
import { SecurityReport, SecurityReportSchema } from '../../../schemas/SecurityReport.schema';
import { withOverrides } from '../../../util/ag-grid-utils';
import { useSchemaToGrid } from '../../../util/schema-utils';

const schema = SecurityReportSchema.gridSchema();

export function useAllSecuritiesColDefs(): ColDef<SecurityReport>[] {
  const schemaToColDef = useSchemaToGrid();

  return useMemo(() => {
    const overrides: Partial<Record<FieldPath<SecurityReport>, ColDef<SecurityReport>>> = {
      companyId: {
        initialSort: 'asc',
      },
      latestEventDate: {
        initialSort: 'desc',
      },
    };
    return withOverrides(
      schemaToColDef(schema, [
        'companyId',
        'currentHolding',
        'latestEventDate',
        'fundId',
        'investmentAmount',
        'fmv',
        'realizedValue',
        'escrow',
        'sharesHeld',
        'pps',
      ]) as ColDef<SecurityReport>[],
      overrides
    );
  }, [schemaToColDef]);
}

export const AllSecuritiesDefaultColDefs = {
  minWidth: 170,
  flex: 1,
};

export const AllSecuritiesAutoGroupColDef = {
  sortable: true,
};
