import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import { Button, Divider, Stack, Typography, useTheme } from '@mui/material';
import { useAtomValue } from 'jotai/index';
import { FC, useCallback, useMemo, useState } from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import AirtableLogo from '../../../../assets/icons/airtable-16.svg?react';
import { MUIAvatar } from '../../../../components/Avatar/MUIAvatar';
import {
  IEstablishedSyncDataModel,
  ISyncedColumnDataModel,
} from '../../../../data-models/airtable-sync.data-model';
import { useCurrentUser } from '../../../../services/hooks/useCurrentUser';
import { usersByEmailMapAtom } from '../../../../services/state/AppConfigStateJ';
import { formatDate } from '../../../../util/formatters/DateFormatters';
import { allFDFieldsMapState, entityMapState } from '../../state/IntegrationsState';
import { StyledCard } from '../components/CommonStyledComponents';
import { useAirtableActions } from '../hooks/useAirtableActions';
import { ConfirmDisconnect } from './ConfirmDisconnect';
import { SyncedColumns } from './SyncedColumns';

interface ISyncTableProps {
  sync: IEstablishedSyncDataModel;
}

export const SyncTable: FC<ISyncTableProps> = ({ sync }) => {
  const [showConfirmDisconnect, setShowConfirmDisconnect] = useState(false);
  const [disconnectSelection, setDisconnectSelection] = useState<
    ISyncedColumnDataModel[] | ISyncedColumnDataModel | null
  >(null);

  const { airtableTableName, airtableBaseName, entity, createdAt, createdBy } = sync;
  const entityMap = useRecoilValueLoadable(entityMapState);
  const entityName = entityMap.valueMaybe()?.get(entity)?.entityDisplayName;

  const userMap = useAtomValue(usersByEmailMapAtom);
  const userName = userMap.get(createdBy ?? '')?.name;
  const currentUser = useCurrentUser();
  const disableAction = currentUser.email !== createdBy;
  const allFDFieldsMap = useRecoilValue(allFDFieldsMapState);

  const { disconnectSync, disconnectSyncColumn } = useAirtableActions();

  const { colors } = useTheme();

  const handleConfirmDisconnect = useCallback(async () => {
    if (disconnectSelection === null) return;

    if (Array.isArray(disconnectSelection)) {
      await disconnectSync(sync);
    } else {
      await disconnectSyncColumn(disconnectSelection, sync);
    }

    setShowConfirmDisconnect(false);
    setDisconnectSelection(null);
  }, [disconnectSelection, disconnectSync, disconnectSyncColumn, sync]);

  const columns = useMemo(() => {
    if (Array.isArray(disconnectSelection)) return disconnectSelection;
    if (disconnectSelection) return [disconnectSelection];
    return [];
  }, [disconnectSelection]);

  const columnsMap = useMemo(() => {
    return sync.airtableSyncColumns.reduce((map, column) => {
      map.set(column.id, column);
      return map;
    }, new Map<number, ISyncedColumnDataModel>());
  }, [sync.airtableSyncColumns]);

  return (
    <Typography component={'section'} color='text.primary'>
      <ConfirmDisconnect
        open={showConfirmDisconnect}
        onClose={() => setShowConfirmDisconnect(false)}
        onDisconnect={handleConfirmDisconnect}
        columns={columns}
        fdFieldsMap={allFDFieldsMap}
      />
      <StyledCard>
        <Stack component={'header'} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <Stack direction={'row'} alignItems={'center'} gap={'1rem'}>
            <Stack direction={'row'} gap='0.5rem'>
              <AirtableLogo />
              <Typography>{airtableBaseName}</Typography>
            </Stack>
            -
            <Stack direction={'row'} gap='0.5rem'>
              <TableChartOutlinedIcon htmlColor={colors.tertiary[60]} />
              <Typography>{airtableTableName}</Typography>
            </Stack>
            -<Typography>{`“${entityName}“ category`}</Typography>
          </Stack>
          <Stack direction={'row'} alignItems={'center'} gap={'1rem'}>
            <Typography variant={'body2'}>{`Sync: ${formatDate(createdAt)}`}</Typography>
            <Typography variant={'body2'} display={'flex'} alignItems={'center'} gap='0.5rem' component='div'>
              <MUIAvatar src={null} nameParts={userName?.split(' ') ?? []} />
              {userName}
            </Typography>
            <Button
              variant='contained'
              color='secondary'
              style={{ marginLeft: '1rem' }}
              disabled={disableAction}
              onClick={() => {
                setDisconnectSelection(sync.airtableSyncColumns);
                setShowConfirmDisconnect(true);
              }}
            >
              Disconnect Sync
            </Button>
          </Stack>
        </Stack>
        <Divider style={{ margin: '1.25rem 0' }} />
        <SyncedColumns
          columns={sync.airtableSyncColumns}
          onDisconnect={(id) => {
            setDisconnectSelection(columnsMap.get(id)!);
            setShowConfirmDisconnect(true);
          }}
          disableAction={disableAction}
        />
      </StyledCard>
    </Typography>
  );
};
