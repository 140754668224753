import { useCallback } from 'react';
import { BlockerFunction } from 'react-router';
import { ProgrammaticNavigationFlag } from '../../hooks/useScenarioActions';
import { ROUTES } from '../../../../../constants/RouteNameMapping';

export function useBlockScenarioExit(block: boolean) {
  const shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => {
      return (
        block &&
        !nextLocation.state?.[ProgrammaticNavigationFlag] &&
        isOutsideModelling(nextLocation.pathname) &&
        currentLocation.pathname !== nextLocation.pathname
      );
    },
    [block]
  );

  return { shouldBlock };
}

function isOutsideModelling(path: string) {
  return !path.includes(`${ROUTES.COMPANY_PROFILE_SCENARIOS}/${ROUTES.COMPANY_PROFILE_SINGLE_SCENARIO}`);
}
