import { useCallback, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { useRecoilValue } from 'recoil';
import { AgTable } from '../../../../components/AgTable/AgTable';
import { useKPIRequestsFilters } from '../KPIRequests/hooks/useKPIRequestsFilters';
import { kpiTemplateAssignmentsListState } from '../../../../services/state/KPI/KPICompaniesState';
import { KPIRequestsSectorsFilter } from '../KPIFilters/KPIRequestsSectorsFilter';
import { SearchInput } from '../../../../CoreComponents/base/SearchInput';
import { FadeInGridWrapper } from '../../../../components/grid-renderers/FadeInGridWrapper';
import { KPIRequestsTemplatesFilter } from '../KPIRequests/components/Filters/KPIRequestsTemplatesFilter';
import { KPIRequestsFrequenciesFilter } from '../KPIRequests/components/Filters/KPIRequestsFrequenciesFilter';
import { FilterSection } from '../KPIRequests/components/Filters/FilterSection';
import { useKPICompaniesAllColDefs } from './hooks/useKPICompaniesAllColDefs';
import { useAssignedFilter } from './hooks/useAssignedFilter';

/**@deprecated: replaced by AssignedCompanies */
export function KPICompaniesAllAssignedGrid() {
  const { columnDefs, defaultColDef } = useKPICompaniesAllColDefs();
  const portCompaniesAssigned = useRecoilValue(kpiTemplateAssignmentsListState);

  const {
    filteredRequests,
    selectedTemplates,
    selectedFrequencies,
    selectedSectors,
    onSelectSectorsChange,
    onSelectTemplateChange,
    onSelectFrequenciesChange,
  } = useKPIRequestsFilters(portCompaniesAssigned);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [searchCompany, setSearchCompany] = useState<string>('');
  const { setGridFilter } = useAssignedFilter();

  const onGridReady = useCallback((e: GridReadyEvent) => {
    setGridApi(e.api);
  }, []);

  useEffect(() => {
    if (!gridApi) return;
    setGridFilter(gridApi);
  }, [gridApi, setGridFilter]);

  const onSearchCompanyChange = useCallback((value: string) => {
    setSearchCompany(value);
  }, []);

  return (
    <Stack height={'100%'}>
      <Stack direction='row' alignItems='center' marginBottom={'1.5rem'} marginTop={'.5rem'}>
        <FilterSection marginTop='1rem' nFilters={4}>
          <SearchInput
            value={searchCompany}
            onChange={onSearchCompanyChange}
            placeholder='Search for a company'
          />
          <KPIRequestsSectorsFilter value={selectedSectors} onChange={onSelectSectorsChange} />
          <KPIRequestsTemplatesFilter value={selectedTemplates} onChange={onSelectTemplateChange} />
          <KPIRequestsFrequenciesFilter value={selectedFrequencies} onChange={onSelectFrequenciesChange} />
        </FilterSection>
      </Stack>
      <FadeInGridWrapper>
        <AgTable
          rowData={filteredRequests}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowGroupPanelShow='always'
          groupDefaultExpanded={-1}
          suppressMovableColumns={true}
          suppressRowDrag={true}
          onGridReady={onGridReady}
          quickFilterText={searchCompany}
        />
      </FadeInGridWrapper>
    </Stack>
  );
}
