import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { mapLineChartData } from '../../../../../components/Charts/LineChart/mapLineChartData';

import { IMetricsDataModel } from '../../../../../data-models/metrics.data-model';
import { MetricsTransactionDataModel } from '../../../../../schemas/MetricsTransaction.schema';
import { sectorsByIdMapAtom } from '../../../../../services/state/AppConfigStateJ';
import { LineChartData, TQuoter } from '../../../../../types';
import { useGetCompanyIfSet } from '../../../../CompanyProfiles/hooks/useGetCompanyData';
import { QuartersBuilder } from '../../../services/QuartersBuilder';
import { selectedViewDatePF } from '../../../state/PageState';

type MoicValues = { total: number; investment: number };
type TQuoterMoic = Record<string, MoicValues>;

// type GroupExtractorFn = (params: { transaction: Transaction; company: Company }) => string;

interface ChartDataByGroup {
  data: LineChartData[];
  last12Quoters: TQuoter[];
}

export function useMoicBySector(rawMetricsData: IMetricsDataModel[]): ChartDataByGroup {
  const date = useRecoilValue(selectedViewDatePF);
  const sectorMap = useAtomValue(sectorsByIdMapAtom);
  const getCompany = useGetCompanyIfSet();

  const last12Quoters = useMemo(
    () =>
      QuartersBuilder.lastFourQuarters(date ?? new Date()).sort(
        (a: TQuoter, b: TQuoter) => a.start.getTime() - b.start.getTime()
      ),
    [date]
  );

  const data = useMemo(() => {
    const datasetPerSector: Record<string, TQuoterMoic> = {};

    function getTransactionQuoters(transaction: MetricsTransactionDataModel, quoters: TQuoter[]): TQuoter[] {
      if (!transaction.transactionDate) return [];

      const tDate = new Date(transaction.transactionDate);
      tDate.setHours(0);
      tDate.setMinutes(0);
      tDate.setSeconds(0);

      return quoters.filter((q) => tDate <= q.end);
    }

    function getFundQuoters(quoters: TQuoter[]) {
      return quoters.reduce((map, q) => ({ ...map, [q.label]: 0 }), {});
    }

    rawMetricsData?.forEach((rawMetric) => {
      rawMetric.transactions.forEach((transaction) => {
        const company = getCompany(rawMetric.companyId);
        const sector = sectorMap.get(company?.sectorId ?? -1)?.name || '';
        if (!sector) return;

        if (!datasetPerSector[sector]) {
          datasetPerSector[sector] = getFundQuoters(last12Quoters);
        }

        const transactionQuoters = getTransactionQuoters(transaction, last12Quoters);
        if (!transactionQuoters?.length) return;

        transactionQuoters.forEach((quoter) => {
          if (!datasetPerSector[sector][quoter.label]) {
            datasetPerSector[sector][quoter.label] = {
              total: 0,
              investment: 0,
            };
          }

          const currentInvestment = transaction.currentInvestment;

          datasetPerSector[sector][quoter.label].total +=
            currentInvestment + (Number(transaction.distributions) || 0);

          datasetPerSector[sector][quoter.label].investment += Number(transaction.investmentAmount) || 0;
        });
      });
    });

    const sectorQuotersMoic: Record<string, Record<string, number>> = {};

    Object.keys(datasetPerSector).forEach((sector) => {
      Object.keys(datasetPerSector[sector]).forEach((quoter) => {
        sectorQuotersMoic[quoter] = {
          ...(sectorQuotersMoic[quoter] ?? {}),
          [sector]:
            (datasetPerSector[sector][quoter].total || 0) /
            Number((datasetPerSector[sector][quoter].investment || 1).toFixed(2)),
        };
      });
    });

    const data = Object.keys(sectorQuotersMoic).map((quoter) => ({
      ...(sectorQuotersMoic[quoter] as Record<string, number>),
      period: quoter,
    }));

    return mapLineChartData(data);
  }, [getCompany, last12Quoters, rawMetricsData, sectorMap]);

  return useMemo(() => ({ data, last12Quoters: last12Quoters }), [data, last12Quoters]);
}
