import { createStore } from 'jotai';

let foresightStore = createStore();

export function getForesightStore() {
  return foresightStore;
}

export function __TEST_ONLY__resetForesightStore() {
  foresightStore = createStore();

  return foresightStore;
}
