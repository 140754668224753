import { useMemo } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { Stack } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { IKPIRequestDataModel } from '../../../../../data-models/kpi-requests.data-model';
import { KpiAction } from '../../../../../services/state/KPI/KPIRequestsState';
import { useRequestActions } from '../KPIRequestResponse/hooks/useKPIRequestResponseActions';
import { MenuButton } from '../../../../Finance2/common-grid-defs/MenuButton';
import { DisableResendMessage, useCanResendRequest } from '../hooks/useCanResendRequest';

export function ArchivedRequestsActions({ data, node }: ICellRendererParams<IKPIRequestDataModel>) {
  const { onSelectAction } = useRequestActions();
  const canResend = useCanResendRequest();

  const disableResend = useMemo(() => {
    return Boolean(data) && !canResend(data!);
  }, [canResend, data]);

  const moreOptions = useMemo(() => {
    return [
      {
        name: 'Resend',
        icon: <SendIcon />,
        disabled: disableResend,
        title: disableResend ? DisableResendMessage : undefined,
        onClick: () => onSelectAction(data!, KpiAction.resend),
      },
    ];
  }, [data, onSelectAction, disableResend]);

  if (!data || !data.respondent?.length || node?.group) return null;

  return (
    <Stack display={'grid'} gridAutoFlow={'column'} alignItems={'center'} justifyContent={'center'}>
      <MenuButton menuItems={moreOptions} label={'Request actions'} />
    </Stack>
  );
}
