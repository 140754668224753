import { useAtomValue } from 'jotai/index';
import { FC, useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { UserSelect } from '../../../../components/Form/UserSelect';
import { usersAtom } from '../../../../services/state/AppConfigStateJ';
import { listOwnerFilterState } from '../state/FiltersState';

export const OwnerFilter: FC = () => {
  const [selectedOwners, setSelectedOwners] = useRecoilState(listOwnerFilterState);
  const users = useAtomValue(usersAtom);

  // we need to reset the state when switching btw lists because the selector is not available in all lists
  useEffect(() => {
    return () => setSelectedOwners(null);
  }, [setSelectedOwners]);

  const userIds = useMemo(() => {
    return users.map((user) => user.id);
  }, [users]);

  const allSelected = useMemo(
    () => selectedOwners === null || selectedOwners.length === users?.length,
    [selectedOwners, users]
  );

  return (
    <UserSelect
      onChange={setSelectedOwners}
      value={allSelected ? userIds : (selectedOwners ?? [])}
      fieldPlaceholder='Select Owners'
      optionName={'Owner'}
      style={{ width: '14rem' }}
    />
  );
};
