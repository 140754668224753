import { useCallback } from 'react';
import { useAtomCallback } from 'jotai/utils';
import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import { getErrorMessage } from '../../../services/queryHelpers';
import {
  createFundData,
  fetchFundData,
  ICreateFundDataPayload,
  IUpdateFundDataPayload,
  updateFundData,
} from '../../../services/queries/MaggieFundQueries';
import { fundDataStateFP } from '../state/FPState';
import { useLoadingBarState } from '../../../components/LoadingBar/LoadingBarContext';
import { LoadingId } from '../../../types';
import { useInvalidateFundMetrics } from './useInvalidateFundMetrics';

export function useFundDataActions() {
  const { pushErrorToast } = useToastMessageState();
  const { actions } = useLoadingBarState();
  const invalidateFundMetrics = useInvalidateFundMetrics();

  const handleUpdateFundData = useAtomCallback(
    useCallback(
      async (_get, set, data: IUpdateFundDataPayload) => {
        try {
          actions.startLoading(LoadingId.updateFundData);
          const updated = await updateFundData(data);
          const allFundData = await fetchFundData(data.fundId);
          set(fundDataStateFP(data.fundId), allFundData);
          await invalidateFundMetrics(data.date, data.fundId);
          return updated;
        } catch (err) {
          console.error(err);
          pushErrorToast({ message: getErrorMessage(err, 'Failed to update fund data.') });
        } finally {
          actions.stopLoading(LoadingId.updateFundData);
        }
      },
      [actions, invalidateFundMetrics, pushErrorToast]
    )
  );

  const handleCreateFundData = useAtomCallback(
    useCallback(
      async (_get, set, data: ICreateFundDataPayload) => {
        try {
          const created = await createFundData(data);
          const allFundData = await fetchFundData(data.fundId);
          set(fundDataStateFP(data.fundId), allFundData);
          await invalidateFundMetrics(data.date, data.fundId);
          return created;
        } catch (err) {
          console.error(err);
          pushErrorToast({ message: getErrorMessage(err, 'Failed to create fund data.') });
        }
      },
      [invalidateFundMetrics, pushErrorToast]
    )
  );

  return { handleUpdateFundData, handleCreateFundData };
}
