import { atom, atomFamily, selector } from 'recoil';
import { IKPITemplate } from '../../../data-models/kpi-template.data-model';
import { FieldEntity, IField } from '../../../data-models/field2.data-model';
import { sortFields } from '../../../util/field-utils';
import { getAllKPIs, getKPIGroupsSettings } from '../../queries/KPIConfigQueries';
import { fetchKPITemplateById, fetchKPITemplates } from '../../queries/KPITemplatesQueries';
import { FDMap } from '../../../util/data-structure/FDMap';
import { IKPIRequestDataModel } from '../../../data-models/kpi-requests.data-model';
import { fetchAssignedKPIRequests } from '../../queries/KPIRequestsQueries';
import { KPIGroup } from '../../../schemas/kpi-group.schema';
import { FMT } from '../../../util/formatter-service';
import { toCamelCase } from '../../../util/stringUtils';

/** includes deleted templates */
export const kpiTemplatesListState = atom<IKPITemplate[] | null>({
  key: 'kpiTemplatesListState',
  default: selector({
    key: 'kpiTemplatesListState/Default',
    get: async () => {
      return await fetchKPITemplates();
    },
  }),
});

/** includes latest version of deleted templates */
export const kpiTemplatesMapByUuidState = selector({
  key: 'kpiTemplatesMapByUuidState',
  get: ({ get }) => {
    const kpisTemplatesList = get(kpiTemplatesListState);
    const kpisTemplatesMap = new Map<string, IKPITemplate>();

    if (!kpisTemplatesList) return kpisTemplatesMap;

    kpisTemplatesList.forEach((template) => {
      const existing = kpisTemplatesMap.get(template.uuid);
      if (!existing) {
        kpisTemplatesMap.set(template.uuid, template);
      } else {
        const latest =
          (existing.version ?? Number.MIN_SAFE_INTEGER) > (template.version ?? Number.MIN_SAFE_INTEGER)
            ? existing
            : template;
        kpisTemplatesMap.set(template.uuid, latest);
      }
    });

    // FIXME MAGGIE-6902
    FMT.get().setFormatterForId('kpiTemplateName', (formatterId: string) => {
      return kpisTemplatesMap.get(formatterId)?.name ?? formatterId;
    });

    return kpisTemplatesMap;
  },
});

export const kpiConfigState = atom<IField<unknown>[]>({
  key: 'kpiConfigState',
  default: selector<IField<unknown>[]>({
    key: 'kpiConfigState/Default',
    get: async () => {
      const res = await getAllKPIs();
      return res.sort(sortFields);
    },
  }),
});

export const kpiConfigByIdMapState = selector<Map<number, IField<unknown>>>({
  key: 'kpiConfigByKpiIdMapState',
  get: ({ get }) => {
    const map = new Map<number, IField<unknown>>();
    const kpiConfig = get(kpiConfigState);
    if (!kpiConfig) return map;
    return kpiConfig.reduce((res, kpi) => {
      res.set(kpi.id, kpi);
      return res;
    }, map);
  },
});

export const kpiConfigByKeyMapState = selector<Map<string, IField<unknown>>>({
  key: 'kpiConfigByKpiKeyMapState',
  get: ({ get }) => {
    const map = new Map<string, IField<unknown>>();
    const kpiConfig = get(kpiConfigState);
    if (!kpiConfig) return map;
    return kpiConfig.reduce((res, kpi) => {
      res.set(kpi.entityField ?? `__${toCamelCase(kpi.entity)}`, kpi);
      return res;
    }, map);
  },
});

export const kpiConfigByDisplayNameMapState = selector<Map<string, IField<unknown>>>({
  key: 'kpiConfigByDisplayNameMapState',
  get: ({ get }) => {
    const kpis = get(kpiConfigState) ?? [];

    return new Map(kpis.map((kpi) => [kpi.displayName, kpi]));
  },
});

export const universalAndSystemKPIState = selector<IField<unknown>[]>({
  key: 'universalAndSystemKPIState',
  get: ({ get }) => {
    const kpis = get(kpiConfigState);

    return kpis.reduce((res, kpi) => {
      if (kpi.entity === FieldEntity.universalKPI || kpi.entity === FieldEntity.systemKPI) {
        res.push(kpi);
      }
      return res;
    }, [] as IField<unknown>[]);
  },
});

export const kpisTemplateByIdState = atomFamily<IKPITemplate | null, number>({
  key: 'kpiTemplateByIdState',
  default: null,
  effects: (templateId) => [
    ({ setSelf, trigger }) => {
      if (trigger === 'get') {
        try {
          const res = fetchKPITemplateById(templateId);
          setSelf(res);
        } catch (error) {
          console.error(error);
        }
      }
    },
  ],
});

export const assignedKPIRequestsState = atom<IKPIRequestDataModel[]>({
  key: 'assignedKPIRequestsState',
  effects: [
    ({ setSelf, trigger }) => {
      if (trigger === 'get') {
        fetchAssignedKPIRequests().then((res) => {
          res.sort((requestA, requestB) => {
            const d1 = new Date(requestA.sentAt!);
            const d2 = new Date(requestB.sentAt!);

            return d2.getTime() - d1.getTime();
          });
          setSelf(res);
        });
      }
    },
  ],
});

export const assignedKPIRequestsStateByCompany = selector<FDMap<number, IKPIRequestDataModel[]>>({
  key: 'assignedKPIRequestsStateByCompany',
  get: () =>
    getAssignedKPIs().then((res) => {
      return res;
    }),
});

export async function getAssignedKPIs() {
  const allRequests = await fetchAssignedKPIRequests();

  return allRequests
    .sort((requestA, requestB) => {
      const d1 = new Date(requestA.sentAt!);
      const d2 = new Date(requestB.sentAt!);

      return d2.getTime() - d1.getTime();
    })
    .reduce((byCompany, request) => {
      return byCompany.setOrUpdate(request.companyId, [request], (value) => {
        value.push(request);
        return value;
      });
    }, new FDMap<number, IKPIRequestDataModel[]>());
}

export const kpiGroupsState = atom({
  key: 'kpiGroupsState',
  default: selector({
    key: 'kpiGroupsState/Default',
    get: async () => {
      const res = await getKPIGroupsSettings();
      res.sort((s1, s2) => s1.sortOrder - s2.sortOrder);

      return res;
    },
  }),
});

export const kpiGroupsByIdState = selector({
  key: 'kpiGroupsByIdState',
  get: ({ get }) => {
    const groups = get(kpiGroupsState);

    return groups.reduce((res, group) => {
      return res.set(group.id, group);
    }, new Map<number, KPIGroup>());
  },
});
