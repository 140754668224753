import { FieldFormatting } from '../data-models/field.data-model';
import { defaultFormatter, FMT } from './formatter-service';

export type GetterFormatType =
  | 'array'
  | 'boolean'
  | 'date'
  | 'number'
  | 'numberArray'
  | 'percent'
  | 'string'
  | 'stringArray';
export type GetterFormatting =
  | 'YYYY'
  | 'boardMembers'
  | 'currency'
  | 'decimalPercent'
  | 'hh:mm:ss'
  | 'image'
  | 'integer'
  | 'longText'
  | 'multiplier'
  | 'percent'
  | 'url'
  | 'yesNo';

/** @deprecated */
export type DeprecatedGetterFormatType = 'number' | 'string' | 'link' | 'date' | 'array';

/** @deprecated */
export type DeprecatedGetterFormatting =
  | 'text'
  | 'number'
  | 'percent'
  | 'multiplier'
  | 'image'
  | 'link'
  | 'url'
  | 'date'
  | 'currency';

/** @deprecated */
const handleDeprecatedCases = (formatting?: string | null) => {
  if (formatting === 'percent') return FMT.get().getFormatterForId('percentRound');
  if (formatting === 'date') return FMT.get().getFormatterForId('dateNumeric');
};

export interface GetFormatterProps {
  type: GetterFormatType | DeprecatedGetterFormatType | null;
  formatting?: FieldFormatting;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getFormatter({ type, formatting }: GetFormatterProps): ((value: any) => string) | null {
  const fmtService = FMT.get();

  switch (type) {
    case 'number': {
      if (formatting === 'percent') return fmtService.getFormatterForId('percentRound');
      if (formatting === 'currency') return fmtService.getFormatterForId('usd');
      if (formatting === 'multiplier') return fmtService.getFormatterForId('multiplier');
      if (formatting === 'decimalPercent') return fmtService.getFormatterForId('decimalPercent');

      return fmtService.getFormatterForId('numeric');
    }
    case 'date': {
      if (formatting === 'YYYY') return fmtService.getFormatterForId('YYYY');
      if (formatting === 'hh:mm:ss') return fmtService.getFormatterForId('time');

      return fmtService.getFormatterForId('dateNumeric');
    }
    case 'array': {
      return fmtService.getFormatterForId('stringArray');
    }
    case 'numberArray': {
      if (formatting === 'currency') return fmtService.getFormatterForId('usdArray');
      if (formatting === 'percent') return fmtService.getFormatterForId('percentArray');
      return fmtService.getFormatterForId('numberArray');
    }
    case 'boolean': {
      if (formatting === 'yesNo') return fmtService.getFormatterForId('yesNo');
      return fmtService.getFormatterForId('trueFalse');
    }
    case 'string': {
      return defaultFormatter;
    }
    default: {
      const formatter = handleDeprecatedCases(formatting);
      return formatter || null;
    }
  }
}
