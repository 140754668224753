import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useSetRecoilState } from 'recoil';
import { selectedFundIdStateFP } from './state/FPState';

export function useSyncFundFromPath() {
  const { fundId } = useParams();
  const setSelectedFundId = useSetRecoilState(selectedFundIdStateFP);

  useEffect(() => {
    const id = parseInt(fundId ?? '');
    if (!isNaN(id)) {
      setSelectedFundId(id);
    }
  }, [fundId, setSelectedFundId]);
}
