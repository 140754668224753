import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import { Typography, useTheme } from '@mui/material';
import { useAtomValue } from 'jotai';
import { FC } from 'react';
import { useRecoilValue } from 'recoil';
import { CompanyLogoAndName } from '../../../components/grid-renderers/CompanyCellRenderer';
import { getInvestmentLabel } from '../../../data-fields/TransactionFields';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { IFinanceRoundDataModel } from '../../../data-models/finance-round.data-model';
import { IFundDataModel } from '../../../data-models/fund.data-model';
import { IMetricFundDataModel } from '../../../data-models/metrics.data-model';
import { fundsByIdMapAtom, roundsByIdMapAtom } from '../../../services/state/AppConfigStateJ';
import { formatUSDLong2dp } from '../../../util/formatters/NumericFormatters';
import { HelpMessage } from '../../CompanyProfiles/components/Messages/HelpMessage';
import {
  FundName,
  InvestmentSummary,
  Summary,
  SummaryRow,
  SummaryRowValue,
  SummarySection,
} from './SummaryStyledComponents';
import { companyDataState, investmentDataState, transactionPreviewState } from './TransactionFormUIState';

interface IInvestmentDataProps {
  company: ICompanyDataModel;
  investmentLabel: string;
}

export const InvestmentData: FC<IInvestmentDataProps> = ({ company, investmentLabel }) => {
  const { colors } = useTheme();
  const color = colors.secondary[60];
  const variant = 'body2';
  return (
    <>
      <SummarySection className='highlight'>
        <InvestmentSummary>
          <CompanyLogoAndName
            logoUrl={company.logoUrl ?? ''}
            name={company.name}
            typographyProps={{ color, variant }}
            avatarProps={{ size: 'small' }}
          />
          <Typography variant={variant} color={color}>
            {investmentLabel}
          </Typography>
        </InvestmentSummary>
      </SummarySection>
    </>
  );
};

interface ISummaryFundDataProps {
  fund?: IFundDataModel;
  fundData: IMetricFundDataModel;
}
export const SummaryFundData: FC<ISummaryFundDataProps> = ({ fund, fundData }) => {
  if (!fund) return null;

  const { fmv, amountInvested, distributions } = fundData;

  return (
    <SummarySection>
      <SummaryRow className='highlight'>
        <FundName variant={'body2'}>
          <MonetizationOnOutlinedIcon color='primary' /> <span>{fund.name}</span>
        </FundName>
      </SummaryRow>
      <SummaryRow>
        <Typography variant={'caption'} color='text.secondary'>
          Investment Amount
        </Typography>
        <SummaryRowValue variant={'body2'}>{formatUSDLong2dp(amountInvested)}</SummaryRowValue>
      </SummaryRow>
      <SummaryRow>
        <Typography variant={'caption'} color='text.secondary'>
          FMV
        </Typography>
        <SummaryRowValue variant={'body2'}>{formatUSDLong2dp(fmv)}</SummaryRowValue>
      </SummaryRow>
      <SummaryRow>
        <Typography variant={'caption'} color='text.secondary'>
          Realized Value
        </Typography>
        <SummaryRowValue variant={'body2'}>{formatUSDLong2dp(distributions)}</SummaryRowValue>
      </SummaryRow>
    </SummarySection>
  );
};

export const TransactionFormSummary: FC = () => {
  const investment = useRecoilValue(investmentDataState);
  const company = useRecoilValue(companyDataState);
  const metrics = useRecoilValue(transactionPreviewState);
  const fundsById = useAtomValue(fundsByIdMapAtom);
  const roundsById = useAtomValue(roundsByIdMapAtom);
  const investmentLabel = investment
    ? getInvestmentLabel(investment as IFinanceRoundDataModel, roundsById)
    : '';

  return (
    <>
      <Summary>
        <Typography variant='body1'>Transaction Summary</Typography>
        {<InvestmentData company={company as ICompanyDataModel} investmentLabel={investmentLabel} />}
        <>
          {metrics?.fundData?.length ? (
            metrics.fundData.map((fundData) => {
              return (
                <SummaryFundData
                  key={fundData.fundId}
                  fund={fundsById.get(fundData.fundId ?? -1)}
                  fundData={fundData}
                />
              );
            })
          ) : (
            <HelpMessage message={'No preview data available'} severity={'warning'} />
          )}
        </>
      </Summary>
    </>
  );
};
