import { Divider, Stack, Typography } from '@mui/material';
import { useAtomValue } from 'jotai/index';
import { Fragment, useMemo } from 'react';
import { MUIAvatar } from '../../../components/Avatar/MUIAvatar';
import { RelationshipScore } from '../../../components/RelationshipScore/RelationshipScore';
import { ROUTES } from '../../../constants/RouteNameMapping';
import { IUserRelationDataModel } from '../../../data-models/user-relation.data-model';
import { usersByIdMapAtom } from '../../../services/state/AppConfigStateJ';
import { NoDataMessage2 } from '../../CompanyProfiles/components/Messages/HelpMessage';
import { useProfileBasePath } from '../../CompanyProfiles/useProfileBasePath';
import { sortByRelationshipScore, usePeopleBreakdown } from '../utils/usePeopleBreakdown';
import { usePanelPagination } from './CompanyDetailsPanel';
import { DetailedPersonData, DetailedPersonDataContainer } from './LeadershipPanel';
import { Panel } from './Panel';
import { PersonActivities } from './PersonActivities';

export function SummaryStrongestConnections() {
  const { getRelationships } = usePeopleBreakdown();
  const relationships = useMemo(() => getRelationships().slice(0, 3), [getRelationships]);
  const baseProfileUrl = useProfileBasePath();

  const { paginationConfig, visibleFields } = usePanelPagination({
    fields: relationships ?? [],
    itemsPerPage: 1,
  });

  const noData = relationships.length === 0;

  return (
    <Panel
      title={'Strongest Connections'}
      showMore={{
        url: `${baseProfileUrl}/${ROUTES.COMPANY_PROFILE_PEOPLE}`,
        title: 'Go to People',
      }}
      paginationConfig={paginationConfig}
    >
      {noData ? (
        <NoDataMessage2 />
      ) : (
        <>
          {visibleFields.map((person) => (
            <Stack key={person.id} display={'grid'} height='100%'>
              <DetailedPersonDataContainer style={{ marginBottom: '0.5rem' }}>
                <DetailedPersonData person={person} key={person.id} />
              </DetailedPersonDataContainer>
              {person.relationships.length > 0 && (
                <Stack
                  display='grid'
                  gridTemplateColumns={'1fr 2fr'}
                  gap='0.15rem'
                  mt='0.5rem'
                  alignItems={'center'}
                  alignContent={'start'}
                >
                  <Typography variant='caption' color='text.secondary'>
                    Team Connection
                  </Typography>
                  <Typography variant='caption' color='text.secondary'>
                    Relationship Strength
                  </Typography>
                  <StrongestConnections relationships={person.relationships} />
                </Stack>
              )}
              <Stack>
                <Divider sx={{ my: '0.75rem' }} />
                <PersonActivities person={person} />
              </Stack>
            </Stack>
          ))}
        </>
      )}
    </Panel>
  );
}

function StrongestConnections({ relationships }: { relationships: IUserRelationDataModel[] }) {
  const usersById = useAtomValue(usersByIdMapAtom);
  const connections = relationships
    .filter((rel) => Boolean(usersById.get(rel.userId)))
    .sort(sortByRelationshipScore)
    .slice(0, 3);
  return (
    <>
      {connections.map((rel) => {
        const user = usersById.get(rel.userId)!;
        return (
          <Fragment key={user.id}>
            <Stack direction='row' gap='0.25rem'>
              <MUIAvatar nameParts={[user.firstName, user.lastName]} src={null} size='small' />
              <Typography variant='caption' whiteSpace={'nowrap'} display={'inline'}>
                {user.name}
              </Typography>
            </Stack>

            <RelationshipScore
              relationshipStrengthScoreNormalized={rel.relationshipStrengthScoreNormalized ?? null}
            />
          </Fragment>
        );
      })}
    </>
  );
}
