import CloseIcon from '@mui/icons-material/Close';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Box, Button, IconButton, LinearProgress, Stack, styled, Typography, useTheme } from '@mui/material';
import { ChangeEvent, DragEvent, SyntheticEvent, useCallback, useState } from 'react';
import { convertFileSize, validateFileExtension } from '../../../pages/KPI/utils';
import { S3URLRequestResponse, UploadedFileMeta, uploadFile } from '../../../services/queries/FileQueries';
import { fetchKPIRequestUploadedFileFromS3 } from '../../../services/queries/KPIRequestsQueries';
import { IFormFieldUploadMeta } from '../../../view-models/form.view-model';
import { IBaseFieldProps } from './types';

const DefaultMaxFileSize = 10 * 1024 * 1024; // 10MB

const InputFileWrapper = styled('div')`
  position: relative;
  padding: 1.5rem;
  width: 100%;
  display: grid;
  align-items: center;

  input[type=file], /* FF, IE7+, chrome (except button) */
  input[type=file]::-webkit-file-upload-button {
    /* chromes and blink button */
    cursor: pointer;
  }
  & input {
    all: unset;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
  }
`;

const preventDefaults = {
  onDragOver: (e: SyntheticEvent) => {
    e.preventDefault();
  },
  onDrop: (e: SyntheticEvent) => {
    e.preventDefault();
  },
};

export function FieldKPIFileUpload2(props: IBaseFieldProps<IFormFieldUploadMeta>) {
  const { formField, formProps } = props;
  const { disabled, onChange } = formProps;
  const { companyId, multiple, maxFileSizeMb = DefaultMaxFileSize } = formField.rendererMeta!;
  const fileMeta = formProps.value as UploadedFileMeta | UploadedFileMeta[] | null;
  const [value, setValue] = useState<UploadedFileMeta[]>(() => {
    if (!fileMeta) return [];
    if (Array.isArray(fileMeta)) {
      return fileMeta;
    }
    return [fileMeta];
  });
  const { colors } = useTheme();
  const [error, setError] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);

  const doUpload = useCallback(
    async (file: File | File[]) => {
      setError(null);
      const initialValue = [...value];
      let filesToUpload = Array.isArray(file) ? file : [file];
      let countBeforeValidate = filesToUpload.length;
      let errorText = '';

      filesToUpload = filesToUpload.filter((f) => validateFileExtension(f.name));
      if (filesToUpload.length < countBeforeValidate) {
        errorText = 'Files must be of valid type (xlsx, xls, csv, pdf)';
      }
      countBeforeValidate = filesToUpload.length;

      filesToUpload = filesToUpload.filter((f) => f.size <= maxFileSizeMb);
      if (filesToUpload.length < countBeforeValidate) {
        errorText = errorText
          ? `${errorText} and less than ${maxFileSizeMb / 1024 / 1024}MB`
          : `Files must be less than ${maxFileSizeMb / 1024 / 1024}MB`;
      }
      const newFiles = filesToUpload.map((f) => ({
        fileName: f.name,
        fileSize: f.size,
        contentType: f.type,
      }));
      setValue(multiple ? initialValue.concat(newFiles) : newFiles);
      if (filesToUpload.length > 0 && errorText) {
        setError(`Some files were excluded. ${errorText}`);
      } else if (filesToUpload.length === 0) {
        setError(errorText);
        return;
      }

      setIsUploading(true);
      await Promise.all(
        filesToUpload.map(async (f) => {
          const response = await uploadFile(companyId, f);
          return { ...response, file: f };
        })
      ).then((res) => {
        const files: (UploadedFileMeta | null)[] = [];
        const errors: (string | null)[] = [];
        res.forEach((response, i) => {
          const data = response?.data as (S3URLRequestResponse & { getUrl?: string }) | null;
          const error = response?.error;
          const f = response?.file;

          errors[i] = error || null;
          if (error || !data || !f) {
            files[i] = null;
          } else {
            files[i] = { fileName: f.name, fileSize: f.size, contentType: f.type, ...data };
          }
        });
        const uploaded = files.filter((f) => f != null);
        const newValue = initialValue.concat(uploaded);
        setValue(multiple ? newValue : [uploaded[0]]);
        if (errors.some((e) => e != null)) {
          setError('Error uploading file(s). Please try again.');
        }
        if (uploaded.length > 0) {
          onChange(multiple ? newValue : uploaded[0]);
        }
      });
      setIsUploading(false);
    },
    [value, maxFileSizeMb, companyId, onChange, multiple]
  );

  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files?.[0]) {
        if (multiple) {
          doUpload(Array.from(event.target.files));
        } else {
          doUpload(event.target.files[0]);
        }
      }
    },
    [doUpload, multiple]
  );

  const handleDrop = useCallback(
    (e: DragEvent) => {
      e.preventDefault();
      if (multiple) {
        doUpload(Array.from(e.dataTransfer.files));
      } else {
        doUpload(e.dataTransfer.files[0]);
      }
      e.stopPropagation();
    },
    [doUpload, multiple]
  );

  const onRemoveFile = useCallback(
    (file: UploadedFileMeta) => {
      const newValue = value.filter((f) => f.fileName !== file.fileName);
      if (newValue.length === 0) setError(null);
      setValue(newValue);
      if (multiple) {
        onChange(newValue);
      } else {
        onChange(null);
      }
    },
    [multiple, onChange, value]
  );

  const onRemoveAll = useCallback(() => {
    setError(null);
    setValue([]);
    if (multiple) {
      onChange([]);
    } else {
      onChange(null);
    }
  }, [multiple, onChange]);

  return (
    <>
      <div>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='center'
          sx={{
            border: `1px dashed ${colors.neutral[60]}`,
            borderRadius: '4px',
          }}
        >
          <InputFileWrapper
            style={{ justifyContent: value.length ? 'stretch' : 'center' }}
            onDrop={disabled ? undefined : handleDrop}
          >
            <input
              disabled={disabled}
              multiple={multiple}
              data-testid='file-upload-field'
              type='file'
              onChange={handleFileChange}
              accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/pdf,text/csv,application/xls,application/xlsx'
            />
            {value.length ? (
              <>
                <SelectedFiles
                  companyId={companyId}
                  isUploading={isUploading}
                  files={value}
                  onRemoveFile={onRemoveFile}
                  readOnly={disabled}
                />
                {value.length > 1 && !isUploading && (
                  <Button
                    disabled={disabled}
                    onClick={onRemoveAll}
                    sx={{
                      placeSelf: 'end',
                      width: 'fit-content',
                      marginBottom: '-.5rem',
                    }}
                    color='secondary'
                    {...preventDefaults}
                  >
                    {'Remove all'}
                  </Button>
                )}
              </>
            ) : (
              <Stack direction='row' alignItems='center' justifyContent='center'>
                {disabled ? (
                  <Typography color='text.secondary'>No Files</Typography>
                ) : (
                  <>
                    <CloudUploadOutlinedIcon sx={{ color: colors.neutral[50], mr: '0.5rem' }} />
                    <Typography
                      variant='body2'
                      sx={{
                        colors: colors.neutral[60],
                      }}
                    >
                      Drag and Drop or
                      <Typography ml={'.25rem'} component='span' variant='body2' color='secondary'>
                        Browse your files
                      </Typography>
                    </Typography>
                  </>
                )}
              </Stack>
            )}
          </InputFileWrapper>
        </Stack>

        {error && (
          <Typography variant='caption' component={'p'} sx={{ color: colors.critical[60] }}>
            {error}
          </Typography>
        )}
      </div>
    </>
  );
}

function FileTitle({ name, size }: { name: string; size: number }) {
  const { colors } = useTheme();

  return (
    <Box display='flex' gap='0.5rem' alignItems={'center'}>
      <Typography variant='body2' sx={{ color: colors.neutral[70] }}>
        {name}
      </Typography>
      <Typography color='text.secondary' variant='body2'>
        {convertFileSize(size ?? 0)}
      </Typography>
    </Box>
  );
}

function SelectedFiles({
  companyId,
  files: files,
  isUploading,
  onRemoveFile,
  readOnly,
}: {
  companyId: number;
  files: UploadedFileMeta[];
  onRemoveFile: (file: UploadedFileMeta) => void;
  isUploading?: boolean;
  readOnly?: boolean;
}) {
  const { colors } = useTheme();
  async function handleDownload(f: UploadedFileMeta) {
    if (!f?.fileId) return;
    const getUrl = await fetchKPIRequestUploadedFileFromS3({
      companyId,
      fileId: f?.fileId as string,
      contentType: f?.contentType,
      fileName: f?.fileName,
    });
    window.open(getUrl);
  }
  return (
    <Box width='100%'>
      {files.map((f) => {
        return (
          <Stack direction={'row'} alignItems='center' justifyContent='space-between' key={f.fileName}>
            <Stack direction={'row'} gap='.5rem' justifyContent={'center'} minHeight={'2rem'}>
              <InsertDriveFileOutlinedIcon sx={{ color: colors.neutral[60] }} fontSize='medium' />
              <Button onClick={() => handleDownload(f)} {...preventDefaults}>
                <FileTitle name={f.fileName} size={f.fileSize} />
              </Button>
            </Stack>
            {!readOnly && (
              <IconButton
                size='small'
                onClick={(e) => {
                  e.preventDefault();
                  onRemoveFile(f);
                }}
                disabled={isUploading}
                {...preventDefaults}
              >
                <CloseIcon fontSize='small' />
              </IconButton>
            )}
          </Stack>
        );
      })}

      <>
        {isUploading ? (
          <LinearProgress color={'primary'} sx={{ width: '100%', px: '.25rem' }} />
        ) : (
          <div style={{ height: '4px' }} />
        )}
      </>
    </Box>
  );
}
