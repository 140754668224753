import { Link } from 'react-router';
import { IconButton } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

interface IBackButtonProps {
  to: string;
  title: string;
}

export function BackButton({ to, title }: IBackButtonProps) {
  return (
    <IconButton component={Link} to={to} size='small' color='secondary' title={title}>
      <ArrowBackIosNewIcon />
    </IconButton>
  );
}
