import { useMatch, useNavigate, useParams, useSearchParams } from 'react-router';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { selectedDealCategoryState, selectedDealTypeState } from '../state/DealboardUIState';
import { dealBoardConfigState } from '../state/DealboardDataState';
import { ROUTES } from '../../../constants/RouteNameMapping';
import { DealBoardDealCategory, SelectItem } from '../../../types';
import { DEAL_TYPE_PARAM } from '../constants/dealsSearchParams';

const ITEMS: SelectItem[] = [
  { id: DealBoardDealCategory.CURRENT, value: 'Current Deals' },
  { id: DealBoardDealCategory.PASS, value: 'Passed Deals' },
  { id: DealBoardDealCategory.MISSED, value: 'Missed Deals' },
  { id: DealBoardDealCategory.TRACK, value: 'Tracked Deals' },
  { id: DealBoardDealCategory.CLOSED, value: 'Closed Deals' },
];

export function useSyncFromPath() {
  const [initialized, setInitialized] = useState(false);
  const match = useMatch(`/${ROUTES.DEAL_FLOW}/*`);
  const { dealCategory } = useParams();
  const [search] = useSearchParams();
  const { dealTypes } = useRecoilValue(dealBoardConfigState);
  const navigate = useNavigate();
  const setSelectedDealTypeState = useSetRecoilState(selectedDealTypeState);
  const [selectedDealCategory, setSelectedDealCategory] = useRecoilState(selectedDealCategoryState);

  useEffect(() => {
    if (!match) {
      return;
    }

    const category = dealCategory;

    const typeId = search.get(DEAL_TYPE_PARAM) ? parseInt(search.get(DEAL_TYPE_PARAM) ?? '') : null;
    const goToType = !typeId ? dealTypes[0].id : typeId;

    if (!category) {
      navigate(`/${ROUTES.DEAL_FLOW}/${ROUTES.DEALS}/${selectedDealCategory.id}`, { replace: true });
    } else {
      setSelectedDealCategory(ITEMS.find((item) => item.id === category) as SelectItem);
      setSelectedDealTypeState(goToType);
    }
    setInitialized(true);
  }, [
    dealCategory,
    dealTypes,
    match,
    navigate,
    search,
    selectedDealCategory.id,
    setSelectedDealCategory,
    setSelectedDealTypeState,
  ]);

  return initialized;
}
