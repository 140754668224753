import { useAtomValue } from 'jotai';
import { FC } from 'react';
import { IDealDataModel } from '../../../../../data-models/deal.data-model';
import { usersByEmailMapAtom } from '../../../../../services/state/AppConfigStateJ';
import { formatDate } from '../../../../../util/formatters/DateFormatters';
import { DrawerLabel, DrawerUneditableContent } from './commonDrawerComponents';

interface IAdditionalInfoProps {
  deal: IDealDataModel;
}

export const AdditionalInfo2: FC<IAdditionalInfoProps> = ({ deal }) => {
  const usersByEmailMap = useAtomValue(usersByEmailMapAtom);

  return (
    <>
      <DrawerLabel>Date Added</DrawerLabel>
      <DrawerUneditableContent variant='body2'>{formatDate(deal.createdAt ?? '')}</DrawerUneditableContent>
      <DrawerLabel>Created By</DrawerLabel>
      <DrawerUneditableContent variant='body2'>
        {usersByEmailMap.get(deal.createdBy ?? '')?.name ?? ''}
      </DrawerUneditableContent>
    </>
  );
};
