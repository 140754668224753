import { FC } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { useNavigate } from 'react-router';
import { useRecoilValue } from 'recoil';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { ROUTES } from '../../../constants/RouteNameMapping';
import { MetricsReconDataModel } from '../../../schemas/MetricsRecon.schema';
import { FMT } from '../../../util/formatter-service';
import { companyState } from '../../../services/state/CompanyState';
import { LinkButtonRenderer } from './LinkButtonRenderer';

export const CompanyTransactionsLink: FC<
  ICellRendererParams<MetricsReconDataModel, ICompanyDataModel | undefined>
> = ({ data }) => {
  const { fundId, companyId } = data ?? {};
  const navigate = useNavigate();
  const company = useRecoilValue(companyState(companyId ?? -1));

  if (!company) return null;

  return (
    <LinkButtonRenderer
      onClick={() =>
        navigate(
          `/${ROUTES.FINANCE2}/${ROUTES.FINANCE2_OVERVIEW}/${ROUTES.FINANCE_DETAILS}/${ROUTES.FINANCE_TRANSACTIONS}?company=${companyId}`,
          {
            state: { fundFilter: FMT.format('fund', fundId) },
          }
        )
      }
      title={`View ${company.name}'s transactions`}
    />
  );
};
