import { atom, selector } from 'recoil';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { portCosState } from '../PortCosState';
import { fetchTemplatesAssignments } from '../../queries/KPITemplatesQueries';
import { TemplateAssignment } from '../../../schemas/template-assignment-schema';

export const kpisSelectedTemplateAssignmentState = atom<number>({
  key: 'kpiSelectedTemplateAssignation',
  default: -1,
});

export const kpiTemplateAssignmentsListState = atom<TemplateAssignment[]>({
  key: 'kpiTemplatesAssignmentsListState',
  default: selector({
    key: 'kpiTemplatesAssignmentsListState/Default',
    get: () => fetchTemplatesAssignments(),
  }),
});

export const kpiTemplateAssignmentsByIdState = selector({
  key: 'kpiTemplateAssignmentsMapByIdState',
  get: ({ get }) => {
    const kpisTemplateAssignmentsList = get(kpiTemplateAssignmentsListState);
    const kpisTemplateAssignmentsMap = new Map<number, TemplateAssignment>();

    if (!kpisTemplateAssignmentsList) return kpisTemplateAssignmentsMap;

    kpisTemplateAssignmentsList.forEach((assignment) => {
      kpisTemplateAssignmentsMap.set(assignment.id, assignment);
    });

    return kpisTemplateAssignmentsMap;
  },
});

export const templateAssignmentsByCompanyIdState = selector<Map<number, TemplateAssignment[]>>({
  key: 'assignedCompaniesState',
  get: ({ get }) => {
    const kpisTemplateAssignments = get(kpiTemplateAssignmentsListState) ?? [];
    return kpisTemplateAssignments.reduce((acc, assignment) => {
      acc.has(assignment.companyId)
        ? acc.get(assignment.companyId)!.push(assignment)
        : acc.set(assignment.companyId, [assignment]);
      return acc;
    }, new Map<number, TemplateAssignment[]>());
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
});
export const assignedTemplateUuidsByCompanyIdState = selector<Map<number, Set<string>>>({
  key: 'assignedTemplateUuidsByCompanyIdState',
  get: ({ get }) => {
    const assignmentsByCompanyId = get(templateAssignmentsByCompanyIdState);
    const uuidsByCompanyId = new Map<number, Set<string>>();

    assignmentsByCompanyId.forEach((assignments, companyId) => {
      uuidsByCompanyId.set(companyId, new Set(assignments.map((assignment) => assignment.templateUuid)));
    });

    return uuidsByCompanyId;
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
});

export const kpiUnassignedCompaniesListState = selector<ICompanyDataModel[] | null>({
  key: 'kpiUnassignedTemplatesListState',
  get: ({ get }) => {
    const portfolioCompanies = get(portCosState);
    const kpisTemplatesAssignmentsList = get(kpiTemplateAssignmentsListState);
    const assignmentsCompaniesId = kpisTemplatesAssignmentsList?.map((assignment) => assignment.companyId);

    return (
      portfolioCompanies?.filter((company) => company && !assignmentsCompaniesId?.includes(company.id)) ?? []
    );
  },
});
