import { ColDef, GridApi, GridReadyEvent, ValueGetterParams } from 'ag-grid-community';
import { useAtomValue } from 'jotai';
import { useCallback, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { AgTable } from '../../../components/AgTable/AgTable';
import { RoundFromInvestmentCellRenderer } from '../../../components/AgTable/cell-renderers/RoundCellRenderer';
import { useExtendedExportSettings } from '../../../components/AgTable/exportToExcelDefs';
import { FadeInGridWrapper } from '../../../components/grid-renderers/FadeInGridWrapper';
import { ITransactionDataModel } from '../../../data-models/transaction.data-model';
import { commonTransactionGridSchema } from '../../../schemas/AbstractTransactionSchema';
import { useGetTransactionGridColdDefsForTypeId } from '../../../schemas/transactions/TransactionSchemaUtil';
import { roundsByIdMapAtom } from '../../../services/state/AppConfigStateJ';
import { withOverrides } from '../../../util/ag-grid-utils';
import { genericComparator } from '../../../util/comparators';
import { useSchemaToGrid } from '../../../util/schema-utils';
import { eqBuyGridSchema } from '../../Finance/components/TransactionModal/Forms/schemas/Equity/EquityBuySchema';
import { genericKeyCreator } from '../common-grid-defs/commonColDefs';
import { defaultColDef } from '../common-grid-defs/defaultColDefs';
import { getMenuColDef } from '../Grids/useTransactionColDefs';
import { allInvestmentRoundsMapState } from '../state/FinanceState';
import { useCommonTransactionGridDefs } from '../Transactions3/Transactions3';
import { IExtendedTransactionDataModel } from './useAllTransactionsData';
import { useAllTransactionsGridFilters } from './useAllTransactionsGridFilters';

const customDefs: Record<string, ColDef<IExtendedTransactionDataModel>> = {
  transactionDate: {
    sortable: true,
    initialSort: 'desc',
    sortIndex: 0,
  },
  fundId: {
    filter: false,
  },
  transactionTypeId: {
    filter: false,
  },
  id: {
    hide: true,
    sortable: true,
    sort: 'asc',
    sortIndex: 1,
  },
};

export interface IAllTransactionsGridProps {
  transactions?: ITransactionDataModel[];
}
export function AllTransactionsGrid({ transactions }: IAllTransactionsGridProps) {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const { doesExternalFilterPass, isExternalFilterPresent, selectedTypes } =
    useAllTransactionsGridFilters(gridApi);
  const schemaToGrid = useSchemaToGrid();
  const investmentsById = useRecoilValue(allInvestmentRoundsMapState);
  const roundsById = useAtomValue(roundsByIdMapAtom);
  const getSchemaForTransactionTypeId = useGetTransactionGridColdDefsForTypeId();
  const { getRowId } = useCommonTransactionGridDefs();

  const onGridReady = useCallback((event: GridReadyEvent) => {
    setGridApi(event.api);
  }, []);

  const { exportParams } = useExtendedExportSettings(
    new Set(['companyId', 'fundId', 'transactionTypeId', 'roundId']),
    'All Transactions'
  );

  const initialGridSettings = useMemo(() => {
    let defs = schemaToGrid(eqBuyGridSchema().concat(commonTransactionGridSchema()), [
      'companyId',
      'transactionDate',
      'fundId',
      'transactionTypeId',
      'investmentName',
      'status',
      'id',
    ]);
    defs.push(getMenuColDef());
    defs.splice(5, 0, {
      headerName: 'Stage',
      valueGetter: (params: ValueGetterParams) => {
        const investment = investmentsById.get(params.data?.investmentRoundId ?? -1);
        if (!investment) return '';
        const round = roundsById.get(investment.roundId ?? -1);
        return round?.name ?? '';
      },
      cellRenderer: RoundFromInvestmentCellRenderer,
      keyCreator: genericKeyCreator,
      enableRowGroup: true,
    });

    if (selectedTypes.size === 1) {
      const companyCol = defs[0];
      defs = getSchemaForTransactionTypeId([...selectedTypes.values()][0]) as ColDef[];
      defs.unshift(companyCol);
    }

    const columnDefs = withOverrides(defs, customDefs);

    return {
      columnDefs,
      defaultColDefs: { ...defaultColDef, minWidth: 150 },
      autoGroupColumnDef: { comparator: genericComparator },
    };
  }, [getSchemaForTransactionTypeId, investmentsById, roundsById, schemaToGrid, selectedTypes]);

  return (
    <>
      <div style={{ paddingTop: '1rem', height: '72vh' }}>
        {
          <FadeInGridWrapper key={[...selectedTypes.values()].join(',')}>
            <AgTable
              columnDefs={initialGridSettings.columnDefs}
              rowData={transactions}
              defaultColDef={initialGridSettings.defaultColDefs}
              autoGroupColumnDef={initialGridSettings.autoGroupColumnDef}
              onGridReady={onGridReady}
              doesExternalFilterPass={doesExternalFilterPass}
              isExternalFilterPresent={isExternalFilterPresent}
              defaultExcelExportParams={exportParams}
              defaultCsvExportParams={exportParams}
              getRowId={getRowId}
              suppressScrollOnNewData
              grandTotalRow='bottom'
            />
          </FadeInGridWrapper>
        }
      </div>
    </>
  );
}
