import { useAtomValue } from 'jotai';
import { fundsAtom } from '../../../services/state/AppConfigStateJ';
import { GenericConfigPage } from '../Common/GenericConfigPage';
import { getFundFields } from './FundFields';

export function ConfigFund() {
  const funds = useAtomValue(fundsAtom);

  return (
    <GenericConfigPage
      baseTitle={'Fund'}
      data={funds}
      fields={getFundFields()}
      pageTitle={'Funds'}
      queryURL={'/fund'}
    />
  );
}
