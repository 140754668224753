import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { useMemo } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CompanyCellRenderer } from '../../../../../components/grid-renderers/CompanyCellRenderer';
import AllCompaniesActionsCellRenderer from '../components/cellRenderers/AllCompaniesActionsCellRenderer';
import { entityFormatter } from '../../../common/GridFormatters';
import { useCommonKpiColDefs } from '../../KPIRequests/hooks/useCommonKpiColDefs';
import { MaggieFeatureFlags } from '../../../../../util/feature-flags';

export const CompanyColId = 'company';

/**@deprecated */
export function useKPICompaniesAllColDefs() {
  const { respondentColDef, commonCompanyDefs } = useCommonKpiColDefs();
  const { showDataCollectionCompanies2 } = useFlags<MaggieFeatureFlags>();

  const columnDefs: ColDef[] = useMemo(() => {
    const defs: ColDef[] = [
      {
        ...commonCompanyDefs,
        rowGroup: true,
        keyCreator: (params) => `${params.value?.name}-${params.value?.id}`,
        enableRowGroup: true,
      },
      {
        field: 'sector',
        headerName: 'Sector',
        valueGetter: (params) => {
          if (!params.data?.sector) return '';

          return params.data?.sector.name ?? '';
        },
        enableRowGroup: true,
      },
      {
        field: 'template',
        headerName: 'Template',
        valueGetter: (params) => {
          if (!params.data?.template) return '';
          return params.data?.template.name;
        },
        enableRowGroup: true,
      },
      {
        field: 'frequency',
        headerName: 'Frequency',
        valueFormatter: entityFormatter,
        enableRowGroup: true,
      },
      respondentColDef,
      {
        field: '',
        maxWidth: 60,
        pinned: 'right',
        sortable: false,
        resizable: false,
        cellRenderer: AllCompaniesActionsCellRenderer,
      },
    ];
    if (!showDataCollectionCompanies2) {
      defs[0] = {
        colId: CompanyColId,
        headerName: 'Company',
        rowGroup: true,
        keyCreator: (params) => `${params.data?.company?.name}`,
        cellRenderer: CompanyCellRenderer,
        getQuickFilterText: (params) => params.value?.name,
        enableRowGroup: true,
        valueGetter: (params) => params.data?.company,
        valueFormatter: (params: ValueFormatterParams) => `${params.data?.company?.name}`,
        filter: 'agSetColumnFilter',
      };
      defs.splice(defs.length - 2, 1); // remove respondents column
    }
    return defs;
  }, [commonCompanyDefs, respondentColDef, showDataCollectionCompanies2]);

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      minWidth: 120,
      resizable: true,
      sortable: true,
      filter: false,
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      getQuickFilterText: () => {
        return '';
      },
    }),
    []
  );

  return {
    columnDefs,
    defaultColDef,
  };
}
