import { ColDef } from 'ag-grid-community';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { CompanyCellRenderer } from '../../../../../components/grid-renderers/CompanyCellRenderer';
import { ICompanyDataModel } from '../../../../../data-models/company.data-model';
import { sectorsByIdMapAtom } from '../../../../../services/state/AppConfigStateJ';
import ActionsCellRenderer from '../components/cellRenderers/ActionsCellRenderer';

/**@deprecated */
export function useKPICompaniesUnassignedColDefs() {
  const sectorsMap = useAtomValue(sectorsByIdMapAtom);

  const columnDefs: ColDef<ICompanyDataModel>[] = useMemo(
    () => [
      {
        headerName: 'Company',
        maxWidth: 200,
        keyCreator: (params) => params.value.name,
        cellRenderer: CompanyCellRenderer,
        valueGetter: (params) => {
          return params.data;
        },
        getQuickFilterText: (params) => params.value?.name,
      },
      {
        field: 'sectorId',
        headerName: 'Sector',
        flex: 1,
        keyCreator: (params) => params.value,
        valueGetter: (params) => {
          if (!params.data?.sectorId) return '';

          return sectorsMap.get(params.data?.sectorId)?.name ?? '';
        },
      },
      {
        maxWidth: 60,
        pinned: 'right',
        sortable: false,
        resizable: false,
        cellRenderer: ActionsCellRenderer,
      },
    ],
    [sectorsMap]
  );

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      resizable: true,
      sortable: true,
      filter: false,
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      enableRowGroup: true,
      getQuickFilterText: () => {
        return '';
      },
    }),
    []
  );

  return {
    columnDefs,
    defaultColDef,
  };
}
