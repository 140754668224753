import { ICompanyDataModel } from '../../../../data-models/company.data-model';
import { RendererType } from '../../../../data-models/field.data-model';
import { isNumericRenderer } from '../../../../data-models/field2.data-model';
import {
  IFundDataMetricFieldsDataModel,
  IMetricFundDataModel,
  IMetricsDataModel,
  createFundDataMetricsFieldsDataModel,
} from '../../../../data-models/metrics.data-model';
import { ColumnMeta } from '../../../../types';

export class MetricsFundDataFieldsCalculator {
  run(
    metric: IMetricsDataModel,
    isGroupedByFund: boolean,
    getCompany: (id: number) => ICompanyDataModel | null,
    customFundFields: ColumnMeta[] = []
  ): IFundDataMetricFieldsDataModel {
    const allFundDataFields = createFundDataMetricsFieldsDataModel();
    const company = getCompany(metric.companyId);
    const exitTypes = new Set<string>();
    const exitValuations: number[] = [];
    let dilutionAssumptionSum = 0;

    let noOfShares = 0;

    const fundsData: IMetricFundDataModel[] = metric.fundData || [];
    fundsData.forEach((fund) => {
      dilutionAssumptionSum += fund.dilutionAssumption ?? 0;
      allFundDataFields.reserves += fund.reserves ?? 0;
      noOfShares += fund.noOfShares ?? 0;
      allFundDataFields.acquisitionShares += fund.acquisitionShares ?? 0;
      allFundDataFields.exitOwnershipPercentage = isGroupedByFund
        ? (fund.exitOwnershipPercentage ?? 0)
        : (allFundDataFields.exitOwnershipPercentage ?? 0) + (fund.exitOwnershipPercentage ?? 0);
      allFundDataFields.convertedOwnership = isGroupedByFund
        ? (fund.convertedOwnership ?? 0)
        : (allFundDataFields.convertedOwnership ?? 0) + (fund.convertedOwnership ?? 0);
      allFundDataFields.entryOwnership = isGroupedByFund ? (fund.entryOwnership ?? 0) : null;
      allFundDataFields.fmvBasis = isGroupedByFund ? (fund.fmvBasis ?? 0) : null;

      customFundFields.forEach((field) => {
        const fieldName = field.path.split('fundCompany.customData.')[1] ?? '';
        const customFundFieldsValues = allFundDataFields._customFundFields;

        if (fund.customData) {
          if (isGroupedByFund) {
            if (isNumericRenderer(field.type as RendererType)) {
              customFundFieldsValues[fieldName] = fund.customData[fieldName] ?? 0;
            }
            if (field.type === 'string') {
              customFundFieldsValues[fieldName] = fund.customData[fieldName] ?? '';
            }
          } else {
            if (isNumericRenderer(field.type as RendererType)) {
              customFundFieldsValues[fieldName] =
                ((customFundFieldsValues[fieldName] as number) ?? 0) +
                ((fund.customData[fieldName] as number) ?? 0);
            }
            if (field.type === 'string' && fund.customData[fieldName]) {
              const currentValue = customFundFieldsValues[fieldName];
              customFundFieldsValues[fieldName] = !currentValue
                ? fund.customData[fieldName]
                : `${currentValue}, ${fund.customData[fieldName]}`;
            }
          }
        }
      });

      if (fund?.exitType) {
        exitTypes.add(fund.exitType);
      }
      if (fund?.exitValuation !== undefined && fund.exitValuation !== null) {
        exitValuations.push(fund.exitValuation);
      }
      allFundDataFields.projectedProceeds += fund.projectedProceeds ?? 0;
    });

    if (!allFundDataFields.exitOwnershipPercentage) {
      allFundDataFields.exitOwnershipPercentage = null;
    }

    if (company?.fullyDilutedShares) {
      const fullyDilutedShares = Number(company?.fullyDilutedShares);
      if (!isNaN(fullyDilutedShares) && fullyDilutedShares > 0) {
        allFundDataFields.ownerShipPercentage = Number(((100 * noOfShares) / fullyDilutedShares).toFixed(10));

        if (allFundDataFields.ownerShipPercentage > 100) {
          allFundDataFields.ownerShipPercentage = null;
        }
      }
    }

    allFundDataFields.projectedMOIC = allFundDataFields.projectedProceeds / (metric.amountInvested || 1);

    allFundDataFields.exitType = exitTypes.size > 0 ? [...exitTypes].join(', ') : null;
    allFundDataFields.exitValuation = exitValuations.length
      ? exitValuations.reduce((a, b) => a + b, 0) / exitValuations.length
      : null;

    return {
      ...allFundDataFields,
      dilutionAssumption: dilutionAssumptionSum / fundsData.length,
      initialPreMoney: this.getInitialPreMoney(fundsData),
      acquisitionDate: this.getAcquisitionDate(fundsData),
    };
  }

  private getInitialPreMoney(fundsData: IMetricFundDataModel[]): number {
    const values = fundsData
      .filter((fundData) => Number.isFinite(fundData?.initialPreMoney))
      .map((fundData) => fundData.initialPreMoney as number);

    if (!values?.length) return 0;

    return Math.min(...values);
  }

  private getAcquisitionDate(fundsData: IMetricFundDataModel[]): string | null {
    const values = fundsData
      .filter((fundData) => fundData?.acquisitionDate)
      .map(({ acquisitionDate: dateString }) => ({
        dateString,
        timestamp: new Date(dateString as unknown as string).getTime(),
      }));

    if (!values?.length) return null;

    values.sort((a, b) => a.timestamp - b.timestamp);

    return values[0].dateString as string;
  }
}
