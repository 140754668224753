import { useLocation } from 'react-router';
import { ROUTES } from '../../../constants/RouteNameMapping';

export const useParseCompanyIdFromPath = (): number => {
  const { pathname } = useLocation();
  if (!pathname.startsWith(`/${ROUTES.COMPANY_PROFILES}`)) return -1;
  const rawId = pathname.split('/')[2];
  const matches = rawId.match(/-\d+/gi) as string[];
  return Number(matches[matches.length - 1].slice(1));
};
