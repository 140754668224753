import { useAtomValue } from 'jotai/index';
import { CSSProperties, useMemo } from 'react';
import { MuiSingleSelect } from '../../../components/MuiSingleSelect/MuiSingleSelect';
import { activeUsersAtom } from '../../../services/state/AppConfigStateJ';

type SelectUserProps = {
  name: string;
  disablePortal?: boolean;
  value: number | number[] | undefined;
  onChange: (id: number | number[] | null) => void;
  placeholder?: string;
  style?: CSSProperties;
};

export const UserSelector = ({
  name,
  value,
  onChange,
  placeholder,
  style,
  disablePortal = true,
}: SelectUserProps) => {
  const data = useAtomValue(activeUsersAtom);

  const mappedUserOptions = useMemo(() => {
    if (!data) return [];
    return data.map((u) => ({ id: u.id, value: u.name }));
  }, [data]);

  if (!data) return null;
  return (
    <MuiSingleSelect
      disablePortal={disablePortal}
      options={mappedUserOptions}
      fieldName={name}
      value={mappedUserOptions.find((o) => o.id === value)}
      onChange={(_, option) => onChange((option?.id as number) ?? null)}
      fieldPlaceholder={placeholder}
      style={style}
    />
  );
};
