import { useCallback } from 'react';
import { IconButton, Stack, useTheme } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import { ICellRendererParams } from 'ag-grid-community';
import { useSetRecoilState } from 'recoil';
import { useLoadingBarState } from '../../../../../components/LoadingBar/LoadingBarContext';
import { useKPIRequestsNotSentActions } from '../hooks/useKPIRequestsNotSentActions';
import { kpisSelectedRequestToDeleteModalState } from '../../../../../services/state/KPI/KPIRequestsState';
import { PermissionService } from '../../../../../services/PermissionService';
import { PermissionKey } from '../../../../../services/PermissionAndRolesKeys';
import { IKPIRequestDataModel } from '../../../../../data-models/kpi-requests.data-model';

export default function UnsentReqsActionsRenderer(params: ICellRendererParams<IKPIRequestDataModel>) {
  const permSvc = PermissionService.get();
  const { colors } = useTheme();
  const {
    state: { isLoading },
  } = useLoadingBarState();
  const { sendKPIRequestAction } = useKPIRequestsNotSentActions();
  const setKpisSelectedRequestToDeleteModal = useSetRecoilState(kpisSelectedRequestToDeleteModalState);

  const handleOnSendRequest = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      if (!params?.data?.id) return;
      sendKPIRequestAction({
        requestsIds: [params?.data?.id],
      });
    },
    [params?.data?.id, sendKPIRequestAction]
  );

  const handleOnDeleteRequest = useCallback(() => {
    if (!params?.data?.id) return;
    setKpisSelectedRequestToDeleteModal(params?.data?.id);
  }, [params?.data?.id, setKpisSelectedRequestToDeleteModal]);

  if (params?.node?.group) return null;

  return permSvc.hasPermission(PermissionKey.canWriteDataCollection) ? (
    <Stack direction='row' justifyContent='center' alignItems='center' gap='.25rem'>
      <IconButton
        onClick={handleOnSendRequest}
        disabled={isLoading}
        color='secondary'
        size={'medium'}
        title='send'
      >
        <SendIcon sx={{ color: colors.primary[60] }} />
      </IconButton>
      <IconButton
        onClick={handleOnDeleteRequest}
        disabled={isLoading}
        color='secondary'
        size={'medium'}
        title='delete'
      >
        <BlockOutlinedIcon sx={{ color: colors.primary[60] }} />
      </IconButton>
    </Stack>
  ) : null;
}
