import CheckIcon from '@mui/icons-material/Check';
import { ButtonBase, Typography, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { useMatch, useNavigate } from 'react-router';
import { useRecoilValue } from 'recoil';
import { ROUTES } from '../../../../../constants/RouteNameMapping';
import { DEAL_TYPE_PARAM } from '../../../../../pages/DealFlow2/constants/dealsSearchParams';
import { dealBoardConfigState } from '../../../../../pages/DealFlow2/state/DealboardDataState';
import { selectedDealCategoryState } from '../../../../../pages/DealFlow2/state/DealboardUIState';
import { DealBoardDealCategory, SelectItem } from '../../../../../types';
import { SubNavSectionWrapper } from '../../SubSectionNavigation/SubSectionNavItems';
import { SubNavItemWrapper } from '../../SubSectionNavigation/components/SubNavItem/SubNavItem';

const ITEMS: SelectItem[] = [
  { id: DealBoardDealCategory.CURRENT, value: 'Active Deals' },
  { id: DealBoardDealCategory.PASS, value: 'Passed Deals' },
  { id: DealBoardDealCategory.MISSED, value: 'Missed Deals' },
  { id: DealBoardDealCategory.TRACK, value: 'Tracked Deals' },
  { id: DealBoardDealCategory.CLOSED, value: 'Closed Deals' },
];

export const DealCategoryList = () => {
  const selectedDealCategory = useRecoilValue(selectedDealCategoryState);
  const match = useMatch(`${ROUTES.DEAL_FLOW}/${ROUTES.DEALS}/*`);
  const { secondary } = useTheme().colors;
  const navigate = useNavigate();
  const { dealTypes } = useRecoilValue(dealBoardConfigState);

  const handleItemClick = useCallback(
    (itemId: DealBoardDealCategory) => {
      const path = `${ROUTES.DEAL_FLOW}/${ROUTES.DEALS}/${itemId}`;
      if (itemId === DealBoardDealCategory.CURRENT) navigate(`${path}?${DEAL_TYPE_PARAM}=${dealTypes[0].id}`);
      else navigate(path);
    },
    [dealTypes, navigate]
  );

  return (
    <SubNavSectionWrapper>
      {ITEMS.map((item) => (
        <ButtonBase
          key={item.id}
          onClick={() => handleItemClick(item.id as DealBoardDealCategory)}
          TouchRippleProps={{ color: 'white' }}
          style={{ width: '100%', display: 'block' }}
        >
          <SubNavItemWrapper isSelected={!!match && item.id === selectedDealCategory.id}>
            <Typography variant='caption' color={secondary[10]}>
              {item.value}
            </Typography>
            {!!match && item.id === selectedDealCategory.id && (
              <CheckIcon style={{ color: secondary[10], fontSize: '1rem', marginRight: '4px' }} />
            )}
          </SubNavItemWrapper>
        </ButtonBase>
      ))}
    </SubNavSectionWrapper>
  );
};
