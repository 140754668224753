import { useCallback } from 'react';
import { Collapse, Divider, ToggleButton, Typography } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import { useRecoilState, useRecoilValue } from 'recoil';
import { css } from '@emotion/react';
import { useAtomValue } from 'jotai';
import { cardStyles } from '../../../theme/component-styles';
import { latestFundDataStateFP, selectedFundStateFP, showWaterfallSettingsState } from '../state/FPState';
import { WaterfallSettingsFields } from './WaterfallSettingsFields';
import { WaterfallSettingsEditableFields } from './WaterfallSettingsEditableFields';

const SettingsContainer = css({
  ...cardStyles,
  width: '14rem',
  marginRight: '0.5rem',
  paddingTop: '1rem',
  height: '100%',
});

export function WaterfallSettings() {
  const show = useRecoilValue(showWaterfallSettingsState);
  const fund = useRecoilValue(selectedFundStateFP);
  const fundData = useAtomValue(latestFundDataStateFP(fund?.id ?? -1));

  if (!fund) return null;

  return (
    <Collapse in={show} orientation='horizontal'>
      <div css={SettingsContainer}>
        <Typography variant='body2' component='h6'>
          Waterfall Parameters
        </Typography>
        <WaterfallSettingsFields fund={fund} />
        <Divider />
        <WaterfallSettingsEditableFields
          fundData={fundData}
          fund={fund}
          key={`${fundData?.date}-${fundData?.updatedAt ?? ''}`}
        />
      </div>
    </Collapse>
  );
}

const ToggleSettings = css`
  border: none;
  padding: 0.1rem 0.2rem;
`;

export function WaterfallSettingsToggle() {
  const [show, setShow] = useRecoilState(showWaterfallSettingsState);

  const onChange = useCallback(() => {
    setShow((prev) => !prev);
  }, [setShow]);

  const value = 'toggle settings';

  return (
    <ToggleButton
      value={value}
      selected={show}
      onChange={onChange}
      size='small'
      title={value}
      css={ToggleSettings}
      color={show ? 'primary' : undefined}
    >
      <TuneIcon fontSize='small' />
    </ToggleButton>
  );
}
