import { Box, Chip, Stack, StackProps, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { FC } from 'react';
import { AlertCard } from '../../../../../components/Card/AlertCard';
import {
  IScenarioTransactionDataModel,
  ScenarioTransactionType,
  ScenarioTransactionTypeToLabel,
} from '../../../../../data-models/scenario.data-model';
import { roundsByIdMapAtom } from '../../../../../services/state/AppConfigStateJ';
import { formatUSDShort } from '../../../../../util/formatters/NumericFormatters';
import { ChipStack, ScenarioTooltip } from '../../components/commonStyledComponents';

const TransactionRow: FC<StackProps> = ({ children, ...props }) => (
  <Stack
    direction={'row'}
    justifyContent={'space-between'}
    alignItems={'center'}
    minWidth='8rem'
    px={2}
    {...props}
  >
    {children}
  </Stack>
);

export const ScenarioTransactionsChips: FC<{ transactions: Partial<IScenarioTransactionDataModel>[] }> = ({
  transactions,
}) => {
  const roundTransactions = transactions.filter((t) => t.type === ScenarioTransactionType.round);
  const exitTransaction = transactions.find((t) => t.type === ScenarioTransactionType.exit);
  const partialExitTransaction = transactions.find((t) => t.type === ScenarioTransactionType.partialExit);
  return (
    <ChipStack>
      {roundTransactions.length > 0 && (
        <ScenarioTooltip title={<RoundTooltipContent transactions={roundTransactions} />}>
          <Chip key={'round'} label={ScenarioTransactionTypeToLabel[ScenarioTransactionType.round]} />
        </ScenarioTooltip>
      )}
      {exitTransaction && (
        <ScenarioTooltip title={<ExitTooltipContent transaction={exitTransaction} />}>
          <Chip key={'exit'} label={ScenarioTransactionTypeToLabel[ScenarioTransactionType.exit]} />
        </ScenarioTooltip>
      )}
      {partialExitTransaction && (
        <Chip
          key={'partialExit'}
          label={ScenarioTransactionTypeToLabel[ScenarioTransactionType.partialExit]}
        />
      )}
    </ChipStack>
  );
};

export const ExitTooltipContent: FC<{ transaction: Partial<IScenarioTransactionDataModel> }> = ({
  transaction,
}) => (
  <Box p='0.25rem'>
    <AlertCard style={{ padding: '0.5rem 0.25rem' }}>
      <TransactionRow minWidth='12rem'>
        <Typography variant='caption'>Valuation</Typography>
        <Typography variant='caption'>
          {transaction.exitValuation != null ? formatUSDShort(transaction.exitValuation) : ''}
        </Typography>
      </TransactionRow>
    </AlertCard>
  </Box>
);

export const RoundTooltipContent: FC<{ transactions: Partial<IScenarioTransactionDataModel>[] }> = ({
  transactions,
}) => {
  const roundsById = useAtomValue(roundsByIdMapAtom);
  return (
    <Box p='0.5rem'>
      <Typography>Round Size</Typography>
      <Stack display={'grid'} gap={'0.4rem'} mt='0.5rem' minWidth='14rem'>
        {transactions.map((t, i) => (
          <AlertCard key={t.id ?? i} style={{ padding: '0.5rem 0.25rem' }}>
            <TransactionRow minWidth='8rem'>
              <Typography variant='caption'>{roundsById.get(t.roundId ?? -1)?.displayName ?? ''}</Typography>
              <Typography variant='caption'>
                {t.roundSize != null ? formatUSDShort(t.roundSize) : ''}
              </Typography>
            </TransactionRow>
          </AlertCard>
        ))}
      </Stack>
    </Box>
  );
};
