import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ICellRendererParams } from 'ag-grid-community';
import { FC, useMemo } from 'react';
import { IPersonDataModel, IWorkExperienceDataModel } from '../../../../data-models/person.data-model';
import { FMT } from '../../../../util/formatter-service';
import { getAllExperience } from '../../utils/peopleHelpers';
import { WorkHistoryTable } from './WorkHistoryTable';

const Cell = styled('span')`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  & * {
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const WorkHistoryRenderer: FC<ICellRendererParams<IPersonDataModel, IWorkExperienceDataModel[]>> = ({
  data,
}) => {
  const experience = useMemo(() => data && getAllExperience(data), [data]);

  const formattedCompanies = useMemo(() => {
    if (!experience) return null;

    const companies = experience.reduce((acc, curr) => {
      if (curr.orgName) acc.add(curr.orgName);
      return acc;
    }, new Set<string>());

    return FMT.format('stringArray', Array.from(companies));
  }, [experience]);

  if (!experience || !formattedCompanies) return null;

  return (
    <Cell data-testid='work-history'>
      <span>{formattedCompanies}</span>
      <Tooltip
        componentsProps={{
          tooltip: { sx: { color: 'black', minWidth: 'fit-content' } },
        }}
        placement='top-end'
        title={<WorkHistoryTable workHistory={experience} />}
        leaveDelay={200} // allow time for user to hover over tooltip
      >
        <InfoOutlinedIcon color={'secondary'} fontSize='small' />
      </Tooltip>
    </Cell>
  );
};
