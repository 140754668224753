import { ColDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { useAtomValue } from 'jotai/index';
import { usersByIdMapAtom } from '../../../../../services/state/AppConfigStateJ';
import { FMT } from '../../../../../util/formatter-service';

export function useMetricsByUserColDefs() {
  const userByIdMap = useAtomValue(usersByIdMapAtom);

  const columnDefs: ColDef[] = [
    {
      headerName: 'User',
      field: 'user',
      suppressMovable: true,
      valueGetter: (params: ValueGetterParams) => {
        const user = userByIdMap.get(params.data.userId);
        return user?.name ?? '';
      },
      initialSort: 'asc',
    },
    {
      headerName: 'IRR',
      field: 'irr',
      valueFormatter: (params: ValueFormatterParams) => {
        return FMT.format('percent2dpAsIs', params.value);
      },
      suppressMovable: true,
    },
    {
      headerName: 'MOIC',
      field: 'moic',
      valueFormatter: (params: ValueFormatterParams) => {
        return FMT.format('multiplier', params.value);
      },
      suppressMovable: true,
    },
  ];

  return columnDefs;
}
