import { FC, useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Card, CardHeader, Stack, Typography, TypographyProps } from '@mui/material';
import { capitalize } from 'lodash-es';
import { BasicDialog } from '../../../../../components/Dialog/BasicDialog';
import { InfoBox } from '../../../../Mappings/forms/InfoBox';
import { companyState } from '../../../../../services/state/CompanyState';
import { StickyFormButtons } from '../../../../../components/Form/StickyFormButtons';
import { MUIAvatar } from '../../../../../components/Avatar/MUIAvatar';
import { MaxModalFormWidth, ModalFormWidth } from '../../../../../constants/styles';
import { KpiAction } from '../../../../../services/state/KPI/KPIRequestsState';
import { FMT, FormatterService } from '../../../../../util/formatter-service';
import { IKPIRequestDataModel } from '../../../../../data-models/kpi-requests.data-model';

const CardTypography: FC<TypographyProps> = ({ children, ...props }) => (
  <Typography variant='body2' color='text.primary' noWrap {...props}>
    {children}
  </Typography>
);

export interface ISendReminderModalProps {
  open: boolean;
  onClose: () => void;
  request: IKPIRequestDataModel;
  onConfirm: () => Promise<void>;
  action: KpiAction.sendReminder | KpiAction.resend;
}

export const SendReminderModal: FC<ISendReminderModalProps> = ({
  open,
  onClose,
  request,
  onConfirm,
  action,
}) => {
  const [loading, setLoading] = useState(false);
  const formatRespondents = FormatterService.get().getFormatterForId('userByEmail');
  const respondents = formatRespondents(request.respondent);
  const company = useRecoilValue(companyState(request.companyId));

  const title = action === KpiAction.resend ? 'Resend Request' : 'Send Additional Reminder?';
  const submitLabel = action === KpiAction.resend ? 'Resend' : 'Send';

  const _onConfirm = useCallback(async () => {
    setLoading(true);
    await onConfirm();
    setLoading(false);
    onClose();
  }, [onConfirm, onClose]);

  return (
    <BasicDialog title={title} open={open} onClose={onClose}>
      <Stack
        style={{ padding: '0.25rem 2.5rem 0', width: ModalFormWidth, maxWidth: MaxModalFormWidth }}
        gap='0.5rem'
      >
        <Card
          sx={{
            display: 'grid',
            gridAutoFlow: 'column',
            alignItems: 'center',
            columnGap: '1rem',
            boxShadow: '0px 4px 10px 0px rgba(44, 46, 49, 0.10)',
            pr: '1rem',
          }}
        >
          {company && (
            <CardHeader
              title={company.name}
              avatar={<MUIAvatar src={company.logoUrl} nameParts={company.name.split(' ')} />}
            />
          )}
          <>
            <CardTypography>{capitalize(request.frequency ?? '')}</CardTypography>
            <CardTypography>{FMT.format('kpiTemplateName', request.templateUuid)} </CardTypography>
            <CardTypography style={{ justifySelf: 'end' }}>{respondents}</CardTypography>
          </>
        </Card>
        {action === KpiAction.sendReminder ? (
          <InfoBox message={`Additional reminder will be sent to ${respondents} one time`} />
        ) : (
          <Stack height={'0.25rem'} />
        )}
      </Stack>
      <StickyFormButtons
        onSubmit={_onConfirm}
        onCancel={onClose}
        submitLabel={submitLabel}
        loading={loading}
      />
    </BasicDialog>
  );
};
