import { useCallback, useMemo, useState } from 'react';
import { Button, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { css } from '@emotion/react';
import { GetRowIdParams, IRowNode } from 'ag-grid-community';
import { useSetAtom } from 'jotai';
import { Fund } from '../../../schemas/Fund.schema';
import { FundReserves } from '../../../schemas/FundReserves.schema';
import { FadeInGridWrapper } from '../../../components/grid-renderers/FadeInGridWrapper';
import { AgTable } from '../../../components/AgTable/AgTable';
import { SearchInput } from '../../../CoreComponents/base/SearchInput';
import { CompanyType } from '../../../data-models/company.data-model';
import { colors } from '../../../theme/colors';
import { ReservesAction, showReservesFormState } from '../state/FPState';
import {
  defaultReservesColDefs,
  useDefaultReservesColGroupDef,
  useReservesColDefs,
} from './reservesGridColDefs';
import {
  useReservesGridData,
  reservesAggFuncs,
  reservesRowClassRules,
  ReservesRowData,
} from './reservesGridData';

const ReservesTableContainer = css`
  height: 85vh;
  & .currentInvestment {
    opacity: 0.7;
  }
  & .ag-row:has(.hypothetical) {
    box-shadow: inset 0.2rem 0 0 ${colors.secondary[40]};
    & .MuiAvatar-root {
      border: 1px solid ${colors.primary[0]};
    }
  }
`;

type InvestmentFilterOption = 'Future Investments' | 'Current Investments';

interface IFundReservesGridProps {
  fund: Fund;
  reserves: FundReserves;
}
export function FundReservesGrid({ fund, reserves }: IFundReservesGridProps) {
  const colDefs = useReservesColDefs();
  const defaultReservesColGroupDef = useDefaultReservesColGroupDef();
  const rowData = useReservesGridData(reserves);
  const [quickFilterText, setQuickFilterText] = useState('');
  const [investmentFilter, setInvestmentFilter] = useState<InvestmentFilterOption | null>(null);

  const getRowId = useCallback((params: GetRowIdParams) => {
    return params?.data?.uuid;
  }, []);

  const context = useMemo(() => {
    return { fund };
  }, [fund]);

  const doesExternalFilterPass = useCallback(
    (node: IRowNode<ReservesRowData>) => {
      if (investmentFilter === 'Future Investments') {
        return node.data?.company?.companyType !== CompanyType.portfolio;
      } else if (investmentFilter === 'Current Investments') {
        return node.data?.company?.companyType === CompanyType.portfolio;
      } else {
        return true;
      }
    },
    [investmentFilter]
  );
  const isExternalFilterPresent = useCallback(() => investmentFilter !== null, [investmentFilter]);

  return (
    <>
      <ReservesGridFiltersAndActions
        onSearch={setQuickFilterText}
        investmentFilter={investmentFilter}
        onInvestmentFilterChange={setInvestmentFilter}
      />
      <div css={ReservesTableContainer}>
        <FadeInGridWrapper key={investmentFilter}>
          <AgTable
            columnDefs={colDefs}
            rowData={rowData}
            suppressGroupChangesColumnVisibility={'suppressHideOnGroup'}
            grandTotalRow='top'
            context={context}
            suppressAggFuncInHeader
            defaultColDef={defaultReservesColDefs}
            aggFuncs={reservesAggFuncs}
            rowClassRules={reservesRowClassRules}
            rowGroupPanelShow='never'
            autoGroupColumnDef={defaultReservesColGroupDef}
            getRowId={getRowId}
            quickFilterText={quickFilterText}
            doesExternalFilterPass={doesExternalFilterPass}
            isExternalFilterPresent={isExternalFilterPresent}
            suppressScrollOnNewData
          />
        </FadeInGridWrapper>
      </div>
    </>
  );
}

interface IReservesGridFiltersProps {
  onSearch: (value: string) => void;
  investmentFilter?: InvestmentFilterOption | null;
  onInvestmentFilterChange: (value: InvestmentFilterOption) => void;
}
function ReservesGridFiltersAndActions({
  onSearch,
  investmentFilter,
  onInvestmentFilterChange,
}: IReservesGridFiltersProps) {
  return (
    <Stack
      display={'grid'}
      gridTemplateColumns={'1fr auto'}
      justifyContent={'space-between'}
      alignItems={'center'}
      mb='0.5rem'
    >
      <SearchInput
        style={{ maxWidth: '22rem' }}
        onChange={onSearch}
        placeholder='Search by company name or website'
      />
      <Stack direction={'row'} gap={'0.5rem'} alignItems={'center'}>
        <AddInvestmentButton />
        <ToggleButtonGroup
          color='secondary'
          value={investmentFilter}
          exclusive
          onChange={(e, newValue: InvestmentFilterOption) => {
            onInvestmentFilterChange(newValue);
          }}
          aria-label='Select investment'
        >
          <ToggleButton value={'Future Investments'}>Future Investments</ToggleButton>
          <ToggleButton value={'Current Investments'}>Current Investments</ToggleButton>
        </ToggleButtonGroup>
      </Stack>
    </Stack>
  );
}

export function AddInvestmentButton() {
  const setShowReservesForm = useSetAtom(showReservesFormState);
  return (
    <Button
      variant='contained'
      color='secondary'
      size='medium'
      startIcon={<AddIcon />}
      onClick={() => setShowReservesForm(ReservesAction.AddInvestment)}
    >
      Add
    </Button>
  );
}
