import { useSearchParams } from 'react-router';
import { useSetRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import { selectedCompanyIdStateFinance } from '../../../services/state/PortCosState';
import { SelectedCompanyParam } from '../finance-constants';

export function useSyncSelectedCompanyFromPath() {
  const setSelectedCompanyId = useSetRecoilState(selectedCompanyIdStateFinance);
  const [initialized, setInitialized] = useState(false);
  const [params] = useSearchParams();
  const companyId = params.get(SelectedCompanyParam);

  useEffect(() => {
    if (companyId) {
      setSelectedCompanyId(parseInt(companyId));
    } else {
      setSelectedCompanyId(null);
    }
    setInitialized(true);
  }, [companyId, setSelectedCompanyId]);

  return initialized;
}
