import { RendererType } from '../../../data-models/field.data-model';
import { ITextMeta } from '../../../data-models/field3.data-model';
import { createFormField } from '../../../view-models/form.view-model';

export function getKPIGroupFields() {
  return [
    createFormField<ITextMeta>({
      key: 'name',
      label: 'Name',
      autoFocus: true,
      renderer: RendererType.text,
      rendererMeta: {
        maxLength: -1,
      },
    }),
  ];
}
