import { iconSetQuartzRegular, themeQuartz } from 'ag-grid-community';
import { colors } from './colors';

export const agBorderColor = colors.neutral[20];

// to use myTheme in an application, pass it to the theme grid option
export const agForesightTheme = themeQuartz
  .withParams({
    accentColor: colors.secondary[50],
    backgroundColor: colors.primary[0],
    borderColor: agBorderColor,
    borderRadius: '4px',
    cellWidgetSpacing: '12px',
    checkboxBorderRadius: '2px',
    checkboxIndeterminateBackgroundColor: '#B2B2BD',
    checkboxIndeterminateBorderColor: '#B2B2BD',
    columnBorder: false,
    columnDropCellBackgroundColor: '#E4E6FF',
    columnDropCellBorder: false,
    dropdownShadow: '#00000049 0px 4px 10px 0px',
    foregroundColor: 'rgba(0, 0, 0, 0.87)',
    headerBackgroundColor: '#F8F8FB',
    headerCellBackgroundTransitionDuration: '0.1s',
    headerCellHoverBackgroundColor: '#E4E4EB3D',
    headerColumnBorder: true,
    headerColumnBorderHeight: '100%',
    headerColumnResizeHandleColor: '#E4E8F200',
    headerFontSize: 14,
    headerFontWeight: 600,
    headerRowBorder: true,
    headerTextColor: '#090C0Eee',
    headerVerticalPaddingScale: 1,
    iconButtonHoverBackgroundColor: '#A9A9AC29',
    iconButtonHoverColor: '#B1B1BD38',
    iconSize: '16px',
    inputBorderRadius: '0px',
    inputFocusBorder: false,
    menuBackgroundColor: '#FBFCFD',
    menuBorder: false,
    menuShadow: '#00000049 0px 4px 10px 0px',
    oddRowBackgroundColor: '#FBFCFD',
    rowBorder: true,
    sidePanelBorder: true,
    spacing: '8px',
    toolPanelSeparatorBorder: true,
    wrapperBorder: true,
    wrapperBorderRadius: '4px',
  })
  .withPart(iconSetQuartzRegular);
