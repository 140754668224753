import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import {
  IconButton,
  Menu,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import {
  isDrawerOpenState,
  selectedActivityTypeState,
  selectedCommentaryState,
  selectedNoteState,
} from '../../state/UIState';
import { MaggieFeatureFlags } from '../../../../util/feature-flags';
import { AppName } from '../../../../data-models/integrations-app.data-model';
import { integrationsState } from '../../../Integrations/state/IntegrationsState';
import { createLexicalNoteTemplate } from '../../utils/noteUtils';
import { NewCommentaryId } from '../../../../schemas/Commentary.schema';
import { kpiRequestsByCompanyIdState } from '../../../../services/state/KPI/KPIRequestsState';
import { PermissionService } from '../../../../services/PermissionService';
import { PermissionKey } from '../../../../services/PermissionAndRolesKeys';
import { ActivityTabs } from './ActivityDrawer2';
import { useGetTodaysCommentary } from './Commentaries/useCommentaryActions';

export interface IActivityDrawerHeaderProps {
  companyId: number;
}

const DrawerWrapper = styled('div')`
  display: grid;
  grid-template-rows: min-content min-content;
  row-gap: 1rem;
`;

const TitleAndButtons = styled('div')`
  align-items: center;
  background-color: ${(props) => props.theme.colors.primary['10']};
  column-gap: 0.25rem;
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  padding: 0.25rem 1rem;
`;

const TogglesWrapper = styled('div')`
  padding: 0 1rem;
  width: 100%;
`;

export function ActivityDrawerHeader2(props: IActivityDrawerHeaderProps) {
  const [createMenuOpen, setCreateMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { showNotesInActivityDrawer, showCommentary } = useFlags<MaggieFeatureFlags>();
  const { companyId } = props;
  const { primary } = useTheme().colors;
  const setSelectedNote = useSetRecoilState(selectedNoteState);
  const setSelectedCommentary = useSetRecoilState(selectedCommentaryState);
  const setDrawerOpen = useSetRecoilState(isDrawerOpenState);
  const [selectedActivityType, setSelectedActivityType] = useRecoilState(selectedActivityTypeState);
  const integrations = useAppIntegrations();
  const todaysCommentary = useGetTodaysCommentary(companyId);
  const showResponses = useShouldShowResponses(companyId);

  const activities = useMemo(() => {
    const baseActivities: ActivityTabs[] = [];

    if (showNotesInActivityDrawer) {
      baseActivities.push('Notes');
    }

    return [...baseActivities, ...integrations, ...(showResponses ? ['KPI Responses' as const] : [])];
  }, [integrations, showResponses, showNotesInActivityDrawer]);

  const handleChange = (event: React.MouseEvent, value: ActivityTabs) => {
    setSelectedActivityType(value);
  };

  function onClickAddButton(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
    setCreateMenuOpen(true);
  }

  function onCloseAddMenu() {
    setCreateMenuOpen(false);
  }

  function onCloseClick() {
    setDrawerOpen(false);
  }

  function onAddNoteClick() {
    setSelectedNote(createLexicalNoteTemplate(companyId));
  }

  function onAddCommentaryClick() {
    setSelectedCommentary(todaysCommentary);
  }

  useEffect(() => {
    // also reset activity when company changes
    if (selectedActivityType == undefined || !activities.includes(selectedActivityType)) {
      setSelectedActivityType(activities[0]);
    }
  }, [activities, selectedActivityType, setSelectedActivityType, showResponses]);

  return (
    <DrawerWrapper>
      <TitleAndButtons>
        <IconButton onClick={onCloseClick}>
          <KeyboardDoubleArrowRightIcon />
        </IconButton>
        <Typography variant={'body1'} color={primary['100']}>
          Activity
        </Typography>
        {selectedActivityType === 'Notes' && (
          <>
            <IconButton color={'secondary'} onClick={onClickAddButton}>
              <AddIcon />
            </IconButton>
            <Menu
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={createMenuOpen}
              onClose={onCloseAddMenu}
            >
              <MenuItem key={'notes'} onClick={onAddNoteClick}>
                Create note
              </MenuItem>
              {showCommentary && (
                <MenuItem key={'commentary'} onClick={onAddCommentaryClick}>
                  {todaysCommentary.id === NewCommentaryId
                    ? `Create performance commentary`
                    : `Edit today's performance commentary`}
                </MenuItem>
              )}
            </Menu>
          </>
        )}
      </TitleAndButtons>
      <TogglesWrapper>
        <ToggleButtonGroup
          color='secondary'
          onChange={handleChange}
          value={selectedActivityType}
          exclusive
          aria-label='Activity type'
          fullWidth
        >
          {activities.map((type) => (
            <ToggleButton key={type} value={type}>
              {type}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </TogglesWrapper>
    </DrawerWrapper>
  );
}

function useAppIntegrations() {
  const { showDropBoxEmbedder, showSlackInActivityDrawer } = useFlags<MaggieFeatureFlags>();
  const integrations = useRecoilValueLoadable(integrationsState);

  return (integrations.valueMaybe() ?? [])
    .filter((integration) => {
      return (
        (integration.appName === AppName.Dropbox && showDropBoxEmbedder && integration.isConnected) ||
        (integration.appName === AppName.GoogleDrive && integration.isConnected) ||
        (integration.appName === AppName.Slack && showSlackInActivityDrawer && integration.isConnected)
      );
    })
    .map((integration) => integration.appName) as ActivityTabs[];
}

function useShouldShowResponses(companyId: number) {
  const responses = useRecoilValue(kpiRequestsByCompanyIdState(companyId));
  const { showKpiResponsesInDrawer } = useFlags<MaggieFeatureFlags>();
  return (
    PermissionService.get().hasPermission(PermissionKey.canReadDataCollection) &&
    showKpiResponsesInDrawer &&
    responses.length > 0
  );
}
