import { FC } from 'react';
import { Outlet, useLocation } from 'react-router';
import { styled } from '@mui/material/styles';
import { useRecoilValueLoadable } from 'recoil';
import { Loader } from '../../components/Loader/Loader';
import { GenericFallbacksWrapper } from '../../components/Fallback/GenericFallbacksWrapper';
import { ROUTES } from '../../constants/RouteNameMapping';
import { AddTransactionOrInvestment } from './Forms/AddTransactionOrInvestment';
import { PortCoFilter } from './PortCoFilter/PortCoFilter';
import { useSyncSelectedCompanyFromPath } from './hooks/useSyncSelectedCompanyFromPath';
import { FinanceTabs } from './FinanceTabs';
import { usePortCos } from './hooks/usePortCos';
import { FinanceTitle } from './FinanceTitle';
import { allInvestmentRoundsState } from './state/FinanceState';

const Header = styled('section')`
  width: 100%;
  padding: 1rem 1.5rem;
`;

const HeaderTitleAndButton = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Finance2: FC = () => {
  const { loading } = usePortCos();
  const allFinanceInvestments = useRecoilValueLoadable(allInvestmentRoundsState);

  const initialized = useSyncSelectedCompanyFromPath();
  const { pathname } = useLocation();

  // hide filter in nested details routes
  const showCompanyFilter =
    !pathname.includes(ROUTES.FINANCE_TRANSACTION_HISTORY) && !pathname.includes(ROUTES.FINANCE_RECON);
  if (loading || !initialized || allFinanceInvestments.state === 'loading') return <Loader />;

  return (
    <Header>
      <HeaderTitleAndButton>
        <FinanceTitle />
        <GenericFallbacksWrapper>
          <AddTransactionOrInvestment />
        </GenericFallbacksWrapper>
      </HeaderTitleAndButton>
      {showCompanyFilter && <PortCoFilter />}
      <FinanceTabs />
      <GenericFallbacksWrapper>
        <Outlet />
      </GenericFallbacksWrapper>
    </Header>
  );
};
