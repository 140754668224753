import { css } from '@emotion/react';
import { List, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useMemo } from 'react';
import { BasicDialog } from '../../../../../components/Dialog/BasicDialog';
import { useTemplateAssignmentErrors } from '../hooks/useTemplateAssignmentErrors';
import { kpiTemplateAssignmentsByIdState } from '../../../../../services/state/KPI/KPICompaniesState';
import { FormFieldsContainer } from '../../../../../components/Form/FormComponents';
import { colors } from '../../../../../theme/colors';
import { TemplateAssignmentSummary } from './AssignTemplateStep3';

const ErrorList = css`
  display: grid;
  gap: 0.5rem;
  list-style: disc;
  list-style-position: inside;
  & li {
    color: ${colors.critical[50]};
  }
  padding-bottom: 2rem;
`;

export function TemplateAssignmentErrorsModal() {
  const { errors, onClose } = useTemplateAssignmentErrors();
  const assignmentsById = useRecoilValue(kpiTemplateAssignmentsByIdState);

  const errorList = useMemo(() => {
    if (!errors?.length) return null;
    return errors.map((error, i) => {
      const assignment = assignmentsById.get(error.existingId ?? -1);

      return (
        <li key={i}>
          <Typography variant='caption' color='error'>
            {`${error.name}: ${error.message}`}
          </Typography>
          {assignment && (
            <TemplateAssignmentSummary
              id={assignment.companyId}
              templateUuid={assignment.templateUuid}
              frequency={assignment.frequency}
              granularity={assignment.granularity}
              reminderThreshold={assignment.reminderThreshold}
            />
          )}
        </li>
      );
    });
  }, [errors, assignmentsById]);

  if (!errors?.length) return null;
  return (
    <BasicDialog open title='Errors' onClose={onClose} lightDismiss>
      <FormFieldsContainer>
        <List css={ErrorList}>{errorList}</List>
      </FormFieldsContainer>
    </BasicDialog>
  );
}
