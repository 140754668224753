import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router';
import {
  KpiCompaniesAction,
  allCompaniesActionState,
  selectedAssignmentIdState,
  selectedKpiCompaniesStateDC,
} from '../AllCompaniesState';
import { ROUTES } from '../../../../../constants/RouteNameMapping';
import { ICompanyDataModel } from '../../../../../data-models/company.data-model';
import { IAssignedRowData } from '../AssignedCompaniesData';
import { useResetTemplateAssignmentFormState } from './AssignTemplateFormState';

export function useAllKpiCompaniesActions() {
  const setAction = useSetRecoilState(allCompaniesActionState);
  const setSelectedCompanies = useSetRecoilState(selectedKpiCompaniesStateDC);
  const setSelectedAssignmentId = useSetRecoilState(selectedAssignmentIdState);
  const resetForm = useResetTemplateAssignmentFormState();
  const selectedAction = useRecoilValue(allCompaniesActionState);
  const navigate = useNavigate();

  const handleReview = useCallback(
    (data: ICompanyDataModel) => {
      navigate(`/${ROUTES.KPI}/${ROUTES.COMPANIES}/${ROUTES.ASSIGNED_COMPANIES}`, {
        state: { companyName: data?.name },
      });
    },
    [navigate]
  );

  const resetUIState = useCallback(() => {
    setAction(null);
    setSelectedCompanies([]);
    setSelectedAssignmentId(null);
    resetForm();
  }, [setAction, setSelectedCompanies, setSelectedAssignmentId, resetForm]);

  const handleSelectAction = useCallback(
    (data: ICompanyDataModel[], action: KpiCompaniesAction) => {
      setSelectedCompanies(data);
      setAction(action);
    },
    [setSelectedCompanies, setAction]
  );

  const handleCreateRequest = useCallback(
    (data: IAssignedRowData) => {
      handleSelectAction([data.company], 'createRequest');
      setSelectedAssignmentId(data.id);
    },
    [handleSelectAction, setSelectedAssignmentId]
  );

  const handleSelectDeleteAssignment = useCallback(
    (id: number) => {
      setSelectedAssignmentId(id);
      setAction('deleteAssignment');
    },
    [setAction, setSelectedAssignmentId]
  );

  return {
    selectedAction,
    handleSelectAction,
    resetUIState,
    handleCreateRequest,
    handleReview,
    handleSelectDeleteAssignment,
  };
}
