import { FC, useCallback, useMemo, useState } from 'react';
import { Button, Dialog, IconButton, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import { CompetitorsSelector } from '../../CompanyComparison/components/StepExpandables/CompetitorsSelector';
import CiIcon from '../../../assets/icons/ci.svg?react';
import { CI_MAX_COMPETITORS } from '../../CompanyComparison/constants/maxCompetitors';
import { CompanySearchResponseWithRealId } from '../../../schemas/CompanySearchResponse.schema';

const Container = styled('div')`
  padding: 1rem 1rem 1rem 1.5rem;
  width: 48rem;
`;

const ModalHeader = styled('header')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const Heading = styled('h3')`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const StyledSection = styled('section')`
  margin: 0.5rem 0;
`;

const InfoSection = styled('section')`
  margin: 1.5rem 0 1rem;
  padding: 1rem 1.5rem;
  background: ${({ theme }) => theme.colors.primary[5]};
  line-height: 1.25rem;
`;

const ButtonContainer = styled('div')`
  padding: 1rem 0;
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  align-items: center;
`;

interface AddCompetitorsDialogProps {
  open: boolean;
  onClose: () => void;
  primaryCompanyId: number;
  disabledMsg?: string;
}

export const AddCompetitorsDialog: FC<AddCompetitorsDialogProps> = ({ open, onClose, primaryCompanyId }) => {
  const { colors } = useTheme();
  const navigate = useNavigate();
  const [competitorsIds, setCompetitorsIds] = useState<number[]>([]);

  const disabledMsg = useMemo(
    () =>
      competitorsIds.length && competitorsIds.length > CI_MAX_COMPETITORS
        ? `You can select at most ${CI_MAX_COMPETITORS} competitors`
        : '',
    [competitorsIds]
  );

  const handleChange = useCallback((selected: CompanySearchResponseWithRealId[]) => {
    setCompetitorsIds(selected.map(({ id }) => id));
  }, []);

  const handleLaunch = useCallback(() => {
    onClose();
    const competitors = competitorsIds?.join(',');
    // prevents dialog unmount lag when navigating to the comparison page
    setTimeout(() =>
      navigate(`/company-comparison/ci-report?primary=${primaryCompanyId}&competitors=${competitors}`)
    );
  }, [competitorsIds, navigate, onClose, primaryCompanyId]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={false} scroll={'body'}>
      <Container>
        <ModalHeader>
          <Heading>
            <CiIcon />
            <Typography>Competitive Intelligence Analysis</Typography>
          </Heading>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </ModalHeader>
        <StyledSection>
          <Typography variant='h5' component='h2'>
            Add Competitors
          </Typography>
        </StyledSection>
        <StyledSection>
          <Typography component='div' variant='subtitle2' color={colors.neutral[60]}>
            <p>Database contains 100,000 companies.</p>
            <p>To start the comparison, please use search to select competitors.</p>
          </Typography>
        </StyledSection>
        <InfoSection>
          <Typography variant='subtitle2'>
            This Analysis allows for the comparison of multiple companies across a broad data library of 3rd
            party data and metrics. By selecting from a variety of individual data points from the data
            library or grouped into packages, you can analyze company growth and performance indicators.
          </Typography>
          <StyledSection>
            <Typography variant='caption' component='p' color={colors.primary[60]}>
              {`This analysis can only be run on a maximum of ${CI_MAX_COMPETITORS} companies.`}
            </Typography>
          </StyledSection>
        </InfoSection>
        <CompetitorsSelector onChange={handleChange} disabledMsg={disabledMsg} hideLoaderOnCompanyCreation />
        <ButtonContainer>
          <Button
            variant='contained'
            color='secondary'
            sx={{ paddingLeft: '2rem', paddingRight: '2rem' }}
            onClick={handleLaunch}
            disabled={!competitorsIds?.length}
          >
            Launch
          </Button>
          <Button variant='outlined' color='secondary' onClick={onClose}>
            Cancel
          </Button>
        </ButtonContainer>
      </Container>
    </Dialog>
  );
};
