import { IconButton, Pagination, Typography } from '@mui/material';
import { PropsWithChildren, CSSProperties } from 'react';
import { styled } from '@mui/system';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from 'react-router';
import { cardStyles } from '../../../theme/component-styles';
import { GenericFallbacksWrapper } from '../../../components/Fallback/GenericFallbacksWrapper';

export const PanelContainer = styled('div')`
  display: grid;
  gap: 1rem;
  grid-template-rows: min-content 1fr;
  height: 100%;
`;

export const PanelHeader = styled('div')`
  align-items: center;
  display: grid;
  grid-template-areas: 'title chevron spacer pages';
  grid-template-columns: max-content min-content 1fr max-content;
  min-height: 32px;
`;

export const PaneContents = styled('div')<{ showOverflow?: boolean }>`
  // overflow-x visible + overflow-y auto is not possible see:
  // https://stackoverflow.com/questions/6421966/css-overflow-x-visible-and-overflow-y-hidden-causing-scrollbar-issue/6433475#6433475
  // therefore use the props to suppress scroll as we need input boxes to overflow on X for details & digest panels...
  overflow-y: ${(props) => (props.showOverflow ? '' : 'auto')};
`;

interface IShowMore {
  url: string;
  title?: string;
}

export interface IPanelProps extends PropsWithChildren {
  title: string;
  paginationConfig?: {
    pageCount: number;
    onChange: (pageId: number) => void;
  };
  showMore?: IShowMore;
  showContentOverflow?: boolean;
  style?: CSSProperties;
  defaultPage?: number;
}
export function Panel(props: IPanelProps) {
  const { children, paginationConfig, showContentOverflow, title, style, showMore, defaultPage } = props;

  const headerElems = [<PanelTitle key={'title'} title={title} />];
  if (showMore) {
    headerElems.push(<ShowMoreButton key={'show-more-button'} url={showMore.url} title={showMore.title} />);
  }

  headerElems.push(<div key={'spacer'} style={{ gridArea: 'spacer' }} />);

  if (paginationConfig && paginationConfig.pageCount > 1) {
    headerElems.push(
      <PanelPagination
        key={'pagination'}
        onChange={paginationConfig.onChange}
        pageCount={paginationConfig.pageCount}
        defaultPage={defaultPage}
      />
    );
  }

  return (
    <GenericFallbacksWrapper>
      <PanelContainer style={{ ...cardStyles, ...(style ?? {}) }}>
        <PanelHeader>{headerElems}</PanelHeader>
        <PaneContents showOverflow={showContentOverflow}>{children}</PaneContents>
      </PanelContainer>
    </GenericFallbacksWrapper>
  );
}

export interface IPanelPaginationProps {
  pageCount: number;
  onChange: (pageId: number) => void;
  defaultPage?: number;
}
export function PanelPagination(props: IPanelPaginationProps) {
  const { pageCount, onChange, defaultPage } = props;
  const hideButtons = pageCount < 5;

  if (pageCount > 1) {
    return (
      <Pagination
        color='primary'
        count={pageCount}
        hidePrevButton={hideButtons}
        hideNextButton={hideButtons}
        key={'pagination'}
        onChange={(_: React.ChangeEvent<unknown>, pageNo: number) => {
          onChange(pageNo);
        }}
        shape='rounded'
        siblingCount={0}
        style={{ gridArea: 'pages' }}
        defaultPage={defaultPage}
      />
    );
  }
  return null;
}

export function ShowMoreButton({ url, title }: IShowMore) {
  const navigate = useNavigate();
  return (
    <IconButton
      aria-label={'view more'}
      onClick={() => navigate(url)}
      style={{ alignSelf: 'end', marginLeft: '.25rem' }}
      title={title}
    >
      <ChevronRightIcon />
    </IconButton>
  );
}

export function PanelTitle({ title }: { title: string }) {
  return <Typography>{title}</Typography>;
}
