import { atom, useAtom } from 'jotai';
import { useSetRecoilState } from 'recoil';
import { IListDataModel } from '../../../../data-models/list.data-model';
import { activeCompanyListIdState } from '../../state/ListsState';

export const ListForm = {
  CreateList: 'CreateList',
  EditList: 'EditList',
} as const;
export type ListForm = (typeof ListForm)[keyof typeof ListForm];

const showListFormState = atom<ListForm | null>(null);

const selectedListState = atom<IListDataModel | null>(null);

export function useListFormState() {
  const [showListForm, setShowListForm] = useAtom(showListFormState);
  const [selectedList, setSelectedList] = useAtom(selectedListState);
  const setActiveListId = useSetRecoilState(activeCompanyListIdState);

  return {
    showListForm,
    selectedList,
    setShowListForm,
    setSelectedList,
    setActiveListId,
  };
}
